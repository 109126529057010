/* eslint-disable simple-import-sort/imports */

import rangy from 'rangy';

import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-textrange';

import { Rangy, RangyDomPosition } from '../types/rangy';

export const getDomPosition = (...args: [RangyDomPosition['node'], RangyDomPosition['offset']]): RangyDomPosition => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new (rangy as any).dom.DomPosition(...args) as RangyDomPosition;
};

export default rangy as unknown as Rangy;
