import { type DocumentWithUrl } from '../types';
import getReaderAppBaseUrl from '../utils/getReaderAppBaseUrl';
import { createToast } from './toasts.platform';
import { copyTextToClipboard } from './utils/copyTextToClipboard';

export default (documentId: DocumentWithUrl['id']): void => {
  // TODO: have platform-specific code to make this also work on mobile...
  copyTextToClipboard(`${getReaderAppBaseUrl()}/read/${documentId}`);
  createToast({ content: 'Copied Reader URL to clipboard', category: 'success' });
};
