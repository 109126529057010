import React from 'react';

import Icon from './Icon';

export default function FeedIcon({ className = '', text = 'Feed' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.25 4C3.25 3.58579 3.58579 3.25 4 3.25C4.01625 3.25 4.03249 3.25003 4.04873 3.25009C7.40977 3.26293 10.6402 4.60306 13.0186 6.98149C15.4086 9.37142 16.75 12.6215 16.75 16C16.75 16.4142 16.4142 16.75 16 16.75C15.5858 16.75 15.25 16.4142 15.25 16C15.25 15.9446 15.2496 15.8892 15.2488 15.8338C15.2054 12.9138 14.0211 10.121 11.958 8.04215C9.83966 5.93988 6.98015 4.75 4 4.75C3.58579 4.75 3.25 4.41421 3.25 4ZM3.25 7.4273C3.25 7.01309 3.58579 6.6773 4 6.6773C4.03139 6.6773 4.06277 6.67746 4.09413 6.67778C6.53003 6.70239 8.86889 7.68139 10.5937 9.40625C12.3408 11.1533 13.3227 13.5303 13.3227 16C13.3227 16.4142 12.9869 16.75 12.5727 16.75C12.1585 16.75 11.8227 16.4142 11.8227 16C11.8227 15.9499 11.8222 15.8998 11.8213 15.8498C11.7823 13.8328 10.9598 11.9048 9.53308 10.4669C8.05958 9.00478 6.07129 8.1773 4 8.1773C3.58579 8.1773 3.25 7.84152 3.25 7.4273ZM3.25015 10.8546C3.25015 10.4404 3.58594 10.1046 4.00015 10.1046C5.56367 10.1046 7.06315 10.7257 8.16872 11.8313C8.17411 11.8367 8.17949 11.8421 8.18486 11.8475C9.28033 12.9515 9.89539 14.444 9.89539 15.9998C9.89539 16.4141 9.55961 16.7498 9.14539 16.7498C8.73118 16.7498 8.39539 16.4141 8.39539 15.9998C8.39539 14.8364 7.93072 13.7146 7.10806 12.8919C6.2854 12.0693 5.16357 11.6046 4.00015 11.6046C3.58594 11.6046 3.25015 11.2688 3.25015 10.8546Z" />
    </svg>
  </Icon>;
}
