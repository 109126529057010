import React, { useEffect, useState } from 'react';

import getNextElementWithinContainer from '../../../shared/foreground/utils/getNextNodeWithinContainer';
import { EpubToc } from '../../../shared/types';
import { useIsLeftSidebarHidden } from '../hooks/hooks';
import styles from './TableOfContents.module.css';

const isHeading = (el: Element): boolean => el.hasAttribute('data-rw-epub-toc');

// Takes an element and generates a table of contents. It will update the content if needed
export default function EpubTableOfContents({
  contentRoot,
  tocItems,
  currentFocusedElement = null,
}: { contentRoot: HTMLElement; tocItems: EpubToc[]; currentFocusedElement: HTMLElement | null; }): JSX.Element | null {
  const [activeId, setActiveId] = useState('');
  const leftSidebarHidden = useIsLeftSidebarHidden();

  const headings = Array.from(contentRoot.querySelectorAll('[data-rw-epub-toc]'));
  useEffect(() => {
    if (!currentFocusedElement || !contentRoot || !headings.length) {
      return;
    }

    if (isHeading(currentFocusedElement)) {
      setActiveId(currentFocusedElement?.getAttribute('data-rw-epub-toc') || '');
      return;
    }

    const closestUpperHeading = getNextElementWithinContainer({
      container: contentRoot,
      direction: 'previous',
      element: currentFocusedElement,
      matcher: isHeading,
      checkParents: true,
    });

    const newClosestUpperHeadingId = closestUpperHeading?.getAttribute('data-rw-epub-toc') ?? headings[0].getAttribute('data-rw-epub-toc');

    setActiveId(newClosestUpperHeadingId || '');
  }, [currentFocusedElement, contentRoot, activeId, headings]);

  if (!headings.length) {
    return null;
  }

  const items = tocItems.map((tocItem: EpubToc): JSX.Element => {
    const itemId = tocItem.id;
    const name = tocItem.title;
    const isActive = activeId === itemId;

    const className = `${styles.listItemWrapper} ${isActive ? styles.active : ''} ${styles[`listItemLevel${tocItem.level}`]}`;
    const listItemClassName = leftSidebarHidden ? styles.hidden : '';
    const onClick = () => {
      const headingElement = contentRoot.querySelector(`#document-reader-root [data-rw-epub-toc="${tocItem.id}"]`);
      headingElement?.scrollIntoView();
      setActiveId(itemId);
    };
    return <button type="button" onClick={onClick} className={className} key={itemId}>
      <div className={styles.bar} />
      <li className={listItemClassName}>
        {name}
      </li>
    </button>;
  });

  return <div className={`${styles.root}`}>
    <h4 id="toc-title" className={`${styles.title} ${leftSidebarHidden ? styles.hidden : ''}`}>Contents</h4>
    <ol>
      {items}
    </ol>
  </div>;
}
