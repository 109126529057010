import React from 'react';

import Icon from './Icon';

export default function BooksIconHeader({ className = '', text = 'Books' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.25 6.1129L8.96046 5.8314C8.9559 5.82717 8.94458 5.81681 8.92763 5.80192C8.89364 5.77207 8.83572 5.72287 8.75565 5.66059C8.59464 5.53536 8.34942 5.36145 8.03404 5.18624C7.39754 4.83263 6.5239 4.5 5.5 4.5C4.17067 4.5 3.10021 5.06272 2.5 5.47413V14.3412C3.325 14.0096 4.34079 13.75 5.5 13.75C7.03792 13.75 8.32349 14.2066 9.25 14.6847V6.1129ZM9.25 16.4209C8.47952 15.9114 7.15982 15.25 5.5 15.25C4.36538 15.25 3.38969 15.559 2.63973 15.9174C1.97494 16.235 1 15.7643 1 15.0276V5.17249C1 4.90239 1.10927 4.64321 1.32244 4.47734C1.95466 3.98541 3.47394 3 5.5 3C8.2 3 10 4.75 10 4.75C10 4.75 11.8 3 14.5 3C16.5261 3 18.0453 3.98541 18.6776 4.47734C18.8907 4.64321 19 4.90239 19 5.17249V15.0276C19 15.7643 18.0251 16.235 17.3603 15.9174C16.6103 15.559 15.6346 15.25 14.5 15.25C12.8402 15.25 11.5205 15.9114 10.75 16.4209C10.2672 16.7403 10 17 10 17C10 17 9.73285 16.7403 9.25 16.4209ZM10.75 14.6847C11.6765 14.2066 12.9621 13.75 14.5 13.75C15.6592 13.75 16.675 14.0096 17.5 14.3412V5.47413C16.8998 5.06272 15.8293 4.5 14.5 4.5C13.4761 4.5 12.6025 4.83263 11.966 5.18624C11.6506 5.36145 11.4054 5.53536 11.2443 5.66059C11.1643 5.72287 11.1064 5.77207 11.0724 5.80192C11.0554 5.81681 11.0445 5.82676 11.04 5.83099L10.75 6.1129V14.6847Z" fill="black" />
    </svg>
  </Icon>;
}
