import React from 'react';

import Icon from './Icon';

export default function AppearanceIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="AppearanceIcon">
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.20001 5.25C7.50669 5.25 7.78247 5.43671 7.89637 5.72146L12.6964 17.7215C12.8502 18.106 12.6631 18.5425 12.2786 18.6964C11.894 18.8502 11.4575 18.6631 11.3037 18.2785L9.81224 14.55H4.58779L3.09637 18.2785C2.94253 18.6631 2.50605 18.8502 2.12147 18.6964C1.73688 18.5425 1.54982 18.106 1.70365 17.7215L6.50365 5.72146C6.61755 5.43671 6.89333 5.25 7.20001 5.25ZM5.18779 13.05H9.21224L7.20001 8.01944L5.18779 13.05Z" fill="black" />
<path d="M15.7694 9.8631C15.4356 10.2525 15.3499 10.6795 15.3499 10.8C15.3499 11.2142 15.0142 11.55 14.5999 11.55C14.1857 11.55 13.8499 11.2142 13.8499 10.8C13.8499 10.3206 14.0643 9.54753 14.6305 8.88692C15.2274 8.19049 16.1879 7.65001 17.5999 7.65001C19.012 7.65001 19.9725 8.19049 20.5694 8.88692C21.1356 9.54753 21.3499 10.3206 21.3499 10.8V18C21.3499 18.4142 21.0142 18.75 20.5999 18.75C20.1857 18.75 19.8499 18.4142 19.8499 18V10.8C19.8499 10.6795 19.7643 10.2525 19.4305 9.8631C19.1274 9.50953 18.5879 9.15001 17.5999 9.15001C16.612 9.15001 16.0725 9.50953 15.7694 9.8631Z" fill="black" />
<path d="M20.5999 10.05C21.0142 10.05 21.3499 10.3858 21.3499 10.8C21.3499 11.3569 21.2329 11.8467 20.9927 12.2654C20.7525 12.6841 20.4158 12.9851 20.0483 13.2056C19.3729 13.6109 18.5134 13.7824 17.8121 13.9224C17.7903 13.9268 17.7686 13.9311 17.747 13.9354C16.9596 14.0929 16.3519 14.2235 15.9233 14.4806C15.7283 14.5976 15.5962 14.7278 15.5083 14.8811C15.4204 15.0342 15.3499 15.2569 15.3499 15.6C15.3499 16.1675 15.5582 16.5514 15.8866 16.8079C16.2364 17.0811 16.7996 17.2671 17.5838 17.2502C18.2231 17.2364 18.8096 16.9705 19.2359 16.6019C19.6848 16.2138 19.8499 15.8122 19.8499 15.6C19.8499 15.1858 20.1857 14.85 20.5999 14.85C21.0142 14.85 21.3499 15.1858 21.3499 15.6C21.3499 16.4085 20.8504 17.1889 20.217 17.7366C19.5609 18.3039 18.6473 18.7276 17.6161 18.7498C16.6003 18.7717 15.6635 18.537 14.9633 17.9901C14.2417 17.4265 13.8499 16.591 13.8499 15.6C13.8499 15.0431 13.967 14.5533 14.2072 14.1346C14.4474 13.7159 14.7841 13.4149 15.1516 13.1944C15.827 12.7891 16.6865 12.6175 17.3878 12.4776C17.4096 12.4732 17.4313 12.4689 17.4529 12.4646C18.2403 12.3071 18.848 12.1765 19.2766 11.9194C19.4716 11.8024 19.6037 11.6722 19.6916 11.5189C19.7795 11.3658 19.8499 11.1431 19.8499 10.8C19.8499 10.3858 20.1857 10.05 20.5999 10.05Z" fill="black" />
</svg>
  </Icon>;
}
