import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import ttsController from '../../../shared/foreground/actions/ttsController.platform';
import { globalState, useIsStaffProfile } from '../../../shared/foreground/models';
import {
  useCurrentTTSLanguageForDoc,
  useCurrentTTSVoiceForDoc,
  useDocument,
  useRssSourceNameForDoc,
} from '../../../shared/foreground/stateHooks';
import { useFocusedDocumentId } from '../../../shared/foreground/stateHooks/useFocusedDocument';
import combineClasses from '../../../shared/foreground/utils/combineClasses';
import {
  getTextToSpeechDisplayName,
  textToSpeechDefaultPlaybackRate,
  textToSpeechDefaultVolume,
} from '../../../shared/foreground/utils/tts';
import useGlobalStateWithFallback from '../../../shared/foreground/utils/useGlobalStateWithFallback';
import { type FirstClassDocument, Category, ShortcutId } from '../../../shared/types';
import type {
  TextToSpeechInfo,
  TextToSpeechVoice,
  TTSLanguage,
} from '../../../shared/types/tts';
import {
  PlaybackRates,
  TextToSpeechVoicesByLanguage,
  TextToSpeechVoiceToApiVersion,
} from '../../../shared/types/tts';
import { isDocumentWithThirdPartyUrl } from '../../../shared/typeValidators';
import getDocumentDomain from '../../../shared/utils/getDocumentDomain';
import getDocumentLanguageDisplayName from '../../../shared/utils/getDocumentLanguageDisplayName';
import getDocumentTitle from '../../../shared/utils/getDocumentTitle';
import getUrlDomain from '../../../shared/utils/getUrlDomain';
import type { MaybePromise } from '../../../shared/utils/typescriptUtils';
import urlJoin from '../../../shared/utils/urlJoin';
import { useHotKeys, useMediaKey } from '../hooks/hooks';
import getNumericCssPropertyValue from '../utils/getNumericCssPropertyValue';
import { useShortcutsMap } from '../utils/shortcuts';
import Button from './Button';
import { DocumentListCoverImage } from './CoverImage/DocumentListCoverImage';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown/Dropdown';
import LargePauseIcon from './icons/LargePauseIcon';
import LargePlayIcon from './icons/LargePlayIcon';
import LargeSkipBackwardIcon from './icons/LargeSkipBackwardIcon';
import LargeSkipForwardIcon from './icons/LargeSkipForwardIcon';
import MutedSpeakerIcon from './icons/MutedSpeakerIcon';
import SpeakerIcon from './icons/SpeakerIcon';
import StrokeCancelIcon from './icons/StrokeCancelIcon';
import WaveformIcon from './icons/WaveformIcon';
import Slider from './Slider';
import styles from './TtsPlayer.module.css';

const allTtsLanguagesSorted = sortBy(
  Object.keys(TextToSpeechVoicesByLanguage) as TTSLanguage[],
  getDocumentLanguageDisplayName,
);

function formatSecondsAsTime(input = 0): string {
  const fixedInput = isNaN(input) ? 0 : input;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(fixedInput / 3600);
  const minutes = Math.floor(fixedInput % 3600 / 60);
  const seconds = Math.floor(fixedInput % 60);

  const resultSegments: (string | number)[] = [];
  if (hours > 0) {
    resultSegments.push(hours);
  }
  resultSegments.push(minutes, zeroPad(seconds));
  return resultSegments.join(':');
}

function useKeyboardShortcuts({
  isVisible,
  tts,
}: {
  isVisible: boolean;
  tts: TextToSpeechInfo | null;
}) {
  // This may be unrelated to anything playing
  const focusedDocumentId = useFocusedDocumentId();

  const shortcutsMap = useShortcutsMap();
  const isStaffProfile = useIsStaffProfile();

  const descriptionsUsedMoreThanOnce = {
    [ShortcutId.Stop]: 'Stop and hide text-to-speech player',
    [ShortcutId.SkipBackwards]: 'Skip backward in text-to-speech audio',
    [ShortcutId.SkipForward]: 'Skip forward in text-to-speech audio',
  };

  const wrapShortcutHandler = useCallback((callback: (event: Event) => MaybePromise<void>, shouldPreventDefault = true) => {
    return (event: Event) => {
      if (!isVisible) {
        return;
      }
      if (shouldPreventDefault) {
        event.preventDefault();
      }
      callback(event);
    };
  }, [isVisible]);

  useHotKeys(
    shortcutsMap[ShortcutId.PlayOrPause],
    useCallback((event) => {
      if (!isStaffProfile || !focusedDocumentId) {
        return;
      }
      event.preventDefault();
      if (tts?.playingDocId) {
        ttsController.resumeOrPauseCurrentlyPlayingDocument();
      } else {
        ttsController.playDocument(focusedDocumentId);
      }
    }, [focusedDocumentId, isStaffProfile, tts?.playingDocId]),
    { description: 'Play / pause text-to-speech' },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.Stop],
    wrapShortcutHandler(ttsController.stop.bind(ttsController)),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.Stop] },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.SkipBackwards],
    wrapShortcutHandler(ttsController.jumpBackward.bind(ttsController)),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipBackwards] },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.SkipForward],
    wrapShortcutHandler(ttsController.jumpForward.bind(ttsController)),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipForward] },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.SpeedUpPlayback],
    wrapShortcutHandler(() => ttsController.increasePlaybackRatePreference({ userInteraction: 'keydown' })),
    {
      description: 'Speed up text-to-speech playback rate',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.SlowDownPlayBack],
    wrapShortcutHandler(() => ttsController.decreasePlaybackRatePreference({ userInteraction: 'keydown' })),
    {
      description: 'Slow down text-to-speech playback rate',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.IncreaseVolume],
    wrapShortcutHandler((event) => {
      if (window.getSelection()?.toString()) {
        return;
      }
      event.preventDefault();
      return ttsController.modifyVolumePreference('increase', {
        userInteraction: 'keydown',
      });
    }, false),
    { description: 'Increase volume' },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.DecreaseVolume],
    wrapShortcutHandler((event) => {
      if (window.getSelection()?.toString()) {
        return;
      }
      event.preventDefault();
      return ttsController.modifyVolumePreference('decrease', {
        userInteraction: 'keydown',
      });
    }, false),
    { description: 'Decrease volume' },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaPlay][0] as MediaSessionAction,
    useCallback(() => {
      ttsController.playOrPauseCurrentlyPlayingDocumentOrPlayNewDocument(focusedDocumentId);
    }, [focusedDocumentId]),
    { description: 'Play text-to-speech', isEnabled: Boolean(isStaffProfile && focusedDocumentId || tts?.playingDocId) },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaPause][0] as MediaSessionAction,
    ttsController.pause.bind(ttsController),
    { description: 'Pause text-to-speech', isEnabled: isVisible },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaStop][0] as MediaSessionAction,
    ttsController.stop.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.Stop], isEnabled: isVisible },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaPreviousTrack][0] as MediaSessionAction,
    ttsController.jumpBackward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipBackwards], isEnabled: isVisible },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaSeekBackward][0] as MediaSessionAction,
    ttsController.jumpBackward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipBackwards], isEnabled: isVisible },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaSeekForward][0] as MediaSessionAction,
    ttsController.jumpForward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipForward], isEnabled: isVisible },
  );

  useMediaKey(
    shortcutsMap[ShortcutId.MediaNextTrack][0] as MediaSessionAction,
    ttsController.jumpForward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipForward], isEnabled: isVisible },
  );
}

function zeroPad(input: number) {
  return String(input).padStart(2, '0');
}

function PlayrateSetting({
  onOptionSelected,
  playrate,
}: {
  onOptionSelected: (rate: number) => void;
  playrate: number;
}) {
  const currentPlayrateCleaned = playrate % 1 === 0 ? playrate : parseFloat(playrate.toFixed(2));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const options = useMemo(() => {
    return PlaybackRates.map((rate) => ({
      checked: currentPlayrateCleaned === rate,
      name: `${rate}×`,
      onSelect: () => onOptionSelected(rate),
      type: DropdownOptionType.Item,
    }));
  }, [currentPlayrateCleaned, onOptionSelected]);

  const dropdownTriggerButton = <DropdownMenu.Trigger asChild>
    <Button
      className={styles.playrateButton}
      variant="secondary">
      {currentPlayrateCleaned}&times;
    </Button>
  </DropdownMenu.Trigger>;

  return <Dropdown
    contentClassName={styles.playrateDropdown}
    isOpen={isDropdownOpen}
    options={options}
    setIsOpen={setIsDropdownOpen}
    trigger={dropdownTriggerButton}
  />;
}

function TrackInfo({
  docId,
}: {
  docId?: FirstClassDocument['id'];
}) {
  const [doc] = useDocument(docId); // TODO
  const rssSourceName = useRssSourceNameForDoc(doc);

  let contents = null;

  if (doc) {
    const titleToShow = getDocumentTitle(doc) || '[No title]';

    let siteNameOrDomainInfo: JSX.Element | undefined;
    if (![Category.EPUB, Category.PDF].includes(doc.category)) {
      const originUrl = isDocumentWithThirdPartyUrl(doc) ? getUrlDomain(doc.url) : undefined;
      const domainOrName = getDocumentDomain({
        rssSourceName,
        siteName: doc.site_name,
        originUrl,
      });

      if (domainOrName) {
        siteNameOrDomainInfo = <p className={styles.trackSourceName}>
          {domainOrName}
        </p>;

        // Should we link to the domain (filter page)?
        if ([doc.site_name, originUrl].includes(domainOrName)) {
          siteNameOrDomainInfo = <Link to={`/filter/domain:%22${domainOrName}%22`}>
            {siteNameOrDomainInfo}
          </Link>;
        }
      }
    }

    const documentLinkUrl = urlJoin(['/read', docId]);

    contents = <>
      <Link to={documentLinkUrl}>
        <DocumentListCoverImage
          category={doc.category}
          imageUrl={doc.image_url ?? undefined}
        />
      </Link>
      <div className={styles.trackInfoText}>
        <Link to={documentLinkUrl}>
          <p className={styles.trackTitle}>{titleToShow}</p>
        </Link>
        {siteNameOrDomainInfo}
      </div>
    </>;
  }

  return <div className={styles.trackInfo}>
    {contents}
  </div>;
}

function VoiceSetting({
  docId,
  onOptionSelected,
}: {
  docId: FirstClassDocument['id'];
  onOptionSelected: (language: TTSLanguage, voiceId: TextToSpeechVoice) => void;
}) {
  const currentVoiceId = useCurrentTTSVoiceForDoc(docId);
  const currentTtsLanguage = useCurrentTTSLanguageForDoc(docId);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [areOptionsLimitedToCurrentTtsLanguage, setAreOptionsLimitedToCurrentTtsLanguage] = useState(true);

  const options = useMemo(() => {
    const languagesToShow = areOptionsLimitedToCurrentTtsLanguage ? [currentTtsLanguage] : allTtsLanguagesSorted;
    const options: DropdownOption[] = languagesToShow
      .map((language) => {
        return [
          {
            name: getDocumentLanguageDisplayName(language),
            type: DropdownOptionType.Title,
          },
          ...TextToSpeechVoicesByLanguage[language].map((voiceId) => {
            const name = getTextToSpeechDisplayName(voiceId);
            let displayNameSuffix: JSX.Element | undefined;
            if (TextToSpeechVoiceToApiVersion[voiceId] === 'v3') {
              displayNameSuffix = <span className={styles.voiceDropdownOptionSuffix}>Powered by Unreal Speech</span>;
            }

            return {
              checked: currentVoiceId === voiceId,
              name,
              nameNode: <span className={styles.voiceDropdownOption}>
                <span className={styles.voiceDropdownOptionName}>{name}</span>
                {displayNameSuffix}
              </span>,
              onSelect: () => onOptionSelected(language, voiceId),
              type: DropdownOptionType.Item,
            };
          }),
        ];
      })
      .flat();

    if (areOptionsLimitedToCurrentTtsLanguage) {
      options.push({
        checked: false,
        name: '',
        onSelect: () => {},
        type: DropdownOptionType.Separator,
      }, {
        checked: false,
        name: 'View all languages',
        onSelect: (event) => {
          event.preventDefault();
          setAreOptionsLimitedToCurrentTtsLanguage(false);
        },
        type: DropdownOptionType.Item,
      });
    }

    return options;
  }, [areOptionsLimitedToCurrentTtsLanguage, currentTtsLanguage, currentVoiceId, onOptionSelected]);

  useEffect(() => {
    if (isDropdownOpen) {
      return;
    }
    setAreOptionsLimitedToCurrentTtsLanguage(true);
  }, [isDropdownOpen]);

  const dropdownTriggerButton = <DropdownMenu.Trigger asChild>
    <Button
      className={styles.voicesTriggerButton}
      variant="secondary">
      <WaveformIcon text="Voice" />
      {getTextToSpeechDisplayName(currentVoiceId)}
    </Button>
  </DropdownMenu.Trigger>;

  return <Dropdown
    contentClassName={combineClasses(styles.voiceDropdown, 'has-visible-scrollbar')}
    isOpen={isDropdownOpen}
    options={options}
    setIsOpen={setIsDropdownOpen}
    trigger={dropdownTriggerButton}
  />;
}

function VolumeSetting({
  lastNonZeroVolumeRef,
  onChanged,
  volume,
}: {
  lastNonZeroVolumeRef: React.MutableRefObject<number>;
  onChanged: (newValue: number, wasUnmuteClicked?: boolean) => void;
  volume: number;
}) {
  const icon = volume === 0 ? <MutedSpeakerIcon /> : <SpeakerIcon />;

  const onClickIcon = useCallback(() => {
    onChanged(volume ? 0 : lastNonZeroVolumeRef.current, volume === 0);
  }, [lastNonZeroVolumeRef, onChanged, volume]);

  return <div className={styles.volume}>
    <Button
      className={styles.toggleMuteButton}
      onClick={onClickIcon}
      variant="unstyled">
      {icon}
    </Button>
    <Slider
      className={styles.volumeSlider}
      onValueChanged={onChanged}
      value={volume}
      valueLabel={Math.round(volume * 100).toString()}
    />
  </div>;
}

export default function TtsPlayer() {
  const tts = globalState((state) => state.tts);
  const isVisible = useMemo(() => Boolean(tts), [tts]);
  const [isPlaying, setIsPlaying] = useState(true);

  const audioRef = useRef<HTMLAudioElement>();

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const currentTimeAsDecimal = useMemo(
    () => currentTime && duration ? currentTime / duration : 0,
    [currentTime, duration],
  );

  const playrate = useGlobalStateWithFallback(
    textToSpeechDefaultPlaybackRate,
    (state) => state.persistent.settings.tts_v2?.playbackRate,
  );
  const volume = useGlobalStateWithFallback(
    textToSpeechDefaultVolume,
    (state) => state.persistent.settings.tts_v2?.volume,
  );
  const lastNonZeroVolumeRef = useRef(volume > 0 ? volume : 1);

  const updateCurrentTime = useCallback((newValue) => {
    if (!audioRef.current?.duration) {
      return;
    }
    audioRef.current.currentTime = newValue * audioRef.current.duration;
  }, []);

  const setPlayrate = useCallback((newValue) => {
    ttsController.setPlaybackRatePreference(newValue, {
      userInteraction: 'unknown',
    });
  }, []);

  const onNewVoiceChosen = useCallback((language: TTSLanguage, voiceId: TextToSpeechVoice) => {
    if (!tts?.playingDocId) {
      return;
    }
    ttsController.setVoicePreferenceAndLanguage({
      documentId: tts.playingDocId,
      language,
      voice: voiceId,
      userInteraction: 'unknown',
    });
  }, [tts?.playingDocId]);

  const onVolumeChanged = useCallback((newValue: number, wasUnmuteClicked?: boolean) => {
    let fixedNewValue = newValue;
    if (wasUnmuteClicked) {

      /*
        When unmuting, we set the volume to the last non-zero volume. But that could be 0.02 if they dragged the volume
        slider slowly.
      */
      fixedNewValue = Math.max(newValue, 0.3);
    }
    ttsController.setVolumePreference(fixedNewValue, {
      userInteraction: 'unknown',
    });
    if (wasUnmuteClicked) {
      ttsController.play();
    }
  }, []);

  useEffect(() => {
    if (volume) {
      lastNonZeroVolumeRef.current = volume;
    }
  }, [volume]);

  useEffect(() => {
    const onDurationUpdated = (event: Event) => setDuration((event.target as HTMLAudioElement).duration ?? 0);
    const onPaused = () => setIsPlaying(false);
    const onPlay = () => setIsPlaying(true);
    const onCurrentTimeUpdated = (event: Event) => setCurrentTime((event.target as HTMLAudioElement).currentTime);

    (async () => {
      await ttsController.trackPlayerCreationPromise;
      audioRef.current = document.getElementById('tts-player') as HTMLAudioElement | null ?? undefined;
      if (!audioRef.current) {
        throw new Error("Can't attach to TTS audio element");
      }
      audioRef.current.addEventListener('durationchange', onDurationUpdated);
      audioRef.current.addEventListener('pause', onPaused);
      audioRef.current.addEventListener('play', onPlay);
      audioRef.current.addEventListener('timeupdate', onCurrentTimeUpdated);
    })();

    return () => {
      if (!audioRef.current) {
        return;
      }
      audioRef.current.removeEventListener('durationchange', onDurationUpdated);
      audioRef.current.removeEventListener('pause', onPaused);
      audioRef.current.removeEventListener('play', onPlay);
      audioRef.current.removeEventListener('timeupdate', onCurrentTimeUpdated);
      audioRef.current = undefined;
    };
  }, [audioRef]);

  useEffect(() => {
    const newValue: number = isVisible
      ? getNumericCssPropertyValue('--tts-player-height')
      : 0;

    // The unit here is neccessary
    document.documentElement.style.setProperty('--js__tts-player-current-height', `${newValue}px`);
  }, [isVisible]);

  // It's important that this is always called
  useKeyboardShortcuts({ isVisible, tts });

  if (!isVisible || !tts) {
    return null;
  }

  const playButtonIcon = isPlaying ? <LargePauseIcon /> : <LargePlayIcon />;

  return <aside className={styles.ttsPlayer}>
    <TrackInfo docId={tts?.playingDocId} />

    <div className={styles.main}>
      <div className={styles.mainButtons}>
        <Button
          onClick={ttsController.jumpBackward.bind(ttsController)}
          variant="unstyled">
          <LargeSkipBackwardIcon />
        </Button>
        <Button
          onClick={ttsController.toggleIsPlaying.bind(ttsController)}
          variant="unstyled">
          {playButtonIcon}
        </Button>
        <Button
          onClick={ttsController.jumpForward.bind(ttsController)}
          variant="unstyled">
          <LargeSkipForwardIcon />
        </Button>
      </div>

      <div className={styles.timeline}>
        <div className={styles.currentTime}>{formatSecondsAsTime(currentTime)}</div>
        <div className={styles.timelineSlider}>
          <Slider
            onValueChanged={updateCurrentTime}
            value={currentTimeAsDecimal}
            valueLabel=""
          />
        </div>
        <div className={styles.duration}>{formatSecondsAsTime(duration)}</div>
      </div>
    </div>

    <div className={styles.secondaryControls}>
      <div className={styles.settings}>
        <VolumeSetting
          lastNonZeroVolumeRef={lastNonZeroVolumeRef}
          onChanged={onVolumeChanged}
          volume={volume} />
        <PlayrateSetting
          onOptionSelected={setPlayrate}
          playrate={playrate}
        />
        <VoiceSetting
          docId={tts.playingDocId}
          onOptionSelected={onNewVoiceChosen}
        />
      </div>

      <Button
        className={styles.closeButton}
        onClick={ttsController.stop.bind(ttsController)}
        variant="secondary">
        <StrokeCancelIcon text="Close" />
      </Button>
    </div>
  </aside>;
}
