import some from 'lodash/some';

import type { DocumentId, DocumentThatCanBeShared } from '../../types';
import nowTimestamp from '../../utils/dates/nowTimestamp';
import database from '../database';
import { updateDocument } from '../stateUpdaters/persistentStateUpdaters/documents/update';
import { createToast } from '../toasts.platform';


const DOMAINS_TO_DISABLE_SHARING_FOR = [
  'macstories.net',
  'agilepainrelief.com',
  'overkill.wtf',
];

export async function disableDocumentShare ({
  docId,
  userInteraction,
}: {
  docId: DocumentId;
  userInteraction: string | null;
}) {
  updateDocument(docId, (doc) => {
    doc.sharedAt = null;
  }, { eventName: 'document-share-disabled', userInteraction });
}

export async function enableDocumentShare ({
  docId,
  userInteraction,
}: {
  docId: DocumentId;
  userInteraction: string | null;
}) {
  const doc = await database.collections.documents.findOne<DocumentThatCanBeShared>(docId);
  if (doc?.sharedAt) {
    return;
  }
  if (doc?.url && some(DOMAINS_TO_DISABLE_SHARING_FOR, (domain: string) => doc.url.includes(domain))) {
    createToast({
      content: 'The publisher of this content has requested it not be shared. Sorry about that!',
      category: 'info',
    });
    return;
  }
  updateDocument(docId, (doc) => {
    doc.sharedAt = nowTimestamp();
  }, { eventName: 'document-share-enabled', userInteraction });
}
