/* eslint-disable no-alert */

import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MangoQuery } from 'rxdb';
import { wrappedKeyCompressionStorage } from 'rxdb/plugins/key-compression';
import { FindBestIndexOutput } from 'rxdb-premium/dist/types/plugins/query-optimizer/query-optimizer-types';
import {
  findBestIndex,
} from 'rxdb-premium/plugins/query-optimizer';

import documents from '../../database/internals/defineSchema/documents';
import getRxStorage from '../../database/internals/getRxStorage.platformIncludingExtension';
import logger from '../../database/internals/logger';
import { Category, DocumentLocation } from '../../types';
import { isMobile } from '../../utils/environment';
import database from '../database';
import eventEmitter from '../eventEmitter';

export default function useDatabaseBestIndexHelper () {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [allDocuments, setAllDocuments] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [bestIndexResult, setBestIndexResult] = useState<FindBestIndexOutput<any>>();
  const [bestIndexStatus, setBestIndexStatus] = useState('ready for input');

  useEffect(() => {
    if (bestIndexStatus === 'ready for input') {
      return;
    }
    logger.debug(`bestIndex: ${bestIndexStatus}`);
  }, [bestIndexStatus]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [firstQuery, setFirstQuery] = useState<MangoQuery<any> | ''>({
    selector: {
      category: {
        $not: {
          $in: [Category.Highlight, Category.Note],
        },
      },
      triage_status: {
        $eq: DocumentLocation.Later,
      },
    },
    sort: [
      { title: 'desc' },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [secondQuery, setSecondQuery] = useState<MangoQuery<any> | ''>({
    selector: {
      category: {
        $not: {
          $in: [Category.Highlight, Category.Note],
        },
      },
      triage_status: {
        $eq: DocumentLocation.Archive,
      },
    },
    sort: [
      { title: 'desc' },
    ],
  });

  const onFirstQueryTextareaChanged = useCallback((value: string) => {
    setFirstQuery((value.trim() ?? '') && JSON.parse(value));
  }, [setFirstQuery]);

  const onSecondQueryTextareaChanged = useCallback((value: string) => {
    setFirstQuery((value.trim() ?? '') && JSON.parse(value));
  }, [setFirstQuery]);

  const firstQueryAsJson = useMemo(() => firstQuery ? JSON.stringify(firstQuery, null, 2) : undefined, [firstQuery]);
  const secondQueryAsJson = useMemo(() => secondQuery ? JSON.stringify(secondQuery, null, 2) : undefined, [secondQuery]);

  const [numberOfRuns, setNumberOfRuns] = useState(1);

  const onNumberOfRunsFieldChanged = useCallback((value: string) => {
    setNumberOfRuns(value ? parseInt(value, 10) : 1);
  }, [setNumberOfRuns]);

  useEffect(() => {
    let wasCleanupCalled = false;
    const rxDbInstance = database.getRxDBInstance();
    (async () => {
      const rxDocuments = await rxDbInstance.collections.documents.find().exec();
      if (wasCleanupCalled) {
        return;
      }
      setAllDocuments(
        rxDocuments.map((rxDocument) => rxDocument.toJSON()),
      );
    })();

    return () => {
      wasCleanupCalled = true;
    };
  }, []);

  const computeBestIndexes = useCallback(() => {
    if (!allDocuments.length) {
      alert('!allDocuments.length');
      return;
    }

    if (!firstQuery) {
      alert('!firstQuery');
      return;
    }
    if (!secondQuery) {
      alert('!secondQuery');
      return;
    }

    (async () => {
      setBestIndexStatus('computing');

      let hasEnded = false;
      try {
        const resultPromise = findBestIndex({
          runs: 1,
          schema: documents.getSchema().documents.schema,
          storage: wrappedKeyCompressionStorage({
            storage: getRxStorage({
              eventEmitter,
              isInForeground: true,
            }),
          }),
          queries: {
            firstQuery,
            secondQuery,
          },
          testData: allDocuments,
        });

        let i = 0;
        const intervalId = setInterval(() => {
          if (hasEnded) {
            clearInterval(intervalId);
            return;
          }
          setBestIndexStatus(`computing (${++i})`);
        }, 1000);

        const result = await resultPromise;
        hasEnded = true;

        setBestIndexStatus('done');
        setBestIndexResult(result);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        hasEnded = true;
        setBestIndexStatus('error');
        logger.error('Getting best indexes failed', { error });
        if (isMobile) {
          alert(`Error while getting best indexes; ${error.message.substr(0, 200)}`);
        }

      }
    })();
  }, [allDocuments, firstQuery, secondQuery, setBestIndexStatus]);

  return {
    allDocuments,
    bestIndexResult,
    bestIndexStatus,
    computeBestIndexes,
    firstQuery,
    firstQueryAsJson,
    numberOfRuns,
    onFirstQueryTextareaChanged,
    onNumberOfRunsFieldChanged,
    onSecondQueryTextareaChanged,
    secondQuery,
    secondQueryAsJson,
    setFirstQuery,
    setNumberOfRuns,
    setSecondQuery,
  };
}
