import type { RangyRange } from '../types/rangy';
import isHighlightNode from './isHighlightNode';
import isImage from './isImage';
// eslint-disable-next-line import/no-cycle
import { isTextNode } from './locationSerializer';
import rangy from './rangy';

export default (nodes: Node[]): RangyRange => {
  let range: RangyRange | undefined;
  const select = (node: Node) => {
    if (!range) {
      range = rangy.createRange();
      if (isImage(node) || node.childNodes.length === 1 && isTextNode(node.childNodes[0])) {
        range.selectNode(node);
      } else {
        range.selectNodeContents(node);
      }
      if (range.collapsed) {
        throw new Error('Failed to include node in range');
      }
      return;
    }

    // Extend the range
    const nodeComparison = range.compareNode(node);
    const nodeIsBeforeRange = 0;
    const nodeIsAfterRange = 1;
    const nodeIsBeforeAndAfterRange = 2;
    if ([nodeIsBeforeAndAfterRange, nodeIsBeforeRange].includes(nodeComparison)) {
      range.setStartBefore(node);
    }
    if ([nodeIsBeforeAndAfterRange, nodeIsAfterRange].includes(nodeComparison)) {
      if (node.parentElement && node.parentElement.childNodes.length === 1 && isHighlightNode(node.parentElement)) {
        // TODO
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        range.setEnd(node, node.length);
      } else {
        range.setEndAfter(node);
      }
    }
    // If it's completely inside range, do nothing
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    if (isHighlightNode(node)) {
      select(node.firstChild as ChildNode);
    } else {
      select(node);
    }
  }
  return range as RangyRange;
};
