import type { FilteredView, SortRule } from '../types';
import { DEFAULT_SORT_RULE_ID, updateState } from './models';

export const setDocumentsSortMenuOpen = async (isOpen: boolean, options: Omit<Parameters<typeof updateState>[1], 'eventName'>): Promise<void> => {
  await updateState((state) => {
    state.isDocumentsSortMenuShown = isOpen;
  }, { ...options, eventName: 'documents-sort-menu-opened' });
};

export const createOrUpdateListSortRule = async (listId: string, sortRule: SortRule) => {
  await updateState((state) => {
    if (!listId) {
      return;
    }

    if (!state.client.listSortRules[listId]) {
      state.client.listSortRules[listId] = [sortRule];
    } else {
      let updatedRuleIndex = state.client.listSortRules[listId].findIndex(({ id }: SortRule) => id === sortRule.id);

      if (updatedRuleIndex === -1) {
        // There is a special case where the user updated a rule on an unsaved filter view
        // and then when the view is created we save the rule with the default id so the ids don't match
        if (state.client.listSortRules[listId]?.[0]?.id === DEFAULT_SORT_RULE_ID) {
          updatedRuleIndex = 0;
        } else {
          throw new Error('sort rule to update not found');
        }
      }

      state.client.listSortRules[listId][updatedRuleIndex] = sortRule;
    }

  }, { userInteraction: null, shouldCreateUserEvent: false, eventName: 'create-or-update-list-sort-rule' });
};


export const updateFilteredViewSortRule = async ({ id, updatedSortRule, options }: {id: string; updatedSortRule: SortRule; options: Omit<Parameters<typeof updateState>[1], 'eventName'>;}): Promise<void> => {
  await updateState((state) => {
    if (!state.persistent.filteredViews[id]) {
      return;
    }
    if (!state.persistent.filteredViews[id].sortRules) {
      state.persistent.filteredViews[id].sortRules = [updatedSortRule];
      return;
    }

    const updatedRuleIndex = state.persistent.filteredViews[id].sortRules?.findIndex(({ id }: SortRule) => id === updatedSortRule.id);

    if (updatedRuleIndex === -1) {
      throw new Error('sort rule to update not found');
    }

    state.persistent.filteredViews[id].sortRules[updatedRuleIndex] = updatedSortRule;
  }, { ...options, eventName: 'sort-rule-updated' });
};

export const updateFilteredOrRegularSortRule = async (
  {
    updatedSortRule, currentFilteredView, listId, options,
  }: { updatedSortRule: SortRule; currentFilteredView?: FilteredView; listId?: string; options: Omit<Parameters<typeof updateState>[1], 'eventName'>;},
): Promise<void> => {
  if (listId) {
    await createOrUpdateListSortRule(listId, updatedSortRule);
  }

  if (currentFilteredView) {
    await updateFilteredViewSortRule({ id: currentFilteredView.id, updatedSortRule, options: { userInteraction: 'click' } });
  }
};
