import React, { useCallback, useEffect, useRef, useState } from 'react';

import forwardRef from '../../../shared/foreground/utils/forwardRef';
import styles from './Textarea.module.css';

export type Props = {
  classNames?: {
    textarea?: string;
    wrapper?: string;
  };
  cols?: number;
  rows?: number;
  value?: string;
} & React.HTMLAttributes<HTMLTextAreaElement>;

export default React.memo(forwardRef<Props, HTMLTextAreaElement | null>(
  function Textarea({ classNames = {}, value: valueProp = '', ...props }, textareaRef) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState<string>(valueProp);

    const onValueUpdated = useCallback(() => {
      if (!textareaRef.current) {
        throw new Error('textareaRef.current missing; bad ref passed?');
      }
      const wrapper = wrapperRef.current as NonNullable<typeof wrapperRef.current>;
      const textarea = textareaRef.current as NonNullable<typeof textareaRef.current>;

      setValue(textarea.value);

      if (wrapper.dataset.replicatedValue !== textarea.value) {
        wrapper.dataset.replicatedValue = textarea.value;
      }
    }, [textareaRef]);

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (...args) => {
      onValueUpdated();
      if (props.onChange) {
        return props.onChange(...args);
      }
    };

    useEffect(() => setValue(valueProp as string), [valueProp]);

    return <div
      className={['wrapper', styles.wrapper, classNames.wrapper].filter(Boolean).join(' ')}
      data-replicated-value={value}
      ref={wrapperRef}>
      <textarea
        {...props}
        className={[classNames.textarea].filter(Boolean).join(' ')}
        onChange={onChange}
        ref={textareaRef}
        value={value} />
    </div>;
  },
));
