/*
  Accepts:
    1. `combineClasses'hello')`
    2. `combineClasses(['hello', 'world'])`
    3. `combineClasses('hello', 'world')`
    4. `combineClasses(['hello', 'world'], 'blah')`

  Returns a single string like `hello world blah`
*/
export default function combineClasses(...args: ((string | undefined)[] | string | undefined)[]): string {
  return args
    .flat()
    .filter(Boolean)
    .join(' ');
}
