import { RWDOMParser } from '../../utils/RWDOMParser.platform';

export default function removeFakePDFLineBreaks(xml: string) {
  try {
    const DOMParser = new RWDOMParser();
    const xmlDoc = DOMParser.parseFromString(xml, 'application/xml');

    const highlightElement = xmlDoc.querySelector('highlight');
    if (!highlightElement) {
      return null;
    }

    const coordsAttribute = highlightElement.getAttribute('coords');

    if (!coordsAttribute) {
      return null;
    }

    const coords = coordsAttribute.split(',').map(Number);

    const linesCoords = [];

    // Each line has 8 coordinates: X,Y for each corner of the rectangle.
    for (let i = 0; i < coords.length; i += 8) {
      linesCoords.push(coords.slice(i, i + 8));
    }

    const trnCustomData = highlightElement.querySelector('trn-custom-data');

    if (!trnCustomData) {
      return null;
    }

    const bytes = trnCustomData.getAttribute('bytes');

    if (!bytes) {
      return null;
    }

    const decodedBytes = JSON.parse(bytes.replace(/&quot;/g, '"'));

    const text = decodedBytes['trn-annot-preview'];
    const textLines: string = text.split('\n');

    let finalText = textLines[0];
    for (let i = 1; i < textLines.length; i++) {
      const prevBottomRightCornerXCoord = linesCoords[i - 1]?.[6];
      const bottomRightCornerXCoord = linesCoords[i]?.[6];
      const currentTextLine = textLines[i];

      if (!prevBottomRightCornerXCoord || !bottomRightCornerXCoord) {
        return null;
      }

      // If the X coordinate changes significantly, treat as a new line; otherwise, treat as continuation.
      const endsWithPunctuation = currentTextLine.match(/[.!?]$/);
      const xCoordinateChangedSignificantly = Math.abs(prevBottomRightCornerXCoord - bottomRightCornerXCoord) > 5;
      const isLast = i === textLines.length - 1;

      if (xCoordinateChangedSignificantly && endsWithPunctuation && !isLast) {
        finalText += ` ${currentTextLine.trim()}\n\n`;
      } else {
        const prevTextLineWasLineBreak = finalText.endsWith('\n');
        finalText += `${prevTextLineWasLineBreak ? '' : ' '}${currentTextLine.replace('\n', ' ').trim()}`;
      }
    }

    return finalText;
  } catch (error) {
    return null;
  }
}
