import {
  type Highlight,
} from '../../../types';
import { QuoteshotAspectRatio, QuoteshotFont, QuoteshotThemeName, ThemeVariant } from '../../../types/quoteshots';
import {
  updateState,
} from '../../models';

export const updateCurrentQuoteshotTheme = async (quoteshotThemeName: QuoteshotThemeName) => {
  return updateState((state) => {
    state.client.quoteshot.currentThemeName = quoteshotThemeName;
  }, { eventName: 'quoteshot-theme-updated', userInteraction: 'click', isUndoable: false });
};

export const updateCurrentQuoteshotVariant = async (quoteshotTheme: QuoteshotThemeName, variantId: ThemeVariant['id']) => {
  return updateState((state) => {
    state.client.quoteshot.variantForTheme[quoteshotTheme] = variantId;
  }, { eventName: 'quoteshot-theme-variant-updated', userInteraction: 'click', isUndoable: false });
};

export const updateCurrentQuoteshotAspectRatio = async (ratio: QuoteshotAspectRatio) => {
  return updateState((state) => {
    state.client.quoteshot.aspectRatio = ratio;
  }, { eventName: 'quoteshot-aspect-ratio-updated', userInteraction: 'click', isUndoable: false });
};

export const setQuoteshotDarkMode = async (darkMode: boolean) => {
  return updateState((state) => {
    state.client.quoteshot.isDarkMode = darkMode;
  }, { eventName: 'quoteshot-dark-mode-updated', userInteraction: 'click', isUndoable: false });
};

export const setCurrentQuoteshotFont = async (currentFont: QuoteshotFont) => {
  return updateState((state) => {
    state.client.quoteshot.currentFont = currentFont;
  }, { eventName: 'quoteshot-font-updated', userInteraction: 'click', isUndoable: false });
};
export const setQuoteshotModalOpen = async (isOpen: boolean, highlightId?: Highlight['id']) => {
  return updateState((state) => {
    state.quoteshotModalOpen = isOpen;
    state.quoteshotHighlightId = isOpen && highlightId ? highlightId : null;
  }, { eventName: 'set-quoteshot-modal-open', userInteraction: 'click', isUndoable: false });
};
