/*
  PLEASE do not add any more to this component. Keep it simply just for sanitizing the document content.
  No extra elements, no extra hooks, no listeners, etc.
*/
import React, { useEffect, useMemo } from 'react';

import forwardRef from '../../../shared/foreground/utils/forwardRef';
import { Category } from '../../../shared/types';
import { rwSanitizeHtml } from '../../../shared/utils/rwSanitizeHtml';

export type Props = {
  category: Category;
  content: string;
  onSanitized?(sanitizedHtml: string): void;
  originalEmailView?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultExport = React.memo(forwardRef<Props, HTMLDivElement>(function JustSanitizedDocumentContentOnly(
  {
    category,
    content,
    onSanitized,
    originalEmailView,
    ...props
  },
  ref,
): JSX.Element {
  const sanitizedHtml = useMemo(() => rwSanitizeHtml(content, category, originalEmailView), [category, content, originalEmailView]);
  useEffect(() => onSanitized?.(sanitizedHtml), [sanitizedHtml, onSanitized]);

  return <div
    {...props}
    dangerouslySetInnerHTML={{
      __html: sanitizedHtml,
    }}
    className={`${props.className ?? ''} ${originalEmailView ? 'document-content--original-email' : 'document-content'}`}
    ref={ref}
  />;
}));

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
