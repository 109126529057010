import React from 'react';

import Icon from './Icon';

export default function InboxIcon({ className, text = 'inbox', width = '20', height = '20' }: { className?: string; text?: string; width?: string; height?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 2.25C3.92893 2.25 2.25 3.92893 2.25 6V11V14C2.25 16.0711 3.92893 17.75 6 17.75H14C16.0711 17.75 17.75 16.0711 17.75 14V11V6C17.75 3.92893 16.0711 2.25 14 2.25H6ZM16.25 10.25V6C16.25 4.75736 15.2426 3.75 14 3.75H6C4.75736 3.75 3.75 4.75736 3.75 6V10.25H5.17157C5.90092 10.25 6.60039 10.5397 7.11612 11.0555L8.31066 12.25H11.6893L12.8839 11.0555C13.3996 10.5397 14.0991 10.25 14.8284 10.25H16.25ZM3.75 11.75H5.17157C5.50309 11.75 5.82104 11.8817 6.05546 12.1161L7.46967 13.5303C7.61032 13.671 7.80109 13.75 8 13.75H12C12.1989 13.75 12.3897 13.671 12.5303 13.5303L13.9445 12.1161C14.179 11.8817 14.4969 11.75 14.8284 11.75H16.25V14C16.25 15.2426 15.2426 16.25 14 16.25H6C4.75736 16.25 3.75 15.2426 3.75 14V11.75Z" />
    </svg>
  </Icon>;
}
