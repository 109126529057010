import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHighlightNote } from '../../../../shared/foreground/database/helperHooks';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { globalState } from '../../../../shared/foreground/models';
import { useDocument, useGlobalTagsAsObject } from '../../../../shared/foreground/stateHooks';
import * as highlighterActions
  from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import {
  deleteHighlight,
  deleteHighlightNote,
  updateHighlightNote,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import type { Highlight } from '../../../../shared/types';
import capitalize from '../../../../shared/utils/capitalize';
import nowTimestamp from '../../../../shared/utils/dates/nowTimestamp';
import parseStringTimestamp from '../../../../shared/utils/dates/parseStringTimestamp';
import delay from '../../../../shared/utils/delay';
import focusFirstFocusableDescendant from '../../utils/focusFirstFocusableDescendant';
import getElementRect from '../../utils/getElementRect';
import mergeRects from '../../utils/mergeRects';
import { DeleteHighlightDialog } from '../DeleteHighlightDialog';
import type { Ref as EditTagsFormRef, RefValue as EditTagsFormRefValue } from '../EditTagsForm';
import AnnotationBarPopover from './AnnotationBarPopover';
import EditNotePopover from './EditNotePopover';
import EditTagsPopover from './EditTagsPopover';
import { Props as PopoverProps } from './Popover';

type Props = {
  getBoundingClientRectForMargins: (references: PopoverProps['reference'][]) => DOMRect;
  getHighlightBoundingClientRect?: () => DOMRect;
  hideAnnotationBarPopover: PopoverProps['hidePopover'];
  highlightId?: Highlight['id'];
  isAnnotationBarPopoverShown: PopoverProps['isShown'];
  isAutoHighlightingAlwaysEnabled?: boolean;
  references?: PopoverProps['reference'][];
  shouldShowInMargin?: boolean;
  shouldUseSelection?: boolean;
  showAnnotationBarPopover: PopoverProps['showPopover'];
  isPdfTron?: boolean;
  isNotebookView?: boolean;
} &
  Omit<PopoverProps, 'getBoundingClientRect' | 'hidePopover' | 'isShown' | 'reference' | 'showPopover'>;

const defaultExport = React.memo(function AnnotationPopovers({
  getBoundingClientRectForMargins,
  getHighlightBoundingClientRect: getHighlightBoundingClientRectArgument,
  hideAnnotationBarPopover,
  highlightId,
  isAnnotationBarPopoverShown,
  isAutoHighlightingAlwaysEnabled,
  positionUpdateCounter,
  references,
  shouldShowInMargin,
  shouldUseSelection,
  showAnnotationBarPopover,
  isPdfTron,
  isNotebookView,
  ...otherProps
}) {
  const [highlight] = useDocument<Highlight>(highlightId);
  const [note] = useHighlightNote(highlight);
  const [globalTagsObject] = useGlobalTagsAsObject();
  const isCmdPaletteOpen = globalState(useCallback((state) => state.cmdPalette.isOpen, []));

  const hasAutoShowRanRef = useRef(false);
  const [isEditNotePopoverShown, setIsEditNotePopoverShown] = useState(false);
  const [isEditTagsPopoverShown, setIsEditTagsPopoverShown] = useState(false);
  const isDescendantPopoverShown = useMemo(() => isEditNotePopoverShown || isEditTagsPopoverShown, [isEditNotePopoverShown, isEditTagsPopoverShown]);
  const isShowingSubPopoverViaShortcutRef = useRef(false);
  const [isDescendantPopoverActive, setIsDescendantPopoverActive] = useState(false);
  const [isDeleteHighlightDialogOpen, setIsDeleteHighlightDialogOpen] = useState(false);

  // When a highlight is created via a shortcut like `n`, we remove it when escape/cancel is pressed (if no changes were made)
  const [shouldRemoveHighlightOnCancel, setShouldRemoveHighlightOnCancel] = useState<boolean | void>(false);
  // Reset shouldRemoveHighlightOnCancel when an edit is made, or there's a click outside, etc.
  useEffect(() => {
    if ([isEditNotePopoverShown, isEditTagsPopoverShown].some(Boolean)) {
      return;
    }
    setShouldRemoveHighlightOnCancel(false);
  }, [isEditNotePopoverShown, isEditTagsPopoverShown]);

  const editNotePopoverInnerRef = useRef<HTMLFormElement | null>(null);
  const focusEditNoteForm = useCallback(() => {
    if (!editNotePopoverInnerRef.current) {
      return;
    }

    focusFirstFocusableDescendant(editNotePopoverInnerRef.current);
  }, []);

  const editTagsFormRef: EditTagsFormRef = useRef<EditTagsFormRefValue>(null);
  const focusEditTagsForm = useCallback(() => {
    editTagsFormRef.current?.focus();
  }, []);

  const openHighlightNoteForm = useCallback(async (_shouldRemoveHighlightOnCancel?: boolean) => {
    hideAnnotationBarPopover();
    setIsEditNotePopoverShown(true);
    setShouldRemoveHighlightOnCancel(_shouldRemoveHighlightOnCancel);
    await delay(10);
    focusEditNoteForm();
  }, [focusEditNoteForm, hideAnnotationBarPopover]);

  const openHighlightTagForm = useCallback(async (_shouldRemoveHighlightOnCancel?: boolean) => {
    hideAnnotationBarPopover();
    setShouldRemoveHighlightOnCancel(_shouldRemoveHighlightOnCancel);

    if (shouldShowInMargin) {
      if (isEditNotePopoverShown) {
        focusEditTagsForm();
        return;
      }
      setIsEditNotePopoverShown(true);
      await delay(2);
      focusEditTagsForm();
      return;
    }

    if (isEditTagsPopoverShown) {
      focusEditTagsForm();
      return;
    }
    setIsEditTagsPopoverShown(true);
    await delay(2);
    focusEditTagsForm();
  }, [focusEditTagsForm, hideAnnotationBarPopover, isEditNotePopoverShown, isEditTagsPopoverShown, shouldShowInMargin]);

  useEffect(() => {
    const onNewContentFocusIndicatorTarget = (newTarget: HTMLElement) => {
      if (shouldUseSelection || isEditNotePopoverShown || isEditTagsPopoverShown) {
        return;
      }
      if (!references?.some((reference) => reference && newTarget.contains(reference))) {
        hideAnnotationBarPopover();
      }
    };

    const hidePopover = () => {
      hideAnnotationBarPopover();
    };

    const onOpenHighlightNoteForm = ({
      shouldRemoveHighlightOnCancel: shouldRemoveHighlightOnCancelArgument = false,
    }: {
      shouldRemoveHighlightOnCancel?: boolean;
    }) => {
      isShowingSubPopoverViaShortcutRef.current = true; // This gets reset elsewhere
      openHighlightNoteForm(shouldRemoveHighlightOnCancelArgument);
    };

    const onOpenHighlightTagsForm = ({
      shouldRemoveHighlightOnCancel: shouldRemoveHighlightOnCancelArgument = false,
    }: {
      shouldRemoveHighlightOnCancel?: boolean;
    }) => {
      isShowingSubPopoverViaShortcutRef.current = true; // This gets reset elsewhere
      openHighlightTagForm(shouldRemoveHighlightOnCancelArgument);
    };

    const id = shouldUseSelection ? 'selection' : highlightId;

    eventEmitter.on('content-focus-indicator:new-focus-target', onNewContentFocusIndicatorTarget);
    eventEmitter.on(`annotationPopover-${id}:hide`, hidePopover);
    eventEmitter.on(`annotationPopover-${id}:openHighlightNoteForm`, onOpenHighlightNoteForm);
    eventEmitter.on(`annotationPopover-${id}:openHighlightTagsForm`, onOpenHighlightTagsForm);

    const onListeningCheck = () => true;
    eventEmitter.on(`annotationPopover-${id}:is-listening?`, onListeningCheck);
    eventEmitter.emit(`annotationPopover-${id}:listening`);

    return () => {
      eventEmitter.off('content-focus-indicator:new-focus-target', onNewContentFocusIndicatorTarget);
      eventEmitter.off(`annotationPopover-${id}:openHighlightNoteForm`, onOpenHighlightNoteForm);
      eventEmitter.off(`annotationPopover-${id}:hide`, hidePopover);
      eventEmitter.off(`annotationPopover-${id}:openHighlightTagsForm`, onOpenHighlightTagsForm);
      eventEmitter.off(`annotationPopover-${id}:is-listening?`, onListeningCheck);
    };
  }, [
    focusEditNoteForm,
    focusEditTagsForm,
    highlight?.id,
    hideAnnotationBarPopover,
    highlightId,
    isEditNotePopoverShown,
    isEditTagsPopoverShown,
    openHighlightNoteForm,
    openHighlightTagForm,
    references,
    setIsEditNotePopoverShown,
    setIsEditTagsPopoverShown,
    shouldShowInMargin,
    shouldUseSelection,
  ]);

  // Show if created just now
  useEffect(() => {
    if (hasAutoShowRanRef.current || !highlight?.saved_at || isShowingSubPopoverViaShortcutRef.current || isCmdPaletteOpen || highlight?.source_specific_data?.generated) {
      hideAnnotationBarPopover();
      return;
    }

    if (nowTimestamp() - parseStringTimestamp(highlight.saved_at) < 1000) {
      hasAutoShowRanRef.current = true;
      showAnnotationBarPopover?.();
    }
    isShowingSubPopoverViaShortcutRef.current = false;
  }, [
    hasAutoShowRanRef,
    highlight?.saved_at,
    highlight?.source_specific_data?.generated,
    showAnnotationBarPopover,
    hideAnnotationBarPopover,
    isCmdPaletteOpen,
  ]);

  // Trigger style changes when popovers are active
  useEffect(() => {
    if (!highlight?.id) {
      return;
    }

    const deactivate = () => {
      eventEmitter.emit('highlight-deactivated', highlight.id);
    };

    const isActive = isAnnotationBarPopoverShown || (
      shouldShowInMargin ? isDescendantPopoverActive : isDescendantPopoverShown
    );

    if (!isActive) {
      deactivate();
      return;
    }

    eventEmitter.emit('highlight-activated', highlight.id);

    return () => {
      deactivate();
    };
  }, [
    highlight?.id,
    isAnnotationBarPopoverShown,
    isDescendantPopoverActive,
    isDescendantPopoverShown,
    shouldShowInMargin,
  ]);

  const getHighlightBoundingClientRect = useMemo(() => {
    if (getHighlightBoundingClientRectArgument) {
      return getHighlightBoundingClientRectArgument;
    }
    if (!references?.length) {
      throw new Error('No references');
    }
    return () => mergeRects(references.map(getElementRect));
  }, [getHighlightBoundingClientRectArgument, references]);

  const getBoundingClientRectForSubPopovers = useMemo(() => {
    return () => {
      const highlightsRect = getHighlightBoundingClientRect();
      if (!shouldShowInMargin) {
        return highlightsRect;
      }
      return getBoundingClientRectForMargins(references ?? []);
    };
  }, [getBoundingClientRectForMargins, getHighlightBoundingClientRect, references, shouldShowInMargin]);

  // Remove highlight on cancel if we need to
  const onCancelNestedPopover = useCallback(() => {
    if (!shouldRemoveHighlightOnCancel || !highlight?.id) {
      return;
    }

    deleteHighlight(highlight?.id, {
      userInteraction: 'unknown',
    });
  }, [highlight?.id, shouldRemoveHighlightOnCancel]);

  const onTagsUpdated = useCallback(() => {
    setShouldRemoveHighlightOnCancel(false);
  }, [setShouldRemoveHighlightOnCancel]);

  const updateNote = useCallback((newNote: string) => {
    if (!highlight) {
      return;
    }
    setShouldRemoveHighlightOnCancel(false);

    const userInteraction = 'click';

    if (!newNote) {
      if (!highlight.children.length) {
        return;
      }
      deleteHighlightNote(highlight.children[0], {
        userInteraction,
      });
      return;
    }

    updateHighlightNote(
      highlight,
      newNote,
      {
        userInteraction,
      },
    );
  }, [highlight]);

  const checkIfShouldScrollIntoView = useCallback(() => {
    if (!highlight?.saved_at) {
      return false;
    }
    return !shouldShowInMargin || nowTimestamp() - parseStringTimestamp(highlight.saved_at) < 1000;
  }, [highlight?.saved_at, shouldShowInMargin]);

  const highlightSelection = useCallback(async (formToOpen?: string) => {
    hideAnnotationBarPopover();
    const id = (await eventEmitter.emitAsync('highlight')).find(Boolean);
    if (!id) {
      throw new Error('No ID returned from highlight event');
    }

    if (!formToOpen) {
      return;
    }

    // New highlights emit this when mounted
    const popoverMountPromise = eventEmitter.waitFor(`annotationPopover-${id}:listening`);

    // Check if existing popover is already alive (e.g. if this action caused a merge rather than creation)
    if (!(await eventEmitter.emitAsync(`annotationPopover-${id}:is-listening?`)).find(Boolean)) {
      // Wait for mount
      await popoverMountPromise;
    }

    await delay(10);

    eventEmitter.emit(`annotationPopover-${id}:openHighlight${capitalize(formToOpen)}Form`, { shouldRemoveHighlightOnCancel: true });
  }, [hideAnnotationBarPopover]);

  const onClickCopy = useCallback(() => {
    let text: string | undefined;

    if (shouldUseSelection) {
      text = window.getSelection()?.toString();
    } else {
      text = highlight?.content;
    }

    if (!text) {
      return;
    }

    navigator.clipboard.writeText(text);
  }, [highlight?.content, shouldUseSelection]);

  const onClickCreate = useCallback(() => {
    highlightSelection();
  }, [highlightSelection]);

  const onCancelDeleteHighlight = useCallback(() => {
    setIsDeleteHighlightDialogOpen(false);
  }, []);

  const onConfirmDeleteHighlight = useCallback(() => {
    if (!highlight?.id) {
      return;
    }
    highlighterActions.deleteHighlight(highlight.id, { userInteraction: 'click' });
    setIsDeleteHighlightDialogOpen(false);
  }, [highlight?.id]);

  const onClickDelete = useCallback((e: MouseEvent) => {
    // prevent shift+enter from triggering this button click (instead of "open highlight in notebook view")
    if (e.shiftKey && !isNotebookView) {
      return;
    }
    if (isNotebookView) {
      setIsDeleteHighlightDialogOpen(true);
    } else {
      onConfirmDeleteHighlight();
    }
  }, [isNotebookView, onConfirmDeleteHighlight]);

  const onClickEditNote = useCallback(async (e: MouseEvent) => {
    // prevent shift+enter from triggering this button click (instead of "open highlight in notebook view")
    if (e.shiftKey && !isNotebookView) {
      return;
    }
    if (shouldUseSelection) {
      highlightSelection('note');
      return;
    }
    openHighlightNoteForm();
  }, [highlightSelection, isNotebookView, openHighlightNoteForm, shouldUseSelection]);

  const onClickEditTags = useCallback((e: MouseEvent) => {
    // prevent shift+enter from triggering this button click (instead of "open highlight in notebook view")
    if (e.shiftKey && !isNotebookView) {
      return;
    }
    if (shouldUseSelection) {
      highlightSelection('tags');
      return;
    }
    openHighlightTagForm();
  }, [highlightSelection, isNotebookView, openHighlightTagForm, shouldUseSelection]);

  const onClickShare = useCallback(() => {
    let text: string | undefined;

    if (shouldUseSelection) {
      text = window.getSelection()?.toString();
    } else {
      text = highlight?.content;
    }

    if (!text) {
      return;
    }

    navigator.share({
      text,
    });
  }, [highlight?.content, shouldUseSelection]);

  let editNotePopover: JSX.Element | null = null;
  let editTagsPopover: JSX.Element | null = null;
  let deleteHighlightDialog: JSX.Element | null = null;

  if (!shouldUseSelection) {
    editNotePopover = <EditNotePopover
      checkIfShouldScrollIntoView={checkIfShouldScrollIntoView}
      doc={highlight}
      editTagsFormRef={editTagsFormRef}
      getBoundingClientRect={getBoundingClientRectForSubPopovers}
      globalTagsObject={globalTagsObject}
      hidePopover={() => {
        setIsEditNotePopoverShown(false);
        hideAnnotationBarPopover();
      }}
      isShown={isEditNotePopoverShown}
      note={note?.content}
      onActivityChange={setIsDescendantPopoverActive}
      onCancel={onCancelNestedPopover}
      onHiddenWithEscape={onCancelNestedPopover}
      onSave={updateNote}
      positionUpdateCounter={positionUpdateCounter}
      ref={editNotePopoverInnerRef}
      reference={references?.[0]}
      shouldShowInMargin={Boolean(shouldShowInMargin)}
      shouldStayInDomWhenHidden={shouldShowInMargin}
      showPopover={() => setIsEditNotePopoverShown(true)}
    />;

    if (!shouldShowInMargin) {
      editTagsPopover = <EditTagsPopover
        doc={highlight}
        getBoundingClientRect={getBoundingClientRectForSubPopovers}
        globalTagsObject={globalTagsObject}
        hidePopover={() => {
          setIsEditTagsPopoverShown(false);
          hideAnnotationBarPopover();
        }}
        isShown={isEditTagsPopoverShown}
        onChange={onTagsUpdated}
        onHiddenWithEscape={onCancelNestedPopover}
        positionUpdateCounter={positionUpdateCounter}
        ref={editTagsFormRef}
        reference={references?.[0]}
        showPopover={() => setIsEditTagsPopoverShown(true)}
        shouldShowInMargin={shouldShowInMargin}
      />;
    }
  }

  if (highlight?.id && isNotebookView) {
    deleteHighlightDialog = <DeleteHighlightDialog
      isOpen={isDeleteHighlightDialogOpen}
      onConfirm={onConfirmDeleteHighlight}
      onCancel={onCancelDeleteHighlight} />;
  }

  return <>
    <AnnotationBarPopover
      getBoundingClientRect={getHighlightBoundingClientRect}
      hidePopover={hideAnnotationBarPopover}
      highlight={highlight}
      isAutoHighlightingAlwaysEnabled={isAutoHighlightingAlwaysEnabled}
      isShown={isAnnotationBarPopoverShown}
      onClickCopy={onClickCopy}
      onClickCreate={onClickCreate}
      onClickDelete={onClickDelete}
      onClickEditNote={onClickEditNote}
      onClickEditTags={onClickEditTags}
      onClickShare={onClickShare}
      references={references}
      shouldUseSelection={shouldUseSelection}
      showPopover={showAnnotationBarPopover}
      isPdfTron={isPdfTron}
      isNotebookView={isNotebookView}
      {...otherProps}
    />

    {editNotePopover}

    {editTagsPopover}

    {deleteHighlightDialog}

  </>;
}) as React.FC<Props>;

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;


