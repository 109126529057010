import React from 'react';

import Icon from './Icon';

export default function FeedIcon({ className = '', text = 'Feed' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="101" height="102" viewBox="0 0 101 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0404 51.0026C19.9242 51.0026 18.8538 51.446 18.0646 52.2352C17.2754 53.0244 16.832 54.0948 16.832 55.2109C16.832 56.3271 17.2754 57.3975 18.0646 58.1867C18.8538 58.9759 19.9242 59.4193 21.0404 59.4193C26.621 59.4193 31.973 61.6362 35.9191 65.5822C39.8651 69.5283 42.082 74.8803 42.082 80.4609C42.082 81.5771 42.5254 82.6475 43.3146 83.4367C44.1038 84.2259 45.1742 84.6693 46.2904 84.6693C47.4065 84.6693 48.4769 84.2259 49.2661 83.4367C50.0553 82.6475 50.4987 81.5771 50.4987 80.4609C50.4987 72.6481 47.3951 65.1553 41.8705 59.6307C36.346 54.1062 28.8532 51.0026 21.0404 51.0026ZM21.0404 34.1693C19.9242 34.1693 18.8538 34.6126 18.0646 35.4019C17.2754 36.1911 16.832 37.2615 16.832 38.3776C16.832 39.4937 17.2754 40.5641 18.0646 41.3533C18.8538 42.1426 19.9242 42.5859 21.0404 42.5859C31.0854 42.5859 40.7191 46.5763 47.822 53.6793C54.925 60.7822 58.9154 70.4159 58.9154 80.4609C58.9154 81.5771 59.3587 82.6475 60.148 83.4367C60.9372 84.2259 62.0076 84.6693 63.1237 84.6693C64.2398 84.6693 65.3102 84.2259 66.0994 83.4367C66.8886 82.6475 67.332 81.5771 67.332 80.4609C67.2994 68.1894 62.4305 56.4254 53.7812 47.7201C45.0759 39.0708 33.3119 34.2019 21.0404 34.1693ZM65.6908 35.8105C53.8182 24.0168 37.775 17.3788 21.0404 17.3359C19.9242 17.3359 18.8538 17.7793 18.0646 18.5685C17.2754 19.3577 16.832 20.4282 16.832 21.5443C16.832 22.6604 17.2754 23.7308 18.0646 24.52C18.8538 25.3092 19.9242 25.7526 21.0404 25.7526C35.5499 25.7526 49.4652 31.5165 59.725 41.7763C69.9848 52.0361 75.7487 65.9514 75.7487 80.4609C75.7487 81.5771 76.1921 82.6475 76.9813 83.4367C77.7705 84.2259 78.8409 84.6693 79.957 84.6693C81.0731 84.6693 82.1436 84.2259 82.9328 83.4367C83.722 82.6475 84.1654 81.5771 84.1654 80.4609C84.1225 63.7263 77.4845 47.6831 65.6908 35.8105Z" />
    </svg>
  </Icon>;
}

