import type { ReducedHighlight } from '../../types/index';
import type { RangyHighlight } from './rangy';

/*
  knownHighlights are highlights that the content frame is aware of. This doesn't necessarily mean
  that they have been rendered.
*/

export enum KnownHighlightStatus {
  DeserializationFailed = 'deserialization-failed',
  EnlargementFailed = 'enlargement-failed',
  EnlargementInProgress = 'enlargement-in-progress',
  NonRendered = 'non-rendered',
  RemovalInProgress = 'removal-in-progress',
  RenderInProgress = 'render-in-progress',
  Rendered = 'rendered',
}

export type AbstractKnownHighlight = ReducedHighlight & {
  intersectionObserver: IntersectionObserver | null;
  // The content and HTML are duplicated because we'd like to know if they differ from persisted values
  renderedData: Pick<AbstractKnownHighlight, 'content' | 'html'> & {
    rangyHighlight?: RangyHighlight; // only exists if it the highlight contains text
  } | null;
};

export type KnownHighlightThatFailedToDeserialize = AbstractKnownHighlight & {
  intersectionObserver: null;
  renderedData: null;
  status: KnownHighlightStatus.DeserializationFailed;
};

export type KnownHighlightThatFailedToEnlarge = AbstractKnownHighlight & {
  intersectionObserver: null;
  status: KnownHighlightStatus.EnlargementFailed;
};

export type NonRenderedKnownHighlight = AbstractKnownHighlight & {
  // intersectionObserver can still be null here
  renderedData: null;
  status: KnownHighlightStatus.NonRendered;
};

export type RemovalInProgressKnownHighlight = AbstractKnownHighlight & {
  intersectionObserver: null;
  status: KnownHighlightStatus.RemovalInProgress;
};

export type RenderedKnownHighlight = AbstractKnownHighlight & {
  renderedData: NonNullable<AbstractKnownHighlight['renderedData']>;
  status: KnownHighlightStatus.Rendered;
};

export type RenderInProgressKnownHighlight = AbstractKnownHighlight & {
  intersectionObserver: null;
  status: KnownHighlightStatus.RenderInProgress;
};
export type EnlargementInProgressKnownHighlight = RenderInProgressKnownHighlight & {
  status: KnownHighlightStatus.EnlargementInProgress;
};

export type KnownHighlight = KnownHighlightThatFailedToDeserialize |
  KnownHighlightThatFailedToEnlarge |
  NonRenderedKnownHighlight |
  RemovalInProgressKnownHighlight |
  RenderedKnownHighlight |
  RenderInProgressKnownHighlight;

export type KnownHighlightsMap = {
  [id: string]: KnownHighlight;
};
