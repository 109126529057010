/* eslint-disable jsx-a11y/no-autofocus */


/* eslint-disable @typescript-eslint/ban-ts-comment */
// eslint-disable-next-line import/no-extraneous-dependencies
import createCache from '@emotion/cache';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CacheProvider } from '@emotion/react';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { GroupBase, OptionProps } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';

/* eslint-enable @typescript-eslint/ban-ts-comment */
import { globalState } from '../../../shared/foreground/models';
import { addTag, removeTag } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import forwardRef from '../../../shared/foreground/utils/forwardRef';
import sortTags from '../../../shared/foreground/utils/sortTags';
import {
  type FirstClassDocument,
  type Highlight,
  type PartialDocument,
  Category,
} from '../../../shared/types';
import type { GlobalTagsObject } from '../../../shared/types/tags';
import { GlobalTag } from '../../../shared/types/tags';
import { isHTMLElement } from '../../../shared/typeValidators';
import { cleanUpTagName } from '../../../shared/utils/cleanAndValidateTagName';
import { convertDocumentTagToGlobalTag } from '../../../shared/utils/globalTags';
import useIsFocused from '../hooks/useIsFocused';
import styles from './EditTagsForm.module.css';
import Tag from './Tag';

// https://github.com/JedWatson/react-select/issues/3680
const MyNonceProvider = ({ nonce, children }: {
  nonce: string;
  children: ReactNode;
}) => {
  const emotionCache = useMemo(
    () => createCache({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: document.querySelector('readwise-tooltip-container')?.shadowRoot,
      key: 'sdfsdfdds',
      nonce,
    }),
    [nonce],
  );
  return <CacheProvider value={emotionCache}>{children}</CacheProvider>;
};

type OptionData = GlobalTag & {
  value?: GlobalTag['name'];
};

const Option: React.FC<OptionProps<OptionData, true, GroupBase<OptionData>>> =
  ({ data, isDisabled, isFocused, innerProps, innerRef, options }) => {
    const classNames = [styles.option];
    if (isDisabled) {
      classNames.push(styles.optionDisabled);
    }
    if (isFocused) {
      classNames.push(styles.optionFocused);
    }

    const tagNames = useMemo(() => {
      return (options.filter((option) => 'name' in option) as OptionData[])
        .map((tag) => cleanUpTagName(tag.name));
    }, [options]);

    const name = useMemo(() => cleanUpTagName(data.name || data.value as OptionData['name']), [data.name, data.value]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isNew = useMemo(() => data.__isNew__ && !tagNames.includes(name), [data.__isNew__, name, tagNames]);
    let prefix: string | JSX.Element = '';
    if (isNew) {
      classNames.push(styles.optionNew);
      prefix = <span className={styles.optionPrefix}>Create tag</span>;
    }

    if (!name) {
      return null;
    }

    return <div
      {...innerProps}
      className={classNames.join(' ')}
      ref={innerRef}>
      <div className={styles.optionInner}>
        {prefix}
        <Tag>{name}</Tag>
      </div>
    </div>;
  };

export type Props = {
  doc?: FirstClassDocument | Highlight | null | void | PartialDocument<FirstClassDocument, 'tags' | 'id' | 'category'>;
  globalTagsObject: GlobalTagsObject;
  isShownInMargin: boolean;
  onActivityChange?: (isActive: boolean) => void;
  onChange?: () => void;
  onMouseDown?: React.MouseEventHandler<HTMLSpanElement>;
  onOptionSelected?: (details: {
    interaction: 'key' | 'mouse';
    keysPressed: {
      cmd: boolean;
      ctrl: boolean;
    };
  }) => void;
  shouldShowIfEmpty?: boolean;
} & CreatableProps<OptionData, true, GroupBase<OptionData>>;

export type RefValue = { focus(): void; };
export type Ref = React.Ref<RefValue> | null | undefined;

export default React.memo(forwardRef<Props, RefValue>((function EditTagsForm({
  /* eslint-disable @typescript-eslint/no-empty-function */
  globalTagsObject,
  className,
  doc,
  isShownInMargin,
  onActivityChange = () => { },
  onChange = () => { },
  onOptionSelected,
  shouldShowIfEmpty = true,
  /* eslint-enable @typescript-eslint/no-empty-function */
  ...otherProps
}, ref) {
  const docId = doc?.id;
  const tagNamesUsedRecently = globalState(useCallback((state) => state.tagNamesUsedRecently, []));
  const sortedOptions = useMemo(() => {
    return sortTags({ tags: globalTagsObject ?? {}, tagNamesUsedRecently }) as unknown as GlobalTag[];
  }, [globalTagsObject, tagNamesUsedRecently]);
  const currentTags = useMemo(() => Object.values(doc?.tags ?? {}).map(convertDocumentTagToGlobalTag), [doc?.tags]);

  const { isFocused, onBlur, onFocus } = useIsFocused();

  useEffect(() => {
    onActivityChange(isFocused);
  }, [isFocused, onActivityChange]);

  const blur = useCallback(() => {
    if (isHTMLElement(document.activeElement)) {
      document.activeElement.blur();
    }
  }, []);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    event.stopPropagation();

    if (event.key !== 'Escape') {
      return;
    }

    if (isShownInMargin) {
      blur();
    }
  }, [blur, isShownInMargin]);

  const [isCmdPressed, setIsCmdPressed] = useState(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const lastUpEventFiredRef = useRef<'key' | 'mouse' | null>(null);
  useEffect(() => {
    const onDocumentKeyDown = (event: KeyboardEvent) => {
      const lowercasedKey = event.key.toLowerCase();
      if (lowercasedKey === 'meta') {
        setIsCmdPressed(true);
      } else if (lowercasedKey === 'control') {
        setIsCtrlPressed(true);
      }
    };
    const onDocumentKeyUp = (event: KeyboardEvent) => {
      lastUpEventFiredRef.current = 'key';
      const lowercasedKey = event.key.toLowerCase();
      if (lowercasedKey === 'meta') {
        setIsCmdPressed(false);
      } else if (lowercasedKey === 'control') {
        setIsCtrlPressed(false);
      }
    };
    const onDocumentMouseUp = () => {
      lastUpEventFiredRef.current = 'mouse';
    };

    const commonEventListenerOptions = { capture: true };

    document.addEventListener('keydown', onDocumentKeyDown, commonEventListenerOptions);
    document.addEventListener('keyup', onDocumentKeyUp, commonEventListenerOptions);
    document.addEventListener('mouseup', onDocumentMouseUp, commonEventListenerOptions);

    return () => {
      document.removeEventListener('keydown', onDocumentKeyDown);
      document.removeEventListener('keyup', onDocumentKeyUp);
      document.removeEventListener('mouseup', onDocumentMouseUp);
    };
  }, []);

  const deselectOption = useCallback((tag: GlobalTag) => {
    if (!docId) {
      return;
    }
    removeTag(
      docId,
      tag.name,
      {
        userInteraction: 'unknown',
      },
    );
  }, [docId]);

  const selectOption = useCallback((tag: GlobalTag & { value?: string; }) => {
    if (!docId) {
      return;
    }
    addTag(
      docId,
      tag.name || (tag.value as string),
      {
        userInteraction: 'unknown',
      },
    );
    onOptionSelected?.({
      interaction: lastUpEventFiredRef.current as NonNullable<typeof lastUpEventFiredRef.current>,
      keysPressed: {
        cmd: isCmdPressed,
        ctrl: isCtrlPressed,
      },
    });
  }, [docId, isCmdPressed, isCtrlPressed, lastUpEventFiredRef, onOptionSelected]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onChangeCallback: NonNullable<CreatableProps<OptionData, true, GroupBase<OptionData>>['onChange']> = useCallback((values, { action, option }) => {
    onChange();
    if (action === 'pop-value') {
      const lastSelectedOption = currentTags[currentTags.length - 1];
      if (!lastSelectedOption) {
        throw new Error("Can't find last selected option");
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      deselectOption(lastSelectedOption);
      return;
    }

    if (!option) {
      throw new Error('No option');
    }

    if (action === 'deselect-option') {
      deselectOption(option);
      return;
    }

    if (['create-option', 'select-option'].includes(action)) {
      selectOption(option);
    }
  }, [currentTags, deselectOption, onChange, selectOption]);

  const rootClasses = [styles.selectRoot, className].filter(Boolean);

  if (!shouldShowIfEmpty && !isFocused && !currentTags.length) {
    rootClasses.push('hideAccessibly');
  }

  if (isShownInMargin) {
    rootClasses.push(styles.selectRootShownInMargin);

    if (!isFocused) {
      rootClasses.push(styles.selectRootInReadOnlyMode);
    }
  }

  useEffect(() => {
    if (!ref.current?.inputRef) {
      return;
    }

    const element = ref.current?.inputRef.closest(`.${styles.selectRoot}`);
    element.addEventListener('focusin', onFocus);
    element.addEventListener('focusout', onBlur);
    return () => {
      element?.removeEventListener('focusin', onFocus);
      element?.removeEventListener('focusout', onBlur);
    };
  }, [onBlur, onFocus, ref]);

  const placeholder = useMemo(
    () => `Find or create ${doc?.category === Category.Highlight ? 'highlight' : 'document'} tag...`,
    [doc?.category],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <MyNonceProvider>
    <Creatable
      autoFocus={false}
      className={rootClasses.join(' ')}
      classNamePrefix="select"
      closeMenuOnSelect={false}
      components={{
        /* eslint-disable @typescript-eslint/naming-convention */
        IndicatorsContainer: () => null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line func-name-matching
        MultiValue: function SelectedTag({ children, data }) {
          return <Tag
            className={styles.selectedTag}
            hasHoverStyle
            isRemovable
            onClick={() => {
              deselectOption(data);
            }}>
            {children as string}
          </Tag>;
        },
        Option,
        /* eslint-enable @typescript-eslint/naming-convention */
      }}
      escapeClearsValue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionLabel={(option) => option.name && cleanUpTagName(option.name)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionValue={(option) => option.name && cleanUpTagName(option.name)}
      hideSelectedOptions
      isMulti
      menuIsOpen
      menuShouldScrollIntoView={false}
      noOptionsMessage={() => 'No tags'}
      onBlur={onBlur}
      onChange={onChangeCallback}
      onKeyDown={onKeyDown}
      options={sortedOptions}
      ref={ref}
      placeholder={placeholder}
      value={currentTags}
      {...otherProps}
    />
  </MyNonceProvider>;
}) as React.FC<Props>));
