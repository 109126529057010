import { Font } from '../../../shared/types';

export const generateAssetFontCss = (
    {
      fontName,
      cssFontName,
      platformOS,
    }: {
      fontName: string;
      cssFontName: string;
      platformOS: string;
    },
    ): { styleContent: string; links: string[]; } => {
  const getFileUri = (fontFileName: string) => {
    return platformOS === 'ios' ? `${fontFileName}` : `file:///android_asset/fonts/${fontFileName}`;
  };

  let styleContent = '';
  let uris: string[] = [];

  if (fontName === Font.OpenDyslexic) {
    uris = [
      getFileUri('OpenDyslexic-Regular.woff2'),
      getFileUri('OpenDyslexic-Italic.woff2'),
      getFileUri('OpenDyslexic-Bold.woff2'),
      getFileUri('OpenDyslexic-Bold-Italic.woff2'),
    ];
    styleContent = `@font-face {
            font-family: 'OpenDyslexic';
            font-style: normal;
            font-weight: 400;
            src: local(''),
                url('${uris[0]}') format("woff2");
            font-display: block;
          }
          
          @font-face {
            font-family: 'OpenDyslexic';
            font-style: italic;
            font-weight: 400;
            src: local(''),
                url('${uris[1]}') format("woff2");
            font-display: block;
          }
          
          @font-face {
            font-family: 'OpenDyslexic';
            font-style: normal;
            font-weight: 700;
            src: local(''),
                url('${uris[2]}') format("woff2");
            font-display: block;
          }
          
          @font-face {
            font-family: 'OpenDyslexic';
            font-style: italic;
            font-weight: 700;
            src: local(''),
                url('${uris[3]}') format("woff2");
            font-display: block;
          }`;
  }
  if (fontName === Font.IBMPlexSans) {
    uris = [
      getFileUri('IBMPlexSansVar-Roman.woff2'),
      getFileUri('IBMPlexSansVar-Italic.woff2'),
    ];
    styleContent = `@font-face {
            font-family: 'IBM Plex Sans VF';
            font-weight: 100 900;
            font-display: block;
            font-style: normal;
            font-named-instance: 'Regular';
            src: url(${uris[0]}) format("woff2");
          }
          @font-face {
            font-family: 'IBM Plex Sans VF';
            font-weight: 100 900;
            font-display: block;
            font-style: italic;
            font-named-instance: 'Italic';
            src: url(${uris[1]}) format("woff2");
          }`;
  }

  if (fontName === Font.AtkinsonHyperlegible) {
    uris = [
      getFileUri('Atkinson-Hyperlegible-Regular-102a.woff2'),
      getFileUri('Atkinson-Hyperlegible-Italic-102a.woff2'),
      getFileUri('Atkinson-Hyperlegible-Bold-102a.woff2'),
      getFileUri('Atkinson-Hyperlegible-BoldItalic-102a.woff2'),
    ];
    styleContent = `@font-face {
            font-family: 'Atkinson Hyperlegible';
            font-weight: 400;
            font-display: block;
            font-style: normal;
            font-named-instance: 'Regular';
            src: url('${uris[0]}') format("woff2");
          }
          @font-face {
            font-family: 'Atkinson Hyperlegible';
            font-weight: 400;
            font-display: block;
            font-style: italic;
            font-named-instance: 'Regular';
            src: url('${uris[1]}') format("woff2");
          }
          @font-face {
            font-family: 'Atkinson Hyperlegible';
            font-weight: 700;
            font-display: block;
            font-style: normal;
            font-named-instance: 'Regular';
            src: url('${uris[2]}') format("woff2");
          }
          @font-face {
            font-family: 'Atkinson Hyperlegible';
            font-weight: 700;
            font-display: block;
            font-style: italic;
            font-named-instance: 'Regular';
            src: url('${uris[3]}') format("woff2");
          }`;
  }


  if (fontName === Font.Inter) {
    uris = [
      getFileUri('Inter-roman.var.woff2?v=3.19'),
      getFileUri('Inter-italic.var.woff2?v=3.19'),
    ];
    styleContent = `@font-face {
            font-family: 'Inter VF';
            font-weight: 100 900;
            font-display: block;
            font-style: normal;
            font-named-instance: 'Regular';
            src: url('${uris[0]}') format("woff2");
          }
          @font-face {
            font-family: 'Inter VF';
            font-weight: 100 900;
            font-display: block;
            font-style: italic;
            font-named-instance: 'Italic';
            src: url('${uris[1]}') format("woff2");
          }`;
  }

  if (fontName === Font.Literata) {
    uris = [
      getFileUri('Literata[opsz,wght].woff2'),
      getFileUri('Literata-Italic[opsz,wght].woff2'),
    ];
    styleContent = `@font-face {
          font-family: 'Literata VF';
          font-weight: 400 700;
          font-display: block;
          font-style: normal;
          font-named-instance: 'Regular';
          src: url('${uris[0]}') format("woff2");
        }
        
        @font-face {
          font-family: 'Literata VF';
          font-weight: 400 700;
          font-display: block;
          font-style: italic;
          font-named-instance: 'Italic';
          src: url('${uris[1]}') format("woff2");
        }`;
  }

  if (fontName === Font.Piazzolla) {
    uris = [
      getFileUri('Piazzolla[opsz,wght].woff2'),
      getFileUri('Piazzolla-Italic[opsz,wght].woff2'),
    ];
    styleContent = `@font-face {
          font-family: 'Piazzolla VF';
          font-weight: 100 900;
          font-display: block;
          font-style: normal;
          font-named-instance: 'Regular';
          src: url('${uris[0]}') format("woff2");
        }
        @font-face {
          font-family: 'Piazzolla VF';
          font-weight: 100 900;
          font-display: block;
          font-style: italic;
          font-named-instance: 'Italic';
          src: url('${uris[1]}') format("woff2");
        }`;
  }

  if (fontName === Font.SourceSans) {
    uris = [
      getFileUri('SourceSans3VF-Roman.ttf.woff2'),
      getFileUri('SourceSans3VF-Italic.ttf.woff2'),
    ];
    styleContent = `@font-face {
          font-family: 'Source Sans VF';
          font-weight: 100 900;
          font-display: block;
          font-style: normal;
          font-named-instance: 'Regular';
          src: url('${uris[0]}') format("woff2");
        }
        @font-face {
          font-family: 'Source Sans VF';
          font-weight: 100 900;
          font-display: block;
          font-style: italic;
          font-named-instance: 'Italic';
          src: url('${uris[1]}') format("woff2");
        }`;
  }

  if (fontName === Font.SourceSerif) {
    uris = [
      getFileUri('SourceSerif4Variable-Roman.ttf.woff2'),
      getFileUri('SourceSerif4Variable-Italic.ttf.woff2'),
    ];
    styleContent = `@font-face {
          font-family: 'Source Serif VF';
          font-weight: 100 900;
          font-display: block;
          font-style: normal;
          font-named-instance: 'Regular';
          src: url('${uris[0]}') format("woff2");
        }
        @font-face {
          font-family: 'Source Serif VF';
          font-weight: 100 900;
          font-display: block;
          font-style: italic;
          font-named-instance: 'Italic';
          src: url('${uris[1]}') format("woff2");
        }`;
  }

  if (fontName === Font.PublicSans) {
    uris = [
      getFileUri('PublicSans-Regular[wght].ttf'),
      getFileUri('PublicSans-Italic[wght].ttf'),
    ];
    styleContent = `@font-face {
      font-family: 'Public Sans VF';
      font-weight: 400 900;
      font-display: block;
      font-style: normal;
      size-adjust: 103%;
      font-named-instance: 'Regular';
      src: url('${uris[0]}') format("woff2");
    }
    @font-face {
      font-family: 'Public Sans VF';
      font-weight: 400 900;
      font-display: block;
      font-style: italic;
      size-adjust: 103%;
      font-named-instance: 'Italic';
      src: url('${uris[0]}') format("woff2");
    }`;
  }

  const links = uris.map((uri) => `<link rel="preload" href="${uri}" as="font" type="font/woff2" >`);

  return { styleContent: `${styleContent}
  :root {
    --reading-editable-font-family: ${cssFontName};
  }`,
    links };
};
