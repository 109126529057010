import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import forwardRef from '../../../../shared/foreground/utils/forwardRef';
import { isHTMLElement } from '../../../../shared/typeValidators';
import delay from '../../../../shared/utils/delay';
import focusFirstFocusableDescendant from '../../utils/focusFirstFocusableDescendant';
import getNumericCssPropertyValue from '../../utils/getNumericCssPropertyValue';
import Button from '../Button';
import EditNoteForm, { Props as EditNoteFormProps } from '../EditNoteForm';
import EditTagsForm, { Props as EditTagsFormProps, Ref as EditTagsFormRef } from '../EditTagsForm';
import CloseIcon from '../icons/CloseIcon';
import styles from './EditNotePopover.module.css';
import Popover, { Props as PopoverProps } from './Popover';

type Props = {
  editTagsFormRef: EditTagsFormRef;
  excludeTags?: boolean;
  formToFocusNextTimeIsShown?: string | null;
  placeholder?: string;
  readOnly?: boolean;
  saverName?: string;
  shouldShowInMargin: boolean;
}
  & PopoverProps
  & Omit<EditNoteFormProps, 'isShownInMargin' | 'requestPopoverHide'>
  & Omit<EditTagsFormProps, 'isShownInMargin'>;

export default React.memo(forwardRef<Props, HTMLFormElement>((function EditNotePopover({
  doc,
  editTagsFormRef,
  excludeTags,
  formToFocusNextTimeIsShown,
  globalTagsObject,
  hidePopover,
  isShown,
  note = '',
  onActivityChange,
  onCancel,
  onHiddenWithEscape,
  onSave,
  placeholder,
  readOnly,
  reference,
  saverName,
  shouldShowInMargin,
  showPopover,
  ...otherProps
}, editNoteFormRef) {
  const [hasDirtyTrimmedValue, setHasDirtyTrimmedValue] = useState(false);
  const hasTags = useMemo(() => !excludeTags && Object.keys(doc?.tags ?? {}).length, [doc?.tags, excludeTags]);
  const [isEditTagsFormFocused, setIsEditTagsFormFocused] = useState(false);

  const isNonEmptyInMargin = useMemo(() => shouldShowInMargin && (note || hasTags), [hasTags, note, shouldShowInMargin]);
  const shouldBeKeptOpen = useMemo(() => {
    return hasDirtyTrimmedValue || isNonEmptyInMargin;
  }, [hasDirtyTrimmedValue, isNonEmptyInMargin]);

  useEffect(() => {
    if (!isShown || !formToFocusNextTimeIsShown) {
      return;
    }
    (async () => {
      await delay(200);
      if (!editNoteFormRef.current) {
        return;
      }

      focusFirstFocusableDescendant(editNoteFormRef.current);
    })();
  }, [editNoteFormRef, formToFocusNextTimeIsShown, isShown]);

  useEffect(() => {
    if (shouldBeKeptOpen) {
      showPopover?.();
    }

    /*
      shouldShowInMargin is included to cover this case:
      1. Press `n` and add note while popover is not shown in the margin.
      2. Hide sidebars. The note should automatically show in the margin.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBeKeptOpen, shouldShowInMargin]);

  // When resizing down, hide popovers without dirty values
  useEffect(() => {
    if (shouldShowInMargin || !isShown) {
      return;
    }

    if (hasDirtyTrimmedValue) {
      showPopover?.();
      return;
    }

    hidePopover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowInMargin]);

  const onHideRequestedFromEditNoteForm = useCallback(() => {
    if (shouldShowInMargin && hasTags) {
      if (isHTMLElement(document.activeElement)) {
        document.activeElement.blur();
      }
      return;
    }
    hidePopover();
  }, [hasTags, hidePopover, shouldShowInMargin]);

  let noteForm: JSX.Element;
  if (readOnly) {
    noteForm = <article
      className={styles.readOnlyNote}
      ref={editNoteFormRef}>
        <header className={styles.readOnlyHeader}>
          <h1 className={styles.readOnlyAuthor}>{saverName}</h1>
          <Button
            aria-hidden
            className={styles.readOnlyCloseButton}
            onClick={hidePopover}
            variant="unstyled">
            <CloseIcon />
          </Button>
        </header>
        {note && <main className={styles.readOnlyNoteContents}>
          <p dangerouslySetInnerHTML={{ __html: note.replace(/\n/g, '<br/>') }} />
        </main>}
    </article>;
  } else {
    noteForm = <EditNoteForm
      isFollowingFormFocused={isEditTagsFormFocused}
      isShownInMargin={shouldShowInMargin}
      note={note}
      onActivityChange={onActivityChange}
      onCancel={onCancel}
      onHasDirtyTrimmedValueUpdated={setHasDirtyTrimmedValue}
      onSave={onSave}
      ref={editNoteFormRef}
      requestPopoverHide={onHideRequestedFromEditNoteForm}
      shouldShowIfEmpty={!shouldShowInMargin}
      placeholder={placeholder}
    />;
  }

  const rootClasses = [styles.root, 'js_edit-note-popover'];
  if (shouldShowInMargin) {
    rootClasses.push(styles.rootShownInMargin);
  } else {
    rootClasses.push(styles.rootShownInline);
  }
  if (readOnly) {
    rootClasses.push(styles.rootIsReadOnly);
  }
  const isFixedToScreenEdge = globalState(useCallback((state) => state.screenWidth < getNumericCssPropertyValue('--document-share-width-popovers-float-at'), []));
  if (!isFixedToScreenEdge) {
    rootClasses.push(styles.rootIsNotFixedToScreenEdge);
  }

  return <Popover
    className={rootClasses.join(' ')}
    hasPopperStyles={!isFixedToScreenEdge}
    hidePopover={hidePopover}
    isShown={isShown}
    onHiddenWithEscape={onHiddenWithEscape}
    pointerDownTimeout={readOnly ? 100 : undefined}
    popperOptions={{
      placement: shouldShowInMargin ? 'right-start' : 'bottom',
      modifiers: [{
        enabled: Boolean(readOnly && !isFixedToScreenEdge && shouldShowInMargin),
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      }],
    }}
    reference={reference}
    shouldAutoFocus={!shouldShowInMargin}
    shouldHideOnBlur={false}
    shouldHideOnClickOutside={!shouldBeKeptOpen}
    shouldHideOnEscape={!isNonEmptyInMargin}
    showPopover={showPopover}
    {...otherProps}
  >
    {noteForm}
    {!excludeTags && shouldShowInMargin && <EditTagsForm
      doc={doc}
      globalTagsObject={globalTagsObject}
      isShownInMargin={shouldShowInMargin}
      onActivityChange={onActivityChange}
      onBlur={() => setIsEditTagsFormFocused(false)}
      onFocus={() => setIsEditTagsFormFocused(true)}
      ref={editTagsFormRef}
      shouldShowIfEmpty={false}
    />}
  </Popover>;
}) as React.FC<Props>));
