import {
  type AnyDocument,
  type UserEvent,
} from '../../../../types';
import { isDocumentWithSummary } from '../../../../typeValidators';
import { overrideGeneratedSummaryInDocData } from '../../../stateUpdateHelpers';
import { createToast } from '../../../toasts.platform';
import type { StateUpdateResult } from '../../../types';
import { updateDocument } from './update';

export async function updateGeneratedDocumentSummary(documentId: AnyDocument['id'], generatedSummary: string): StateUpdateResult {
  const result = await updateDocument(documentId, (document) => {
    if (!isDocumentWithSummary(document)) {
      throw new Error('Document type does not support summaries');
    }

    document.generated_summary = generatedSummary;
      // reset override for generated summary
    overrideGeneratedSummaryInDocData(document, undefined);
  }, {
    eventName: 'generated-document-summary-updated',
    userInteraction: 'click',
  });

  createToast({
    content: 'Document summary generated',
    category: 'success',
    undoableUserEventId: (result.userEvent as UserEvent).id,
  });
  return result;
}
