import binarySearch from '../../utils/binarySearch';

// If in scrollable element, set viewportTop to the scrollable element's (bounding client rect) top
const getFirstElementInViewport = async ({
  element,
  scrollableRoot,
}: {
  element: HTMLElement;
  scrollableRoot: HTMLElement;
}): Promise<HTMLElement | null> => {
  if (!element) {
    return null;
  }

  const children = Array.from(element.children) as HTMLElement[];
  const firstChildInViewport = await binarySearch(children, async (child) => child.getBoundingClientRect().bottom > 0);

  if (!firstChildInViewport) {
    return null;
  }

  if (!firstChildInViewport.children.length) {
    return firstChildInViewport;
  }

  return await getFirstElementInViewport({ element: firstChildInViewport, scrollableRoot }) ?? firstChildInViewport;
};

export default getFirstElementInViewport;
