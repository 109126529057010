import { useCallback } from 'react';

import type {
  DocumentWithTransientData,
  FirstClassDocument,
  KeyOfDocumentWithTransientData,
  PartialDocument,
} from '../../types';
import { DatabaseHookResultArray } from '../../types/database';
import { globalState } from '../models';
import { useDocument, usePartialDocument } from './index';

export function useFocusedDocumentId(): FirstClassDocument['id'] | null {
  return globalState(useCallback((state) => state.focusedDocumentId, []));
}
export function useFocusedPartialDocument<
  T extends DocumentWithTransientData<FirstClassDocument>,
  OnlyKey extends KeyOfDocumentWithTransientData<T>,
>(
  keys: OnlyKey[],
): DatabaseHookResultArray<PartialDocument<T, OnlyKey> | null> {
  return usePartialDocument<T, OnlyKey>(useFocusedDocumentId(), keys);
}
export default function useFocusedDocument() {
  return useDocument<FirstClassDocument>(useFocusedDocumentId());
}
