import escapeStringRegexp from 'escape-string-regexp';
import { HotkeysEvent } from 'hotkeys-js';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import * as hotkeys from 'react-hotkeys-hook';
import type { HotkeyCallback } from 'react-hotkeys-hook/dist/types';
import { useRouteMatch } from 'react-router-dom';

import { globalState } from '../../../shared/foreground/models';
import { isNarrowScreenSize } from '../../../shared/foreground/stateHooks';
import { setKeyboardShortcut, unsetKeyboardShortcut } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/keyboardShortcuts';
import isComposing from '../../../shared/foreground/utils/isComposing';
import isElementTypable from '../../../shared/foreground/utils/isElementTypable';
import type { LenientWindow } from '../../../shared/types/LenientWindow';
import { notEmpty } from '../../../shared/typeValidators';
import { NOTEBOOK_PATH_PATTERN } from '../components/NotebookView/constants';
import type { NotebookRouteParams } from '../components/NotebookView/NotebookPage';
import getEventTarget from '../utils/getEventTarget';
import { isNotebookViewPath, isReaderViewUrl } from '../utils/pathnameHelpers';
import aliasesWeReplace from '../utils/shortcuts/aliasesWeReplace';
import useLocation from '../utils/useLocation';

declare let window: LenientWindow;

function canExecuteKeyboardShortcutCallback(
  event?: KeyboardEvent,
  options?: {
    hotKeyOptions?: hotkeys.Options;
  },
): boolean {
  if (
    window.isRadixDropdownOpen ||
    window.isRecordingCustomShortcut ||
    event && (
      isComposing(event) ||
      options?.hotKeyOptions &&
      !options.hotKeyOptions.enableOnContentEditable &&
      !options.hotKeyOptions.enableOnFormTags &&
      isElementTypable(getEventTarget(event))
    )
  ) {
    return false;
  }
  return true;
}

// Internal
function useSetKeyboardShortcut({
  customId,
  description,
  keys,
  shouldShowInHelp,
  wrappedCallback,
}: {
  customId?: string;
  description: string;
  keys: string[];
  shouldShowInHelp: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrappedCallback: (...args: any[]) => unknown;
} & Omit<Parameters<typeof useHotKeys>[2], 'dhotKeyOptions'>) {
  const currentShortcutKeysRef = useRef<string | null>(null);
  useEffect(() => {
    currentShortcutKeysRef.current = keys[0];
    const setKeyboardShortcutPromise = setKeyboardShortcut({
      callback: wrappedCallback,
      description,
      customId,
      keys: keys[0],
      shouldShowInHelp,
    }, { userInteraction: null });

    return () => {
      currentShortcutKeysRef.current = null;
      (async () => {
        const id = await setKeyboardShortcutPromise;

        /*
          The same shortcut is being re-set. Skip the `unset...` call to avoid a race condition.
          `set...` being called again (without an `unset...` call in between) is safe.
        */
        if (currentShortcutKeysRef.current === keys[0]) {
          return;
        }
        unsetKeyboardShortcut(id, { userInteraction: null });
      })();
    };
  }, [customId, description, keys, shouldShowInHelp, wrappedCallback]);
}

// Use CmdOrCtrl in the keys will result in Command being used for Mac, Control being used for everything else
export const useHotKeys = <T extends HTMLElement>(
  keys: string[],
  callback: (event: KeyboardEvent, handler?: HotkeysEvent) => void,
  options: {
    customId?: string;
    description?: string;
    hotKeyOptions?: hotkeys.Options;
    shouldShowInHelp?: boolean;
  } = {},
): ReturnType<typeof hotkeys.useHotkeys> => {
  const {
    customId,
    description = '',
    hotKeyOptions = {},
    shouldShowInHelp = Boolean(options.description),
  } = options;

  const _hotKeyOptions: hotkeys.Options = useMemo(
    () => keys ? hotKeyOptions : { ...hotKeyOptions, enabled: false },
    [hotKeyOptions, keys],
  );

  const wrappedCallback: (event: KeyboardEvent, handler?: HotkeysEvent) => void = useCallback((
    event: KeyboardEvent,
    handler?: HotkeysEvent,
  ) => {
    if (!canExecuteKeyboardShortcutCallback(event, { hotKeyOptions })) {
      return;
    }
    callback(event, handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  useSetKeyboardShortcut({
    customId,
    description,
    keys,
    shouldShowInHelp,
    wrappedCallback,
  });

  const hotKeysArguments: Parameters<typeof hotkeys.useHotkeys> = useMemo(() => {
    const keyEntries: string[] = [];
    let didDuplicateAKeyEntry = false;

    /*
      If no keys are provided, use a dummy key to prevent hotkeys from breaking
      but we are setting enabled: false in the options
    */
    const keysToUse = keys || ['-'];

    for (const item of keysToUse) {
      keyEntries.push(item);

      /*
        For cases like https://github.com/JohannesKlauss/react-hotkeys-hook/issues/947, we create multiple listeners
        with slight variations on the keys (e.g. ` -> ` and INTLBACKSLASH).
        Also see aliasesWeReplace.ts
      */
      for (const [typicalName, badValues] of Object.entries(aliasesWeReplace)) {
        for (const badValue of badValues) {
          const itemLowered = item.toLowerCase();
          const badValueLowered = badValue.toLowerCase();

          if (itemLowered.includes(typicalName)) {
            keyEntries.push(item.replace(new RegExp(escapeStringRegexp(typicalName), 'gi'), badValue));
            didDuplicateAKeyEntry = true;
          } else if (itemLowered.includes(badValueLowered)) {
            keyEntries.push(item.replace(new RegExp(escapeStringRegexp(badValue), 'gi'), typicalName));
            didDuplicateAKeyEntry = true;
          }
        }
      }
    }

    const debouncedWrappedCallback = didDuplicateAKeyEntry
      ? debounce(wrappedCallback)
      : wrappedCallback;

    // eslint-disable-next-line @shopify/react-hooks-strict-return
    return [
      keyEntries,
      debouncedWrappedCallback as unknown as HotkeyCallback,
      _hotKeyOptions,
    ];
  }, [keys, wrappedCallback, _hotKeyOptions]);

  return hotkeys.useHotkeys<T>(...hotKeysArguments);
};

export const useHotKeysPreventDefault = (
  keys: string[],
  callback: (event: KeyboardEvent, handler?: HotkeysEvent) => void,
  options?: Parameters<typeof useHotKeys>[2],
): ReturnType<typeof useHotKeys> => {
  const newCallback = useCallback((event: KeyboardEvent, handler?: HotkeysEvent) => {
    event.preventDefault();
    callback(event, handler);
  }, [callback]);
  return useHotKeys(keys, newCallback, options);
};

export function useMediaKey(
  key: MediaSessionAction | undefined,
  callback: () => void,
  options: Parameters<typeof useHotKeys>[2] & {
    isEnabled?: boolean;
  } = {},
) {
  const {
    customId,
    description = '',
    isEnabled: isEnabledArgument = true,
    shouldShowInHelp = Boolean(options.description),
  } = options;

  const wrappedCallback = useCallback(() => {
    if (!canExecuteKeyboardShortcutCallback()) {
      return;
    }
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  const isEnabled = useMemo(() => isEnabledArgument && 'mediaSession' in navigator && key, [isEnabledArgument, key]);

  useSetKeyboardShortcut({
    customId,
    description,
    keys: [key].filter(notEmpty),
    shouldShowInHelp,
    wrappedCallback,
  });

  useEffect(() => {
    if (!isEnabled || !key) {
      return;
    }

    navigator.mediaSession.setActionHandler(key, wrappedCallback);
    return () => {
      navigator.mediaSession.setActionHandler(key, null);
    };
  }, [isEnabled, key, wrappedCallback]);
}

export const useScrollToTop = (element: HTMLElement | null): void => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [pathname, element]);
};


export const useIsLeftSidebarHidden = (): boolean => {
  const { pathname } = useLocation();
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));
  const hiddenInReadingView = globalState(useCallback((state) => state.leftSidebarHiddenInReadingView, []));
  const leftSidebarHidden = isReaderViewUrl(pathname) || isNotebookViewPath(pathname) ? hiddenInReadingView : false;
  const leftSidebarHiddenForNarrowScreen = globalState(useCallback((state) => state.leftSidebarHiddenForNarrowScreen, []));
  return isNarrowScreenSize(screenWidth) ? leftSidebarHiddenForNarrowScreen : leftSidebarHidden;
};

export const useIsRightSidebarHidden = (): boolean => {
  const { pathname } = useLocation();
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));
  const hiddenInReadingView = globalState(useCallback((state) => state.rightSidebarHiddenInReadingView, []));
  const hiddenInList = globalState(useCallback((state) => state.client.rightSidebarHiddenInList, []));
  const rightSidebarHidden = isReaderViewUrl(pathname) || isNotebookViewPath(pathname) ? hiddenInReadingView : hiddenInList;
  const rightSidebarHiddenForNarrowScreen = globalState(useCallback((state) => state.rightSidebarHiddenForNarrowScreen, []));
  const pathsToAlwaysHideRightSidebar = [
    '/account',
    '/database-explorer',
    '/database-query-optimizer',
    '/feed/sources',
    '/import',
    '/integrations',
    '/preferences',
    '/product-emails',
    '/profile',
    '/tags',
    '/views',
  ];

  const shouldHideRightSidebar = pathsToAlwaysHideRightSidebar.some((path) => pathname.startsWith(path));

  if (shouldHideRightSidebar) {
    return true;
  }

  return isNarrowScreenSize(screenWidth) ? rightSidebarHiddenForNarrowScreen : rightSidebarHidden;
};

export const useSidebarsHidden = (): boolean => {
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const rightSidebarHidden = useIsRightSidebarHidden();
  return leftSidebarHidden && rightSidebarHidden;
};

export function useNotebookViewParams(): NotebookRouteParams | null {
  return useRouteMatch<NotebookRouteParams>(NOTEBOOK_PATH_PATTERN)?.params ?? null;
}
