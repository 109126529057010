import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { disableDocumentShare, enableDocumentShare } from '../../../shared/foreground/actions/documentShare';
import eventEmitter from '../../../shared/foreground/eventEmitter';
import { useDocument } from '../../../shared/foreground/stateHooks';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { copyTextToClipboard } from '../../../shared/foreground/utils/copyTextToClipboard';
import { AnyDocument, Article } from '../../../shared/types';
import getServerBaseUrl from '../../../shared/utils/getServerBaseUrl.platform';
import urlJoin from '../../../shared/utils/urlJoin';
import Button from './Button';
import { Dialog } from './Dialog';
import LinkIcon from './icons/LinkIcon';
import Popover from './Popovers/Popover';
import styles from './PublicLinkButton.module.css';

export default function PublicLinkButton({
  docId,
}: {
  docId: AnyDocument['id'];
}) {
  const [doc] = useDocument<Article>(docId);
  const isEnabled = useMemo(() => Boolean(doc?.sharedAt), [doc?.sharedAt]);

  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const hidePopover = useCallback(() => setIsPopoverShown(false), []);
  const showPopover = useCallback(() => setIsPopoverShown(true), []);

  useEffect(() => {
    const onEvent = () => {
      showPopover();
    };
    eventEmitter.on('document-header-document-share-enable-option-clicked', onEvent);
    eventEmitter.on('enable-public-link-shorcut-pressed', onEvent);

    return () => {
      eventEmitter.off('document-header-document-share-enable-option-clicked', onEvent);
      eventEmitter.off('enable-public-link-shorcut-pressed', onEvent);
    };
  }, [showPopover]);

  const triggerButtonRef = useRef<HTMLButtonElement>();

  const onClickButton = useCallback(() => {
    enableDocumentShare({ docId, userInteraction: 'unknown' });
    showPopover();
  }, [docId, showPopover]);

  const onClickDisable = useCallback(() => {
    setIsDisablePromptShown(true);
    hidePopover();
  }, [hidePopover]);

  const [isDisablePromptShown, setIsDisablePromptShown] = useState(false);
  const onConfirmDisable = useCallback(() => {
    disableDocumentShare({ docId, userInteraction: 'unknown' });
    setIsDisablePromptShown(false);
  }, [docId]);
  const onCancelDisable = useCallback(() => setIsDisablePromptShown(false), []);
  let confirmDisableDialog: JSX.Element | null = null;
  if (isDisablePromptShown) {
    confirmDisableDialog = <Dialog
      id="disable-public-link"
      action={onConfirmDisable}
      actionTitle="Disable public link"
      cancelAction={onCancelDisable}
      cancelTitle="Cancel"
      redActionButton
      subtitle="Disabling this public link will prevent anyone with the link from accessing this document."
      title="Disable public link?"
    />;
  }

  const buttonClasses = [styles.publicLinkButton];
  if (isEnabled) {
    buttonClasses.push(styles.publicLinkButtonWhenEnabled);
  }

  if (!isEnabled) {
    return null;
  }

  return <>
    <Button
      className={buttonClasses.join(' ')}
      onClick={onClickButton}
      ref={triggerButtonRef}
      variant="default">
      <LinkIcon text="View public link" />
    </Button>
    <Popover
      className={styles.publicLinkPopover}
      hidePopover={hidePopover}
      isShown={isPopoverShown}
      pointerDownTimeout={100}
      portalDestinationElementId="view-public-link-popover"
      reference={triggerButtonRef.current}
      showPopover={showPopover}
    >
      <div className={styles.publicLinkMain}>
        <p className={styles.publicLinkPopoverTitle}>Public link enabled</p>
        <p>Anyone with this link can view this document along with your highlights, notes, and tags.</p>
        <Button
          className={styles.publicLinkCopyButton}
          onClick={() => {
            copyTextToClipboard(urlJoin([getServerBaseUrl(), `reader/shared/${docId}`]));
            createToast({ content: 'Copied to clipboard', category: 'success' });
            hidePopover();
          }}
          variant="primary">
          Copy link
        </Button>
      </div>

      <Button
        className={styles.publicLinkDisableButton}
        onClick={onClickDisable}
        variant="default">
        Disable public link
      </Button>
    </Popover>
    {confirmDisableDialog}
  </>;
}
