import { MangoQuery } from 'rxdb';

import { AnyDocument, Category, DocumentLocation, JobType } from '../../../../types';
import { setCmdPaletteOpen } from '../../../cmdPalette';
import { createToast } from '../../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../../types';
import { queueJob } from '../jobs';
import { deleteAllDocumentsInList } from './bulk';
import { updateDocument } from './update';

export const toggleEmailSubscription = (fromEmail: string, subscribed: boolean, options: StateUpdateOptionsWithoutEventName) => {
  queueJob({
    jobType: JobType.UpdateEmailSubscriptionStatus,
    jobArguments: { from_email: fromEmail, subscribed },
    options: { ...options },
  });
  createToast({
    content: `${subscribed ? 'Unsubscribed from' : 'Subscribed to'} ${fromEmail}`,
    category: 'success',
  });
};


export const removeDocsFromTheSameEmailAddress = async (fromEmail: string | null | undefined): Promise<void> => {
  if (!fromEmail) {
    return;
  }

  const docQuery: MangoQuery<AnyDocument> = {
    selector: {
      category: Category.Email,
      triage_status: DocumentLocation.Feed,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'source_specific_data.email.from_email': fromEmail,
    },
  };
  deleteAllDocumentsInList({ docQuery, options: { userInteraction: 'unknown' } });
};

export const toggleEmailSubscriptionfromPalette = async (
  emailAddress: string,
  subscribed: boolean,
  removeEmailSubscriptionWarningText: string,
  options: StateUpdateOptionsWithoutEventName,
) => {

  await setCmdPaletteOpen(false, options);
  if (subscribed && emailAddress) {
    // eslint-disable-next-line no-alert
    const shouldContinue = confirm(`Warning: ${removeEmailSubscriptionWarningText} Continue?`);

    if (!shouldContinue) {
      return;
    }

    await removeDocsFromTheSameEmailAddress(emailAddress);
  }
  await toggleEmailSubscription(emailAddress, subscribed, options);
};

export const toggleEmailOriginalStyles = async (documentId: string, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateDocument(documentId, (doc) => {
    if (!doc?.source_specific_data?.email?.originalEmailView) {
      doc.source_specific_data = {
        ...doc?.source_specific_data ?? {},
        email: {
          ...doc?.source_specific_data?.email ?? {},
          originalEmailView: true,
        },
      };
    } else {
      doc.source_specific_data.email.originalEmailView = false;
    }
  }, { ...options, eventName: 'email-original-styles-toggled' });
};
