import React from 'react';

import { Category, FirstClassDocument } from '../../../shared/types';
import styles from './DocumentFaviconOrIcon.module.css';
import BooksIcon from './icons/BooksIcon';
import ArticlesIcon from './icons/DocListArticlesIcon';
import EmailsIcon from './icons/DocListEmailsIcon';
import PDFIcon from './icons/PDFIcon';


const iconMap = {
  [Category.Email]: <EmailsIcon className={styles.docIcon} />,
  [Category.PDF]: <PDFIcon className={styles.docIcon} />,
  [Category.EPUB]: <BooksIcon className={styles.docIcon} />,
};

export default function DocumentFaviconOrIcon({ category, faviconUrl, className = '' }: {category: Category; faviconUrl?: FirstClassDocument['favicon_url']; className?: string; }) {
  const useFaviconUrl = faviconUrl && category !== Category.EPUB && category !== Category.PDF;
  if (useFaviconUrl) {
    return <div className={`favicon ${styles.favicon} ${className}`} style={{ backgroundImage: `url(${faviconUrl})` }} />;
  }

  if (iconMap[category] !== undefined) {
    return iconMap[category];
  }

  return <ArticlesIcon className={`${styles.docIcon} ${className}`} />;
}
