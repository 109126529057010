import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useEffect, useMemo, useRef } from 'react';

import useIsMountedRef from '../../../../shared/foreground/useIsMountedRef';
import forwardRef from '../../../../shared/foreground/utils/forwardRef';
import { getDisplayShortcutKeys } from '../../../../shared/utils/shortcuts';
import Checkbox from '../Checkbox';
import { ActionButton } from '../DocumentActionButtons';
import CheckIcon from '../icons/CheckIcon';
import EllipsisIcon from '../icons/EllipsisIcon';
import PlusCircleIcon from '../icons/PlusCircleIcon';
import Tooltip from '../Tooltip';
import styles from './Dropdown.module.css';

export enum DropdownOptionType {
  Item = 'item',
  Separator = 'separator',
  Title = 'title',
  Checkbox = 'checkbox',
  ScrollableItems = 'ScrollableItems',
  Node = 'Node'
}

export interface DropdownOption {
  checked?: boolean;
  isMinusIcon?: boolean;
  className?: string;
  isCreate?: boolean;
  isDanger?: boolean;
  isDisabled?: boolean;
  disabledTooltip?: string;
  name?: string;
  nameNode?: JSX.Element;
  node?: JSX.Element;
  icon?: JSX.Element;
  onSelect?: (event: Event) => void;
  shortcut?: string | string[];
  setChecked?: (value: boolean) => void;
  type: DropdownOptionType;
  childOptions?: DropdownOption[];
}

type Props = {
  alignOffset?: number;
  appendToDocumentBody?: boolean;
  contentAlignment?: DropdownMenu.DropdownMenuContentProps['align'];
  contentClassName?: string;
  defaultTriggerAttributes?: DropdownMenu.DropdownMenuTriggerProps;
  isOpen: boolean;
  isSubDropdownOpen?: boolean;
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  onFocusOutside?: (event: Event) => void;
  options: DropdownOption[];
  setIsOpen: (isOpen: boolean) => void;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  trigger?: JSX.Element;
  triggerClassName?: string;
  triggerShortcut?: string | string[];
  triggerTooltipText?: string;
  zIndexOverride?: number;
};

const ItemOption = ({ option, index }: {option: DropdownOption; index: number;}) => {
  if (option.type === DropdownOptionType.Item) {
    const itemClasses = [styles.item];
    let tooltip = '';

    if (option.className) {
      itemClasses.push(option.className);
    }

    if (option.isDisabled) {
      itemClasses.push(styles.isDisabled);
      if (option.disabledTooltip) {
        tooltip = option.disabledTooltip;
        itemClasses.push(styles.enablePointerEvents);
      }
    }

    if (option.isCreate) {
      itemClasses.push(styles.isCreate);
    }

    return (
        <DropdownMenu.Item
          key={option.name}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onSelect={(event) => {
            if (!option.isDisabled) {
              option.onSelect?.(event);
            }
          }}
          className={itemClasses.join(' ')}
        >
            <>
              {option.checked && <CheckIcon className={styles.checkIcon} text="Selected:" />}
              {option.isCreate && <PlusCircleIcon /> }
              <Tooltip content={tooltip} maxWidth="300">
                <span className={[option.isDanger ? styles.isDanger : '', option.isDisabled ? styles.isDisabled : '', styles.optionContent].join(' ')}>{option.icon}{option.nameNode ?? option.name}</span>
              </Tooltip>
              {option.shortcut && <div className={styles.shortcut}>{getDisplayShortcutKeys(option.shortcut)}</div>}
            </>
        </DropdownMenu.Item>
    );
  }

  if (option.type === DropdownOptionType.Title && option.name) {
    return <div className={`${styles.title} ${option.className || ''}`}>{option.name}</div>;
  }

  if (option.type === DropdownOptionType.Node && option.node) {
    return option.node;
  }

  if (option.type === DropdownOptionType.Checkbox && option.name) {
    const toggleCheck = () => option.setChecked?.(!option.checked);
    const labelId = `dropdown-item-label-${index}-${Math.random()}`;

    return (
      <DropdownMenu.Item
        className={styles.item}
        onClick={(e) => {
          e.preventDefault();
          toggleCheck();
        }}
      >
        <Checkbox
          className={styles.checkbox}
          isChecked={Boolean(option.checked)}
          isMinusIcon={Boolean(option.isMinusIcon)}
          labelId={labelId}
          onCheckedChange={toggleCheck}
        />

        <label id={labelId}>{option.name}</label>
      </DropdownMenu.Item>
    );
  }

  return <DropdownMenu.Separator key={`separator-${index}`} className={styles.separator} />;
};

export const Dropdown = forwardRef<Props, HTMLButtonElement>(function Dropdown ({
  alignOffset = 0,
  appendToDocumentBody = false,
  contentAlignment = 'start',
  contentClassName,
  defaultTriggerAttributes = {},
  isOpen,
  isSubDropdownOpen,
  onEscapeKeyDown,
  onFocusOutside,
  options,
  setIsOpen,
  side = 'bottom',
  sideOffset = 10,
  trigger,
  triggerClassName = '',
  triggerShortcut = '',
  triggerTooltipText = '',
  zIndexOverride,
}, defaultTriggerRef) {
  const isMountedRef = useIsMountedRef();

  // Setting this flag so we can use it in our useHotKeys hook and prevent
  // our shortcuts to run when the dropdown is open.
  useEffect(() => {
    window.isRadixDropdownOpen = Boolean(isOpen || isSubDropdownOpen);

    return () => {
      window.isRadixDropdownOpen = false;
    };
  }, [isOpen, isSubDropdownOpen]);

  const onOpenChange = (val: boolean) => {
    // Without this timeout the `isDocMoreActionsDropdownOpen` value in Documents.tsx shortcuts
    // is updated too soon.
    setTimeout(() => {
      if (!isMountedRef.current) {
        return;
      }
      setIsOpen(val);
    }, 0);
  };

  const onCloseAutoFocus = (e: Event) => {
    e.preventDefault();
  };

  const defaultTriggerRefToUse = useMemo(() => {
    if (defaultTriggerRef) {
      return defaultTriggerRef;
    }
    return () => undefined;
  }, [defaultTriggerRef]);

  const triggerClasses = [styles.triggerButton, triggerClassName, defaultTriggerAttributes.className].filter(Boolean);

  const defaultTriggerButtonClasses = [];
  if (isOpen || isSubDropdownOpen) {
    defaultTriggerButtonClasses.push(styles.isActive);
  }
  const defaultTrigger = <DropdownMenu.Trigger
    {...defaultTriggerAttributes}
    className={triggerClasses.join(' ')}
    ref={defaultTriggerRefToUse}>
    <ActionButton
      className={defaultTriggerButtonClasses.join(' ')}
      name="dropdown-trigger"
      shortcut={triggerShortcut}
      tooltipText={triggerTooltipText}>
      <EllipsisIcon />
    </ActionButton>
  </DropdownMenu.Trigger>;

  const hasCheckedOptions = options.some((option) => option.type === DropdownOptionType.Item && option.checked);
  const contentClasses = [styles.content];
  if (hasCheckedOptions) {
    contentClasses.push(styles.contentWithChecks);
  }
  if (contentClassName) {
    contentClasses.push(contentClassName);
  }

  const dropdownContentDestinationRef = useRef<HTMLDivElement>(null);

  const dropdownContentDestinationStyles = {
    zIndex: zIndexOverride,
  };

  return <>
    <DropdownMenu.Root
      modal={false}
      onOpenChange={onOpenChange}
      open={isOpen}>
      {trigger ?? defaultTrigger}

      <DropdownMenu.Portal container={appendToDocumentBody ? document.body : dropdownContentDestinationRef.current}>
        <DropdownMenu.Content
          align={contentAlignment}
          alignOffset={alignOffset}
          className={contentClasses.join(' ')}
          onCloseAutoFocus={onCloseAutoFocus}
          onFocusOutside={onFocusOutside}
          onEscapeKeyDown={onEscapeKeyDown}
          side={side}
          sideOffset={sideOffset}
        >
          {options.map((option, index) => {
            if (option.type === DropdownOptionType.ScrollableItems) {
              return (
                <div className={styles.scrollableOptions} key={option.name ?? index}>
                  {option.childOptions?.map((_option) => <ItemOption key={_option.name ?? index} option={_option} index={index} />)}
                </div>
              );
            }

            return <ItemOption key={option.name ?? index} option={option} index={index} />;
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>

    <div ref={dropdownContentDestinationRef} style={dropdownContentDestinationStyles} />
  </>;
});

