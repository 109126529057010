export enum FeedbackCategory {
  Product = 'PRODUCT',
  Account = 'ACCOUNT',
  Parsing = 'PARSING',
  Bug = 'BUG',
  Feature = 'FEATURE',
}

export enum FeedbackSubCategory {
  Performance = 'PERFORMANCE',
  Parsing = 'PARSING',
  Extension = 'EXTENSION',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
  TTS = 'TTS',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
  PDF = 'PDF',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
  EPUB = 'EPUB',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
  RSS = 'RSS',
  Email = 'EMAIL',
  Video = 'VIDEO',
  Tagging = 'TAGGING',
  Ghostreader = 'GHOSTREADER',
  Twitter = 'TWITTER',
  Other = 'OTHER',
  Urgent = 'URGENT'
}

export const mapRequestSubCategoryToLabel = new Map([
  [FeedbackSubCategory.Performance, 'Performance'],
  [FeedbackSubCategory.Extension, 'Browser extension'],
  [FeedbackSubCategory.TTS, 'Text-to-speech'],
  [FeedbackSubCategory.PDF, 'PDFs'],
  [FeedbackSubCategory.EPUB, 'EPUBs / Books'],
  [FeedbackSubCategory.RSS, 'RSS / Feed'],
  [FeedbackSubCategory.Email, 'Emails / Newsletters'],
  [FeedbackSubCategory.Video, 'Videos / YouTube'],
  [FeedbackSubCategory.Tagging, 'Tagging'],
  [FeedbackSubCategory.Ghostreader, 'Ghostreader'],
  [FeedbackSubCategory.Twitter, 'Twitter Threads / Lists'],
  [FeedbackSubCategory.Other, 'Other'],
  [FeedbackSubCategory.Urgent, 'Urgent'],
]);

export const mapRequestCategoryToPlaceholder = new Map([
  [FeedbackCategory.Account, 'account issue'],
  [FeedbackCategory.Bug, 'bug report'],
  [FeedbackCategory.Feature, 'feature suggestion'],
  [FeedbackCategory.Parsing, 'document failure'],
  [FeedbackCategory.Product, 'product question'],
]);
