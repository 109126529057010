import omit from 'lodash/omit';
import { ulid } from 'ulid';

import { type MinimalRss, type RSSSuggestion, type UserEvent, JobType } from '../../../types';
import nowTimestamp from '../../../utils/dates/nowTimestamp';
import { setCmdPaletteOpen } from '../../cmdPalette';
import { updateState } from '../../models';
import background, { portalGate as backgroundPortalGate } from '../../portalGates/toBackground/singleProcess';
import { updateFeedDescriptionFromState, updateFeedTitleFromState } from '../../stateUpdateHelpers';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import { removeFeeds } from './documents/bulk';
import { queueJob } from './jobs';

export const addFeed = async (feedData: MinimalRss, options: StateUpdateOptionsWithoutEventName & { toastContent?: string; }) => {
  const feedUrl = feedData.url;
  let id = ulid().toLowerCase();

  const url = feedUrl.trim();
  // TODO: make sure that when you undo, it removes all it's documents
  const updateResult = await updateState((state) => {
    if (!state.persistent.rssFeeds) {
      state.persistent.rssFeeds = {};
    }

    // TODO: we can probably filter below based on `id` now?
    const feeds = Object.keys(state.persistent.rssFeeds);
    const feedIndex = feeds.findIndex((key) => state.persistent.rssFeeds?.[key].url === url);
    const feedAlreadyExists = feedIndex !== -1;

    // make sure we correct the ID if feed exists
    if (feedAlreadyExists) {
      const key = feeds[feedIndex];
      const feed = state.persistent.rssFeeds[key];
      if (feed.db_feed_id) {
        id = key;
      }
    }

    if (feedAlreadyExists) {
      return;
    }
    state.persistent.rssFeeds[id] = {
      created: nowTimestamp(),
      ...omit(feedData, 'id'),
      url,
    };
  }, { ...options, eventName: 'rss-feed-added' });

  const onBackgroundToServerUpdatesConsumed = (userEventsIds: string[]) => {
    if (updateResult?.userEvent && !userEventsIds.includes(updateResult.userEvent.id)) {
      return;
    }

    queueJob({
      jobType: JobType.RssImport,
      jobArguments: { triggeredByClient: true, only: id },
      options: {
        correlationId: updateResult.userEvent?.correlationId,
        ...options,
      },
    });
    background.pollLatestStateAndContent(20);
    backgroundPortalGate.off('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
  };

  backgroundPortalGate.on('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);

  createToast({
    content: options.toastContent || 'Adding newest 5 items...',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
  return id;
};

export const removeFeed = async (feedId: string, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  removeFeeds([feedId], options);
};

export const toggleDocumentFeedSubscription = async (
  possibleRss: { url: string; } | null,
  subscribed: boolean,
  feedId: string | undefined,
  removeFeedWarningText: string,
  options: StateUpdateOptionsWithoutEventName,
) => {
  if (!possibleRss) {
    return;
  }
  await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  if (subscribed && feedId) {
    // eslint-disable-next-line no-alert
    const shouldContinue = confirm(`Warning: ${removeFeedWarningText} Continue?`);

    if (!shouldContinue) {
      return;
    }

    await removeFeed(feedId, options);
  } else {
    await addFeed(possibleRss, options);
  }
};

export const restoreDeletedFeedSuggestions = async (signalLevel: 'low' | 'high', options: StateUpdateOptionsWithoutEventName) => {
  const updateResult = await updateState((state) => {
    if (!state.persistent.deletedRssSuggestions) {
      state.persistent.deletedRssSuggestions = {};
    }
    state.persistent.deletedRssSuggestions[signalLevel] = [];
  }, { ...options, eventName: `feed-restore-deleted-suggestions-${signalLevel}-signal` });
  createToast({
    content: `Restored ${signalLevel} signal suggestions`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const deleteFeedSuggestions = async (feedIds: string[], signalLevel: 'low' | 'high', options: StateUpdateOptionsWithoutEventName) => {
  const updateResult = await updateState((state) => {
    if (!state.persistent.deletedRssSuggestions) {
      state.persistent.deletedRssSuggestions = {};
    }
    if (!state.persistent.deletedRssSuggestions[signalLevel]) {
      state.persistent.deletedRssSuggestions[signalLevel] = [];
    }
    for (const feedId of feedIds) {
      (state.persistent.deletedRssSuggestions[signalLevel] as string[]).push(feedId);
    }
  }, { ...options, eventName: `feed-delete-suggestions-${signalLevel}-signal` });
  createToast({
    content: `Deleted ${feedIds.length} suggestions`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const subscribeToSuggestedFeeds = async (feeds: RSSSuggestion[], options: StateUpdateOptionsWithoutEventName) => {
  const updateResult = await updateState((state) => {
    if (!state.persistent.rssFeeds) {
      state.persistent.rssFeeds = {};
    }
    for (const feed of feeds) {
      state.persistent.rssFeeds[feed.id] = {
        created: nowTimestamp(),
        url: feed.url,
        name: feed.name,
        description: feed.description,
        image_url: feed.image_url,
      };
    }
  }, { ...options, eventName: 'rss-subscribed-to-suggested-feeds' });
  const onBackgroundToServerUpdatesConsumed = (userEventsIds: string[]) => {
    if (updateResult?.userEvent && !userEventsIds.includes(updateResult.userEvent.id)) {
      return;
    }

    queueJob({
      jobType: JobType.RssImport,
      jobArguments: { triggeredByClient: true, newArticlesToAdd: 1 },
      options: {
        correlationId: updateResult.userEvent?.correlationId,
        ...options,
      },
    });
    background.pollLatestStateAndContent(20);
    backgroundPortalGate.off('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
  };
  backgroundPortalGate.on('backgroundToServerUpdatesConsumed', onBackgroundToServerUpdatesConsumed);
  createToast({
    content: `Subscribed to ${feeds.length} feeds`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};


export const updateFeedTitle = async (feedId: string, title: string, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const updateResult = await updateState((state) => {
    updateFeedTitleFromState({ state, feedId, title });
  }, { ...options, eventName: 'feed-title-updated' });

  createToast({
    content: 'Title updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const updateFeedDescription = async (feedId: string, description: string, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const updateResult = await updateState((state) => {
    updateFeedDescriptionFromState({ state, feedId, description });
  }, { ...options, eventName: 'feed-description-updated' });

  createToast({
    content: 'Description updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const updateFeedDetails = async ({
  feedId,
  title,
  description,
  options,
}: { feedId: string; title?: string; description?: string; options: StateUpdateOptionsWithoutEventName; }): Promise<void> => {
  const updateResult = await updateState((state) => {
    if (title) {
      updateFeedTitleFromState({ state, feedId, title });
    }

    if (description) {
      updateFeedDescriptionFromState({ state, feedId, description });
    }
  }, { ...options, eventName: 'feed-description-updated' });

  createToast({
    content: 'Feed updated',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};
