import React, { useCallback, useEffect, useRef, useState } from 'react';

import { globalState } from '../../../shared/foreground/models';
import styles from './ReadingProgressBar.module.css';

export default function ReadingProgressBar({ progress, currentScroll, large = false, fakeReadingProgress = false }: {progress: number; currentScroll: number; large?: boolean; fakeReadingProgress?: boolean;}): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [barWidth, setBarWidth] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));

  useEffect(() => {
    if (!barWidth) {
      return;
    }

    setScrollX(barWidth * currentScroll / 100);
  }, [barWidth, currentScroll, setScrollX]);

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    const newBarWidth = containerRef.current.clientWidth;
    setBarWidth(newBarWidth);
  }, [screenWidth]);

  return <div ref={containerRef} className={`${styles.progressBarContainer} ${large ? styles.progressBarLarge : ''}`}>
    <div className={styles.progressPoint} style={{ transform: `translateX(${scrollX}px)`, opacity: currentScroll >= progress ? 0 : 1 }} />
    <div
      className={`${styles.scrollProgressBar} ${fakeReadingProgress ? styles.scrollProgressBarGradient : ''}`}
      style={{
        width: `${currentScroll}%`,
      }}
    />
    <div className={styles.gradient} style={{ width: `${progress}%` }} />
    <div className={styles.progressBar} style={{ width: '100%' }} />
  </div>;
}
