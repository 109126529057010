import debounce from 'lodash/debounce';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FixedSizeList } from 'react-window';

import database from '../../../../shared/foreground/database';
import { useFindByIds, useFindOne } from '../../../../shared/foreground/databaseHooks';
import { fetchRelatedRSS } from '../../../../shared/foreground/methods';
import { globalState } from '../../../../shared/foreground/models';
import {
  useFaviconUrlFromDoc,
  usePartialDocument,
  useRssSourceNameForDoc,
} from '../../../../shared/foreground/stateHooks';
import { useFocusedDocumentId } from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import {
  fetchDocumentContent,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/documentContent';
import {
  setDocumentsListScrolled,
  setInboxZero,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/lists';
import { setFocusedDocumentId } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import forwardRef from '../../../../shared/foreground/utils/forwardRef';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import { getSummaries } from '../../../../shared/summary';
import type { AnyDocument, BaseDocument, FullZustandState, SortRule } from '../../../../shared/types';
import { Category, DocumentLocation, FeedDocumentLocation, ShortcutId } from '../../../../shared/types';
import {
  isDocumentWithThirdPartyUrl,
  isDocumentWithWordCount,
  isFirstClassDocument,
  notEmpty,
} from '../../../../shared/typeValidators';
import {
  articlesQueries,
  emailsQueries,
  epubsQueries,
  pdfsQueries,
  tweetsQueries,
} from '../../../../shared/utils/filteredViews';
import getDocumentAuthor from '../../../../shared/utils/getDocumentAuthor';
import getDocumentCategoryOverrideOrReal from '../../../../shared/utils/getDocumentCategoryOverrideOrReal';
import getDocumentLanguage from '../../../../shared/utils/getDocumentLanguage';
import getDocumentOverrideOrReal from '../../../../shared/utils/getDocumentOverrideOrReal';
import getDocumentPublishedDate from '../../../../shared/utils/getDocumentPublishedDate';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import getListeningTime from '../../../../shared/utils/getListeningTime';
import getUrlDomain from '../../../../shared/utils/getUrlDomain';
import { useHotKeys } from '../../hooks/hooks';
import useUserScrollable from '../../hooks/useUserScrollable';
import getNumericCssPropertyValue from '../../utils/getNumericCssPropertyValue';
import getSafeWindowHeight from '../../utils/getSafeWindowHeight';
import {
  getCurrentDocumentLocationFromPathname,
  getFeedDocumentLocationFromPathname,
  getFilterViewQueryFromPathname,
  getSplitByValueFromPathname,
} from '../../utils/pathnameHelpers';
import { useShortcutsMap } from '../../utils/shortcuts';
import useLocation from '../../utils/useLocation';
import ArchiveIcon from '../icons/ArchiveIcon';
import FeedIconWithPadding from '../icons/FeedIconWithPadding';
import InboxIcon from '../icons/InboxIcon';
import LaterIcon from '../icons/LaterIcon';
import ShortlistIcon from '../icons/ShortlistIcon';
import InboxFocusIndicator, { position as positionFocusIndicator } from '../InboxFocusIndicator';
import { openSingleParentNotebookView } from '../NotebookView/notebookHelpers';
import styles from './DocumentList.module.css';
import { DocumentListItem } from './DocumentListItem';

const computeHeight = (rootElement: HTMLElement | null): number => {
  return getSafeWindowHeight() - (rootElement ? getNumericCssPropertyValue('--inbox-header-height_js') : 0);
};

const limitCalls = (callback: () => void) => debounce(callback, 150);

const onScrollKeyboardShortcut = (
  {
    documentIds,
    event,
    focusedDocumentId,
    isShown,
    offset,
  }: {
    documentIds: Props['documentIds'];
    focusedDocumentId: FullZustandState['focusedDocumentId'];
    event: KeyboardEvent;
    isShown: boolean;
    offset: number;
  },
) => {
  if (!isShown || !documentIds.length) {
    return;
  }
  event.preventDefault();
  const currentIndex = documentIds.findIndex((docId) => docId === focusedDocumentId) ?? 0;
  // Constrain to list:
  const newIndex = Math.max(Math.min(currentIndex + offset, documentIds.length - 1), 0);
  setFocusedDocumentId(documentIds[newIndex], { userInteraction: 'keypress' });
};

const OuterWrapper = React.memo(forwardRef<{ children: React.ReactNode; style: CSSProperties; }, HTMLDivElement>((
  { children, style: incomingStyle, ...props },
  ref,
) => {
  const scrollableRootRef = useRef<HTMLDivElement>();
  const refSetter = (element: HTMLDivElement) => {
    scrollableRootRef.current = element;
    type RefFunction = (element: HTMLElement) => unknown;
    (ref as unknown as RefFunction)(element);
  };

  const style = { ...incomingStyle };
  delete style.overflow; // To be safe, don't allow `overflow: auto`

  return <div
    ref={refSetter}
    style={style}
    {...props}
  >
    <InboxFocusIndicator scrollableRootRef={scrollableRootRef as React.MutableRefObject<HTMLDivElement>} />
    {children}
  </div>;
}));

const InnerWrapper = React.memo(forwardRef<{ children: React.ReactNode; style: CSSProperties; }, HTMLOListElement>((
  { children, style: incomingStyle, ...props },
  ref,
) => {
  const style = { ...incomingStyle };
  style.minHeight = style.height; // Overrides flex height

  return <ol
    ref={ref}
    style={style}
    {...props}
  >
    {children}
  </ol>;
}));

type VirtualizedListRef = React.RefObject<FixedSizeList<{
  scrollTo: (offset: number) => void;
}> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
}>;

type VirtualizedListData = {
  currentSortRule?: SortRule;
  documentIdBeingRemoved: string | null;
  documentIds: Props['documentIds'];
  documentPathPrefix: string;
  focusedDocumentId: FullZustandState['focusedDocumentId'];
  virtualizedListRef: VirtualizedListRef;
  showSearchPreview?: boolean;
};

const VirtualizedListItem = React.memo(function VirtualizedListItem(
  { index, data, style }: {
    index: number;
    data: VirtualizedListData;
    style: React.CSSProperties;
  },
) {
  const [doc] = useFindOne('documents', data.documentIds[index]);
  const transientData = globalState((state) => doc && state.transientDocumentsData[doc.id]);

  const rssSourceName = useRssSourceNameForDoc(doc);
  const faviconUrl = useFaviconUrlFromDoc(doc);

  if (!doc) {
    return null;
  }

  const imageUrl = isFirstClassDocument(doc) && getDocumentOverrideOrReal(doc, 'image_url') || '';
  const { summary } = getSummaries(doc);

  const readingPercent = doc.readingPosition?.scrollDepth ? doc.readingPosition.scrollDepth * 100 : 0;
  const scrollPercent = doc.currentScrollPosition?.scrollDepth ? doc.currentScrollPosition.scrollDepth * 100 : 0;

  const wordCount = isDocumentWithWordCount(doc) ? doc.word_count : undefined;
  const author = getDocumentAuthor(doc);
  const publishedDate = getDocumentPublishedDate(doc);
  const url = isDocumentWithThirdPartyUrl(doc) && doc.url || undefined;
  const originUrl = url ? getUrlDomain(url) : undefined;
  const siteName = doc.site_name;
  const listeningTime = getListeningTime(doc);
  const category = getDocumentCategoryOverrideOrReal(doc);

  return <DocumentListItem
    author={author}
    category={category}
    currentSortRule={data.currentSortRule}
    description={summary}
    documentPathPrefix={data.documentPathPrefix}
    faviconUrl={faviconUrl}
    firstOpenedAt={doc.firstOpenedAt}
    id={doc.id}
    isBeingRemoved={data.documentIdBeingRemoved === doc.id}
    isFocused={data.focusedDocumentId === doc.id}
    key={doc.id}
    languageCode={getDocumentLanguage(doc)}
    lastOpenedAt={doc.lastOpenedAt}
    lastStatusUpdate={doc.last_status_update}
    onMouseEnter={(event: unknown, docId: BaseDocument['id']) => {
      setFocusedDocumentId(docId, { userInteraction: 'mouse-enter' });
    }}
    originUrl={originUrl}
    previewImgUrl={imageUrl}
    publishedDate={publishedDate}
    readingPercent={readingPercent}
    rssSourceName={rssSourceName}
    savedAt={doc.saved_at}
    savedAtHistory={doc.saved_at_history}
    scrollPercent={scrollPercent}
    shouldRenderImmediately={false}
    searchPreviewText={data.showSearchPreview ? transientData?.searchPreviewText : undefined}
    siteName={siteName}
    style={style}
    tags={doc.tags}
    title={getDocumentTitle(doc)}
    documentLocation={doc.triage_status ?? null}
    wordCount={wordCount}
    listeningTimeInSeconds={listeningTime}
  />;
}, ({ data: prevData, index: prevIndex }, { data: nextData, index: nextIndex }) => {
  const prevDocId = prevData.documentIds[prevIndex];
  const nextDocId = prevData.documentIds[nextIndex];
  const prevFocus = prevDocId === prevData.focusedDocumentId;
  const nextFocus = nextDocId === nextData.focusedDocumentId;
  return prevIndex === nextIndex &&
    prevDocId === nextDocId &&
    prevFocus === nextFocus &&
    prevData.documentIds === nextData.documentIds &&
    prevData.documentIdBeingRemoved === nextData.documentIdBeingRemoved;
});

// This hook takes care of a possible edge case: if the Feed document list is
// sorting chronologically, what happens if the last ~5 articles from the new
// RSS feed are too old to appear at the top of the list? This hook focuses
// the most recent article from the new RSS feed.
const useFocusLastRssDoc = (documentIds: AnyDocument['id'][]) => {
  const { search } = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const shouldSelectLatestFeedDoc = query.get('shouldSelectLatestFeedDoc');
  const [documents] = useFindByIds('documents', documentIds, { isEnabled: Boolean(shouldSelectLatestFeedDoc) });

  const rssFeeds = globalState(useCallback((state) => state.persistent.rssFeeds, []));

  useEffect(() => {
    if (!shouldSelectLatestFeedDoc || !rssFeeds) {
      return;
    }

    // Sort all the RSS feeds from latest to oldest
    const sortedRssFeeds = Object.keys(rssFeeds).sort((a, b) => {
      return rssFeeds[b].created - rssFeeds[a].created;
    });

    const latestFeedKey = sortedRssFeeds[0];

    if (!latestFeedKey) {
      return;
    }

    const latestFeedUrl = rssFeeds[latestFeedKey].url;

    const docToFocus = documents.find((doc) => {
      const rssId = doc.source_specific_data?.rss_feed;
      const rssFeedUrl = rssId && rssFeeds[rssId]?.url;
      return rssFeedUrl === latestFeedUrl;
    });

    if (docToFocus) {
      setFocusedDocumentId(docToFocus.id, { userInteraction: 'unknown' });

      history.replace({
        search: '',
      });
    }
  }, [shouldSelectLatestFeedDoc, rssFeeds, documents, history]);
};

type Props = {
  className: string;
  currentSortRule?: SortRule;
  documentIdBeingRemoved: string | null;
  documentIds: AnyDocument['id'][];
  documentPathPrefix: string;
  isShown: boolean;
  parentPath: string;
  showSearchPreview?: boolean;
  onEndThresholdReached?: () => void;
  pageSize?: number;
};

export default React.memo(function DocumentList({
  className,
  currentSortRule,
  documentIdBeingRemoved,
  documentIds,
  documentPathPrefix,
  isShown,
  parentPath,
  showSearchPreview,
  onEndThresholdReached,
  pageSize,
}: Props) {
  const rootElementRef = useRef<HTMLDivElement>(null);
  const lastKnownScrollTopRef = useRef(0);
  const virtualizedListRef = useRef<VirtualizedListRef['current']>(null);
  const [height, setHeight] = useState(0);
  const focusedDocumentId = useFocusedDocumentId();
  const itemHeight = getNumericCssPropertyValue('--document-list-item-height_js');
  const numberOfItemsPerPage = Math.ceil(height / itemHeight);
  const shortcutsMap = useShortcutsMap();

  useFocusLastRssDoc(documentIds);
  useUserScrollable(rootElementRef.current, (scrollTop) => setDocumentsListScrolled(scrollTop > 0));

  const documentsRef = useRef(documentIds);
  useEffect(() => {
    documentsRef.current = documentIds;
  }, [documentIds]);
  // NOTE: InboxFocusIndicator also sets scrollTop
  useEffect(() => {
    if (!virtualizedListRef.current || !isShown) {
      return;
    }
    virtualizedListRef.current.scrollTo(lastKnownScrollTopRef.current);

    /*
      If the focused row still isn't in view, scroll to it. This would happen after pressing J/K a lot
      while viewing a document, then pressing escape.
    */
    requestAnimationFrame(() => {
      if (!rootElementRef.current || !virtualizedListRef.current || !focusedDocumentId) {
        return;
      }
      const index = documentsRef.current.findIndex((docId) => docId === focusedDocumentId);
      if (index < 0) {
        return;
      }
      virtualizedListRef.current.scrollToItem(index); // Does nothing if already in view
      positionFocusIndicator(focusedDocumentId, rootElementRef.current);
    });
  }, [focusedDocumentId, lastKnownScrollTopRef, isShown]);

  // Track height
  useEffect(() => {
    if (!rootElementRef.current) {
      return;
    }
    setHeight(computeHeight(rootElementRef.current));

    if (!rootElementRef.current) {
      return;
    }
    let wasCleanupCalled = false;
    const resizeObserver = new ResizeObserver(limitCalls(() => {
      if (wasCleanupCalled) {
        resizeObserver?.disconnect();
        return;
      }
      return setHeight(computeHeight(rootElementRef.current));
    }));
    resizeObserver.observe(rootElementRef.current);

    const onResize = limitCalls(() => setHeight(computeHeight(rootElementRef.current)));
    window.addEventListener('resize', onResize);

    return () => {
      wasCleanupCalled = true;
      resizeObserver.disconnect();
      window.removeEventListener('resize', onResize);
    };
  }, [height, rootElementRef, setHeight]);

  // Keyboard shortcut: scroll to top
  useHotKeys(
    shortcutsMap[ShortcutId.ScrollToTop],
    useCallback(
      (event) => onScrollKeyboardShortcut({ documentIds, event, focusedDocumentId, isShown, offset: -Infinity }),
      [documentIds, focusedDocumentId, isShown],
    ),
  );

  // Keyboard shortcut: scroll to bottom
  useHotKeys(
    shortcutsMap[ShortcutId.ScrollToBottom],
    useCallback(
      (event) => onScrollKeyboardShortcut({ documentIds, event, focusedDocumentId, isShown, offset: Infinity }),
      [documentIds, focusedDocumentId, isShown],
    ),
  );

  // Keyboard shortcut: scroll up a "page"
  useHotKeys(
    shortcutsMap[ShortcutId.PageUp],
    useCallback(
      (event) => onScrollKeyboardShortcut({ documentIds, event, focusedDocumentId, isShown, offset: -numberOfItemsPerPage }),
      [documentIds, focusedDocumentId, isShown, numberOfItemsPerPage],
    ),
    {
      description: 'Navigate one page up',
    },
  );

  // Keyboard shortcut: scroll down a "page"
  useHotKeys(
    shortcutsMap[ShortcutId.PageDown],
    useCallback(
      (event) => onScrollKeyboardShortcut({ documentIds, event, focusedDocumentId, isShown, offset: numberOfItemsPerPage }),
      [documentIds, focusedDocumentId, isShown, numberOfItemsPerPage],
    ),
    {
      description: 'Navigate one page down',
    },
  );

  const [partialDocument] = usePartialDocument(focusedDocumentId, ['children', 'id']);
  const history = useHistory();

  useHotKeys(
    shortcutsMap[ShortcutId.ToggleNotebookView],
    useCallback(() => {
      if (!partialDocument?.children) {
        return;
      }
      openSingleParentNotebookView(history, partialDocument.id);
    }, [history, partialDocument]),
  );

  const onItemsRendered = useCallback(({ overscanStartIndex, overscanStopIndex }: { overscanStartIndex: number; overscanStopIndex: number; }) => {
    setTimeout(async () => {
      const ids = documentIds.slice(overscanStartIndex, overscanStopIndex + 1);
      if (ids.length) {
        fetchDocumentContent(ids);

        const docs = await database.collections.documents.findByIds(ids);
        const domains = docs.map((doc) => doc.category === Category.Article && doc.url ? getUrlDomain(doc.url) : undefined).filter(notEmpty);

        if (domains.length) {
          fetchRelatedRSS(domains);
        }
      }
    }, 100);
  }, [documentIds]);

  const [onEndThresholdCalled, setOnEndThresholdCalled] = useState(false);
  useEffect(() => {
    setOnEndThresholdCalled(false);
  }, [documentIds]);

  const onScroll = useCallback(({ scrollOffset }: {scrollOffset: number;}) => {
    if (!isShown) {
      return;
    }
    lastKnownScrollTopRef.current = scrollOffset;
    const itemsSeen = (scrollOffset + height) / itemHeight;
    // If we are half of a pagesize away from reaching the bottom, trigger the function call
    if (itemsSeen > documentIds.length - (pageSize ?? 1) / 2 && !onEndThresholdCalled) {
      if (onEndThresholdReached) {
        onEndThresholdReached();
        setOnEndThresholdCalled(true);
      }
    }
  }, [documentIds.length, height, isShown, itemHeight, onEndThresholdCalled, onEndThresholdReached, pageSize]);

  const classes = ['listRoot', 'has-visible-scrollbar', styles.listRoot, className];
  if (!isShown) {
    classes.push(styles.listRootHidden);
  }

  const profile = globalState(useCallback((state) => state.client.profile, []));
  const currentQuery = useMemo(() => getFilterViewQueryFromPathname(parentPath) || '', [parentPath]);
  const splitByValue = useMemo(() => getSplitByValueFromPathname(parentPath) ?? getFeedDocumentLocationFromPathname(parentPath) ?? getCurrentDocumentLocationFromPathname(parentPath), [parentPath]);
  const libraryViews = useDocumentLocations().map((view) => `/${view}`);

  const isPdfsCategory = useMemo(() => pdfsQueries.includes(currentQuery), [currentQuery]);
  const isEpubsCategory = useMemo(() => epubsQueries.includes(currentQuery), [currentQuery]);
  const isLibraryCategory = useMemo(() => libraryViews.includes(parentPath), [parentPath, libraryViews]);
  const isFeedCategory = useMemo(() => Boolean(parentPath.match(`/${DocumentLocation.Feed}/(${Object.values(FeedDocumentLocation).join('|')})`)), [parentPath]);
  const isArticlesCategory = useMemo(() => articlesQueries.includes(currentQuery), [currentQuery]);
  const isEmailsCategory = useMemo(() => emailsQueries.includes(currentQuery), [currentQuery]);
  const isTweetsCategory = useMemo(() => tweetsQueries.includes(currentQuery), [currentQuery]);

  const isInboxZero = useMemo(() => !documentIds.length && !parentPath.startsWith('/search'), [documentIds.length, parentPath]);

  useEffect(() => {
    setInboxZero(isInboxZero, { userInteraction: 'unknown' });
  }, [isInboxZero]);


  if (!documentIds.length && !parentPath.startsWith('/search')) {
    const EmptyStateIconMap = {
      [DocumentLocation.Archive]: ArchiveIcon,
      [DocumentLocation.Later]: LaterIcon,
      [DocumentLocation.New]: InboxIcon,
      [DocumentLocation.Shortlist]: ShortlistIcon,
      [FeedDocumentLocation.New]: FeedIconWithPadding,
      [FeedDocumentLocation.Seen]: FeedIconWithPadding,
    };
    let emptyStateMessage;
    const emptyStateMessageClasses = [styles.emptyStateMessage];

    if (isLibraryCategory) {
      if (splitByValue === DocumentLocation.Archive) {
        emptyStateMessage = <span>Archive is where documents you’ve chosen to set aside will&nbsp;appear</span>;
      } else if (splitByValue === DocumentLocation.Later) {
        emptyStateMessage = <span>Later is where documents you’ve chosen to read later will appear until&nbsp;archived</span>;
      } else if (splitByValue === DocumentLocation.New) {
        emptyStateMessage = <span>Inbox is where newly saved documents of all kinds will appear until&nbsp;triaged</span>;
      } else if (splitByValue === DocumentLocation.Shortlist) {
        emptyStateMessage = <span>Documents you&apos;ve chosen to shortlist will appear&nbsp;here.<br />Find documents to share in <Link to={DocumentLocation.Later}>Later</Link> or <Link to={DocumentLocation.Feed}>Feed</Link></span>;
      }
    } else if (isFeedCategory) {
      if (splitByValue === FeedDocumentLocation.New) {
        emptyStateMessage = <span>Unseen is where newly pushed items from subscriptions will appear until marked as&nbsp;seen</span>;
      } else if (splitByValue === FeedDocumentLocation.Seen) {
        emptyStateMessage = <span>Seen is where items marked as seen in your Feed will appear until someday automatically&nbsp;deleted</span>;
      }
    } else if (isArticlesCategory) {
      if (splitByValue === DocumentLocation.Archive) {
        emptyStateMessage = <span>Articles you’ve chosen to set aside will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.Later) {
        emptyStateMessage = <span>Articles you’ve chosen to read later will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.New) {
        emptyStateMessage = <span>Newly saved articles will appear in Inbox until&nbsp;triaged</span>;
      } else if (splitByValue === DocumentLocation.Shortlist) {
        emptyStateMessage = <span>Articles you&apos;ve chosen to shortlist will appear&nbsp;here.<br />Find articles to share in <Link to={DocumentLocation.Later}>Later</Link> or <Link to={DocumentLocation.Feed}>Feed</Link></span>;
      }
    } else if (isEmailsCategory) {
      if (splitByValue === DocumentLocation.Archive) {
        emptyStateMessage = <span>Emails you’ve chosen to set aside will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.New) {
        emptyStateMessage = <span>New emails forwarded to <i>{profile?.custom_library_email}</i> (or saved from your feed) will appear&nbsp;here</span>;
        emptyStateMessageClasses.push(styles.wideEmptyStateMessage);
      } else if (splitByValue === DocumentLocation.Later) {
        emptyStateMessage = <span>Emails you’ve chosen to read later will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.Shortlist) {
        emptyStateMessage = <span>Emails you&apos;ve chosen to shortlist will appear&nbsp;here.</span>;
      }
    } else if (isPdfsCategory) {
      if (splitByValue === DocumentLocation.Archive) {
        emptyStateMessage = <span>PDFs you’ve chosen to set aside will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.New) {
        emptyStateMessage = <span>Newly saved PDFs will appear in Inbox until&nbsp;triaged</span>;
      } else if (splitByValue === DocumentLocation.Later) {
        emptyStateMessage = <span>PDFs you’ve chosen to read later will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.Shortlist) {
        emptyStateMessage = <span>PDFs you&apos;ve chosen to shortlist will appear&nbsp;here.</span>;
      }
    } else if (isEpubsCategory) {
      if (splitByValue === DocumentLocation.Archive) {
        emptyStateMessage = <span>Books you’ve chosen to set aside will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.New) {
        emptyStateMessage = <span>Newly saved books will appear in Inbox until&nbsp;triaged</span>;
      } else if (splitByValue === DocumentLocation.Later) {
        emptyStateMessage = <span>Books you’ve chosen to read later will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.Shortlist) {
        emptyStateMessage = <span>Books you&apos;ve chosen to shortlist will appear&nbsp;here</span>;
      }
    } else if (isTweetsCategory) {
      if (splitByValue === DocumentLocation.Archive) {
        emptyStateMessage = <span>Threads you’ve chosen to set aside will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.Later) {
        emptyStateMessage = <span>Threads you’ve chosen to read later will appear&nbsp;here</span>;
      } else if (splitByValue === DocumentLocation.New) {
        emptyStateMessage = <span>Newly saved Twitter threads will appear in Inbox until&nbsp;triaged</span>;
      } else if (splitByValue === DocumentLocation.Shortlist) {
        emptyStateMessage = <span>Threads you&apos;ve chosen to shortlist will appear&nbsp;here</span>;
      }
    }

    if (emptyStateMessage && splitByValue) {
      const EmptyStateIcon = EmptyStateIconMap[splitByValue];

      classes.push(styles.newListRootEmpty);
      emptyStateMessageClasses.push(styles.text);
      return (
        <div className={classes.join(' ')} ref={rootElementRef}>
          <div className={styles.emptyListContentWrapper}>
            <div><EmptyStateIcon className={styles.bigEmptyStateIcon} /></div>
            <p className={emptyStateMessageClasses.join(' ')}>{emptyStateMessage}</p>
          </div>
        </div>
      );
    } else {
      // If we don't have an empty state for this view, show our original unsplash inbox zero
      classes.push(styles.listRootEmpty);
      return <div
        className={classes.join(' ')}
        ref={rootElementRef}>
        <div />
        <p>Inbox zero</p>
      </div>;
    }
  }

  return <FixedSizeList
    className={classes.join(' ')}
    direction="ltr"
    height={height}
    innerElementType={InnerWrapper}
    initialScrollOffset={lastKnownScrollTopRef.current}
    itemCount={documentIds.length}
    itemData={{ documentIds, documentIdBeingRemoved, documentPathPrefix, focusedDocumentId, virtualizedListRef, currentSortRule, showSearchPreview }}
    itemKey={(index: number, data: VirtualizedListData) => data.documentIds[index]}
    itemSize={itemHeight}
    onItemsRendered={onItemsRendered}
    onScroll={onScroll}
    outerElementType={OuterWrapper}
    outerRef={rootElementRef}
    overscanCount={1}
    ref={virtualizedListRef as React.RefObject<FixedSizeList<VirtualizedListData>>}
    width="100%"
    >
      {VirtualizedListItem}
    </FixedSizeList>;
});
