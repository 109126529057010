import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import * as reactRouterDom from 'react-router-dom';

import nowTimestamp from '../../../shared/utils/dates/nowTimestamp';
import { isExtension } from '../../../shared/utils/environment';

/*
  Imagine this is just react-router-dom.useLocation. It is, except we use a custom implementation
  in the extension. It looks a lot more complicated because of React Hooks™️

  In the extension we used to use react-router-dom's useLocation and wrapped the app with react-router-dom's
  BrowserRouter, but then we discovered:
  https://linear.app/readwise/issue/RW-9077/highlighter-extension-apparently-messing-with-browser-back-button
*/
export default (): typeof window.location | ReturnType<typeof reactRouterDom.useLocation> => {
  const shouldUseReactRouter = !isExtension;

  let reactRouterResult: ReturnType<typeof reactRouterDom.useLocation> | undefined;
  try {
    reactRouterResult = reactRouterDom.useLocation();
  } catch (e) {
    if (shouldUseReactRouter) {
      throw e;
    }
  }

  const [lastKnownHref, setLastKnownHref] = useState(window.location.href);
  const [whenLoadEventFired, setWhenLoadEventFired] = useState<number | null>(null);

  // To be safe, recreate the MutationObserver if the load event is fired
  useEffect(() => {
    if (shouldUseReactRouter) {
      return;
    }

    const onLoad = () => {
      setWhenLoadEventFired(nowTimestamp());
    };

    window.addEventListener('load', onLoad);

    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, [shouldUseReactRouter]);

  useEffect(() => {
    if (shouldUseReactRouter) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const mutation of mutations) {
        if (lastKnownHref === window.location.href) {
          return;
        }
        setLastKnownHref(window.location.href);
      }
    });

    // I don't know why, but overally this is more reliable than document.body
    const body = document.querySelector('body');
    if (!body) {
      throw new Error("Can't find body");
    }
    observer.observe(body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer?.disconnect();
    };
  }, [lastKnownHref, shouldUseReactRouter, whenLoadEventFired]);

  if (shouldUseReactRouter) {
    if (!reactRouterResult) { // Just for TypeScript
      throw new Error('Got no result from react-router-dom.useLocation');
    }
    return reactRouterResult;
  }

  return window.location;
};
