import React, { Suspense } from 'react';

import { reactLazy } from '../utils/dynamicImport';
import Button from './Button';
import styles from './ErrorFallback.module.css';

const InboxSidebar = reactLazy(() => import('./InboxSidebar'));

export default function ErrorFallback() {
  return <div className={styles.page}>
    <Suspense fallback={null}><InboxSidebar hasError /></Suspense>
    <div className={styles.contentWrapper}>
      <h1>Something went wrong</h1>
      <p>We track these errors automatically.</p>
      <Button onClick={() => window.location.reload()} variant="primary">
        Reload
      </Button>
    </div>
  </div>;
}
