import snakeCase from 'lodash/snakeCase';

import { updateState } from '../models';
import type { StateUpdateOptionsWithoutEventName } from '../types';


export const hideModal = (
  {
    id,
  }: {
    id: string;
  },
  options: StateUpdateOptionsWithoutEventName,
) => {
  return updateState((state) => {
    state.modal = null;
  }, {
    ...options,
    eventName: `${snakeCase(id)}-modal-hidden`,
  });
};

export const showModal = (
  {
    id,
  }: {
    id: string;
  },
  options: StateUpdateOptionsWithoutEventName,
 ) => {
  return updateState((state) => {
    state.modal = id;
  }, {
    ...options,
    eventName: `${snakeCase(id)}-modal-shown`,
  });
};
