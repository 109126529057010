import React from 'react';

import Icon from './Icon';

export default function TagIcon({ className, text = 'Tag' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.73999 3.5C5.73999 3.08579 6.07578 2.75 6.48999 2.75C12.4275 2.75 17.24 7.56245 17.24 13.5C17.24 13.9142 16.9042 14.25 16.49 14.25C16.0758 14.25 15.74 13.9142 15.74 13.5C15.74 8.39088 11.5991 4.25 6.48999 4.25C6.07578 4.25 5.73999 3.91421 5.73999 3.5Z" />
      <path d="M5.73999 7.12039C5.73999 6.70618 6.07578 6.37039 6.48999 6.37039C10.4279 6.37039 13.6196 9.56212 13.6196 13.5C13.6196 13.9142 13.2838 14.25 12.8696 14.25C12.4554 14.25 12.1196 13.9142 12.1196 13.5C12.1196 10.3905 9.59945 7.87039 6.48999 7.87039C6.07578 7.87039 5.73999 7.53461 5.73999 7.12039Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.23608 10.5C7.23608 10.0858 6.9003 9.75 6.48608 9.75C6.07187 9.75 5.73608 10.0858 5.73608 10.5V10.8535C5.2925 10.979 4.89447 11.213 4.5725 11.525L4.26258 11.3484C3.9027 11.1433 3.44471 11.2688 3.23962 11.6286C3.03454 11.9885 3.16002 12.4465 3.5199 12.6516L3.82012 12.8227C3.76524 13.0394 3.73608 13.2663 3.73608 13.5C3.73608 13.7337 3.76524 13.9606 3.82012 14.1773L3.5199 14.3484C3.16002 14.5535 3.03454 15.0115 3.23962 15.3714C3.44471 15.7312 3.9027 15.8567 4.26258 15.6516L4.5725 15.475C4.89447 15.787 5.2925 16.021 5.73608 16.1465V16.5C5.73608 16.9142 6.07187 17.25 6.48608 17.25C6.9003 17.25 7.23608 16.9142 7.23608 16.5V16.1465C7.68061 16.0208 8.07938 15.786 8.4017 15.4731L8.70496 15.6489C9.0633 15.8566 9.52222 15.7345 9.72997 15.3762C9.93772 15.0178 9.81564 14.5589 9.45729 14.3512L9.15273 14.1746C9.20717 13.9588 9.23608 13.7328 9.23608 13.5C9.23608 13.2673 9.20717 13.0413 9.15274 12.8254L9.45729 12.6488C9.81564 12.4411 9.93772 11.9822 9.72997 11.6238C9.52222 11.2655 9.0633 11.1434 8.70496 11.3512L8.40171 11.527C8.07939 11.214 7.68061 10.9792 7.23608 10.8535V10.5ZM6.42066 12.2517C6.44222 12.2535 6.46404 12.2545 6.48608 12.2545C6.50813 12.2545 6.52995 12.2535 6.55151 12.2517C7.21145 12.2857 7.73608 12.8316 7.73608 13.5C7.73608 14.1684 7.21141 14.7143 6.55141 14.7483C6.52988 14.7465 6.50809 14.7455 6.48608 14.7455C6.46407 14.7455 6.44229 14.7465 6.42076 14.7483C6.00802 14.7271 5.64821 14.5056 5.43653 14.1792C5.42906 14.1637 5.421 14.1484 5.41235 14.1332C5.4012 14.1136 5.3893 14.0947 5.37672 14.0766C5.28687 13.9041 5.23608 13.708 5.23608 13.5C5.23608 13.292 5.28687 13.0959 5.37671 12.9234C5.3893 12.9053 5.4012 12.8864 5.41235 12.8668C5.421 12.8516 5.42906 12.8363 5.43654 12.8208C5.64819 12.4944 6.00797 12.273 6.42066 12.2517Z" />
    </svg>
  </Icon>;
}
