import React from 'react';

import { Dialog } from './Dialog';

export const DeleteHighlightDialog = ({ isOpen, onCancel, onConfirm }: {isOpen: boolean; onCancel: () => void; onConfirm: () => void;}) => {
  if (isOpen) {
    return <div>
    <Dialog
      id="delete-highlight"
      title="Delete highlight?"
      subtitle="This will also remove the highlight from the original document"
      cancelTitle="Cancel"
      darkRedActionButton
      cancelAction={onCancel}
      actionTitle="Delete"
      action={onConfirm} />
  </div>;
  }

  return null;
};
