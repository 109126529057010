import { AnyDocument } from '../../types';
import { createToast } from '../toasts.platform';
import { exportHighlights } from './export';

export const exportHighlightsToClipboard = async (documentId: AnyDocument['id']): Promise<void> => {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  if (isFirefox) {
    const md = await exportHighlights(documentId);
    const copyText = md || '';

    try {
      await navigator.clipboard.writeText(copyText);
      createToast({ content: 'Markdown copied to clipboard', category: 'success' });
    } catch (error) {
      createToast({ content: 'Failed to copy to clipboard', category: 'error' });
    }
  } else {
    const clipboardItem = new ClipboardItem({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'text/plain': exportHighlights(documentId)
      .then((md) => {
        if (!md) {
          return new Promise((resolve) => {
            resolve(new Blob([``], { type: 'text/plain' }));
          });
        }

        const copyText = md;
        return new Promise((resolve) => {
          resolve(new Blob([copyText], { type: 'text/plain' }));
        });
      }),
    });
    await navigator.clipboard.write([clipboardItem]);
    createToast({ content: 'Markdown copied to clipboard', category: 'success' });
  }

};
