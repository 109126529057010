import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { useEffect, useMemo } from 'react';

import type { DocumentId, DocumentWithParsedDocId, Highlight, Note, PartialDocument } from '../../types';
import type { DatabaseHookResultArray } from '../../types/database';
import { isDocumentThatCanHaveHighlights, notEmpty } from '../../typeValidators';
import makeLogger from '../../utils/makeLogger';
import { useFind, useFindByIds, useFindOne } from '../databaseHooks';

const logger = makeLogger(__filename);

export const useHighlights = ({
  excludePDFHighlights = false,
  isEnabled = true,
  parentDocId,
}: {
  excludePDFHighlights?: boolean;
  isEnabled?: boolean;
  parentDocId?: string;
}): Highlight[] => {
  const [doc] = useFindOne('documents', parentDocId, { isEnabled });
  const children = useMemo(() => {
    if (!doc) {
      return [];
    }
    if (!isDocumentThatCanHaveHighlights(doc)) {
      return [];
    }
    return doc.children;
  }, [doc]);
  const [allHighlights, { isFetching }] =
    useFindByIds<'documents', Highlight>('documents', children, { isEnabled });

  useEffect(() => {
    if (isFetching) {
      return;
    }
    for (const highlightId of children) {
      const highlightDoc = allHighlights.find(({ id }) => id === highlightId);
      if (!highlightDoc) {
        logger.error('Highlight does not exist', { highlightId });
      }
    }
  }, [allHighlights, children, isFetching]);
  return useMemo(() => {
    const highlights = excludePDFHighlights ? allHighlights.filter((h) => !h.source_specific_data?.pdf_highlight) : allHighlights;
    // This orderby guarantees that the highlights will be in the same order for memo purposes
    return orderBy(highlights, ['offset']);
  }, [allHighlights, excludePDFHighlights]);
};

export const useHighlightsMap = (parentDocId?: string): { [highlightId: string]: Highlight; } => {
  const highlights = useHighlights({ parentDocId });
  return useMemo(() => keyBy(highlights, 'id'), [highlights]);
};

export function useHighlightNote(highlight: PartialDocument<Highlight, 'children'> | null | void): DatabaseHookResultArray<Note | null> {
  const noteDocumentId = highlight?.children.length ? highlight.children[0] : undefined;
  return useFindOne('documents', noteDocumentId);
}

export function useHighlightNoteTextsMap(highlights: PartialDocument<Highlight, 'children'>[]): {
  [highlightId: string]: string;
} {
  const noteIds = useMemo(() =>
      highlights.map((highlight) => highlight.children[0]).filter(notEmpty),
    [highlights]);
  const [notes] = useFindByIds('documents', noteIds);
  return useMemo(() => mapValues(
    keyBy(notes, 'parent'),
    ({ content }) => content ?? '',
  ), [notes]);
}

export function useHighlightNoteText(highlight: Parameters<typeof useHighlightNote>[0]): string {
  const [noteDocument] = useHighlightNote(highlight);
  return useMemo(() => noteDocument?.content ?? '', [noteDocument]);
}

export function useDocumentsFromSearchResults({
  contentResults,
  documentResults,
}: {
  contentResults: { id: string | null; }[];
  documentResults: { id: string; }[];
}): {
  mergedDocIds: DocumentId[];
  contentResultDocuments: DocumentWithParsedDocId[];
  isFetchingContentResultDocuments: boolean;
} {
  const contentResultParsedDocIds = useMemo(
    () => contentResults
      .map(({ id }) => id ? parseInt(id.toString(), 10) : null)
      .filter(notEmpty),
    [contentResults],
  );
  const rxdbQuery = useMemo(() => ({
    selector: {
      parsed_doc_id: {
        $in: contentResultParsedDocIds,
      },
    },
  }), [contentResultParsedDocIds]);
  const [contentResultDocuments, { isFetching: isFetchingContentResultDocuments }] =
    useFind<'documents', DocumentWithParsedDocId>(
      'documents',
      rxdbQuery,
      {
        isEnabled: contentResultParsedDocIds.length > 0,
      },
    );
  const mergedDocIds = useMemo(() => {
    const documentResultDocIds = documentResults.map(({ id }) => id);
    const contentResultDocIds = contentResultDocuments.map(({ id }) => id);
    return uniq(documentResultDocIds.concat(contentResultDocIds));
  }, [documentResults, contentResultDocuments]);

  return {
    mergedDocIds,
    contentResultDocuments,
    isFetchingContentResultDocuments,
  };
}


