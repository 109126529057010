import type { RangyRange } from '../types/rangy';
import rangy from './rangy';

export default (range: Range): RangyRange => {
  // Is already one?
  if ('toHtml' in range) {
    return range as RangyRange;
  }

  const rangyRange = rangy.createRange();
  rangyRange.setStartAndEnd(range.startContainer, range.startOffset, range.endContainer, range.endOffset);
  return rangyRange;
};
