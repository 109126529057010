import {
  type BaseDocument,
  type FirstClassDocument,
  type ReadingPosition,
  type UserEvent,
} from '../../../../types';
import { updateReadingPositionInDocData } from '../../../stateUpdateHelpers';
import { createToast } from '../../../toasts.platform';
import { updateDocument } from './update';

export const updateReadingPosition = async (
  docId: BaseDocument['id'],
  position: ReadingPosition,
  options: Parameters<typeof updateDocument>[2] & {
    force?: boolean;
    showToast?: boolean;
  },
): Promise<void> => {
  const updateResult = await updateDocument(docId, (doc) => {
    updateReadingPositionInDocData(doc, position, { force: options.force ?? false });
  }, { ...options, isUndoable: options.isUndoable });

  if (options.showToast) {
    const content = position.scrollDepth === 1 ? 'Marked as read' : 'Reading position updated';
    createToast({
      content,
      category: 'success',
      undoableUserEventId: options.isUndoable ? (updateResult.userEvent as UserEvent).id : undefined,
    });
  }
};

export const updateScrollPosition = async (
  docId: BaseDocument['id'],
  position: ReadingPosition,
  options: Parameters<typeof updateDocument>[2],
): Promise<void> => {
  await updateDocument(docId, (doc) => {
    doc.currentScrollPosition = position;
  }, { ...options, isUndoable: false });
};

export const resetReadingProgress = (docId: FirstClassDocument['id']) =>
  updateReadingPosition(docId, {
    scrollDepth: 0,
    serializedPosition: null,
  }, {
    eventName: 'document-progress-position-updated',
    force: true,
    showToast: true,
    userInteraction: 'click',
  });
