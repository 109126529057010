import get from 'lodash/get';
import type { MangoQuery, MangoQuerySelector } from 'rxdb';
import type { MangoQueryOperators } from 'rxdb/dist/types/types/rx-query';

import mangoQueryBuilders from '../database/mangoQueryBuilders';
import type { AnyDocument } from '../types';
import exceptionHandler from './exceptionHandler.platform';

/**
 * Slices the given ordered document list query based on the cutoff document and direction.
 *
 * NOTE: this function has to be very careful. if it returns some erroneous query, and that query is used for a bulk
 * action, it could mess up a user's live data really badly.
 *
 * Example Usage of sliceDocListQuery:
 *
 * Consider a document list sorted by 'saved_at', 'last_status_update', and 'id', all in ascending order:
 * let orderedDocListQuery = {
 *   sort: [{'saved_at': 'asc'}, {'last_status_update': 'asc'}, {'id': 'asc'}]
 * };
 *
 * Assume we have a cutoff document:
 * let cutoffDoc = { saved_at: '2021-01-01T12:00:00', last_status_update: '2021-01-02T12:00:00', id: 5 };
 *
 * To get all documents 'above' this cutoff (i.e., older than this document considering the sort order):
 * let slicedQuery = sliceDocListQuery(orderedDocListQuery, 'above', cutoffDoc);
 *
 * This will create a query to fetch documents that are either:
 * - Saved before '2021-01-01T12:00:00', or
 * - Saved exactly at '2021-01-01T12:00:00' but last updated before '2021-01-02T12:00:00', or
 * - Saved exactly at '2021-01-01T12:00:00', last updated exactly at '2021-01-02T12:00:00', but with an id less than 5.
 *
 * The function dynamically adjusts the query based on the sorting fields and the cutoff document's values
 * to ensure correct slicing of the document list.
*/

export function sliceDocListQuery(
  orderedDocListQuery: MangoQuery<AnyDocument>,
  direction: 'above' | 'below',
  cutoffDoc: AnyDocument,
): MangoQuery<AnyDocument> | undefined {
  if (!orderedDocListQuery) {
    // should never happen, but safer to return early here rather than mess up a user's library
    exceptionHandler.captureException(new Error('sliceDocListQuery(mitch): !orderedDocListQuery'));
    return undefined;
  }

  const cutoffSubSelectors: MangoQuerySelector<AnyDocument>[] = [];
  const sortParts = Array.from(orderedDocListQuery.sort ?? []);
  // Sort by ID asc gets implicitly added by both mobile and web query backend, so we need to include it in our selector.
  if (!sortParts.some((part) => 'id' in part)) {
    sortParts.push({
      id: 'asc',
    });
  }
  for (let lastSortPartIndex = 0; lastSortPartIndex < sortParts.length; lastSortPartIndex++) {
    const sortSubSlice = sortParts.slice(0, lastSortPartIndex + 1);
    const sortSliceSelectorParts: MangoQuerySelector<AnyDocument>[] = [];
    for (let sortPartIndex = 0; sortPartIndex < sortSubSlice.length; sortPartIndex++) {
      for (const [keypath, order] of Object.entries(sortSubSlice[sortPartIndex])) {
        let cutoffOperator: keyof MangoQueryOperators<unknown>;
        if (sortPartIndex === sortSubSlice.length - 1) {
          // only select by inequality for the current sort field
          cutoffOperator =
            order === 'asc' && direction === 'above' ||
            order === 'desc' && direction === 'below'
              ? '$lt' : '$gt';
        } else {
          // select all up to the current sort fields by equality
          cutoffOperator = '$eq';
        }
        const cutoffValue = get(cutoffDoc, keypath);
        if (cutoffValue === undefined) {
          exceptionHandler.captureException('sliceDocListQuery(mitch): cutoffDoc sort value not present', {
            extra: {
              cutoffDoc,
              keypath,
              order,
            },
          });
          return undefined;
        }
        sortSliceSelectorParts.push({
          [keypath]: {
            [cutoffOperator]: cutoffValue,
          },
        });
      }
    }
    cutoffSubSelectors.push(mangoQueryBuilders.$and(sortSliceSelectorParts));
  }
  const cutoffSelector = mangoQueryBuilders.$or(cutoffSubSelectors);
  const queryWithCutoff: MangoQuery<AnyDocument> = {
    ...orderedDocListQuery,
    selector: mangoQueryBuilders.$and([
      orderedDocListQuery.selector,
      cutoffSelector,
    ]),
  };
  return queryWithCutoff;
}
