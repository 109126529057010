import {
  findCenteredElementInViewport,
  getVisibleElementsWithinRectBounds, isIntersecting,
} from '../../../shared/foreground/utils/findCenteredElementInViewport';
import getClosestHTMLElement from '../../../shared/foreground/utils/getClosestHTMLElement';
import getDeepestWith from '../../../shared/foreground/utils/getDeepestWith';
import getNextElementWithinContainer from '../../../shared/foreground/utils/getNextNodeWithinContainer';
import getRangyClassApplier from '../../../shared/foreground/utils/getRangyClassApplier';
import isFocusableElement from '../../../shared/foreground/utils/isFocusableElement';
import { deserializePosition } from '../../../shared/foreground/utils/locationSerializer';
import { LenientReadingPosition } from '../../../shared/types';
import { isHTMLElement } from '../../../shared/typeValidators';
import nowTimestamp from '../../../shared/utils/dates/nowTimestamp';
import { DeferredPromise } from '../../../shared/utils/DeferredPromise';
import delay from '../../../shared/utils/delay';
import makeLogger from '../../../shared/utils/makeLogger';
import { ScrollingManagerError } from './errors';
import {
  animateEndOfReadingButton,
} from './initEndOfReading';
import { ScrollingManager } from './ScrollingManager';
import { populateHighlightableElements } from './textToSpeechUtils';
import { CLICKABLE_TAGS, CLICKABLE_TAGS_THROUGH_PAGINATION_MARGINS, PAGINATION_DOCUMENT_TOP_MARGIN } from './types';


const TOP_MARGIN = 80;
const BOTTOM_MARGIN = 120;

type PageRect = {
  // The top coordinate of the page (relative to entire document)
  top: number;
  // The bottom coordinate of the page (relative to entire document)
  bottom: number;
  // The height of the divider that sits above the page
  topPageDividerHeight: number;
};

const TWEET_CLASS_NAME = 'rw-embedded-tweet';
// Add tags here that should not have page borders cross them
const NON_SPLITTABLE_TAGS = new Set<string>(['IMG', 'VIDEO', 'FIGURE']);
const SPLIT_BORDER_HEIGHT = 0;

export class PaginatedScrollingManager extends ScrollingManager {
  currentlyScrollingBecauseOfTouch = false;
  currentHeight = 0;
  logger = makeLogger(__filename, { shouldLog: false });

  pageHeight = 0;

  selectionChangeTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  areTouchesDisabledBecauseOfSelection = false;
  pageRects: PageRect[] = [];
  topPageSnapshotElement: HTMLElement | undefined;
  bottomPageSnapshotElement: HTMLElement | undefined;

  scrollStartTime = 0;
  startPageOnTouch: number | null = 0;
  startTouchY: number | null = null;
  startTouchClientY = 0;
  startScrollYOnSelect: number | null = null;
  endTouchClientY = 0;
  // Used for tracking direction changes, its throttled to help with very sensitive changed
  throttledTouchClientY = 0;
  endTouchY = 0;
  touchYDirection = 0;
  startTouchX = 0;
  endTouchX = 0;

  // Empirically determined values for determining what swipe speed warrants a page turn
  velocityThreshold = 0.4;
  verticalSwipeDistanceMinimumThreshold = 40;
  // If we swipe this far, its definitely a vertical swipe
  verticalSwipeDistanceConclusiveThreshold = 150;
  tapTimeThreshold = 200;
  horizontalSwipeDistanceThreshold = 80;

  scrollTimeForPage = 300;
  iosScrollDelay = 250;
  hapticsOnScrollTimeModifier = 20;
  scrollStepDelay = 8;

  currentlyTransitioningPages = false;
  // While we transition, we might log multiple gestures to swipe up or down
  // keep an array of all ongoing page transitions
  currentTransitionPromises: DeferredPromise<boolean>[] = [];

  debugFreeScroll = false;
  currentPage = 0;
  enableScrollTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
  centerElementUpdatingBlockedTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

  baseLineHeight = 28;
  _internalChildrenForCloning: Element[] | undefined = undefined;
  async init(firstTimeOpenedDocumentOffset: number): Promise<void> {
    if (this.initialized) {
      return;
    }
    await super.init(firstTimeOpenedDocumentOffset);
    this.updatingCenterElementDisabled = true;
    this.disableScrollingWithTouch();
    if (!this.documentRoot || !this.documentTextContent) {
      throw new ScrollingManagerError('No documentRoot element found');
    }
    if (!this.highlightableElements.length) {
      populateHighlightableElements(this.documentTextContent, this.highlightableElements);
    }
    this.document.addEventListener('touchstart', this.onTouchStart.bind(this));
    this.document.addEventListener('touchmove', this.onTouchMove.bind(this));
    this.document.addEventListener('touchend', this.onTouchEnd.bind(this));
    this.window.addEventListener('scroll', this.onScroll.bind(this));
    this.document.addEventListener('selectionchange', this.onSelectionChange.bind(this));

    this.updateCurrentCenteredElement();
    this.initialized = true;

    if (!this.endOfContentElement) {
      throw new ScrollingManagerError('No end of content element found');
    }
    this.currentHeight = this.getDocumentTopOfElement(this.endOfContentElement);
    this.pageHeight = this.window.innerHeight - BOTTOM_MARGIN - TOP_MARGIN;

    if (!this.documentRoot) {
      throw new ScrollingManagerError('No documentRoot element found');
    }

    this.logger.log('Init ', this.documentTextContent?.getBoundingClientRect().height);
    const bottomPageContentResult = this.document.querySelector<HTMLElement>('.absolutely-positioned-content.bottom');
    const topPageContentResult = this.document.querySelector<HTMLElement>('.absolutely-positioned-content.top');
    if (!bottomPageContentResult || !topPageContentResult) {
      throw new ScrollingManagerError('No bottom or top content element found');
    }

    this.bottomPageSnapshotElement = bottomPageContentResult;
    this.topPageSnapshotElement = topPageContentResult;
    this.computePageRects(50);
    // Block updating center element for 5000ms so that resizes can retain our position
    this.blockUpdatingCenterElementForNMS();
    this.enableScrollingWithTouch();
  }

  getScrollingElementTop() {
    return this.getScrollingElement().scrollTop - PAGINATION_DOCUMENT_TOP_MARGIN;
  }

  setScrollingElementTop(newTop: number) {
    this.getScrollingElement().scrollTop = newTop + PAGINATION_DOCUMENT_TOP_MARGIN;
  }

  scrollingElementScrollTo({ top, behavior }: {top: number; behavior: 'smooth' | 'auto';}) {
    this.getScrollingElement().scrollTo({ top: top + PAGINATION_DOCUMENT_TOP_MARGIN, behavior: 'smooth' });
  }

  // Util function for debugging
  setLoggerShouldLog(shouldLog: boolean) {
    this.logger.shouldLog = () => shouldLog;
  }

  // util function for debugging, will allow free scrolling
  setDebugFreeScroll(enabled: boolean) {
    this.debugFreeScroll = enabled;
    if (enabled) {
      this.enableScrollingWithTouch();
    }
  }

  getScrollingElement(): HTMLElement {
    if (!this.document.scrollingElement) {
      throw new ScrollingManagerError('No documentRoot element found');
    }
    return this.document.scrollingElement as HTMLElement;
  }

  blockUpdatingCenterElementForNMS(delay = 2000) {
    this.updatingCenterElementDisabled = true;
    if (this.centerElementUpdatingBlockedTimeout) {
      clearTimeout(this.centerElementUpdatingBlockedTimeout);
    }

    this.centerElementUpdatingBlockedTimeout = setTimeout(() => {
      this.updatingCenterElementDisabled = false;
      this.updateCurrentCenteredElement();
      if (!this.currentlyTransitioningPages || !this.currentlyScrollingBecauseOfTouch) {
        this.onScrollToPageEnd(this.currentPage);
      }
    }, delay);


  }

  disableAllPaginationElements() {
    const allPageDividers = this.document.querySelectorAll<HTMLElement>('.page-divider');
    for (const divider of allPageDividers) {
      divider.classList.add('hide-divider');
    }
    if (!this.topPageSnapshotElement || !this.bottomPageSnapshotElement) {
      throw new ScrollingManagerError('No page content elements found');
    }
    this.topPageSnapshotElement.classList.add('hide-snapshot-content-from-selection');
    this.bottomPageSnapshotElement.classList.add('hide-snapshot-content-from-selection');
  }

  showVisiblePageDividerElements() {
    // TODO: Re-add when ready
    // if (this.window.pagination?.smoothAnimationsDisabled) {
    //   return;
    // }
    // const allDividerBorders = Array.from(this.document.querySelectorAll<HTMLElement>('.divider-border'));
    // if (!allDividerBorders) {
    //   throw new ScrollingManagerError(`No divider borders found`);
    // }
    // for (const divider of allDividerBorders) {
    //   divider.classList.add('divider-shadow');
    // }
  }

  hideVisiblePageDividerElements() {
    // TODO: Re-add when ready
    // const allDividerBorders = Array.from(this.document.querySelectorAll<HTMLElement>('.divider-border'));
    // if (!allDividerBorders) {
    //   throw new ScrollingManagerError(`No divider borders found`);
    // }
    // for (const divider of allDividerBorders) {
    //   divider.classList.remove('divider-shadow');
    // }
  }

  enableAllPaginationElements() {
    const allPageDividers = this.document.querySelectorAll<HTMLElement>('.page-divider');
    for (const divider of allPageDividers) {
      divider.classList.remove('hide-divider');
    }
    if (!this.topPageSnapshotElement || !this.bottomPageSnapshotElement) {
      throw new ScrollingManagerError('No page content elements found');
    }
    this.topPageSnapshotElement.classList.remove('hide-snapshot-content-from-selection');
    this.bottomPageSnapshotElement.classList.remove('hide-snapshot-content-from-selection');
  }


  onSelectionChange() {
    if (this.currentlyTransitioningPages) {
      this.enableAllPaginationElements();
      return;
    }
    const selection = this.document.getSelection();
    if (selection && !selection.isCollapsed) {
      if (this.startScrollYOnSelect === null) {
        this.startScrollYOnSelect = this.getScrollingElementTop();
      }
      this.logger.debug(`On selection change firing with a selection active, startScrollYOnSelect ${this.startScrollYOnSelect} getScrollTop: ${this.getScrollingElementTop()}`);
      this.areTouchesDisabledBecauseOfSelection = true;
      // this.logger.log('WE HAVE A SELECTION');
      this.disableAllPaginationElements();
      return;
    }
    this.logger.debug('On selection change firing with no active selection');
    this.enableAllPaginationElements();
    if (!this.areTouchesDisabledBecauseOfSelection) {
      this.logger.debug('this selection was not related to us');
      // We never made any selection
      this.startScrollYOnSelect = null;
      return;
    }
    if (this.selectionChangeTimer) {
      clearTimeout(this.selectionChangeTimer);
    }
    const currentScrollY = this.getScrollingElementTop();
    if (this.startScrollYOnSelect !== null) {
      const scrollDelta = currentScrollY - this.startScrollYOnSelect;
      const direction = scrollDelta < 0 ? -1 : 1;
      const numberOfPagesDelta = Math.ceil(Math.abs(scrollDelta / this.pageHeight)) * direction;
      this.logger.debug(`this selection was made by us, ${this.startScrollYOnSelect} scrollDelta: ${scrollDelta} direction ${direction} number of pages: ${numberOfPagesDelta}`);
      this.startScrollYOnSelect = null;
      if (numberOfPagesDelta !== 0) {
        this.scrollToPageSmooth(this.currentPage + numberOfPagesDelta, 0.1);
      } else {
        this.scrollToPage(this.currentPage);
      }
    }

    this.selectionChangeTimer = setTimeout(() => {
      // Timeout so touch end / or other touch events dont register too quickly
      this.areTouchesDisabledBecauseOfSelection = false;
      this.selectionChangeTimer = undefined;
    }, 100);
  }


  // On Resize handles when the text content resizes
  // We want to take the old center element and scroll to it
  // we also need to recompute all page rect data and re-draw the pages
  async onResize() {
    if (!this.initialized) {
      // The first resize after we are ready to be initialized
      // This means the fonts were loaded
      await this.init(0);
      this.initializeCallback();
      return;
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (this.documentTextContentHeight === newHeight || !newHeight) {
      this.logger.log('Resize fired but we are the same height as before');
      return;
    }
    this.documentTextContentHeight = newHeight;
    this.logger.log('resize ', this.documentTextContent?.getBoundingClientRect().height);
    // Block updating the element so random scrolls won't throw us off
    this.blockUpdatingCenterElementForNMS(1000);
    const centeredElementScrollDelta = this.currentCenteredElementInfo?.scrollDelta;
    const centeredElement = this.currentCenteredElementInfo?.element;
    const numberOfComputedPages = this.pageRects.length;

    this.pageRects = [];
    this.computePageRects(numberOfComputedPages);

    this.logger.debug(`OnResize: fired , ${this.getScrollingElementTop()}`);

    if (this.getScrollingElementTop() <= this.firstTimeOpenedDocumentOffset) {
        // If we are at the top, we don't need to do anything
      this.scrollToPage(0);
      return;
    }

    if (centeredElementScrollDelta === undefined || !centeredElement) {
      this.logger.debug('OnResize failed due to no center element');
      return;
    }

    this.scrollToElement(centeredElement, -centeredElementScrollDelta);
  }

  isElementIntersectingAtYCoord(element: Element, yCoord: number) {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const relativeYOfPageBottom = this.documentRoot.getBoundingClientRect().top + yCoord;
    return isIntersecting({
      top: relativeYOfPageBottom,
      bottom: relativeYOfPageBottom + 1,
      left: 0,
      right: this.window.innerWidth,
    }, element);
  }

  getElementAtY(yCoord: number): HTMLElement | null {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const visibleElements = this.documentTextContentChildren;
    let left = 0;
    let right = visibleElements.length - 1;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const middleElement = visibleElements[mid];
      const absoluteTopOfElement = middleElement.getBoundingClientRect().top + this.getScrollingElementTop();
      const absoluteBottomOfElement = middleElement.getBoundingClientRect().bottom + this.getScrollingElementTop();
      if (this.isElementIntersectingAtYCoord(middleElement, yCoord)) {
        return middleElement as HTMLElement;
      }
      if (absoluteBottomOfElement < yCoord) {
        // this element is above the ycoord
        left = mid + 1;
      } else if (absoluteTopOfElement > yCoord) {
        // this element is below the ycoord
        right = mid - 1;
      } else {
        // this else case makes no sense
        // for us to arrive here the element must
        // have a bottom coord below the ycoord and a top above ycoord
        // but it didn't intersect... ?
        // In any case I wouldn't want the loop to run forever so returning null here is ok
        return null;
      }
    }
    return null;
  }

  getDocumentTopOfElement(element: HTMLElement) {
    return element.getBoundingClientRect().top + this.getScrollingElementTop();
  }

  // Determine if element is allowed to be split across pages
  isElementSplittable(element: HTMLElement) {
    if (element.classList.contains(TWEET_CLASS_NAME) || NON_SPLITTABLE_TAGS.has(element.nodeName)) {
      return false;
    }
    let parentElement = element.parentElement;
    for (let i = 0; i < 7; i++) {
      if (!parentElement || parentElement === this.documentTextContent) {
        return true;
      }
      if (parentElement.classList.contains(TWEET_CLASS_NAME)) {
        return false;
      }
      parentElement = parentElement.parentElement;
    }
    return true;
  }


  findElementThatIntersectsBottomOfPageRect(elementAtBottomParam: HTMLElement, pageRect: PageRect, shouldLogForDebug = false): [HTMLElement, number] {
    const documentRoot = this.documentRoot;
    if (!documentRoot) {
      throw new ScrollingManagerError('Document Text Container not found');
    }
    // Determine where we intersect
    // if we are looking at a bottom element and it intersects, we determine line height
    // and figure out how many lines of text are visible, and move the new bottom divider to show the right amount of lines

    const logger = makeLogger('findElementThatIntersectsBottomOfPageRect', { shouldLog: shouldLogForDebug });

    // This value offsets the bottom coordinate based on where we intersect the element
    let bottomCoordModifier = 0;

    let elementThatIntersects = elementAtBottomParam;
    const matcherFunc = (element: Node): element is HTMLElement => {
      return element.nodeName !== '#text' && isHTMLElement(element) && element.getBoundingClientRect().height > 0 && this.isElementIntersectingAtYCoord(element, pageRect.bottom);
    };

    // If the element has children, we need to find the deepest child that intersects the bottom of the page
    if (elementThatIntersects.children.length) {
      const deepestChildThatIntersects = getDeepestWith<HTMLElement>(elementThatIntersects, matcherFunc);
      if (deepestChildThatIntersects && deepestChildThatIntersects.nodeName !== '#text') {
        logger.log('We found the deepest child node!');
        logger.log(deepestChildThatIntersects);
        elementThatIntersects = deepestChildThatIntersects;
      }
    }

    const doesElementContainDirectTextNodes = (element: HTMLElement) => {
      for (const child of element.childNodes) {
        if (child.nodeType === Node.TEXT_NODE && child.textContent && child.textContent.length > 1) {
          return true;
        }
      }
      return false;
    };
    // if the element has text nodes, we possibly intersect a text node, we should get the line height and move the page boundary to not cut text
    // If the element does not have direct text node children, we probably did not find the deepest element intersecting the page boundary, no need to move the page
    if (elementThatIntersects.children.length === 0 || doesElementContainDirectTextNodes(elementThatIntersects)) {
      logger.log('-------------------');
      logger.log(elementThatIntersects);
      const relativeYOfPageBottom = documentRoot.getBoundingClientRect().top + pageRect.bottom;
      logger.log('relativeY ', relativeYOfPageBottom);

      const computedLineHeightValue = this.window.getComputedStyle(elementThatIntersects).lineHeight;
      const computedLineHeightNum = parseFloat(computedLineHeightValue ?? '');
      let lineHeightFromCSS = this.baseLineHeight;
      if (!isNaN(computedLineHeightNum) && computedLineHeightNum !== 0) {
        lineHeightFromCSS = computedLineHeightNum;
      }
      logger.log(`line height from CSS ${lineHeightFromCSS}`);

      const elementHeight = elementThatIntersects.getBoundingClientRect().height;
      logger.log(`bottom element height ${elementHeight}`);
      // We estimate the line height of the element by taking the line height from CSS
      // getting the rough idea of the total lines in the element
      // and then dividing that value by its height to get its "true" line height
      // the CSS line height is more of an "estimate"
      // This code is rough and will be replaced once I have a better idea of how to find where we intersect in a text node
      const totalLinesInElement = Math.abs(Math.round(elementHeight / lineHeightFromCSS) - elementHeight / lineHeightFromCSS) < 0.3 ? Math.round(elementHeight / lineHeightFromCSS) : Math.ceil(elementHeight / lineHeightFromCSS);
      logger.log(`total lines in element ${totalLinesInElement}`);
      let lineHeight = elementHeight / totalLinesInElement;

      logger.log(`Found line height ${lineHeight}`);

      // Get the number of lines that are above the bottom divider
      const bottomElementTop = elementThatIntersects.getBoundingClientRect().top;
      logger.log(`bottomElementTop ${bottomElementTop}`);
      const diff = relativeYOfPageBottom - bottomElementTop;
      logger.log(`diff ${diff}`);
      const lines = Math.round(diff / lineHeight);
      // this is an empirical test where I found that nodes with one line match the CSS from styles
      if (lines === 1) {
        lineHeight = Math.ceil(lineHeightFromCSS);
      }

      logger.log(`Computed lines ${lines}`);
      bottomCoordModifier = diff - lines * lineHeight;
    }
    logger.log(`final bottom margin modifier ${bottomCoordModifier}`);
    logger.log('-----------------');

    return [elementThatIntersects, bottomCoordModifier];
  }

  /**
   * @param currentPageNum the current page number to compute
   * @param currentPageRect the info of the page right above the pageNUm
   * @return new page rect for pageNum
   */
  computePageRectsForNextPage(currentPageNum: number, currentPageRect: PageRect, shouldLogForDebug = false): PageRect {
    const logger = makeLogger('computePageRectsForNextPage', { shouldLog: shouldLogForDebug });
    const documentTextContent = this.documentTextContent;
    if (!documentTextContent) {
      throw new ScrollingManagerError('Document Text Container not found');
    }
    const relativeTopOfDocumentTextContent = this.getDocumentTopOfElement(documentTextContent);
    const intersectingElement = this.getElementAtY(currentPageRect.bottom);
    // IF element At top or bottom is null, that's great, the border height is already determined

    // This value offsets the bottom coordinate based on where we intersect the element
    let bottomCoordModifier = 0;
    let elementAtBottom = intersectingElement;
    logger.log(`Compute page rect for page ${currentPageNum}, previousPageRect: `, currentPageRect);
    logger.log(`Starting with element at bottom of page ${currentPageNum}`, intersectingElement);

    if (elementAtBottom && elementAtBottom.getBoundingClientRect().height > 0) {
      [elementAtBottom, bottomCoordModifier] = this.findElementThatIntersectsBottomOfPageRect(elementAtBottom, currentPageRect, shouldLogForDebug);
      logger.log('New element at bottom that intersects the bottom of the page and new page bottom', elementAtBottom, bottomCoordModifier, currentPageRect.bottom);
    }

    currentPageRect.bottom -= Math.trunc(bottomCoordModifier);
    let dividerHeight = this.window.innerHeight - TOP_MARGIN - (currentPageRect.bottom - currentPageRect.top);
    if (currentPageNum === 0) {
      // uncomment this if we want a divider on the first page
      dividerHeight -= relativeTopOfDocumentTextContent - TOP_MARGIN;
      // uncomment this if we want the divider under the first page to be hidden
      // dividerHeight = 0;
    }
    dividerHeight = Math.max(0, dividerHeight);

    // check if this element is splittable at all, if not, dont draw a border
    if (elementAtBottom && !this.isElementSplittable(elementAtBottom)) {
      dividerHeight = SPLIT_BORDER_HEIGHT;
    }

    // add new border for this page
    return { top: Math.trunc(currentPageRect.bottom), bottom: Math.trunc(currentPageRect.bottom + this.pageHeight), topPageDividerHeight: Math.trunc(dividerHeight) };
  }

  /*
    This is the meat of the paginated manager
    We generate pages up to pageNumToComputeTo,
    if we already have some pages to generate, we reuse their values (memoization essentially)
    otherwise, go through each page, looking at elements intersecting the bottom of the page
    if there are elements, do some math to determine where the page border will be drawn
   */
  computePageRects(pageNum = 0, shouldLogForDebug = false) {
    const logger = makeLogger('computePageRects', { shouldLog: shouldLogForDebug });

    const pageNumToReach = pageNum + 3;
    if (shouldLogForDebug) {
      logger.log(`Compute up to page ${pageNumToReach}`);
    }
    if (pageNumToReach < this.pageRects.length || this.pageRects.length > 0 && this.pageRects[this.pageRects.length - 1].bottom >= this.currentHeight) {
      logger.log(`No need to compute, we have page margin data up to page ${this.pageRects.length}`);
      return;
    }
    const documentTextContent = this.documentTextContent;
    if (!documentTextContent) {
      throw new ScrollingManagerError('Document Text Container not found');
    }
    if (!this.endOfContentElement) {
      throw new ScrollingManagerError('No end of content element found');
    }
    // Start from page zero, compute page margins as we go down to page num
    this.currentHeight = this.getDocumentTopOfElement(this.endOfContentElement);

    const relativeTopOfDocumentTextContent = this.getDocumentTopOfElement(documentTextContent);

    if (this.pageRects.length === 0) {
      this.pageRects = [{ top: Math.trunc(relativeTopOfDocumentTextContent), bottom: Math.trunc(this.pageHeight + TOP_MARGIN), topPageDividerHeight: 0 }];
    }
    logger.log(`going from ${this.pageRects.length} pages to ${pageNumToReach} pages ${this.currentHeight}`);
    // go through each page
    for (let i = this.pageRects.length - 1; i < pageNumToReach; i++) {
      const currentPageRect = this.pageRects[i];
      // start from previous page margin, compute the start of that page
      // then go down to the bottom of the page and figure out where that start
      const newPageRect = this.computePageRectsForNextPage(i, currentPageRect, shouldLogForDebug);
      if (newPageRect.bottom < currentPageRect.bottom) {
        throw new ScrollingManagerError('We somehow got a smaller coord for next page');
      }

      // add new border for this page
      this.pageRects.push(newPageRect);
      if (newPageRect.bottom >= this.currentHeight) {
        break;
      }
    }
  }

  showDebugStyles(enabled: boolean) {
    if (enabled) {
      this.document.body.classList.add('debug-styles');
    } else {
      this.document.body.classList.remove('debug-styles');
    }
  }

  updateCurrentCenteredElement() {
    this.logger.debug('UpdateCurrentCenteredElement fired');
    if (this.updatingCenterElementDisabled) {
      return;
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('UpdateCurrentCenteredElement Document Text Container not found');
    }
    if (!this.highlightableElements.length) {
      populateHighlightableElements(this.documentTextContent, this.highlightableElements);
    }
    const centeredElement = findCenteredElementInViewport(this.highlightableElements, this.window) as HTMLElement;
    this.logger.debug('updateCurrentCenteredElement ', { centeredElement, top: centeredElement?.getBoundingClientRect().top });

    // Uncomment for debug purposes
    // const prevElementDebug = this.document.querySelector('.centeredElementDebug');
    // prevElementDebug?.classList.remove('centeredElementDebug');
    // centeredElement?.classList.add('centeredElementDebug');
    // // // this.logger.log(centeredElement);
    // // // this.logger.log('remember to comment me back out');

    this.currentCenteredElementInfo = {
      element: centeredElement,
      scrollDelta: centeredElement?.getBoundingClientRect().top,
    };
  }

  exponentialDecayArray(initialVelocity: number, startDistance: number, targetDistance: number, totalTime: number, steps: number) {
    // ChatGPT helped me here, generate an array of values that simulate an exponential decay function
    const decayConstant = -Math.log(initialVelocity / (startDistance - targetDistance)) / totalTime;
    const values = [];
    const stepTime = totalTime / steps;

    for (let i = 0; i <= steps; i++) {
      const t = i * stepTime;
      const value = targetDistance + (startDistance - targetDistance) * Math.exp(-decayConstant * t);
      values.push(value);
    }

    return values;
  }

  async fakeSmoothScrollToCoord(targetScrollTop: number, velocity = 1) {
    const currentTransitionPromise = new DeferredPromise<boolean>();
    this.currentTransitionPromises.push(currentTransitionPromise);

    const currentScrollTop = this.getScrollingElementTop();
    const delta = currentScrollTop - targetScrollTop;
    // if delta is positive, we are scrolling up, if delta is negative we are scrolling down
    if (delta === 0) {
      return;
    }
    const steps = Math.ceil(this.scrollTimeForPage / this.scrollStepDelay);
    let expDecayPoints: number[] = [];
    if (currentScrollTop < targetScrollTop) {
      const generatedExpPoints = this.exponentialDecayArray(Math.abs(velocity), targetScrollTop, currentScrollTop, this.scrollTimeForPage, steps);
      const deltaPoints = generatedExpPoints.map((p) => targetScrollTop - p);
      expDecayPoints = deltaPoints.map((dp) => currentScrollTop + dp);

    } else {
      expDecayPoints = this.exponentialDecayArray(Math.abs(velocity), currentScrollTop, targetScrollTop, this.scrollTimeForPage, steps);
    }

    let i = 0;
    const move = (d: number, num: number, targetScrollTop: number) => {
      if (currentTransitionPromise.status === 'resolved') {
        return;
      }
      if (d < 0) {
        this.setScrollingElementTop(Math.min(num, targetScrollTop));
      } else {
        this.setScrollingElementTop(Math.max(num, targetScrollTop));
      }
    };
    for (const num of expDecayPoints) {
      requestAnimationFrame(() => {
        move(delta, num, targetScrollTop);
      });
      i += 1;
      if (i > expDecayPoints.length * 0.9) {
        break;
      }
      await delay(this.scrollStepDelay);
    }
    if (this.window.osType === 'ios') {
      this.scrollingElementScrollTo({ top: targetScrollTop, behavior: 'smooth' });
    } else {
      this.setScrollingElementTop(targetScrollTop);
    }
    currentTransitionPromise.resolve(true);
    if (this.currentTransitionPromises.length > 20) {
      this.currentTransitionPromises.slice(this.currentTransitionPromises.length - 2);
    }
  }

  get documentTextContentChildren() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('No document text content found');
    }
    let childrenToUse = this.documentTextContent.children;
    // 2 actually means 1 element, (there is always an end of content element added)
    // So if we only have one main element, and that element has children most likely thats the element we are interested in
    if (this.documentTextContent.children.length === 2 && this.documentTextContent.children[0].children.length > 0) {
      childrenToUse = this.documentTextContent.children[0].children;
    }
    return childrenToUse;
  }


  get documentTextChildrenForCloning() {
    if (this._internalChildrenForCloning) {
      return this._internalChildrenForCloning;
    }
    const getChildrenToUseRecursively = (childArray: HTMLCollection): HTMLCollection => {
      if (childArray.length === 0 || childArray.length > 1) {
        return childArray;
      }
      if (childArray.length === 1 && childArray[0].getBoundingClientRect().height > this.pageHeight * 2 && childArray[0].children) {
        return getChildrenToUseRecursively(childArray[0].children);
      }
      return childArray;
    };
    this._internalChildrenForCloning = Array.from(getChildrenToUseRecursively(this.documentTextContentChildren));
    return this._internalChildrenForCloning;
  }

  // Take a node and a page number, and fill that node with cloned elements from the page
  populateNodeWithElementsFromPage(contentChildNode: Node, pageNum: number) {
    if (pageNum < 0 || pageNum >= this.pageRects.length) {
      this.logger.warn(`Attempted to populate node with elements from page num out of bounds ${pageNum} max: ${this.pageRects.length}`);
      return [];
    }

    const pageRect = this.pageRects[pageNum];
    const viewportWidth = this.window.innerWidth;
    const relativePageRect = {
      top: pageRect.top - this.getScrollingElementTop() - this.pageHeight,
      bottom: pageRect.bottom - this.getScrollingElementTop() + this.pageHeight,
      left: 0,
      right: viewportWidth,
    };

    const childrenToUse = this.documentTextChildrenForCloning;
    const allVisibleElements = getVisibleElementsWithinRectBounds(childrenToUse, relativePageRect);
    this.logger.log(`pageRect for page ${pageNum}`, this.pageRects[pageNum]);
    this.logger.log(`getting all visible elements`, allVisibleElements);

    for (const element of allVisibleElements) {
      const clonedNode = element.cloneNode(true) as HTMLElement;
      const rwHighlights = clonedNode.querySelectorAll('rw-highlight') ?? [];
      for (const highlight of rwHighlights) {
        highlight.setAttribute('data-highlight-id', `none`);
      }
      contentChildNode.appendChild(clonedNode);
    }
    return allVisibleElements;
  }

  // This function takes a page number, and re-creates that page dynamically in either the top or bottom snapshot container
  matchPageSnapshotToPage(element: 'top' | 'bottom', pageNum: number, offset = 0) {
    if (pageNum < 0 || pageNum >= this.pageRects.length) {
      return;
    }
    this.logger.log(`Matching ${element} to page ${pageNum}`);
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('No document text content found');
    }
    const pageSnapshotElement = element === 'top' ? this.topPageSnapshotElement : this.bottomPageSnapshotElement;
    if (!pageSnapshotElement) {
      throw new ScrollingManagerError('No page snapshot elements found');
    }
    // Clear out any old logic that hides this snapshot container
    pageSnapshotElement.classList.remove('hide-snapshot-content');
    // Delete old child
    if (pageSnapshotElement.childNodes[0]) {
      pageSnapshotElement.removeChild(pageSnapshotElement.childNodes[0]);
    }

    // Create the inner child again
    const contentChild = this.document.createElement('div');
    contentChild.className = 'content-bottom document-content';
    pageSnapshotElement.style.height = '0px';
    pageSnapshotElement.appendChild(contentChild);

    // If the border above this page is non-existent, we don't need this snapshot
    if (element === 'bottom' && this.pageRects[pageNum].topPageDividerHeight === SPLIT_BORDER_HEIGHT) {
      pageSnapshotElement.classList.add('hide-snapshot-content');
      return;
    }
    // If the border below this page is non-existent, we don't need this snapshot
    if (element === 'top' && this.pageRects[Math.min(this.pageRects.length - 1, pageNum + 1)].topPageDividerHeight === SPLIT_BORDER_HEIGHT) {
      pageSnapshotElement.classList.add('hide-snapshot-content');
      return;
    }

    const topOfContent = this.getDocumentTopOfElement(this.documentTextContent);
    this.logger.log(`top of content ${topOfContent}`);
    // get a position relative to the text content
    const tempTopPagePos = pageNum > 0 ? Math.max(0, this.pageRects[pageNum].top - topOfContent) : 0;
    this.logger.log(`temp top page Pos ${tempTopPagePos}`);

    pageSnapshotElement.setAttribute('current-page', `${pageNum}`);

    // first, make the snapshot the same height as the page
    // then make the snapshot stretch to either above border or below border
    // Take this example
    /*
      --------- Border (Not existent) ------------
          ------- Top Snapshot ------
          -------- Border -------------
         --------current page -------

         The top snapshot needs to be extra high to cover the content that the border otherwise would have covered
         the same idea applies to the bottom snapshot
     */
    // ONE EXCEPTION IS IF THE TOP SNAPSHOT IS FOR PAGE ZERO

    pageSnapshotElement.style.height = `${this.pageRects[pageNum].bottom - this.pageRects[pageNum].top}px`;
    let topPageHeightModifier = 0;
    if (element === 'top' && pageNum > 0) {
      // If the border above the fold is zero, that means the snapshot won't reach the top of the screen
      // and a bit of true content will peek. Double the height of the snapshot to hide this
      // unlike for the "bottom" element the top is a bit tricky
      // we will need to move the snapshot element up by the same amount we stretched it
      // this all happens lower down in this file
      const borderAboveThisPageHeight = this.getBoundedPageRect(pageNum).topPageDividerHeight;
      if (borderAboveThisPageHeight === 0) {
        topPageHeightModifier = this.pageHeight;
      }
      pageSnapshotElement.style.height = `${this.pageRects[pageNum].bottom - this.pageRects[pageNum].top + topPageHeightModifier}px`;
    } else if (element === 'bottom') {
      const borderBelowThisPageHeight = this.getBoundedPageRect(pageNum + 1).topPageDividerHeight;
      // If the border below the fold is zero, that means the snapshot wont reach the bottom of the screen
      // and a bit of true content will peek. Double the height of the snapshot to hide this
      const modifier = borderBelowThisPageHeight === 0 ? 2 : 1;
      pageSnapshotElement.style.height = `${(this.pageRects[pageNum].bottom - this.pageRects[pageNum].top) * modifier}px`;
    }
    // move the snapshot to the start of the page we are simulating
    // Here is where we move the top snapshot element up by however much we increased its height by
    pageSnapshotElement.style.top = `${tempTopPagePos + offset - topPageHeightModifier}px`;

    const allVisibleElements = this.populateNodeWithElementsFromPage(contentChild, pageNum);

    let childOffset = 0;
    // Now that we created all the children, we actually need to move the content inside the snapshot by the offset provided
    if (allVisibleElements.length > 0) {
      this.logger.log(`First visible element in document`, allVisibleElements[0], allVisibleElements[0].getBoundingClientRect().top);
      this.logger.log(`First element in snapshot`, contentChild.children[0], contentChild.children[0].getBoundingClientRect().top);

      childOffset = allVisibleElements[0].getBoundingClientRect().top - contentChild.children[0].getBoundingClientRect().top;
      this.logger.log(`initial child offset comp ${childOffset}`);

      const pageBelow = Math.min(this.pageRects.length, pageNum + 1);
      this.logger.log(`The page below is ${pageBelow}`, this.pageRects[pageBelow]);
      const topElementOffset = this.getBoundedPageRect(pageBelow).topPageDividerHeight;
      const bottomElementOffset = this.getBoundedPageRect(pageNum).topPageDividerHeight;

      if (element === 'top') {
        // If we are simulating the top element, we want to move the content UP by the height of the border of the page below
        childOffset -= topElementOffset;
        // if the border is empty, no need for this, slightly redundant code, technically we shouldnt reach here
        if (topElementOffset === 0) {
          pageSnapshotElement.classList.add('hide-snapshot-content');
        }
        this.logger.log(`Top Element offset ${topElementOffset}`);
      } else {
        // If we are simulating the bottom element, we want to move the content down by the height of the border of the page above
        childOffset += bottomElementOffset;
        // if the border is empty, no need for this, slightly redundant code, technically we shouldnt reach here
        if (bottomElementOffset === 0) {
          pageSnapshotElement.classList.add('hide-snapshot-content');
        }
        this.logger.log(`bottom Element offset ${bottomElementOffset}`);
      }
    }
    const firstChild = pageSnapshotElement.childNodes[0] as HTMLElement;
    firstChild.style.top = `${childOffset}px`;

    return tempTopPagePos + offset;
  }

  getBoundedPageNum(pageNum: number) {
    if (this.pageRects.length - 1 < pageNum) {
      this.computePageRects(pageNum + 3);
    }

    // return a value from either zero to up to pageRects; we should have all the pageRects computed
    // up to this pageNum, if the page num was greater than all the pages we could compute, return the last page
    return Math.min(Math.max(0, pageNum), this.pageRects.length - 1);
  }

  getBoundedPageRect(pageNum: number) {
    if (this.pageRects.length <= pageNum) {
      this.computePageRects(pageNum);
    }

    if (pageNum < 0) {
      return this.pageRects[0];
    }

    // At this point we should have computed all the pages up to pageNum or max pages
    // if pageNum is greater than max page number, return the last page
    const pageNumToReturn = Math.min(pageNum, this.pageRects.length - 1);
    return this.pageRects[pageNumToReturn];
  }

  movePageBorder(borderId: string, pageRect: PageRect, newTop: number, shouldHideBorder: boolean) {
    const pageDivider = this.document.querySelector<HTMLElement>(`#page-divider-${borderId}`);

    if (!pageDivider) {
      throw new ScrollingManagerError(`No element with ID #page-divider-${borderId} found`);
    }
    pageDivider.style.top = `${newTop}px`;
    if (pageRect.topPageDividerHeight === 0 || shouldHideBorder) {
      pageDivider.classList.add('non-splittable-divider');
    } else {
      pageDivider.classList.remove('non-splittable-divider');
      pageDivider.style.height = `${pageRect.topPageDividerHeight + 1}px`;
    }

  }

  onScrollToPageEnd(newPage: number) {
    this.logger.log(`On scroll to page end, newPage: ${newPage}`);

    this.hideVisiblePageDividerElements();

    if (!this.bottomPageSnapshotElement || !this.topPageSnapshotElement || !this.headerContainer) {
      throw new ScrollingManagerError('[ScrollToPage] No page snapshot elements or header element found');
    }

    const previousPageRect = this.getBoundedPageRect(newPage - 1);
    const pageRect = this.getBoundedPageRect(newPage);
    const nextPageRect = this.getBoundedPageRect(newPage + 1);
    const nextNextPageRect = this.getBoundedPageRect(newPage + 2);

    this.movePageBorder('border-above-previous-page', previousPageRect, previousPageRect.top - pageRect.topPageDividerHeight - previousPageRect.topPageDividerHeight, newPage === 0);
    this.movePageBorder('border-above-current-page', pageRect, pageRect.top - pageRect.topPageDividerHeight, false);
    this.movePageBorder('border-below-current-page', nextPageRect, nextPageRect.top, newPage >= this.pageRects.length - 1);
    this.movePageBorder('border-below-next-page', nextNextPageRect, nextPageRect.bottom + nextPageRect.topPageDividerHeight, newPage >= this.pageRects.length - 1);

    if (newPage === 0) {
      this.headerContainer.style.top = '0px';
    } else {
      const secondPageRect = this.getBoundedPageRect(1);
      // offset the header by the size of the first pages bottom border
      this.headerContainer.style.top = `${-secondPageRect.topPageDividerHeight}px`;
    }

    const newScrollTop = newPage <= 0 ? 0 : pageRect.top - TOP_MARGIN;
    this.setScrollingElementTop(newScrollTop);

    if (newPage <= 0) {
      this.topPageSnapshotElement.classList.add('hide-snapshot-content');
    }
    if (newPage >= this.pageRects.length - 1) {
      animateEndOfReadingButton(0);
      this.bottomPageSnapshotElement.classList.add('hide-snapshot-content');
    } else {
      this.bottomPageSnapshotElement.classList.remove('hide-snapshot-content');
    }

    // re-simulate top and bottom content snapshots
    // Move the top content snapshot above this current page and offset it by the border size at the top
    if (newPage - 1 >= 0) {
      this.matchPageSnapshotToPage('top', newPage - 1, -this.getBoundedPageRect(newPage).topPageDividerHeight);
    }
    // Move the bottom content snapshot below this current page and offset it by the border size at the bottom of this page
    if (newPage + 1 < this.pageRects.length) {
      this.matchPageSnapshotToPage('bottom', newPage + 1, this.getBoundedPageRect(newPage + 1).topPageDividerHeight);
    }

    this.enableScrollingWithTouch();
    this.updateCurrentCenteredElement();
    this.currentlyTransitioningPages = false;

    const { clientHeight, scrollHeight } = this.getScrollingElement();
    if (!this.scrollingEventsDisabled) {
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('scroll_end', {
        currentScrollValue: newPage < this.pageRects.length - 1 ? newScrollTop : scrollHeight,
        maxScrollValue: scrollHeight,
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      });
    }

    for (const func of this.scrollListeners) {
      func();
    }
  }

  onScrollToPageStart(newPage: number) {
    if (!this.bottomPageSnapshotElement || !this.topPageSnapshotElement) {
      throw new ScrollingManagerError('[ScrollToPage] No page snapshot elements found');
    }
    this.disableScrollingWithTouch();
    this.logger.log(`We want to scroll to page ${newPage}`);
    this.computePageRects(newPage);

    if (newPage > 1) {
      this.headerImageContainer?.classList.add('hide-snapshot-content');
    } else {
      this.headerImageContainer?.classList.remove('hide-snapshot-content');
    }
  }

  emitScrollStartEvent() {
    if (this.scrollingEventsDisabled) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
    this.window.portalGateToForeground.emit('scroll_start', {
      currentScrollValue: scrollTop,
      maxScrollValue: scrollHeight,
      clientScrollableWindowSize: clientHeight,
      serializedPosition: serializedPositionInfo?.serializedPosition,
      serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
    });
  }

  scrollToPage(newPageParam: number): void {
    if (this.debugFreeScroll || this.pageRects.length === 0) {
      return;
    }

    const currentScrollPos = this.getScrollingElementTop();

    const newPage = this.getBoundedPageNum(newPageParam);

    const currentPage = this.currentPage;
    this.currentPage = newPage;

    const pageRect = this.getBoundedPageRect(newPage);
    const newScrollTop = newPage <= 0 ? 0 : pageRect.top - TOP_MARGIN;
    this.logger.log(`Scroll to page ${newPage}`, { newScrollTop, pageRect });

    const direction = currentPage < newPage ? 'down' : 'up';
    let initialScrollTarget = 0;
    if (direction === 'down') {
      initialScrollTarget = newScrollTop;
    } else {
      initialScrollTarget = Math.max(0, newScrollTop);
    }

    if (currentScrollPos === initialScrollTarget) {
      return;
    }

    // Since we didn't trigger this via a scroll, we should fire these events here
    this.emitScrollStartEvent();

    this.onScrollToPageStart(newPage);
    this.setScrollingElementTop(initialScrollTarget);
    this.onScrollToPageEnd(newPage);
  }


  async scrollToPageSmooth(newPageParam: number, initialVelocity = 0): Promise<void> {
    if (this.debugFreeScroll || this.pageRects.length === 0) {
      return;
    }
    if (!this.headerImageContainer) {
      throw new ScrollingManagerError('[ScrollToPage] No header image container found');
    }
    if (!this.bottomPageSnapshotElement || !this.topPageSnapshotElement) {
      throw new ScrollingManagerError('[ScrollToPage] No page snapshot elements found');
    }
    if (!this.documentRoot) {
      throw new ScrollingManagerError('[ScrollToPage] No document root found');
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('[ScrollToPage] No document text content found');
    }
    if (!this.headerContainer) {
      throw new ScrollingManagerError('[ScrollToPage] No header container found');
    }
    const currentPage = this.currentPage;
    const newPage = this.getBoundedPageNum(newPageParam);

    if (this.window.pagination?.smoothAnimationsDisabled) {
      for (const promise of this.currentTransitionPromises) {
        try {
          promise.resolve(true);
        } catch (e) { /* empty */ }
      }
      return this.scrollToPage(newPage);
    }

    await Promise.all(this.currentTransitionPromises);

    this.onScrollToPageStart(newPage);

    const scrollingToSamePage = currentPage === newPage;
    this.logger.log(`Scroll to page ${newPage} currentPage ${currentPage} smooth `);

    this.currentlyTransitioningPages = true;

    this.currentPage = newPage;
    const currentPageRect = this.getBoundedPageRect(newPage);
    const newScrollTop = newPage <= 0 ? 0 : currentPageRect.top - TOP_MARGIN;

    const promises = [];

    const direction = currentPage < newPage ? 'down' : 'up';

    const pageBelow = this.getBoundedPageNum(newPage + 1);
    const pageBelowRect = this.getBoundedPageRect(pageBelow);
    const topElementOffset = pageBelowRect.topPageDividerHeight;
    const bottomElementOffset = currentPageRect.topPageDividerHeight;

    let initialScrollTarget = newScrollTop;
    if (direction === 'down') {
      if (currentPageRect.topPageDividerHeight !== SPLIT_BORDER_HEIGHT) {
        initialScrollTarget = newScrollTop + bottomElementOffset;
      }
    } else {
      initialScrollTarget = newScrollTop - topElementOffset;
    }

    // If we are not on the first page, move the border up a bit
    // so when we reach the first page, its correctly offset until we finish scrolling to first page
    if (currentPage !== 0) {
      const secondPageRect = this.getBoundedPageRect(1);
      // offset the header by the size of the first pages bottom border
      this.headerContainer.style.top = `${-secondPageRect.topPageDividerHeight}px`;
    }

    if (scrollingToSamePage) {
      initialScrollTarget = newScrollTop;
    }


    if (this.window.osType === 'ios') {
      this.scrollingElementScrollTo({ top: initialScrollTarget, behavior: 'smooth' });
      if (this.window.pagination?.hapticsOnScrollEnabled) {
        setTimeout(() => {
          this.window.portalGateToForeground.emit('haptics_feedback');
        }, this.iosScrollDelay - this.hapticsOnScrollTimeModifier);
      }
      await delay(this.iosScrollDelay);
    } else {
      promises.push(this.fakeSmoothScrollToCoord(initialScrollTarget, initialVelocity));
      await Promise.all(promises);
    }

    // ON SCROLL END
    this.onScrollToPageEnd(newPage);
  }

  onScroll() {
    if (this.scrollingEventsDisabled) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
    this.window.portalGateToForeground.emit('scroll', {
      currentScrollValue: scrollTop,
      maxScrollValue: scrollHeight,
      clientScrollableWindowSize: clientHeight,
      serializedPosition: serializedPositionInfo?.serializedPosition,
      serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
    });
  }

  scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    this.logger.log('Scroll To Reading Position ', readingPosition);
    if (readingPosition.serializedPosition) {
      try {
        this.scrollToSerializedPosition(readingPosition.serializedPosition, readingPosition.mobileSerializedPositionElementVerticalOffset ?? 0);
      } catch (e) {
        if (readingPosition.scrollDepth) {
          this.scrollToPercentOfViewport(readingPosition.scrollDepth);
        }
      }
    } else if (readingPosition.scrollDepth) {
      this.scrollToPercentOfViewport(readingPosition.scrollDepth);
    }
    this.updateCurrentCenteredElement();
    this.startTouchY = null;
    this.enableScrollingWithTouch();
  }

  scrollToSerializedPosition(serializedPosition: string, offset: number) {
    this.logger.log('Scroll to serialized position ', serializedPosition, offset);
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollToSerializedPosition no document text content container found');
    }
    const position = deserializePosition({
      classApplier: getRangyClassApplier(),
      rootNode: this.documentTextContent,
      serialized: serializedPosition,
    });
    const range = this.document.createRange();
    range.setStart(position.node, position.offset);
    range.setEnd(position.node, position.offset);

    const closestElement = getClosestHTMLElement(position.node);
    if (!closestElement) {
      throw new ScrollingManagerError('Could not get closest element from node');
    }

    const target = isFocusableElement(closestElement)
      ? closestElement
      : getNextElementWithinContainer({
        container: this.documentTextContent,
        direction: 'next',
        element: closestElement,
        matcher: isFocusableElement,
      }) as HTMLElement;
    if (!target) {
      throw new ScrollingManagerError(`ScrollToSerializedPosition no target found for serialized position ${serializedPosition}`);
    }
    this.currentCenteredElementInfo = { element: target, scrollDelta: offset };
    return this.scrollToElement(target, -offset, 'auto');
  }


  isDocumentScrolledToBeginning(): boolean {
    return this.getScrollingElementTop() < 100;
  }

  // Util function to help move a debug border to a coordinate, useful for visualizing coordinates
  drawDebugBorderAtCoordY(coordY: number) {
    const border = this.document.querySelector<HTMLElement>('.debug-border');
    if (!border) {
      return;
    }
    border.style.top = `${coordY}px`;
    border.style.display = 'block';
  }

  scrollToElement(element: HTMLElement, offset = 0, behavior: 'smooth' | 'auto' = 'auto') {
    this.logger.log(`Scroll to element with offset ${offset}`, element);
    const y = this.getDocumentTopOfElement(element);
    this.logger.log(`The Y of element is ${y}`);
    this.setScrollingElementTop(y + offset);
    this.currentPage = this.getPageNumberFromCoordinate(y + offset);
    return this.scrollToPage(this.currentPage);
  }

  scrollToRect(rect: DOMRect, offset = 0) {
    const newTop = Math.floor(this.getScrollingElementTop() + rect.top) + offset;
    this.logger.log(`Scroll to rect ${newTop}`);
    const newPageNumber = this.getPageNumberFromCoordinate(newTop);
    this.scrollToPage(newPageNumber);
  }

  scrollToTop() {}

  // It's important to note that pageRect is relative to the entire document
  // thus CSS top positions and pageRect might not always align as CSS top positions are relative to the coordinates of their parents
  getPageNumberFromCoordinate(yCoord: number) {
    if (!this.pageRects.length) {
      return 0;
    }

    this.logger.log(`getPageNumberFromCoordinate ${yCoord}`);
    if (this.pageRects[0].top > yCoord) {
      return 0;
    }

    for (let i = 0; i < this.pageRects.length; i++) {
      if (this.pageRects[i].top <= yCoord && this.pageRects[i].bottom >= yCoord) {
        return i;
      }
    }
    this.logger.log('We tried to find the page coordinate but we ran out of pages, probably need to make more pages?');
    this.logger.log(`number of pages computed: ${this.pageRects.length}`);
    this.logger.log('Estimate how many more pages we need ');
    this.logger.log(`yCoord: ${yCoord} pageHeight: ${this.pageHeight} page num to generate: ${Math.ceil(yCoord / this.pageHeight)}`);
    this.computePageRects(Math.ceil(yCoord / this.pageHeight));

    for (let i = 0; i < this.pageRects.length; i++) {
      if (this.pageRects[i].top - TOP_MARGIN > yCoord) {
        return i - 1;
      }
    }
    return this.pageRects.length - 1;
  }

  scrollToPercentOfViewport(percent: number, animated = false, disableEvents = false) {
    this.logger.log('scroll to percent of viewport');
    if (disableEvents) {
      this.disableScrollEventsForNMilliseconds();
    }
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const { scrollHeight } = this.getScrollingElement();
    const newScrollTop = scrollHeight * percent;
    const newPageNumber = this.getPageNumberFromCoordinate(newScrollTop);
    this.logger.log(`Scroll to page ${newPageNumber}`);
    this.scrollToPage(newPageNumber);
  }

  handleScrollFromHref() {
    const newPage = this.getPageNumberFromCoordinate(this.getScrollingElementTop());
    this.scrollToPage(newPage);
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    if (this.ttsAutoScrollingEnabled) {
      // The offset helps make sure the TTS element stays on the right page
      this.scrollToRect(rect, rect.height - 2);
    }
  }

  returnToReadingPosition() {
    this.disableScrollEventsForNMilliseconds(400);
    if (this.readingPosition) {
      this.scrollToReadingPosition(this.readingPosition);
    }
    setTimeout(() => {
      const scrollTop = this.getScrollingElementTop();
      if (!this.documentRoot) {
        throw new ScrollingManagerError('No document root found');
      }
      const { clientHeight, scrollHeight } = this.getScrollingElement();
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('return_to_reading_position', {
        currentScrollValue: scrollTop,
        maxScrollValue: scrollHeight,
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      });
    }, 400);
  }

  disableScrollingWithTouch() {
    this.logger.log('Disable scroll with touch');
    if (this.debugFreeScroll) {
      return;
    }
    this.document.body.classList.add('disable-scroll');
    if (this.enableScrollTimeout) {
      clearTimeout(this.enableScrollTimeout);
    }
  }


  enableScrollingWithTouch() {
    if (this.window.pagination?.smoothAnimationsDisabled) {
      return;
    }
    if (this.enableScrollTimeout) {
      clearTimeout(this.enableScrollTimeout);
    }
    this.logger.log('We want to re-enable scroll with touch in a sec');
    this.enableScrollTimeout = setTimeout(() => {
      // We are still holding the screen if this was necessary to fire, dont
      if (this.startTouchY !== null) {
        return;
      }
      this.document.body.classList.remove('disable-scroll');
      this.logger.log('enable scroll with touch');
    }, 100);
  }

  onTouchStart(e: TouchEvent) {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('onTouchStart, Document root not found');
    }
    if (this.window.pagination?.smoothAnimationsDisabled) {
      this.disableScrollingWithTouch();
    }
    if (e.touches.length === 1) {
      this.showVisiblePageDividerElements();
      this.scrollStartTime = nowTimestamp();
      this.startPageOnTouch = this.currentPage;
      this.startTouchY = e.touches[0].pageY;
      this.endTouchY = this.startTouchY;
      this.touchYDirection = 0;

      this.startTouchClientY = e.touches[0].clientY;
      this.endTouchClientY = e.touches[0].clientY;
      this.throttledTouchClientY = e.touches[0].clientY;

      this.startTouchX = e.touches[0].screenX;
      this.endTouchX = this.startTouchX;

      this.emitScrollStartEvent();
    }
  }

  onTouchMove(e: TouchEvent) {
    if (this.startTouchY === null) {
      // should never happen
      return;
    }
    this.currentlyScrollingBecauseOfTouch = true;
    if (this.touchMoveThrottle === 0) {
      this.window.portalGateToForeground.emit('touch_move');
    }
    if (this.areTouchesDisabledBecauseOfSelection) {
      return;
    }
    const newClientY = e.touches[0].clientY;

    this.touchMoveThrottle += 1;
    if (this.touchMoveThrottle > 2) {
      this.logger.log(`newTouch ${newClientY} oldTouch: ${this.throttledTouchClientY} `);
      this.throttledTouchClientY = newClientY;
      this.touchMoveThrottle = 0;
    }
    const touchYDelta = Math.abs(this.throttledTouchClientY - newClientY);
    if (touchYDelta > 0) {
      const direction = (this.throttledTouchClientY - newClientY) / touchYDelta;
      if (this.touchYDirection !== direction) {
        this.logger.log(`Direction changed!! new startY ${newClientY}`);
        this.scrollStartTime = nowTimestamp();
      }
      this.touchYDirection = direction;
    }
    this.endTouchY = e.touches[0].pageY;
    this.endTouchClientY = newClientY;
    this.endTouchX = e.touches[0].screenX;
    if (this.enableScrollTimeout) {
      clearTimeout(this.enableScrollTimeout);
    }
  }

  // return true if an element should not allow a scroll event to happen if it is clicked on a margin
  isTouchTargetClickableOnMargins(touchTarget: Node | undefined | null) {
    return isHTMLElement(touchTarget) && (CLICKABLE_TAGS_THROUGH_PAGINATION_MARGINS.has(touchTarget.nodeName) || touchTarget.classList.contains('tts-button-text'));
  }

  onTouchEnd(e: TouchEvent) {
    if (this.startTouchY === null || this.startPageOnTouch === null) {
      return;
    }
    this.hideVisiblePageDividerElements();
    this.currentlyScrollingBecauseOfTouch = false;
    const currentPage = this.startPageOnTouch;
    this.startPageOnTouch = null;
    this.startTouchY = null;
    const currentTime = nowTimestamp();
    const timeDelta = currentTime - this.scrollStartTime;
    this.scrollStartTime = 0;
    const yTouchDelta = this.startTouchClientY - this.endTouchClientY;
    // Default to slightly less than threshold
    let verticalDelta = (this.verticalSwipeDistanceMinimumThreshold - 2) * this.touchYDirection;

    if (this.touchYDirection < 0 && yTouchDelta < 0 || this.touchYDirection > 0 && yTouchDelta > 0) {
      // we need to check that the direction we ended up swiping also reflects the amount of screen drag we created
      // if we dragged up, touchYDirection is -1, and if directionalTouchDelta is negative that means the screen moved up from the start touch spot
      // if this wasnt true, then we did not move enough to justify an entire page swipe
      verticalDelta = yTouchDelta;
    }

    const horizontalDelta = Math.abs(this.startTouchX - this.endTouchX);
    const isHorizontalSwipe = horizontalDelta > this.horizontalSwipeDistanceThreshold && horizontalDelta > verticalDelta && Math.abs(verticalDelta) < this.verticalSwipeDistanceConclusiveThreshold;

    const velocity = Math.abs(verticalDelta / timeDelta);

    this.logger.log(`On touch end - startY: ${this.startTouchClientY} end: ${this.endTouchClientY} touchDelta ${verticalDelta} direction ${this.touchYDirection} velocity ${velocity} timeDelta ${timeDelta} horizontal delta: ${horizontalDelta} isHorizontalSwipe: ${isHorizontalSwipe}`);

    const isTapOnScreen = timeDelta < this.tapTimeThreshold && (Math.abs(verticalDelta) < 2 && Math.abs(velocity) < 0.05);
    this.logger.log('IS TAP ON SCREEN ', isTapOnScreen);

    if (!this.window.pagination) {
      throw new ScrollingManagerError('this.window.pagination does not exist!');
    }

    if (isTapOnScreen) {
      let target = null;
      if (e.changedTouches.length > 0) {
        target = e.changedTouches[0].target as Node;
      }
      if (this.isTouchTargetClickableOnMargins(target) || this.isTouchTargetClickableOnMargins(target?.parentElement)) {
        this.hideVisiblePageDividerElements();
        return;
      }
      if (this.endTouchX <= this.window.pagination.leftClickAreaWidth) {
        // Press on left side
        this.scrollToPage(currentPage - 1);
        this.window.portalGateToForeground.emit('touch_move');
        this.window.portalGateToForeground.emit('haptics_feedback');
        e.preventDefault();
        e.stopPropagation();
        return;
      } else if (this.endTouchX >= this.window.pagination.rightClickAreaWidth) {
        // Press on right side
        this.scrollToPage(currentPage + 1);
        this.window.portalGateToForeground.emit('touch_move');
        this.window.portalGateToForeground.emit('haptics_feedback');
        e.preventDefault();
        e.stopPropagation();
        return;
      } else {
        // Code that handles a general press on the center of screen
        this.hideVisiblePageDividerElements();
        if (target && isHTMLElement(target) && CLICKABLE_TAGS.has(target.nodeName)) {
          return;
        }
        this.window.portalGateToForeground.emit('touch_move');
        this.window.portalGateToForeground.emit('root-clicked');
        return;
      }
    }

    if (this.areTouchesDisabledBecauseOfSelection) {
      this.hideVisiblePageDividerElements();
      return;
    }
    // Here we actually commit the scroll
    let newX: number = currentPage;
    if (!isHorizontalSwipe && (verticalDelta > this.verticalSwipeDistanceMinimumThreshold || verticalDelta > 0 && velocity > this.velocityThreshold)) {
      newX = currentPage + 1;
    } else if (!isHorizontalSwipe && (verticalDelta < -this.verticalSwipeDistanceMinimumThreshold || verticalDelta < 0 && velocity > this.velocityThreshold)) {
      newX = currentPage - 1;
    } else {
      newX = currentPage;
    }
    this.scrollToPageSmooth(newX, velocity);
  }
}
