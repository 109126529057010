import React from 'react';

import Icon from './Icon';

export default function LargePlayIcon({ className, text = 'Play' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 33.4408V10.5592C13 9.4132 14.233 8.69049 15.2329 9.25042L35.2734 20.4731C36.4667 21.1413 36.4667 22.8587 35.2734 23.5269L15.2329 34.7496C14.233 35.3095 13 34.5868 13 33.4408Z" fill="currentColor" />
    </svg>
  </Icon>;
}
