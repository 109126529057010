import sanitizeHtml, { Attributes, Tag } from 'sanitize-html';

import { Category } from '../types';
import { RWDOMParser } from './RWDOMParser.platform';

export const rwSanitizeHtml = (content: string, category: Category, originalEmailView?: boolean) => {
  // we keep inline styles for images in emails, as sometimes they're needed to properly size the images.
  // TODO: the parser could transform the inline widths/heights to width/height dom properties so we could avoid this
  const allowImageStyles = category === Category.Email;
  let allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img', 'video', 'source', 'svg', 'path', 'picture']);
  let allowedAllAttrs: string[] = ['data-rw-*'];
  let allowedClasses: {[key: string]: string[];} = {
    article: ['rw-embedded-tweet'],
    hr: ['twitter-thread-delimiter'],
    p: ['rw-outer-content', 'rw-ai', 'rw-hide'],
    span: ['rw-ai', 'rw-ai-explainer', 'rw-ai-highlighted'],
    li: ['rw-ai'],
    div: ['rw-digest-thread-wrapper'],
  };
  if (category === Category.EPUB) {
    allowedAllAttrs = ['data-rw-*', 'id', 'class'];
    allowedTags = allowedTags.concat(['style']);
    allowedClasses = { '*': ['*'] }; // eslint-disable-line
  }
  if (originalEmailView) {
    // TODO: this is a bit of hack. Ideally we should pass the correct original content from higher up in the tree..
    const htmlDoc = new RWDOMParser().parseFromString(content, 'text/html');
    const scriptElement = htmlDoc.querySelector('script.rw-email-original');
    if (scriptElement) {
      // eslint-disable-next-line no-param-reassign
      content = scriptElement.textContent;
    }
    allowedAllAttrs = ['style', 'align', 'alt', 'background', 'bgcolor', 'border', 'colspan', 'cellpadding', 'cellspacing', 'color', 'dir', 'face', 'height', 'rowspan', 'valign', 'width'];
    allowedClasses = { '*': ['*'] }; // eslint-disable-line
  }
  return sanitizeHtml(content, {
    allowedAttributes: {
      a: ['href', 'name', 'target', 'rel'], // eslint-disable-line id-length
      img: ['src', 'referrerpolicy', ...(allowImageStyles ? ['style', 'width', 'height', 'class'] : [])], // eslint-disable-line @typescript-eslint/no-extra-parens
      source: ['src', 'type', 'srcset', 'media'],
      video: ['controls', 'src'],
      path: ['*'],
      svg: ['*'],
      ol: ['start'],
      th: ['rowspan', 'colspan'],
      td: ['rowspan', 'colspan'],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '*': allowedAllAttrs,
    },
    allowedClasses,
    allowedTags,
    // For Style tag warning (style tag is considered dangerous)
    allowVulnerableTags: true,
    transformTags: {
      svg(tagName: string, attribs: Attributes): Tag {
        const viewBox = attribs.viewbox;
        if (viewBox) {
          try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_x, _y, width, height] = viewBox
              .replace(',', '')
              .split(' ');
            attribs.width = width;
            attribs.height = height;
          } catch (e) {
            // do nothing
          }
        } else {
          // Pick some default size for this SVG, so it isn't humungous.
          attribs.width = '32';
          attribs.height = '32';
        }
        return {
          tagName,
          attribs,
        };
      },
      img: (tagName: string, attribs: Attributes) => {
        attribs.referrerpolicy = 'no-referrer';
        if (originalEmailView) {
          attribs.class = 'allow-image-styles';
        } else if (allowImageStyles) {
          attribs.class = 'allow-image-styles';

          // Preserve width/height properties _unless_ they're a % as they'll likely break our formatting
          // because we don't preserve their parent properties often...
          if (typeof attribs.height === 'string') {
            // In case of height attribute, if its set to specific value, we replace height with max-height, to preserve image ratio when on mobile or small screen device
            if (!attribs.height.includes('%')) {
              attribs.style = attribs.style ? `${attribs.style}; max-height: ${attribs.height}px;` : `max-height: ${attribs.height}px;`;
            }
            delete attribs.height;
          }
          // If style attribute includes height, change it to max-height
          if (typeof attribs.style === 'string') {
            if (attribs.style.startsWith('height:')) {
              attribs.style = attribs.style.replace('height:', 'max-height:');
            }
            if (attribs.style.includes(' height:')) {
              attribs.style = attribs.style.replace(' height:', ' max-height:');
            }
            if (attribs.style.includes(';height:')) {
              attribs.style = attribs.style.replace(';height:', ';max-height:');
            }
          }
          if (typeof attribs.width === 'string' && attribs.width.includes('%')) {
            delete attribs.width;
          }
          if (attribs.style?.includes('width: 100%')) {
            attribs.style = attribs.style.replace('width: 100%', '');
          }
          if (attribs.style?.includes('height: 100%')) {
            attribs.style = attribs.style.replace('height: 100%', '');
          }
        }
        return {
          attribs,
          tagName: 'img',
        };
      },
      a: (tagName: string, attribs: Attributes) => {
        const isInternalLink = category === Category.EPUB && attribs?.href?.startsWith('#');
        if (!isInternalLink) {
          attribs.target = '_blank';
        }
        attribs.rel = 'noreferrer';
        return {
          attribs,
          tagName: 'a',
        };
      },
    },
  });
};
