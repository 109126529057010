import React, { KeyboardEventHandler, useCallback, useEffect, useRef } from 'react';

import useDevOnlyHtmlIdValidation from '../hooks/useDevOnlyHtmlIdValidation';
import Button from './Button';
import styles from './Dialog.module.css';
import Portal from './Popovers/Portal';

interface DialogProps {
  action: () => void;
  actionTitle: string;
  blueSecondaryActionButton?: boolean;
  cancelAction: () => void;
  cancelTitle?: string;
  children?: React.ReactNode;
  darkRedActionButton?: boolean;
  id: string;
  redActionButton?: boolean;
  secondaryAction?: () => void;
  secondaryActionTitle?: string;
  subtitle?: string;
  title: string;
}

export const Dialog = (
  {
    id,
    title,
    subtitle,
    children,
    cancelAction,
    cancelTitle = 'Cancel',
    actionTitle,
    action,
    secondaryAction,
    secondaryActionTitle,
    redActionButton = false,
    darkRedActionButton = false,
    blueSecondaryActionButton = false,
  }: DialogProps,
) => {
  useDevOnlyHtmlIdValidation(id);
  const dialogRef = useRef<HTMLInputElement>(null);

  const keyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Enter') {
      action();
    }
    if (event.key === 'Escape') {
      cancelAction();
    }
  }, [action, cancelAction]);

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    dialogRef.current.focus();
  }, [dialogRef]);


  const onClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      cancelAction();
    }
  }, [cancelAction]);

  return <Portal id={`dialog--${id}`}>
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
    <div className={styles.dialogWrapper} onClick={onClick} tabIndex={0} onKeyDown={keyDown} ref={dialogRef}>
      <div className={styles.dialogBox}>
        <div className={styles.text}>
          <h1 className={styles.title}>{title}</h1>
          {children ?? <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.cancelButton}
            onClick={cancelAction}
            variant="secondary">
            {cancelTitle}
          </Button>
          {secondaryActionTitle && secondaryAction && <Button
            className={[redActionButton ? styles.redButton : '', blueSecondaryActionButton ? styles.blueButton : ''].filter(Boolean).join(' ')}
            onClick={secondaryAction}
            variant="secondary">
            <span className={styles.buttonText}>{secondaryActionTitle}</span>
          </Button>}
          <Button
            className={[redActionButton ? styles.redButton : '', darkRedActionButton ? styles.darkRedButton : '', blueSecondaryActionButton ? styles.blueButton && !redActionButton : ''].filter(Boolean).join(' ')}
            onClick={action}
            variant="primary">
              <span className={styles.buttonText}>{actionTitle}</span>
            </Button>
        </div>
      </div>
    </div>
  </Portal>;
};
