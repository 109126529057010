
import makeLogger from '../../utils/makeLogger';
import convertRangeToRangyRange from './convertRangeToRangyRange';
import getClosestHTMLElement from './getClosestHTMLElement';
import getRangeFromNodes from './getRangeFromNodes';

const logger = makeLogger(__filename);

export default (element: HTMLElement, range: Range): boolean => {
  if (range.collapsed) {
    logger.warn('isElementInRange: range is collapsed');
    return false;
  }
  if (!getClosestHTMLElement(range.commonAncestorContainer)?.contains(element)) {
    return false;
  }
  const rangyRange = convertRangeToRangyRange(range);
  return rangyRange.intersectsRange(convertRangeToRangyRange(getRangeFromNodes([element])));
};
