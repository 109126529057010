import { useCallback } from 'react';

import { AnyDocument, Category, PartialDocument } from '../types';
import { isDocumentWithUrl } from '../typeValidators';
import getUrlDomain from '../utils/getUrlDomain';
import { globalState } from './models';

export const useMatchingRSS = (doc: AnyDocument | PartialDocument<AnyDocument, 'category' | 'source_specific_data' | 'url'> | null) => {
  return globalState(useCallback((state) => {
    if (!doc || doc.category !== Category.RSS && doc.category !== Category.Article || !isDocumentWithUrl(doc)) {
      return { possibleRss: null, subscribed: false };
    }
    if (doc.category === Category.RSS) {
      const feedId = doc.source_specific_data?.rss_feed;
      if (feedId && state.persistent.rssFeeds && state.persistent.rssFeeds[feedId]) {
        return {
          possibleRss: state.persistent.rssFeeds[feedId], subscribed: true,
        };
      }
    }
    const domain = getUrlDomain(doc.url);
    let possibleRss = state.possibleRssFeeds[domain];

    if (!possibleRss) {
      // Reminder, named groups aren't supported on react native
      const mediumUrl = doc.url.match(/\/\/(www\.)?([^.]+)?\.?medium.com\/(@?[^/?]+)(\?|$|\/)?/);
      const substackUrl = doc.url.match(/\/\/(www\.)?([^.]+)\.substack.com\/p.+/);
      if (mediumUrl) {
        const subDomainAuthor = mediumUrl[2];
        const suffixAuthor = mediumUrl[3];
        if (subDomainAuthor) {
          possibleRss = {
            url: `https://${subDomainAuthor}.medium.com/feed`,
            name: `${subDomainAuthor} - Medium`,
          };
        }
        if (suffixAuthor) {
          possibleRss = {
            url: `https://medium.com/feed/${suffixAuthor}`,
            name: `${suffixAuthor} - Medium`,
          };
        }
      } else if (substackUrl) {
        const subDomainAuthor = substackUrl[2];
        possibleRss = {
          url: `https://${subDomainAuthor}.substack.com/feed`,
          name: `${subDomainAuthor} on Substack`,
        };
      }
    }

    const allRssFeeds = state.persistent.rssFeeds ?? {};
    const current = Object.values(allRssFeeds).filter((feed) => possibleRss && feed.url === possibleRss.url);

    if (current.length) {
      possibleRss = current[0];
    }

    return { possibleRss, subscribed: current.length > 0 };
  }, [doc]));
};
