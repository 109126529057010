import 'tippy.js/dist/tippy.css';

import type { Options } from '@popperjs/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

import isComposing from '../../../../shared/foreground/utils/isComposing';
import nowTimestamp from '../../../../shared/utils/dates/nowTimestamp';
import delay from '../../../../shared/utils/delay';
import { scrollIntoViewIfNeeded } from '../../hooks/useScrollIntoViewIfNeeded';
import focusFirstFocusableDescendant from '../../utils/focusFirstFocusableDescendant';
import getEventTarget from '../../utils/getEventTarget';
import styles from './Popover.module.css';
import Portal from './Portal';

/*
  Tooltip:

  - Simple.
  - Concise.
  - Non-interactive.
  - Shown on hover (and or tap).
  - Does not take focus.
  - Auto re-positions itself to stay on screen.

  Example: when you hover over the inbox icon, a tooltip appears containing "Inbox".

  Popover (at least some of these):

  - Not so simple.
  - Verbose.
  - Interactive.
  - Is focusable / takes focus when opened by user action.
  - Can have many visibility triggers.
  - If off-screen, it doesn't re-position. We scroll to it.

  Example: edit highlight note form.
*/

export type Props = {
  allowFlip?: boolean;
  allowOverflow?: boolean;
  checkIfShouldScrollIntoView?(): boolean;
  className?: string;
  getBoundingClientRect?: () => DOMRect;
  hasPopperStyles?: boolean;
  hidePopover(): void;
  isClickOutside?: (data: { event: MouseEvent; isInPopperElement: boolean; popperElement: HTMLElement; }) => boolean;
  isShown: boolean;
  onHiddenWithEscape?: () => void;
  pointerDownTimeout?: number; // See usage
  popperOptions?: Parameters<typeof usePopper>[2];
  portalDestinationElementId?: string;
  positionUpdateCounter?: number;
  preventOverflow?: boolean;
  reference?: Element;
  selectorToAutoFocus?: Parameters<typeof focusFirstFocusableDescendant>[1];
  shouldAutoFocus?: boolean;
  shouldHideOnBlur?: boolean;
  shouldHideOnClickOutside?: boolean;
  shouldHideOnEscape?: boolean;
  shouldStayInDomWhenHidden?: boolean;
  showPopover?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultExport = React.memo(function Popover({
  allowFlip,
  allowOverflow,
  checkIfShouldScrollIntoView,
  children,
  className,
  getBoundingClientRect,
  hasPopperStyles,
  hidePopover,
  isClickOutside,
  isShown: isShownArgument,
  onHiddenWithEscape,
  pointerDownTimeout,
  popperOptions: customPopperOptions,
  portalDestinationElementId,
  positionUpdateCounter,
  preventOverflow = false,
  reference,
  selectorToAutoFocus,
  shouldAutoFocus = true,
  shouldHideOnBlur = true,
  shouldHideOnClickOutside = true,
  shouldHideOnEscape = true,
  shouldStayInDomWhenHidden,
  showPopover,
  ...extraProps
}: Props) {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  // We maintain a separate variable for this so we can do stuff right before it changes
  const [isShown, setIsShown] = useState<Props['isShown']>(false);
  const [timeShownAt, setTimeShownAt] = useState<number | null>(null);
  const [hasAutoFocused, setHasAutoFocused] = useState(false);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  useEffect(() => {
    if (isShown) {
      setTimeShownAt(nowTimestamp());
      return;
    }
    setTimeShownAt(null);
  }, [isShown, setTimeShownAt]);

  const hasBeenThisLongSinceLastShown = useCallback(
    (milliseconds: number): boolean => !timeShownAt || nowTimestamp() - timeShownAt > milliseconds,
    [timeShownAt],
  );

  const virtualElement = useMemo(() => {
    if (!getBoundingClientRect) {
      return reference;
    }

    return {
      getBoundingClientRect,
    };
  }, [getBoundingClientRect, reference]);

  const popperOptions = useMemo(() => {
    const modifiers: Options['modifiers'] = Array.from(customPopperOptions?.modifiers ?? []);

    const defaultModifiers = [
      { name: 'flip', enabled: Boolean(allowFlip) },
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'preventOverflow', enabled: preventOverflow },
    ];

    for (const defaultModifier of defaultModifiers) {
      if (!modifiers.find(({ name }) => name === defaultModifier.name)) {
        modifiers.push(defaultModifier);
      }
    }

    return {
      ...customPopperOptions ?? {},
      modifiers,
    };
  }, [allowFlip, customPopperOptions, preventOverflow]);

  const { attributes, styles: popperStyles, update } = usePopper(virtualElement, popperElement, popperOptions);

  useEffect(() => {
    // When it's shown, trigger a position update. Sometimes the position is wrong (maybe shadow DOM issue? Not sure)
    if (isShownArgument && update) {
      update();
    }
    setIsShown(isShownArgument);

    /*
      Also, update if positionUpdateCounter changes. This is a number parameter that an ancestor
      can increment to trigger a position recalculation.
    */
  }, [isShownArgument, positionUpdateCounter, update]);

  useEffect(() => {
    if (!popperElement) {
      return;
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Escape' || !shouldHideOnEscape || isComposing(event)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      onHiddenWithEscape?.();
      hidePopover();
    };

    popperElement.addEventListener('keydown', onKeyDown);
    return () => popperElement.removeEventListener('keydown', onKeyDown);
  }, [hidePopover, onHiddenWithEscape, popperElement, shouldHideOnEscape]);

  useEffect(() => {
    if (!popperElement) {
      return;
    }

    const onPointerDown = (event: MouseEvent) => {
      if (
        !isShown ||
        !popperElement ||
        !shouldHideOnClickOutside
      ) {
        return;
      }

      const isInPopperElement = popperElement.contains(getEventTarget(event));
      if (isClickOutside) {
        if (!isClickOutside({
          event,
          isInPopperElement,
          popperElement,
        })) {
          return;
        }
      } else if (isInPopperElement || !hasBeenThisLongSinceLastShown(typeof pointerDownTimeout === 'number' ? pointerDownTimeout : 1000)) {
        return;
      }
      hidePopover();
    };

    document.addEventListener('pointerdown', onPointerDown);
    return () => document.removeEventListener('pointerdown', onPointerDown);
  }, [hasBeenThisLongSinceLastShown, hidePopover, isShown, isClickOutside, pointerDownTimeout, popperElement, reference, shouldHideOnClickOutside]);

  // Hide on blur
  useEffect(() => {
    if (!popperElement || !shouldHideOnBlur) {
      return;
    }
    const onFocusChange = (event: Event) => {
      const target = event.target as HTMLElement | null;
      if (target?.isEqualNode(document.body) || !hasBeenThisLongSinceLastShown(100) || popperElement.contains(target)) {
        return;
      }
      hidePopover();
    };

    document.addEventListener('focusin', onFocusChange);
    return () => document.removeEventListener('focusin', onFocusChange);
  }, [hasBeenThisLongSinceLastShown, hidePopover, popperElement, shouldHideOnBlur]);

  useEffect(() => {
    if (!popperElement || !isShown) {
      return;
    }

    let wasCleanupCalled = false;

    (async () => {
      await delay(10);
      if (wasCleanupCalled) {
        return;
      }
      if ((!checkIfShouldScrollIntoView || checkIfShouldScrollIntoView()) && !hasScrolledIntoView) {
        scrollIntoViewIfNeeded(popperElement);
        setHasScrolledIntoView(true);
      }
      if (shouldAutoFocus && !hasAutoFocused) {
        focusFirstFocusableDescendant(popperElement, selectorToAutoFocus);
        setHasAutoFocused(true);
      }
    })();

    return () => {
      wasCleanupCalled = true;
    };
  }, [
    checkIfShouldScrollIntoView,
    hasAutoFocused,
    hasScrolledIntoView,
    isShown,
    popperElement,
    selectorToAutoFocus,
    setHasAutoFocused,
    shouldAutoFocus,
  ]);

  // When it's hidden, reset some state
  useEffect(() => {
    if (isShown) {
      return;
    }
    setHasScrolledIntoView(false);
    setHasAutoFocused(false);
  }, [isShown]);

  const classes = useMemo(() => {
    const results = ['popover', styles.root, className];

    if (allowOverflow) {
      results.push(styles.rootWithOverflowAllowed);
    }

    if (!isShown && shouldStayInDomWhenHidden) {
      results.push(styles.rootHidden);
    }

    return results;
  }, [allowOverflow, className, isShown, shouldStayInDomWhenHidden]);


  const rootAttributes = useMemo(() => ({
    ...attributes.popper ?? {},
    ...extraProps,
    className: classes.filter(Boolean).join(' '),
    ref: setPopperElement,
    style: hasPopperStyles === false ? {} : popperStyles?.popper,
  }), [attributes.popper, classes, extraProps, hasPopperStyles, popperStyles?.popper]);

  let kids = children;

  if (!isShown) {
    if (shouldStayInDomWhenHidden) {
      kids = null;
    } else {
      // Remove from DOM
      return null;
    }
  }

  const outputRoot = <div {...rootAttributes}>
    {kids}
  </div>;

  if (portalDestinationElementId) {
    return <Portal id={portalDestinationElementId}>{outputRoot}</Portal>;
  }

  return outputRoot;
});

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
