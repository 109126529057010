import map from 'lodash/map';
import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import foregroundEventEmitter from '../../../shared/foreground/eventEmitter';
import { globalState, isStaffProfile } from '../../../shared/foreground/models';
import { hideRightSidebar } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import {
  BaseDocument,
  Category,
  FirstClassDocument,
  Highlight,
  RightSidebarVisiblePanel,
} from '../../../shared/types';
import { isDocumentWithTTS } from '../../../shared/typeValidators';
import { formatDateForFilter } from '../../../shared/utils/dates/formatDateForFilter';
import formatPublishedDate from '../../../shared/utils/dates/formatPublishedDate';
import { getReadingTimeDisplay } from '../../../shared/utils/getTimeToRead';
import pluralize from '../../../shared/utils/pluralize';
import { useIsLeftSidebarHidden, useIsRightSidebarHidden } from '../hooks/hooks';
import fixedEncodeURIComponent from '../utils/fixedEncodeURIComponent';
import updateDocumentTitle from '../utils/updateDocumentTitle';
import Button from './Button';
import DocumentFaviconOrIcon from './DocumentFaviconOrIcon';
import styles from './DocumentTextContent.module.css';
import NotebookIcon from './icons/16StrokeNotebook';
import tocStyles from './TableOfContents.module.css';
import Tag from './Tag';
import TtsTriggerButton from './TtsTriggerButton';

interface DocumentFrontMatterProps {
  author?: string;
  category: BaseDocument['category'];
  docId: BaseDocument['id'];
  failedExtensionHighlightIds?: Highlight['id'][];
  faviconUrl?: BaseDocument['favicon_url'];
  isNotebookView?: boolean;
  languageCode?: BaseDocument['language'];
  nameOrDomain?: string;
  numberOfHighlights?: number;
  publishedOrLastHighlightDate?: FirstClassDocument['published_date'];
  tags?: BaseDocument['tags'];
  title: string;
  wordCount?: number;
  url?: BaseDocument['url'];
}

export function DocumentFrontMatter({
  author,
  category,
  docId,
  failedExtensionHighlightIds,
  faviconUrl,
  isNotebookView = false,
  languageCode,
  nameOrDomain,
  numberOfHighlights,
  publishedOrLastHighlightDate,
  tags,
  title,
  url,
  wordCount,
}: DocumentFrontMatterProps) {
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const rightSidebarHidden = useIsRightSidebarHidden();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const showNameOrDomain = !isNotebookView && nameOrDomain && category !== Category.EPUB && category !== Category.PDF;
  const publishedDateLink = publishedOrLastHighlightDate && !isNotebookView ? `/filter/published:"${formatDateForFilter(publishedOrLastHighlightDate)}"` : '';
  const formattedDate = publishedOrLastHighlightDate
    ? formatPublishedDate(publishedOrLastHighlightDate)
    : '';

  let ttsButton: JSX.Element | undefined;

  if (!isNotebookView && isDocumentWithTTS({ category, url }, false) && isStaffProfile(globalState.getState())) {
    ttsButton = <TtsTriggerButton docId={docId} />;
  }

  useEffect(() => {
    updateDocumentTitle(title);
  }, [title]);

  useEffect(() => {
    if (!title || !titleRef.current) {
      return;
    }

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const docEl = document.getElementById('toc-title');

        if (!docEl) {
          return;
        }

        if (entry.isIntersecting) {
          // We need a timeout so the max-height animation can finish
          setTimeout(() => {
            docEl.innerText = 'Contents';
          }, 200);
          docEl.classList.remove(tocStyles.withDocTitle);
        } else {
          docEl.classList.add(tocStyles.withDocTitle);
          docEl.innerText = title;
        }
      });
    });

    observerRef.current.observe(titleRef.current);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [title, titleRef, leftSidebarHidden, rightSidebarHidden]);

  let highlightsFailedToRenderMessage: JSX.Element | null = null;
  if (failedExtensionHighlightIds?.length) {
    const pronoun = failedExtensionHighlightIds.length === 1 ? 'it' : 'them';
    const amount = failedExtensionHighlightIds.length === 1 ? 'a' : failedExtensionHighlightIds.length;
    const onClick = () => {
      hideRightSidebar(false, { userInteraction: 'click' });
      foregroundEventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);
      foregroundEventEmitter.emit('document-sidebar:scrollToHighlight', { id: failedExtensionHighlightIds[0] });
    };
    highlightsFailedToRenderMessage = <p className={styles.highlightsFailedToRenderMessage}>
      <span>You made {amount} {pluralize('highlight', failedExtensionHighlightIds.length)} on the original page that we aren&apos;t able to overlay here.</span>
      <Button
        className={styles.highlightsFailedToRenderMessageButton}
        onClick={onClick}
        variant="default">
        View {pronoun}
      </Button>
    </p>;
  }

  const timeDisplayOrHighlights = useMemo(() => {
    if (isNotebookView) {
      return `${numberOfHighlights} Highlights`;
    } else {
      return getReadingTimeDisplay(wordCount, false);
    }
  }, [isNotebookView, numberOfHighlights, wordCount]);

  const tagInfo = map(
    tags ?? [],
    ({ name, created, type }) =>
      <Link
        key={`${name}-${created}`}
        to={`/filter/tag:"${encodeURIComponent(name)}"`}
      >
        <Tag hasHoverStyle>{type === 'generated' ? `#${name}` : name}</Tag>
      </Link>,
  );
  // Most of the styles for this element is found in documentTextContent.css
  // So we can share the styles with mobile (anything custom for web is done through css module)
  return (
    <div id="document-header" className={styles.header}>
      {highlightsFailedToRenderMessage}
      <div className="header-content">
        <div className="header-top-row">
          <div className="header-source-container">
            {showNameOrDomain && <> <span className="document-header-domain">
              <DocumentFaviconOrIcon
                className="document-header-domain-favicon" category={category}
                faviconUrl={faviconUrl} />
              <span>{nameOrDomain}</span>
            </span>
            </>}
            {isNotebookView && <>
                <span className="document-header-domain">
                  <NotebookIcon className="document-header-domain-favicon" />
                  <span>HIGHLIGHTS FROM</span>
                </span>
            </>}
          </div>
          {ttsButton}
        </div>
        <h1 lang={languageCode} ref={titleRef}>{title}</h1>
        <hr />
        <div className="document-header-metadata-row">
          <span className="document-header-author-content">
            {author !== undefined && <Link to={`/filter/author:"${fixedEncodeURIComponent(author)}"`}>
              <span className="document-header-author-text">
                {author}
              </span>
            </Link>
            }
            {timeDisplayOrHighlights !== '' ? <>
              <div className="document-header-separator" />
              <span>{timeDisplayOrHighlights}</span>
            </> : null}
          </span>
          {Boolean(formattedDate) &&
            <span className="document-header-published-date">
              {isNotebookView ? `Last highlighted ${formattedDate}`
                : <Link to={publishedDateLink}>
                  {formattedDate}
                </Link>}
            </span>
          }
          <span className="document-header-tags">
            {tagInfo}
          </span>
        </div>
      </div>
    </div>
  );
}
