import debounce from 'lodash/debounce';

import getRangyClassApplier from '../../../shared/foreground/utils/getRangyClassApplier';
import { serializePosition } from '../../../shared/foreground/utils/locationSerializer';
import type { LenientReadingPosition, TtsPosition, WordBoundary } from '../../../shared/types';
import { findWordBoundaryForTrackPosition } from '../utils/findWordBoundaryForTrackPosition';
import { ScrollingManagerError, TextToSpeechContentFrameError } from './errors';
import {
  findTTSableElementFromCenterOfViewport,
  findTtsAbleNode,
  playTtsByGuessingTimestamp,
  populateTtsAbleElements,
} from './textToSpeechUtils';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

function preventDefault(e: TouchEvent) {
  e.preventDefault();
}


type ScrollListenerFunction = () => void;

export class ScrollingManager {
  // This is a base class for all things scrolling
  document = document;
  headerComponent: HTMLElement | undefined;
  headerContainer: HTMLElement | undefined;
  documentTextContent: HTMLElement | undefined;
  documentRoot: HTMLElement | undefined;
  documentRootContainer: HTMLElement | undefined;
  endOfContentElement: HTMLElement | undefined;
  headerContent: HTMLElement | undefined;
  headerImageContainer: HTMLElement | undefined;
  ttsPosIndicator: HTMLElement | undefined;
  ttsPosIndicatorEnd: HTMLElement | undefined;
  ttsAutoScrollingEnabled = false;
  ttsAbleElements: Element[] = [];
  highlightableElements: Element[] = [];
  readingPosition: LenientReadingPosition | null = null;
  wordBoundaries: WordBoundary[] = [];
  lastTTSWord: string | undefined;
  isScrollingDown = false;
  currentScrollValue = 0;
  previousScrollValue = 0;
  scrollTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  scrollingEnabled = true;
  scrollEventsDisabledTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  touchMoveThrottle = 0;
  scrollingEventsDisabled = true;
  updatingCenterElementDisabled = false;
  firstTimeOpenedDocumentOffset = 0;
  documentTextContentHeight = 0;

  scrollListeners: ScrollListenerFunction[] = [];

  initialized = false;
  currentCenteredElementInfo: {element?: HTMLElement | undefined | null; scrollDelta?: number;} = {};
  bodyObserver: ResizeObserver | undefined;

  window: MobileContentFrameWindow = window;
  initializeCallback: () => void = () => {
    throw new ScrollingManagerError('On initialize callback was never created');
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(window: MobileContentFrameWindow) {
    this.window = window;
  }


  getScrollingElement() {
    if (this.document.scrollingElement === null) {
      throw new ScrollingManagerError('ScrollingElement is null!');
    }
    return this.document.scrollingElement;
  }

  getScrollingElementTop() {
    return this.getScrollingElement().scrollTop;
  }

  setScrollingElementTop(newTop: number) {
    this.getScrollingElement().scrollTop = newTop;
  }

  scrollingElementScrollTo({ top, behavior }: {top: number; behavior: 'smooth' | 'auto';}) {
    this.getScrollingElement().scrollTo({ top, behavior });
  }

  async init(firstTimeOpenedDocumentOffset: number) {
    if (this.initialized) {
      throw new ScrollingManagerError('ScrollingManager already initialized; make sure to not call init twice!');
    }
    this.firstTimeOpenedDocumentOffset = firstTimeOpenedDocumentOffset;
    // Register functions for window so React Native can use them
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (newHeight) {
      this.documentTextContentHeight = newHeight;
    }
  }

  createResizeObserver() {
    // this set timeout accounts for a brief moment where fonts load but don't apply correctly,
    // resulting in an unnecessary resize event
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (this.bodyObserver) {
      return;
    }
    const debouncedOnResize = debounce(this.onResize.bind(this), 20);
    this.bodyObserver = new ResizeObserver(debouncedOnResize);
    this.bodyObserver.observe(this.documentTextContent);
  }

  destroyResizeObserver() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (!this.bodyObserver) {
      return;
    }
    this.bodyObserver.unobserve(this.documentTextContent);
    delete this.bodyObserver;
  }

  onResize() {
    // This event is handled inside ResizeObserver which handles errors a bit annoyingly
    // eslint-disable-next-line no-alert
    alert('HandleResize must be implemented in child class');
  }

  addScrollListener(func: ScrollListenerFunction) {
    this.scrollListeners.push(func);
  }

  disableScrollEventsForNMilliseconds(milliseconds = 1000) {
    this.scrollingEventsDisabled = true;
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    if (milliseconds > 0) {
      this.scrollEventsDisabledTimer = setTimeout(() => {
        this.scrollingEventsDisabled = false;
      }, milliseconds);
    } else {
      this.scrollingEventsDisabled = false;
    }
  }


  updateCurrentCenteredElement() {
    throw new ScrollingManagerError('UpdateCurrentCenteredElement must be implemented in child class');
  }

  disableScrollEvents() {
    this.scrollingEventsDisabled = true;
  }

  enableScrollEvents() {
    this.scrollingEventsDisabled = false;
  }

  scrollToElement(element: Element, offset = 0, behavior: 'auto' | 'smooth' = 'smooth') {
    throw new ScrollingManagerError('ScrollToElement must be implemented in child class');
  }

  scrollToRect(rect: DOMRect, offset = 0) {
    throw new ScrollingManagerError('ScrollToRect must be implemented in child class');
  }

  scrollToPercentOfViewport(percent: number, animated = false, disableEvents = false) {
    throw new ScrollingManagerError('ScrollToPercentOfViewport must be implemented in child class');
  }

  scrollToSerializedPosition(serializedPosition: string, offset: number) {
    throw new ScrollingManagerError('ScrollToSerializedPosition must be implemented in child class');
  }

  scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    throw new ScrollingManagerError('ScrollToReadingPosition must be implemented in child class');
  }

  initializeHTMLComponents() {
    if (!this.headerContent) {
      const headerContentResult = this.document.querySelector<HTMLElement>('.header-content');
      if (!headerContentResult) {
        throw new ScrollingManagerError('No .header-content found');
      }
      this.headerContent = headerContentResult;
    }
    const headerImageContainerResult = this.document.getElementById('header-image-container');
    if (!headerImageContainerResult) {
      throw new ScrollingManagerError('No #header-image-container found');
    }
    this.headerImageContainer = headerImageContainerResult;
    if (!this.headerComponent) {
      const headerComponentResult = this.document.getElementById('document-header');
      if (!headerComponentResult) {
        throw new ScrollingManagerError('No #header found');
      }
      this.headerComponent = headerComponentResult;
    }
    if (!this.headerContainer) {
      const headerContainerResult = this.document.querySelector<HTMLElement>('.header-container');
      if (!headerContainerResult) {
        throw new ScrollingManagerError('No .header-container found');
      }
      this.headerContainer = headerContainerResult;
    }
    if (!this.documentTextContent) {
      const documentContentResult = this.document.getElementById('document-text-content');
      if (!documentContentResult) {
        throw new ScrollingManagerError('No #document-text-content found');
      }
      this.documentTextContent = documentContentResult;
    }
    if (!this.documentRoot) {
      const documentRootResult = this.document.querySelector<HTMLElement>('.document-root');
      if (!documentRootResult) {
        throw new ScrollingManagerError('No .document-root found');
      }
      this.documentRoot = documentRootResult;
    }
    if (!this.documentRootContainer) {
      const documentRootContainerResult = this.document.querySelector<HTMLElement>('.document-container');
      if (!documentRootContainerResult) {
        throw new ScrollingManagerError('No .document-container found');
      }
      this.documentRootContainer = documentRootContainerResult;
    }
    if (!this.ttsPosIndicator) {
      this.ttsPosIndicator = this.document.createElement('div');
      this.ttsPosIndicator.classList.add('tts-position-indicator');
      this.document.body.appendChild(this.ttsPosIndicator);
    }
    if (!this.ttsPosIndicatorEnd) {
      this.ttsPosIndicatorEnd = this.document.createElement('div');
      this.ttsPosIndicatorEnd.classList.add('tts-position-indicator');
      this.ttsPosIndicatorEnd.classList.add('tts-position-indicator-end');
      this.document.body.appendChild(this.ttsPosIndicatorEnd);
    }

    const endOfContentElementResult = this.document.querySelector<HTMLElement>('#end-of-content');
    if (!endOfContentElementResult) {
      throw new ScrollingManagerError('computePageRects: No end of content element found');
    }
    this.endOfContentElement = endOfContentElementResult;
  }

  handleScrollFromHref() {
  }

  setReadingPosition(pos: LenientReadingPosition | null) {
    this.readingPosition = pos;
  }

  updateWordBoundaries(wordBoundaries: WordBoundary[]) {
    this.wordBoundaries = wordBoundaries;
  }

  toggleTTSAutoScrolling(enabled: boolean) {
    this.ttsAutoScrollingEnabled = enabled;
  }

  isDocumentScrolledToBeginning(): boolean {
    throw new ScrollingManagerError('IsDocumentScrolledToBeginning must be implemented in child class');
  }

  playTtsFromSelection() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    this.ttsAutoScrollingEnabled = false;
    if (this.isDocumentScrolledToBeginning()) {
      this.ttsAutoScrollingEnabled = true;
      this.window.portalGateToForeground.emit('play-tts-from-timestamp', { timestamp: 0 });
      return;
    }
    // This function looks at the current selection
    // Or makes a selection based on the position of the elements in the doc
    // Then, using that selection, finds either a word boundary that matches the selected word (best case)
    // OR roughly estimates the timestamp for TTS by adding up all the content up to this selection
    if (!this.wordBoundaries) {
      // If we have no word boundaries, guess the timestamp
      playTtsByGuessingTimestamp(this.documentTextContent, this.ttsAbleElements, this.window);
      return;
    }
    const { element } = findTTSableElementFromCenterOfViewport(
      this.documentTextContent,
      this.ttsAbleElements,
      this.window,
    );
    if (!element) {
      throw new TextToSpeechContentFrameError('playTtsFromSelection: no containingEl');
    }
    // Once we have a containing node, find it inside our list of TTSable nodes
    const nodeIndex = this.ttsAbleElements.indexOf(element);
    // Get the offset of the selection
    const closestWordBoundary = this.wordBoundaries.find((wordBoundary) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_trackPos, _textPos, _word, _paraTextPos, paraIndex] = wordBoundary;
      if (paraIndex < nodeIndex) {
        return false;
      }
      return paraIndex === nodeIndex;
    });
    // This offset might match an existing word boundary
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.ttsAutoScrollingEnabled = true;
    // If we failed to match the word, revert to guessing
    if (!closestWordBoundary || closestWordBoundary[4] !== nodeIndex) {
      playTtsByGuessingTimestamp(this.documentTextContent, this.ttsAbleElements, this.window);
      this.window.getSelection()?.removeAllRanges();
      return;
    }
    this.window.getSelection()?.removeAllRanges();
    // But we probably didnt fail, so use the word timestamp!
    this.window.portalGateToForeground.emit('play-tts-from-timestamp', { timestamp: closestWordBoundary[0] });
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    throw new ScrollingManagerError('ScrollViewportToCurrentTTSLocation must be implemented in child class');
  }

  updateTTSIndicator(rect: DOMRect, containerRect: DOMRect, clientRects: DOMRectList, range: Range, perfectWordMatch: boolean): number {
    // Given some new rects from a text selection
    // Update the TTS slugs to wrap the text
    if (!this.ttsPosIndicator || !this.ttsPosIndicatorEnd) {
      throw new ScrollingManagerError('TTS position indicator elements not found');
    }
    const scrollableRootTop = this.getScrollingElementTop();
    const ttsPosTop = Math.floor(scrollableRootTop + rect.top);
    const ttsWidth = perfectWordMatch ? rect.width : containerRect.width;
    const ttsLeft = perfectWordMatch ? rect.left : containerRect.left;
    const ttsHeight = rect.height;

    this.ttsPosIndicator.style.top = `${ttsPosTop}px`;
    this.ttsPosIndicator.style.height = `${ttsHeight}px`;
    this.ttsPosIndicator.style.width = `${ttsWidth}px`;
    this.ttsPosIndicator.style.transform = `translateX(${ttsLeft}px)`;
    this.ttsPosIndicator.classList.remove('tts-position-indicator-large');
    if (!perfectWordMatch && clientRects.length !== 2) {
      this.ttsPosIndicator.classList.add('tts-position-indicator-large');
    }

    if (!perfectWordMatch || clientRects.length !== 2) {
      this.ttsPosIndicatorEnd.style.top = `${ttsPosTop}px`;
      this.ttsPosIndicatorEnd.style.height = `${0}px`;
      this.ttsPosIndicatorEnd.style.width = `${0}px`;
      this.ttsPosIndicatorEnd.style.transform = `translateX(${ttsLeft}px)`;
    } else {
      // If we need to wrap the word around two lines
      const rectEnd = range.getClientRects()[1];
      const ttsPosTopEnd = Math.floor(scrollableRootTop + rectEnd.top);
      const ttsWidthEnd = rectEnd.width;
      const ttsLeftEnd = rectEnd.left;
      const ttsHeightEnd = rectEnd.height;
      this.ttsPosIndicator.style.top = `${ttsPosTopEnd}px`;
      this.ttsPosIndicator.style.height = `${ttsHeightEnd}px`;
      this.ttsPosIndicator.style.width = `${ttsWidthEnd}px`;
      this.ttsPosIndicator.style.transform = `translateX(${ttsLeftEnd}px)`;
      this.ttsPosIndicatorEnd.style.top = `${ttsPosTop}px`;
      this.ttsPosIndicatorEnd.style.height = `${ttsHeight}px`;
      this.ttsPosIndicatorEnd.style.width = `${ttsWidth}px`;
      this.ttsPosIndicatorEnd.style.transform = `translateX(${ttsLeft}px)`;
    }
    return ttsPosTop;
  }

  scrollToTtsPos(ttsPos: TtsPosition) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    const { textPos, word, paraTextPos, paraIndex } = ttsPos;
    if (word === this.lastTTSWord) {
      return;
    }
    this.lastTTSWord = word;
    if (word.length === 1 && word.match(/[!#$%&()*,./:;=?\\^_`{}~-]/g)) {
      // Skip punctuation words
      return;
    }

    if (!this.ttsAbleElements.length) {
      populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    }

    let wordOffset = 0;
    let currentNode;
    if (this.ttsAbleElements[paraIndex]) {
      currentNode = this.ttsAbleElements[paraIndex];
      wordOffset = paraTextPos;
    }
    if (!currentNode) {
      // Fallback to searching through entire document for the node using text position
      [currentNode, wordOffset] = findTtsAbleNode(textPos, this.ttsAbleElements);
    }

    if (!currentNode || !currentNode.textContent) {
      throw new ScrollingManagerError('No node found');
    }

    // We need to find the exact textNode inside the currentNode

    // New traverse idea
    // Loop through children, adding up their text content until we surpass the wordOffset
    // Once we do that, the previous child will be the right node
    // Get the paragraph length up to that point, and recurse until the right node is a text node
    if (wordOffset >= currentNode.textContent.length) {
      wordOffset = currentNode.textContent.length - 1;
    }
    // If the current node is a LI item inside an OL, add some padding for the number
    if (currentNode.nodeName === 'LI' && currentNode.parentNode?.nodeName === 'OL') {
      wordOffset -= 3;
      if (wordOffset < 0) {
        wordOffset = 0;
      }
    }

    const traverse = (currentNode: Node, startingOffset: number): Node | undefined => {
      if (currentNode.nodeType === 3) {
        // The found text node will be offset from the parent html element by startingOffset amount. This needs to be reflected
        // in the wordOffset we found previously, which was relative to the parent HTML element
        wordOffset -= startingOffset;
        return currentNode;
      }
      // Loop through children, adding up their text content until we surpass the wordOffset
      let textOffset = startingOffset;
      if (currentNode.childNodes.length === 1) {
        return traverse(currentNode.childNodes[0], textOffset);
      }
      for (const child of currentNode.childNodes) {
        const childTextContent = child.textContent;
        if (!childTextContent) {
          continue;
        }
        if (textOffset + childTextContent.length > wordOffset) {
          // we have surpassed the wordOffset, the word should be in this child
          return traverse(child, textOffset);
        }
        textOffset += childTextContent.length;
      }
    };

    // We now should have the exact text content node that contains our word
    const lastSeenTextNode = traverse(currentNode, 0);

    if (!lastSeenTextNode || !lastSeenTextNode.textContent) {
      throw new TextToSpeechContentFrameError('Could not find text node');
    }
    // Using the text node, find the exact range containing the word
    // If we cannot find that, fallback to selecting the entire element
    let perfectWordMatch = true;
    const range = new Range();
    range.setStart(lastSeenTextNode, 0);
    range.collapse(true);
    range.setStart(lastSeenTextNode, wordOffset);
    range.setEnd(lastSeenTextNode, Math.min(wordOffset + word.length, lastSeenTextNode.textContent.length));

    const clientRects = range.getClientRects();
    // If the word does not match, fallback to the entire row
    if (range.collapsed || range.toString() !== word || clientRects.length > 2) {
      range.setStart(lastSeenTextNode, Math.max(0, wordOffset - 50));
      range.setEnd(lastSeenTextNode, Math.min(lastSeenTextNode.textContent.length, wordOffset + word.length + 50));
      perfectWordMatch = false;
    }

    const scrollableRoot = this.getScrollingElement();
    const rect = perfectWordMatch ? range.getClientRects()[0] : range.getBoundingClientRect();
    const containerRect = lastSeenTextNode.parentElement ? lastSeenTextNode.parentElement.getBoundingClientRect() : scrollableRoot.getBoundingClientRect();
    if (!rect.width) {
      return;
    }

    this.scrollViewportToCurrentTTSLocation(rect);
    return this.updateTTSIndicator(rect, containerRect, clientRects, range, perfectWordMatch);

  }

  updateWordBoundaryIndicator(position: number) {
    const nearestTTSPosition = findWordBoundaryForTrackPosition(
      position,
      this.wordBoundaries,
    );
    // If the track position is over 2 seconds away from our progress, most likely this is not right
    // Lets be patient and not jump to that spot right away
    if (nearestTTSPosition && Math.abs(nearestTTSPosition.trackPos - position) < 2) {
      this.scrollToTtsPos(nearestTTSPosition);
    } else {
      this.window.portalGateToForeground.emit('fetch-word-boundaries');
    }
  }

  hideWordBoundaryIndicator() {
    if (this.ttsPosIndicator) {
      this.ttsPosIndicator.style.top = `${0}px`;
      this.ttsPosIndicator.style.height = `${0}px`;
      this.ttsPosIndicator.style.width = `${0}px`;
      this.ttsPosIndicator.style.transform = `translateX(${0}px)`;
    }
    if (this.ttsPosIndicatorEnd) {
      this.ttsPosIndicatorEnd.style.top = `${0}px`;
      this.ttsPosIndicatorEnd.style.height = `${0}px`;
      this.ttsPosIndicatorEnd.style.width = `${0}px`;
      this.ttsPosIndicatorEnd.style.transform = `translateX(${0}px)`;
    }
  }

  computeSerializedPositionFromCenteredElement() {
    const { element, scrollDelta } = this.currentCenteredElementInfo;
    if (!this.documentTextContent || !element || scrollDelta === undefined) {
      return;
    }
    const serializedPosition = serializePosition({
      classApplier: getRangyClassApplier(),
      node: element,
      offset: 0,
      rootNode: this.documentTextContent,
    });

    return {
      serializedPosition,
      serializedPositionElementOffset: scrollDelta,
    };
  }

  createHighlightAtTtsPosition(ttsPos: TtsPosition) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    const { paraIndex } = ttsPos;

    if (!this.ttsAbleElements.length) {
      populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    }
    const node = this.ttsAbleElements[paraIndex];

    if (!node.textContent) {
      throw new TextToSpeechContentFrameError('Could not find text node');
    }

    const highlightRange = new Range();
    highlightRange.selectNode(node);

    const selection = this.document.getSelection();
    if (!selection) {
      throw new TextToSpeechContentFrameError('no selection');
    }
    selection.removeAllRanges();
    selection.addRange(highlightRange);

    this.window.portalGateToForeground.emit('create-highlight');
  }

  scrollToTop() {
    this.scrollToPercentOfViewport(0, true);
  }

  returnToReadingPosition() {
    throw new ScrollingManagerError('ReturnToReadingPosition must be implemented in child class');
  }

  onScrollStart() {
    throw new ScrollingManagerError('OnScrollStart must be implemented in child class');
  }

  // This is unthrottled, only add code to this if you need to listen to scroll a lot (like animating elements due to scroll position)
  onScroll() {
    throw new ScrollingManagerError('OnScroll must be implemented in child class');
  }

  onScrollEnd() {
    throw new ScrollingManagerError('OnScrollEnd must be implemented in child class');
  }

  onTouchMove(e: TouchEvent) {
    throw new ScrollingManagerError('OnTouchMove must be implemented in child class');
  }

  toggleScrollingEnabled(enabled: boolean) {
    if (!enabled) {
      if (this.scrollingEnabled) {
        this.document.body.style.overflow = 'hidden';
        this.window.addEventListener('touchmove', preventDefault, false); // mobile
      }
      this.scrollingEnabled = false;
    } else {
      this.window.removeEventListener('touchmove', preventDefault, false);
      this.document.body.style.overflow = 'visible';
      this.scrollingEnabled = true;
    }
  }
}

