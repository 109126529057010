import {
  CancelStateUpdate,
  updateState,
} from '../../models';

export const setSeekYtPlayerTo = (seekTo: number | null) => {
  updateState((state) => {
    // When seeking to < 1 the player just continues where it was
    // so we set it to 1 to make sure it starts playing.
    if (typeof seekTo === 'number' && seekTo < 1) {
      state.youtube.seekTo = 1;
    } else {
      state.youtube.seekTo = seekTo;
    }
  }, { eventName: 'youtube-seek-to-updated', userInteraction: 'unknown', shouldCreateUserEvent: false, isUndoable: false });
};

export const toggleYtVideoPlaying = () => {
  updateState((state) => {
    state.youtube.isPlaying = !state.youtube.isPlaying;
  }, { eventName: 'youtube-is-playing-toggled', userInteraction: 'unknown', isUndoable: false });
};

export const setYtVideoPlaying = (value: boolean) => {
  updateState((state) => {
    if (state.youtube.isPlaying === value) {
      throw new CancelStateUpdate();
    }
    state.youtube.isPlaying = value;
  }, { eventName: 'youtube-is-playing-updated', userInteraction: 'unknown', isUndoable: false });
};
