import React, { useEffect, useRef } from 'react';

import getClosestHTMLElement from '../../../shared/foreground/utils/getClosestHTMLElement';
import getFirstElementInViewport from '../../../shared/foreground/utils/getFirstElementInViewport';
import getRangyClassApplier from '../../../shared/foreground/utils/getRangyClassApplier';
import getVisibilityDetails from '../../../shared/foreground/utils/getVisibilityDetails';
import { deserializePosition, isTextNode, serializePosition } from '../../../shared/foreground/utils/locationSerializer';
import type { LenientReadingPosition, ReadingPosition } from '../../../shared/types';
import makeLogger from '../../../shared/utils/makeLogger';
import getDistanceFromAncestor from '../utils/getDistanceFromAncestor';

const logger = makeLogger(__filename);

const getTextStartRange = async (node: Node, scrollableRoot: HTMLElement): Promise<Range> => {
  if (!node.ownerDocument) {
    throw new Error('Cannot get owner document from node');
  }
  const wholeRange = node.ownerDocument.createRange();
  wholeRange.selectNodeContents(node);
  const endOffset = wholeRange.endOffset;

  const workingRange = wholeRange.cloneRange();
  let result: Range | undefined;
  for (let position = 0; position <= endOffset; position++) {
    workingRange.setStart(node, position);
    if (position + 1 > endOffset) {
      workingRange.setEndAfter(node);
    } else {
      workingRange.setEnd(node, position + 1);
    }

    if ((await getVisibilityDetails({ subject: workingRange, scrollableRoot })).isTopInView) {
      result = workingRange;
      break;
    }
  }

  if (!result) {
    result = wholeRange;
  }

  result.collapse(true);
  return result;
};

const getLocationInfo = async (scrollableElement: HTMLElement, contentRoot: HTMLElement): Promise<{
  actualScrollDepth: number;
  distanceFromMaxScrollPosition: number;
  effectiveScrollDepth: number;
  range: Range;
}> => {
  const firstElement = await getFirstElementInViewport({ element: contentRoot, scrollableRoot: scrollableElement });
  if (!firstElement) {
    throw new Error('No child in viewport');
  }

  const node = firstElement.childNodes && firstElement.childNodes.length
    ? firstElement.childNodes[0]
    : firstElement;

  let range: Range;

  // If it's a text node and its top is off-screen, find the first line in viewport
  if (isTextNode(node) && !(await getVisibilityDetails({
    subject: firstElement,
    scrollableRoot: scrollableElement,
  })).isTopInView) {
    range = await getTextStartRange(node, scrollableElement);
  } else {
    range = new Range();
    range.setStartBefore(node);
    range.setEndBefore(node);
  }

  const scrollTop = scrollableElement.scrollTop;
  const maxScrollPosition = scrollableElement.scrollHeight - scrollableElement.clientHeight;
  const distanceFromMaxScrollPosition = Math.max(maxScrollPosition - scrollTop, 0);
  // Fallback to 0 if we're out of date / something weird is going on
  const distanceFromScrollableRootToContentRoot = scrollableElement.contains(contentRoot)
    ? getDistanceFromAncestor(contentRoot, scrollableElement)
    : { top: 0, left: 0 };
  const topToIgnore = Math.max(distanceFromScrollableRootToContentRoot.top, 0);
  const actualScrollDepth = Math.max(scrollTop - topToIgnore, 0) / Math.max(scrollableElement.scrollHeight - topToIgnore, 0);
  const effectiveScrollDepth = distanceFromMaxScrollPosition < 5 ? 1 : actualScrollDepth;
  return { actualScrollDepth, distanceFromMaxScrollPosition, effectiveScrollDepth, range };
};

export const getScrollLocation = async (
  {
    contentRootRef,
    scrollableRootRef,
  }: {
    contentRootRef: HTMLElement;
    scrollableRootRef: HTMLElement;
  },
) => {
  if (!scrollableRootRef || !contentRootRef) {
    return;
  }

  const locationInfo = await getLocationInfo(scrollableRootRef, contentRootRef);

  const contentHeight = contentRootRef.clientHeight;
  const currentScrollOffsetY = scrollableRootRef.scrollTop;
  const windowHeight = scrollableRootRef.clientHeight;

  if (!currentScrollOffsetY || !windowHeight) {
    return { scrollDepth: 0, serializedPosition: null };
  }

  let readingPercent = currentScrollOffsetY / contentHeight;
  if (contentHeight - currentScrollOffsetY < windowHeight) {
    readingPercent = 1;
  }

  if (!scrollableRootRef || !contentRootRef || !contentRootRef.contains(locationInfo.range.startContainer)) {
    return;
  }

  return {
    scrollDepth: readingPercent,
    serializedPosition: serializePosition({
      classApplier: getRangyClassApplier(),
      node: locationInfo.range.startContainer,
      offset: locationInfo.range.startOffset,
      rootNode: contentRootRef,
    }),
  };
};

export const scrollToPosition = (scrollableRootRef: React.MutableRefObject<HTMLElement>, contentRootRef: React.MutableRefObject<HTMLElement>, initialPosition: LenientReadingPosition) => {
  if (!scrollableRootRef.current || !contentRootRef.current || !initialPosition) {
    return;
  }

  let scrollTop: number | undefined;

  if (initialPosition.serializedPosition) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const position = deserializePosition({
        classApplier: getRangyClassApplier(),
        rootNode: contentRootRef.current,
        serialized: initialPosition.serializedPosition,
      });

      const range = document.createRange();
      range.setStart(position.node, position.offset);
      range.setEnd(position.node, position.offset);

      const closestElement = getClosestHTMLElement(position.node);
      if (!closestElement) {
        throw new Error("Can't get closest element from node");
      }
      scrollTop = Math.floor(
        getDistanceFromAncestor(closestElement, contentRootRef.current).top +
        Math.max(range.getBoundingClientRect().top - closestElement.getBoundingClientRect().top, 0),
      );
    } catch (error) {
      logger.error('scrolling error', { error });
      logger.debug('Scrolling to saved serialized scroll location has failed (error above), falling back to scroll depth');
    }
  }

  if (typeof scrollTop !== 'number') {
    scrollTop = Math.floor(scrollableRootRef.current.scrollHeight * (initialPosition.scrollDepth ?? 0));
  }
  scrollableRootRef.current.scroll(0, scrollTop);
};

export default (
  {
    contentRootRef,
    initialLocation,
    scrollableRootRef,
    onFirstScroll,
    onScroll,
  }: {
    contentRootRef: React.MutableRefObject<HTMLElement>;
    initialLocation?: LenientReadingPosition;
    scrollableRootRef: React.MutableRefObject<HTMLElement>;
    onFirstScroll: (scrollTop: number) => unknown;
    onScroll: (locationInfo: ReadingPosition, currentOffsetY: number) => unknown;
  },
): void => {
  // There's no way to detect when a scroll was triggered programmatically
  const numberOfScrollsToIgnore = useRef(0);

  // Trigger scroll on load
  useEffect(
    () => {
      if (!scrollableRootRef.current || !contentRootRef.current || !initialLocation) {
        return;
      }

      scrollToPosition(scrollableRootRef, contentRootRef, initialLocation);

      numberOfScrollsToIgnore.current = 1;
      onFirstScroll(scrollableRootRef.current.scrollTop);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contentRootRef,
      contentRootRef.current, // This forces the hook to re-run if the content is unnecessarily re-rendered on load
      initialLocation,
      scrollableRootRef,
    ],
  );

  // Listen for scroll
  useEffect(() => {
    if (!contentRootRef.current || !scrollableRootRef.current) {
      return;
    }
    const scrollableElement = scrollableRootRef.current;

    const onScrollEvent = () => {
      const contentHeight = contentRootRef.current.clientHeight;
      const currentScrollOffsetY = scrollableRootRef.current.scrollTop;
      const windowHeight = scrollableRootRef.current.clientHeight;
      let readingPercent = currentScrollOffsetY / contentHeight;
      if (contentHeight - currentScrollOffsetY < windowHeight) {
        readingPercent = 1;
      }
      onScroll({
        scrollDepth: readingPercent,
        serializedPosition: null,
      }, currentScrollOffsetY);
    };

    scrollableElement.addEventListener('scroll', onScrollEvent, { passive: true });
    return () => {
      // scrollableElement.removeEventListener('scroll', onDebouncedScroll);
      scrollableElement.removeEventListener('scroll', onScrollEvent);
    };
  }, [contentRootRef, onScroll, scrollableRootRef]);
};
