import { isHTMLElement } from '../../typeValidators';

const getFirstDescendantWith = (element: Element, matcher: (element: Element) => boolean, direction: 'forward' | 'reverse' = 'forward'): Element | undefined => {
  if (!element?.children.length) {
    return;
  }
  if (!isHTMLElement(element)) {
    throw new Error('element must be an element (not a node, etc.)');
  }

  let children = Array.from(element.children) as unknown as Element[];
  if (direction === 'reverse') {
    children = children.reverse();
  }

  for (const child of children) {
    const descendant = getFirstDescendantWith(child, matcher, direction) ?? (matcher(child) ? child : null);
    if (descendant) {
      return descendant;
    }
  }
};

export default getFirstDescendantWith;
