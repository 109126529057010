import type { AnyDocument, PartialDocument } from '../types';
import { isDocumentWithAuthor } from '../typeValidators';
import getDocumentOverrideOrReal from './getDocumentOverrideOrReal';

export default (doc?: AnyDocument | PartialDocument<AnyDocument, 'author' | 'category' | 'overrides'> | null | void): string | undefined => {
  if (!doc) {
    return;
  }
  if (isDocumentWithAuthor(doc)) {
    return getDocumentOverrideOrReal(doc, 'author');
  }
  return 'You';
};
