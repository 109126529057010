import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import makeLogger from '../../utils/makeLogger';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import database from '../database';
import { updateGeneratedDocumentSummary } from '../stateUpdaters/persistentStateUpdaters/documents/summary';
import {
  resetDocumentSummaryGeneration,
  startDocumentSummaryGeneration,
  stopDocumentSummaryGeneration,
} from '../stateUpdaters/transientStateUpdaters/documentSummary';

const logger = makeLogger(__filename);

export async function generateSummary(
  documentId: string,
  userInteraction = 'unknown',
) {
  try {
    await startDocumentSummaryGeneration(userInteraction);

    const result = await requestWithAuth(`${getServerBaseUrl()}/reader/api/summarize`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({ document_id: documentId }),
    });

    if (!result.ok) {
      throw new Error('server failed generating summary');
    }

    const json = await result.json();
    const summary = json.summary;

    const { userEvent } = await updateGeneratedDocumentSummary(
      documentId,
      summary,
    );
    await stopDocumentSummaryGeneration(userInteraction);

    return { summary, userEvent };
  } catch (error) {
    await resetDocumentSummaryGeneration(userInteraction);
    throw error;
  }
}

type HighlightResponse = {
  highlights: [string];
};

export async function generateArtificialHighlights(
  documentId: string,
  controller?: AbortController,
): Promise<HighlightResponse> {
  try {
    let signal;
    if (controller) {
      signal = controller.signal;
    }

    const result = await requestWithAuth(`${getServerBaseUrl()}/reader/api/create_artificial_highlights_for_document`, {
      signal,
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({ document_id: documentId }),
    });

    if (!result.ok) {
      throw new Error('server failed generating highlights');
    }

    return await result.json();
  } catch (error) {
    // Request was aborted, we throw the AbortError
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw error;
    }

    logger.error('failed generating highlights', { error });
    throw error;
  }
}

type TagResponse = {
  tags: [string];
};

export async function generateArtificialTags(
  documentId: string,
  controller?: AbortController,
): Promise<TagResponse> {
  try {
    let signal;
    if (controller) {
      signal = controller.signal;
    }

    const tagNames = (await database.collections.global_tags.findAll()).map((tag) => tag.name);
    const result = await requestWithAuth(`${getServerBaseUrl()}/reader/api/create_auto_tags_for_document`, {
      signal,
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({ document_id: documentId, tags: tagNames }),
    });

    if (!result.ok) {
      throw new Error('server failed generating tags');
    }

    return await result.json();
  } catch (error) {
    // Request was aborted, we throw the AbortError
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw error;
    }

    logger.error('failed generating highlights', { error });
    throw error;
  }
}
