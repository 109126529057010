import { formatNumbersWithCommas } from './numbers';
import pluralize from './pluralize';

export const getTimeToRead = (wordCount: number): number => Math.max(Math.ceil(wordCount / 265), 1);

export const getReadingTimeDisplay = (wordCount: number | undefined, includeWordCount = true): string => {
  if (!wordCount) {
    return '';
  }
  const timeToRead = getTimeToRead(wordCount);
  const partialHours = Math.floor(timeToRead / 60);
  const partialMinutes = timeToRead % 60;
  let readingTimeDisplay;
  if (partialHours) {
    readingTimeDisplay = `${partialHours} ${pluralize('hr', partialHours)} ${partialMinutes} ${pluralize('min', timeToRead)}`;
  } else {
    readingTimeDisplay = `${timeToRead} ${pluralize('min', timeToRead)}`;
  }
  if (includeWordCount) {
    return `${readingTimeDisplay} (${formatNumbersWithCommas(wordCount)} ${pluralize('word', wordCount)})`;
  } else {
    return readingTimeDisplay;
  }
};
