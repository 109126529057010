import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import copyTextOnWeb from '../../../../shared/foreground/copyTextOnWeb';
import { saveLinkToReader } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { setNewLinkLoading } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/links';
import getClosestHTMLElement from '../../../../shared/foreground/utils/getClosestHTMLElement';
import getUIFriendlyNameForDocumentLocation from '../../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import { DocumentId, DocumentLocation, ShortcutId } from '../../../../shared/types';
import { getDisplayShortcutKeys } from '../../../../shared/utils/shortcuts';
import urlJoin from '../../../../shared/utils/urlJoin';
import { useHotKeys } from '../../hooks/hooks';
import { openURL } from '../../utils/openURL';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import StrokeAddCircleIcon from '../icons/StrokeAddCircleIcon';
import StrokeArrowUpRightIcon from '../icons/StrokeArrowUpRightIcon';
import StrokeLinkIcon from '../icons/StrokeLinkIcon';
import Popover from './Popover';
import styles from './SaveLinkInAppPopover.module.css';

export default function SaveLinkInAppPopover({ sanitizedHtml, contentRef, focusTargetRef, docId }: {
  docId: DocumentId;
  sanitizedHtml: string;
  contentRef: React.MutableRefObject<HTMLDivElement>;
  focusTargetRef: React.MutableRefObject<HTMLElement | undefined>;
}) {
  const history = useHistory();
  const [clickedLink, setClickedLink] = useState<HTMLAnchorElement | undefined>(undefined);
  const hideActionsPopoverShown = useCallback(() => setClickedLink(undefined), []);
  const shortcutsMap = useShortcutsMap();

  // We store the index of the rect because some links will break line and we
  // want to always show the popover under the line the user clicked.
  //
  // E.g:
  // Fofofofofofofo here it is
  // a long link fofofofofofofo
  //
  // We use link.getClientRects() to detect if the user clicked "here it is" or
  // "a long link".
  const [rectIndex, setRectIndex] = useState(0);

  // Adding this flag to prevent the `enter` shortcut firing after
  // opening the doc with `enter`.
  const [shouldRunShortcut, setShouldRunShortcut] = useState(false);

  useEffect(() => {
    setTimeout(() => setShouldRunShortcut(true), 1000);
  }, []);

  const isAnchorLinkInsideContent = useCallback((clickedLinkElement: HTMLAnchorElement) => {
    const clickedHref = clickedLinkElement.href;

    if (clickedHref.startsWith('#')) {
      return true;
    }

    const currentHref = document.location.href;

    if (clickedHref.includes(currentHref)) {
      return true;
    }

    return getClosestHTMLElement(clickedLinkElement, (ancestor: HTMLElement) => ancestor.classList?.contains('rw-outer-content'));
  }, []);

  useEffect(() => {
    if (!contentRef.current || !sanitizedHtml) {
      return;
    }

    const onContentClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Close popover when click outside content
      if (!contentRef.current.contains(target)) {
        setClickedLink(undefined);
        return;
      }

      // Override popover when doing cmd/ctrl + click
      if (event.metaKey || event.ctrlKey) {
        return;
      }

      const closestLink = target.closest('a');

      if (closestLink?.href && contentRef.current.contains(closestLink) && !isAnchorLinkInsideContent(closestLink)) {
        event.preventDefault();

        // If we clicked the same link, close the popover
        if (clickedLink?.isEqualNode(closestLink)) {
          setClickedLink(undefined);
        } else {
          // Otherwise, open the popover.
          // We set it to undefined before to force a re-render.
          setClickedLink(undefined);
          setClickedLink(closestLink);

          const rectIndex = Array.from(closestLink.getClientRects()).findIndex((rect) => {
            return event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
          });

          setRectIndex(rectIndex > -1 ? rectIndex : 0);
        }
      } else if (contentRef.current.contains(target)) {
        setClickedLink(undefined);
      }
    };

    document.addEventListener('click', onContentClick);

    return () => document.removeEventListener('click', onContentClick);
  }, [contentRef, sanitizedHtml, isAnchorLinkInsideContent, clickedLink]);

  const goToNewDoc = useCallback((docId: string) => history.push(urlJoin(['/', DocumentLocation.New, 'read', docId])), [history]);

  const documentLocations = useDocumentLocations();

  const onSaveLinkToReader = useCallback(async (url: string, tooltipContent?: string) => {
    setClickedLink(undefined);
    const newDocId = await saveLinkToReader({
      newDocumentLocation: documentLocations[0],
      onButtonClick: goToNewDoc,
      tooltipContent,
      url,
    });
    setNewLinkLoading({
      docId,
      newDocId,
      url,
      userInteraction: 'click',
    });
  }, [documentLocations, goToNewDoc, docId]);

  const getFirstClickableLink = useCallback((htmlEl: HTMLElement) => {
    const links = htmlEl.querySelectorAll('a');
    return Array.from(links).find((link) => {
      return link.href && !isAnchorLinkInsideContent(link);
    });
  }, [isAnchorLinkInsideContent]);

  useHotKeys(
    shortcutsMap[ShortcutId.Enter],
    useCallback(() => {
      if (!focusTargetRef.current) {
        return;
      }

      if (clickedLink) {
        openURL(clickedLink.href, '_blank');
        return;
      }

      const firstLink = getFirstClickableLink(focusTargetRef.current);

      if (firstLink) {
        openURL(firstLink.href, '_blank');
      }
    }, [focusTargetRef, clickedLink, getFirstClickableLink]),
    {
      description: 'Open first link',
      hotKeyOptions: { keydown: true },
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.SaveLinkToReader],
    useCallback(() => {
      if (!focusTargetRef.current || !shouldRunShortcut) {
        return;
      }

      if (clickedLink) {
        onSaveLinkToReader(clickedLink.href);
        return;
      }

      const firstLink = getFirstClickableLink(focusTargetRef.current);

      if (firstLink) {
        onSaveLinkToReader(
          firstLink.href,
          `First link saved to ${getUIFriendlyNameForDocumentLocation(documentLocations[0])}`,
        );
      }
    }, [clickedLink, documentLocations, focusTargetRef, getFirstClickableLink, onSaveLinkToReader, shouldRunShortcut]),
    {
      description: 'Save first link to reader',
      hotKeyOptions: { keyup: true },
    },
  );


  const getBoundingClientRect = useMemo(() => {
    if (clickedLink) {
      return () => clickedLink.getClientRects()[rectIndex];
    }

    return undefined;
  }, [clickedLink, rectIndex]);

  if (!clickedLink) {
    return null;
  }

  return <Popover
    className={styles.actionsPopover}
    getBoundingClientRect={getBoundingClientRect}
    hidePopover={hideActionsPopoverShown}
    isShown
    pointerDownTimeout={250}
    popperOptions={{
      placement: 'bottom-start',
    }}
    preventOverflow
    reference={clickedLink}
    shouldHideOnBlur={false}
    shouldHideOnClickOutside={false}>
        <ul className={styles.actionsPopoverList}>
          <li className={styles.actionsPopoverListItem}>
            <a
              className={styles.actionsPopoverAction}
              href={clickedLink.href}
              target="_blank"
              rel="noopener noreferrer"
              onClick={hideActionsPopoverShown}
              data-open-on-desktop
            >
                <StrokeArrowUpRightIcon />
                Open link in new tab
                <span className={styles.actionsPopoverShortcut}>{getDisplayShortcutKeys(shortcutsMap[ShortcutId.Enter])}</span>
            </a>
          </li>

          <li className={styles.actionsPopoverListItem}>
            <Button
              className={styles.actionsPopoverAction}
              onClick={() => onSaveLinkToReader(clickedLink.href)}
              variant="unstyled">
                <StrokeAddCircleIcon />
                Save link to Reader
                <span className={styles.actionsPopoverShortcut}>{getDisplayShortcutKeys(shortcutsMap[ShortcutId.SaveLinkToReader])}</span>
            </Button>
          </li>

          <li className={styles.actionsPopoverListItem}>
            <Button
              className={styles.actionsPopoverAction}
              onClick={() => {
                copyTextOnWeb(clickedLink.href);
                hideActionsPopoverShown();
              }}
              variant="unstyled">
                <StrokeLinkIcon />
                Copy link to clipboard
            </Button>
          </li>
        </ul>
    </Popover>;
}
