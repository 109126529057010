import forOwn from 'lodash/forOwn';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { Highlight } from '../types';
import cloneDeep from '../utils/cloneDeep';
import makeLogger from '../utils/makeLogger';
import { createPDFHighlight } from './pdfActions';
import { deleteHighlight, updateHighlight } from './stateUpdaters/persistentStateUpdaters/documents/highlight';

const logger = makeLogger(__filename);

interface UsePDFAnnotationSync {
  importAnnotations: (data: string) => Promise<void>;
  deleteAnnotations: (annotations: {id: string; pageNumber: number;}[]) => Promise<void>;
  highlights: Highlight[];
  docId?: string;
  initialHighlightForNavId: string | null;
  onInitialHighlightForNavLoaded: (id: string) => void;
}

type AnnotationCreatedProps = {
  highlightText: string;
  id: string;
  parentId: string;
  pdfData: string;
  pdfId: string;
  pageNumber: number;
  offset: number;
  readerFileId?: string;
  imgUrl?: string;
};
export const usePDFAnnotationSync = ({ importAnnotations, deleteAnnotations, highlights, initialHighlightForNavId, onInitialHighlightForNavLoaded }: UsePDFAnnotationSync) => {
  const highlightMap = useMemo(() => keyBy(highlights, 'id'), [highlights]);
  const pdfAnnotationsMap = useRef<{[key: string]: Set<string>;}>({});
  const [hasLoaded, setHasLoaded] = useState(false);

  const addReadwiseHighlightsToPDF = useCallback((highlights: Highlight[]) => {
    try {
      highlights.forEach((highlight) => {
        if (!highlight.source_specific_data?.pdf_highlight) {
          return;
        }
        const pdfHighlight = highlight.source_specific_data.pdf_highlight;
        const pdfHighlightId = pdfHighlight.id;
        const pdfHighlightPageNum = pdfHighlight.page;
        if (!pdfHighlightId || !pdfHighlightPageNum) {
          return;
        }
        importAnnotations(pdfHighlight.data)
          .then(() => {
            if (pdfAnnotationsMap.current[pdfHighlightPageNum]) {
              pdfAnnotationsMap.current[pdfHighlightPageNum].add(pdfHighlightId);
            } else {
              pdfAnnotationsMap.current[pdfHighlightPageNum] = new Set([pdfHighlightId]);
            }
            if (highlight.id === initialHighlightForNavId) {
              onInitialHighlightForNavLoaded(highlight.id);
            }
          })
          .catch(() => {
            if (pdfAnnotationsMap.current[pdfHighlightPageNum]) {
              pdfAnnotationsMap.current[pdfHighlightPageNum].delete(pdfHighlightId);
            }
          });
      });
    } catch (e) {
      logger.error('error addReadwiseHighlightsToPDF', { e });
    }
  }, [importAnnotations, initialHighlightForNavId, onInitialHighlightForNavLoaded]);

  useEffect(() => {
    // I want to sync the current list of highlights with the PDF
    if (!hasLoaded) {
      return;
    }
    const highlightsToAdd: Highlight[] = [];
    const pdfAnnotationsMapClone = cloneDeep(pdfAnnotationsMap.current);
    for (const rwHighlight of highlights) {
      const pdfHighlight = rwHighlight.source_specific_data?.pdf_highlight;
      if (!pdfHighlight) {
        continue;
      }
      if (!pdfHighlight.page || !pdfHighlight.id) {
        continue;
      }

      if (pdfAnnotationsMapClone[pdfHighlight.page] && pdfAnnotationsMapClone[pdfHighlight.page].has(pdfHighlight.id)) {
        pdfAnnotationsMapClone[pdfHighlight.page].delete(pdfHighlight.id);
        if (pdfAnnotationsMapClone[pdfHighlight.page].size === 0) {
          delete pdfAnnotationsMapClone[pdfHighlight.page];
        }
      }

      // Check if this highlight is missing
      if (pdfAnnotationsMap.current[pdfHighlight.page] === undefined || !pdfAnnotationsMap.current[pdfHighlight.page].has(pdfHighlight.id)) {
        highlightsToAdd.push(rwHighlight);
      }

    }
    const highlightsToRemove: {id: string; pageNumber: number;}[] = [];
    forOwn(pdfAnnotationsMapClone, (highlightIds, page) => {
      highlightIds.forEach((id) => {
        highlightsToRemove.push({ id, pageNumber: parseInt(page, 10) });
      });
    });

    deleteAnnotations(highlightsToRemove);
    addReadwiseHighlightsToPDF(highlightsToAdd);


  }, [addReadwiseHighlightsToPDF, deleteAnnotations, hasLoaded, highlights]);

  const onAnnotationCreated = useCallback(({ highlightText, id, parentId, pdfData, pdfId, pageNumber, offset, readerFileId, imgUrl }: AnnotationCreatedProps) => {
    if (pdfAnnotationsMap.current[pageNumber]) {
      pdfAnnotationsMap.current[pageNumber].add(pdfId);
    } else {
      pdfAnnotationsMap.current[pageNumber] = new Set([pdfId]);
    }
    return createPDFHighlight({ highlightText, highlightId: id, parentId, pdfData, pdfId, pdfPageNum: pageNumber, offset, readerFileId, imgUrl })
      .catch((e) => {
        pdfAnnotationsMap.current[pageNumber].delete(pdfId);
        throw new Error(e);
      });
  }, []);

  const drawHighlightsOnLoad = useCallback(() => {
    setHasLoaded(true);
    addReadwiseHighlightsToPDF(highlights);
  }, [addReadwiseHighlightsToPDF, highlights]);

  const onAnnotationRemoved = useCallback(({ page, pdfId, readwiseId }: {page: number; pdfId: string; readwiseId: string;}) => {
    if (pdfAnnotationsMap.current[page]) {
      pdfAnnotationsMap.current[page].delete(pdfId);
    }
    if (readwiseId in highlightMap) {
      deleteHighlight(readwiseId, { userInteraction: 'unknown' });
    }
  }, [highlightMap]);

  const onAnnotationUpdated = useCallback(({ readwiseId, updates }: {readwiseId: string; updates: Partial<Highlight>;}) => {
    updateHighlight(readwiseId, updates);
  }, []);

  return { drawHighlightsOnLoad, onAnnotationCreated, onAnnotationRemoved, onAnnotationUpdated };
};
