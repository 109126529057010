import replaceStringStrict from '../../utils/replaceStringStrict';

export default function removeSingleWrapperElementFromHtml (html: string): string {
  let draft = html.trim();

  const startRegex = /^<([\w-]+)[^>]*>/s;
  const tagName = draft.match(startRegex)?.[1];
  if (tagName) {
    const endRegex = new RegExp(`</${tagName}[^>]*>$`, 's');
    if (endRegex.test(draft)) {
      draft = replaceStringStrict(draft, startRegex, '');
      draft = replaceStringStrict(draft, endRegex, '');
      // Do nothing if there are multiple of the same element
      if (!new RegExp(`</?${tagName}[^>]*>`, 's').test(draft)) {
        return draft.trim();
      }
    }
  }

  return html;
}
