import './css/main.css';

import * as Sentry from '@sentry/react';
import React, { Suspense, useCallback, useEffect, useMemo, useRef } from 'react';
import { BrowserRouter as Router, matchPath, Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';

import {
    openBulkActionsSubMenu,
    openFeedsSubMenu,
    openFiltersSubMenu,
    openSaveDocSubMenu,
    openShortcutsSubmenu,
    openViewFiltersSubMenu,
} from '../../shared/foreground/cmdPalette';
import { globalState } from '../../shared/foreground/models';
import { sortViews, usePartialDocument } from '../../shared/foreground/stateHooks';
import { toggleDarkModeTheme } from '../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import { markDocumentAsOpened } from '../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { setCurrentlyReading } from '../../shared/foreground/stateUpdaters/persistentStateUpdaters/other';
import { addRouteToNavigationStack, setPathNameToRedirectAfterOnboarding, setWebEffectiveTheme } from '../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { hideReaderViewSidebars, toggleHideLeftSidebar, toggleHideRightSidebar, toggleHideSidebars } from '../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import { redoLastDocumentAction, undoLastDocumentAction } from '../../shared/foreground/stateUpdaters/transientStateUpdaters/undo';
import { onPageChange } from '../../shared/foreground/userEvents';
import getUIFriendlyNameForDocumentLocation from '../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../shared/foreground/utils/useDocumentLocations';
import useGlobalStateWithFallback from '../../shared/foreground/utils/useGlobalStateWithFallback';
import useScreenWidthMonitor from '../../shared/foreground/utils/useScreenWidthMonitor';
import {
  DefaultPage,
  DisplayTheme,
  DocumentLocation,
  FeedDocumentLocation,
  OnboardingStep,
  PersistentStateLoadingState,
  ReadingStatus,
  ShortcutId,
  SubscriptionProduct,
} from '../../shared/types';
import { isFirstClassDocument } from '../../shared/typeValidators';
import { isDevOrTest } from '../../shared/utils/environment';
import urlJoin from '../../shared/utils/urlJoin';
import useDebounce from '../../shared/utils/useDebounce';
import styles from './App.module.css';
import DatabaseQueryOptimizer from './components/DatabaseQueryOptimizer';
import ErrorFallback from './components/ErrorFallback';
import FAQRedirect from './components/FAQRedirect';
import FileDropzone from './components/FileDropzone';
import Ghost from './components/Ghost';
import { InboxPage } from './components/InboxPage';
import { NOTEBOOK_PATH_PATTERN } from './components/NotebookView/constants';
import Spinner from './components/Spinner';
import TtsPlayer from './components/TtsPlayer';
import { useHotKeys, useHotKeysPreventDefault, useIsRightSidebarHidden, useScrollToTop } from './hooks/hooks';
import { useShouldForceMobileApp } from './hooks/useShouldForceMobileApp';
import { getCookie, setCookie } from './utils/cookies';
import { reactLazy, reactLazyPickExport } from './utils/dynamicImport';
import { getFilteredViewPath } from './utils/getFilteredViewPath';
import { readerViewUrl, shouldShowBulkActionsMenu } from './utils/pathnameHelpers';
import { useShortcutsMap } from './utils/shortcuts';
import updateDocumentTitle from './utils/updateDocumentTitle';
import useLocation from './utils/useLocation';

const DatabaseExplorer = reactLazy(() => import('./components/DatabaseExplorer'));
const NotebookPage = reactLazyPickExport(
  () => import('./components/NotebookView/NotebookPage'),
  'NotebookPage',
);
const QuoteshotModal = reactLazy(() => import('./components/QuoteshotModal'));
const CommandPalette = reactLazy(() => import('./components/CommandPalette/CommandPalette'));
const RightSidebar = reactLazyPickExport(
  () => import('./components/RightSidebar/RightSidebar'),
  'RightSidebar',
);
const FeedsSourceList = reactLazyPickExport(
  () => import('./components/FeedsSourceList'),
  'FeedsSourceList',
);
const FeedsSuggestionList = reactLazyPickExport(
  () => import('./components/FeedsSuggestionList'),
  'FeedsSuggestionList',
);
const FilterPage = reactLazyPickExport(
  () => import('./components/FilterPage'),
  'FilterPage',
);
const HomePage = reactLazy(() => import('./components/HomePage'));
const ImportPage = reactLazyPickExport(
  () => import('./components/ImportPage'),
  'ImportPage',
);
const ProfilePage = reactLazyPickExport(
  () => import('./components/ProfilePage'),
  'ProfilePage',
);
const PreferencesPage = reactLazyPickExport(
  () => import('./components/PreferencesPage'),
  'PreferencesPage',
);
const EmailPreferencesPage = reactLazyPickExport(
  () => import('./components/EmailPreferencesPage'),
  'EmailPreferencesPage',
);
const ShortcutsPage = reactLazyPickExport(
  () => import('./components/ShortcutsPage'),
  'ShortcutsPage',
);
const IntegrationsPage = reactLazyPickExport(
  () => import('./components/IntegrationsPage'),
  'IntegrationsPage',
);
const ModalsContainer = reactLazy(() => import('./components/Modals/ModalsContainer'));
const OnboardingPage = reactLazy(() => import('./components/OnboardingPage'));
const SearchPage = reactLazyPickExport(
  () => import('./components/SearchPage/SearchPage'),
  'SearchPage',
);
const TagsList = reactLazyPickExport(
  () => import('./components/TagsList'),
  'TagsList',
);
const ToastContainer = reactLazy(() => import('./components/ToastContainer'));
const ViewsList = reactLazyPickExport(
  () => import('./components/ViewsList'),
  'ViewsList',
);


const TrackActiveItemPage = ({ name }: { name: string; }) => {
  const { uncategorizedDocumentIdToOpen, openDocumentId, documentLocation } = useParams<{
    openDocumentId?: string;
    documentLocation?: DocumentLocation;
    uncategorizedDocumentIdToOpen?: string;
  }>();

  const docId = useMemo(() => uncategorizedDocumentIdToOpen ?? openDocumentId, [openDocumentId, uncategorizedDocumentIdToOpen]);
  const [existingDoc] = usePartialDocument(docId, ['id', 'category', 'reading_status']);

  useEffect(() => {
    const nameToUse = docId ? 'document' : name;

    updateDocumentTitle(
      nameToUse.toLowerCase() === 'inbox' && documentLocation
        ? getUIFriendlyNameForDocumentLocation(documentLocation)
        : nameToUse,
    );
    onPageChange({
      name: nameToUse,
      itemId: docId ?? null,
    });

    if (docId) {
      markDocumentAsOpened(docId);
      if (existingDoc && isFirstClassDocument(existingDoc) && existingDoc.reading_status !== ReadingStatus.Archived) {
        setCurrentlyReading(existingDoc.id, { userInteraction: 'unknown' });
      }
    }
  }, [name, documentLocation, existingDoc, docId, openDocumentId]);
  return null;
};

function DevOnly ({ children }: { children: React.ReactElement | React.ReactElement[]; }) {
  if (!isDevOrTest) {
    return null;
  }
  return <>{children}</>;
}

const Main = () => {
  const documentLocations = useDocumentLocations();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const isReaderViewUrl = readerViewUrl.test(pathname);
  const isFeedsSources = pathname.startsWith('/feed/sources');
  const isTagsList = pathname.startsWith('/tags');
  const isViewsList = pathname.startsWith('/views');
  const isImportPage = pathname.startsWith('/import');
  const isPreferencesPage = pathname.startsWith('/preferences');
  const isEmailPreferencesPage = pathname.startsWith('/product-emails');
  const isProfilePage = pathname.startsWith('/profile');
  const isIntegrationsPage = pathname.startsWith('/integrations');
  const persistentStateLoaded = globalState(useCallback((state) => state.persistentStateLoaded, []));
  const theme = globalState(useCallback((state) => state.client.theme, []));
  const clientStateLoaded = globalState(useCallback((state) => state.clientStateLoaded, []));
  const hasOnboarded = globalState(useCallback((state) => state.persistent && state.persistent.onboardedAt !== null, []));
  const themeMediaQueryRef = useRef<MediaQueryList>();

  useEffect(() => {
    const newPath = `${pathname}${search || ''}`;
    addRouteToNavigationStack(decodeURIComponent(newPath));
  }, [pathname, search]);

  useEffect(() => {
    if (!isReaderViewUrl) {
      hideReaderViewSidebars(false, { userInteraction: 'unknown' });
    }
  }, [isReaderViewUrl]);

  useScreenWidthMonitor();

  const applyTheme = useCallback((newTheme: DisplayTheme) => {
    const page = document.querySelector('html') as HTMLHtmlElement;
    const currentThemes = Array.from(page.classList).filter((c) => c.startsWith('theme--'));
    currentThemes.forEach((themeClass) => {
      page.classList.remove(themeClass);
    });
    page.classList.add(`theme--${newTheme}`);
    setWebEffectiveTheme(newTheme);
    setCookie('theme', newTheme);
  }, []);

  useEffect(() => {
    if (!clientStateLoaded) {
      const theme = getCookie('theme') as DisplayTheme;
      if (theme) {
        applyTheme(theme);
      }
      return;
    }

    let newTheme;

    if (window.matchMedia) {
      if (!themeMediaQueryRef.current) {
        themeMediaQueryRef.current = window.matchMedia('(prefers-color-scheme: dark)');
      }

      if (theme === DisplayTheme.System) {
        const isSystemDarkTheme = themeMediaQueryRef.current.matches;
        newTheme = isSystemDarkTheme ? DisplayTheme.Dark : DisplayTheme.Light;
        themeMediaQueryRef.current.onchange = (e) => {
          applyTheme(e.matches ? DisplayTheme.Dark : DisplayTheme.Light);
        };
      } else {
        newTheme = theme === DisplayTheme.Dark ? DisplayTheme.Dark : DisplayTheme.Light;
        themeMediaQueryRef.current.onchange = null;
      }
    } else {
      newTheme = theme === DisplayTheme.Dark ? DisplayTheme.Dark : DisplayTheme.Light;
    }

    applyTheme(newTheme);
  }, [theme, applyTheme, clientStateLoaded]);

  const appRef = useRef(null);

  const shortcutsMap = useShortcutsMap();

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.Search], useCallback(() => {
    const isSearchView = history.location.pathname === '/search';

    if (isSearchView) {
      return;
    }

    history.push('/search');
  }, [history]), { description: 'Search' });

  const onUndoPressed = () => undoLastDocumentAction('keypress');
  useHotKeys(shortcutsMap[ShortcutId.Undo], onUndoPressed, { description: 'Undo' });
  useHotKeys(
    shortcutsMap[ShortcutId.Redo],
    useCallback(async () => redoLastDocumentAction('keypress'), []),
    { description: 'Redo' },
  );
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.OpenShortcutsMenu], useCallback(async () => openShortcutsSubmenu(), []));

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToHome], useCallback(() => {
    history.push('/home');
  }, [history]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToLibrary], useCallback(() => {
    history.push('/library');
  }, [history]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.Feed], useCallback(() => {
    history.push('/feed');
  }, [history]), {
    shouldShowInHelp: false,
  });

  const goToPinnedViewIndex = useCallback((index: number) => {
    const views = globalState.getState().persistent.filteredViews || {};
    const pinnedIds = Object.keys(views).filter((id) => views[id].isUnpinned !== true);
    const sortedViews = pinnedIds.map((id) => views[id]).slice().sort(sortViews);
    const view = sortedViews[index];

    if (view) {
      const url = getFilteredViewPath(view, documentLocations);
      history.push(url);
    }
  }, [documentLocations, history]);

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToPinnedView1], useCallback(() => {
    goToPinnedViewIndex(0);
  }, [goToPinnedViewIndex]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToPinnedView2], useCallback(() => {
    goToPinnedViewIndex(1);
  }, [goToPinnedViewIndex]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToPinnedView3], useCallback(() => {
    goToPinnedViewIndex(2);
  }, [goToPinnedViewIndex]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToPinnedView4], useCallback(() => {
    goToPinnedViewIndex(3);
  }, [goToPinnedViewIndex]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToPinnedView5], useCallback(() => {
    goToPinnedViewIndex(4);
  }, [goToPinnedViewIndex]), {
    shouldShowInHelp: false,
  });

  useHotKeysPreventDefault(shortcutsMap[ShortcutId.GoToPinnedView6], useCallback(() => {
    goToPinnedViewIndex(5);
  }, [goToPinnedViewIndex]), {
    shouldShowInHelp: false,
  });

  useHotKeys(shortcutsMap[ShortcutId.HidePanels], useCallback(async () => {
    await toggleHideSidebars({ userInteraction: 'keyup' });
  }, []), { description: 'Toggle panel hidden' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.HideLeftPanel], useCallback(async () => {
    await toggleHideLeftSidebar({ userInteraction: 'keyup' });
  }, []), { description: 'Toggle left panel hidden' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.HideRightPanel], useCallback(async () => {
    await toggleHideRightSidebar({ userInteraction: 'keyup' });
  }, []), { description: 'Toggle right panel hidden' });
  useHotKeys(shortcutsMap[ShortcutId.ToggleDarkMode], useCallback(async () => {
    await toggleDarkModeTheme();
  }, []), { description: 'Toggle dark mode' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.ManageFeedSubscriptions], useCallback(async () => {
    await openFeedsSubMenu();
  }, []), { description: 'Add/remove RSS subscriptions' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.OpenFiltersCmdPanel], useCallback(async () => {
    await openFiltersSubMenu();
  }, []), { description: 'Filter all documents' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.OpenFilteredViews], useCallback(async () => {
    await openViewFiltersSubMenu();
  }, []), { description: 'Open quick view switcher' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.OpenBulkActionsSubMenu], useCallback(async () => {
    if (shouldShowBulkActionsMenu(pathname)) {
      await openBulkActionsSubMenu();
    }
  }, [pathname]), { description: 'Apply bulk actions' });
  useHotKeysPreventDefault(shortcutsMap[ShortcutId.OpenSaveDocFromUrlPalette], useCallback(async () => {
    await openSaveDocSubMenu();
  }, []), { description: 'Save doc from URL' });

  useScrollToTop(appRef.current);

  const documentLocationUrlPlaceholder = `:documentLocation(${Object.values(DocumentLocation).join('|')})`;
  const feedDocumentLocationUrlPlaceholder = `/:documentLocation(feed)/:feedDocumentLocation(${Object.values(FeedDocumentLocation).join('|')})`;
  const rightSidebarHidden = useIsRightSidebarHidden();

  const hasRightSidebar = persistentStateLoaded && ![
    '/home', '/feed/suggestions', '/welcome', '/welcome/extension', '/welcome/mobile', '/welcome/app', '/welcome/ready', '/preferences/shortcuts',
  ].some((urlPath) => matchPath(
    window.location.pathname,
    {
      path: urlPath,
      exact: true,
    },
  ));

  // We don't want to animate the sidebars if we are coming from the list with
  // sidebars already hidden.
  const possibleTransitionClassName = isReaderViewUrl ? styles.withTransition : '';
  const debouncedTransitionClassName = useDebounce(possibleTransitionClassName, 500);
  const transitionClassName = isReaderViewUrl || isFeedsSources || isTagsList || isViewsList || isImportPage || isProfilePage || isPreferencesPage || isEmailPreferencesPage || isIntegrationsPage ? debouncedTransitionClassName : styles.withTransition;

  const appClasses = ['appContent', styles.appContent, transitionClassName];
  if (rightSidebarHidden) {
    appClasses.push(styles.rightSidebarHidden);
  }
  if (isDevOrTest) {
    appClasses.push('isDev');
  }

  const onboardingStep = useGlobalStateWithFallback(OnboardingStep.Welcome, useCallback(
    (state) => state.persistent.onboardingStep,
    [],
  ));

  const subscription = globalState(useCallback((state) => state.client?.profile?.subscription, []));
  const shouldForceMobileApp = useShouldForceMobileApp();

  useEffect(() => {
    if (!persistentStateLoaded) {
      return;
    }

    if (hasOnboarded && !shouldForceMobileApp) {
      return;
    }

    setPathNameToRedirectAfterOnboarding(pathname);
    history.push(onboardingStep);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistentStateLoaded, hasOnboarded, shouldForceMobileApp]);

  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (!persistentStateLoaded) {
      return;
    }

    setTimeout(() => {
      isFirstLoad.current = false;
    }, 0);
  }, [persistentStateLoaded]);

  const defaultPage = globalState((state) => {
    const defaultPageVal = state.persistent.settings.defaultPage ?? DefaultPage.Library;
    const currentlyReadingId = state.persistent.currentlyReadingId;
    if (defaultPageVal === DefaultPage.CurrentlyReading) {
      if (currentlyReadingId && isFirstLoad.current) {
        return urlJoin(['read', currentlyReadingId]);
      }

      return DefaultPage.Library;
    }
    return defaultPageVal;
  });

  /*
    We wait for state to know which default page to open.
    NOTE: if that's ever removed, we need to show this anyway if it's the first time a user has opened reader
  */

  const persistentStateLoadingState = globalState((state) => state.persistentStateLoadingState);
  const totalDocumentCount = globalState((state) => state.persistentStateTotalDocumentsToAddCount);
  const addedDocumentsCount = globalState((state) => state.persistentStateNumberOfDocumentsAdded);

  const currentLoadingMessage = useMemo(() => {
    if (persistentStateLoadingState === PersistentStateLoadingState.DownloadingDocuments) {
      return 'Fetching all of your documents';
    }
    if (persistentStateLoadingState === PersistentStateLoadingState.AddingDocumentsToDatabase) {
      return 'Adding documents, please wait...';
    }
    return '';
  }, [persistentStateLoadingState]);

  const currentLoadingSubMessage = useMemo(() => {
    if (persistentStateLoadingState === PersistentStateLoadingState.DownloadingDocuments) {
      return 'This initial load may take a moment...';
    }
    if (persistentStateLoadingState === PersistentStateLoadingState.AddingDocumentsToDatabase) {
      return `Added ${addedDocumentsCount}/${totalDocumentCount} documents`;
    }
    return '';
  }, [addedDocumentsCount, persistentStateLoadingState, totalDocumentCount]);

  if (!persistentStateLoaded) {
    return <div className={styles.spinnerWrapper}>
      <Spinner />
      <p>{currentLoadingMessage}</p>
      <p>{currentLoadingSubMessage}</p>
      </div>;
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <FileDropzone className={['app', styles.app].join(' ')}>
        <div className={styles.appMainAndTtsPlayer}>
          <main className={styles.appMain}>
            <div className={appClasses.join(' ')} ref={appRef}>
              <Suspense fallback={<div className="hideAccessibly">Loading...</div>}>
                <Switch>
                  <Redirect exact from="/" to={urlJoin(['/', defaultPage])} />
                  <Redirect exact from="/library" to={urlJoin(['/', documentLocations[0] || DocumentLocation.New])} />
                  <Redirect exact from="/inbox" to={urlJoin(['/', DocumentLocation.New])} />
                  {/* Redirect to main app login for end-to-end tests */}
                  <Route
                    component={() => {
                      window.location.href = `https://${window.location.hostname}:8000/accounts/login`;
                      return null;
                    }}
                    path="/accounts/login"
                  />
                  <Route
                    component={() => {
                      window.location.href = `https://${window.location.hostname}:8000/empty`;
                      return null;
                    }}
                    path="/empty"
                  />

                  <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> {/* remove trailing slashes */}
                  <Redirect exact from={`/${DocumentLocation.Feed}`} to={urlJoin(['/', DocumentLocation.Feed, FeedDocumentLocation.New])} />

                  <Route exact path="/profile">
                    <TrackActiveItemPage name="profile" />
                    <ProfilePage />
                  </Route>
                  {/* lock out users with expired plans */}
                  {subscription?.product === SubscriptionProduct.Expired ? <Redirect from="*" to="/profile" /> : null}

                  <Route exact path="/feed/sources">
                    <TrackActiveItemPage name="feed sources" />
                    <FeedsSourceList />
                  </Route>

                  <Route exact path="/feed/suggestions">
                    <TrackActiveItemPage name="feed suggestions" />
                    <FeedsSuggestionList />
                  </Route>

                  <Route exact path="/home">
                    <TrackActiveItemPage name="home" />
                    <HomePage />
                  </Route>

                  <Route exact path="/tags">
                    <TrackActiveItemPage name="tags" />
                    <TagsList />
                  </Route>

                  <Route exact path="/views">
                    <TrackActiveItemPage name="views" />
                    <ViewsList />
                  </Route>

                  <Route exact path={['/welcome', '/welcome/extension', '/welcome/mobile', '/welcome/app', '/welcome/ready']}>
                    <TrackActiveItemPage name="onboarding" />
                    <OnboardingPage />
                  </Route>

                  <Route
                    exact path={[
                      `/${documentLocationUrlPlaceholder}`,
                      `/${documentLocationUrlPlaceholder}/read/:openDocumentId`,
                      `/read/:uncategorizedDocumentIdToOpen`,
                      feedDocumentLocationUrlPlaceholder,
                      `${feedDocumentLocationUrlPlaceholder}/read/:openDocumentId`,
                    ]}
                  >
                    <TrackActiveItemPage name="inbox" />
                    <InboxPage />
                  </Route>
                  <Route exact path={NOTEBOOK_PATH_PATTERN}>
                    <TrackActiveItemPage name="notebook" />
                    <NotebookPage />
                  </Route>
                  <Route exact path="/import">
                    <TrackActiveItemPage name="import" />
                    <ImportPage />
                  </Route>
                  <Route exact path="/preferences">
                    <TrackActiveItemPage name="preferences" />
                    <PreferencesPage />
                  </Route>
                  <Route exact path="/product-emails">
                    <TrackActiveItemPage name="product emails" />
                    <EmailPreferencesPage />
                  </Route>
                  <Route exact path="/preferences/shortcuts">
                    <TrackActiveItemPage name="shortcuts" />
                    <ShortcutsPage />
                  </Route>
                  <Route exact path="/integrations">
                    <TrackActiveItemPage name="integrations" />
                    <IntegrationsPage />
                  </Route>
                  <Route exact path={['/search', '/search/read/:openDocumentId']}>
                    <TrackActiveItemPage name="search" />
                    <SearchPage />
                  </Route>
                  <Route
                    exact path={[
                      '/filter/:filterQuery',
                      '/filter/:filterQuery/read/:openDocumentId',
                      '/filter/:filterQuery/split/:splitBy',
                      '/filter/:filterQuery/split/:splitBy/:splitValue',
                      '/filter/:filterQuery/split/:splitBy/:splitValue/read/:openDocumentId',
                    ]}>
                    <TrackActiveItemPage name="filter" />
                    <FilterPage />
                  </Route>
                  <Route exact path="/faq">
                    <FAQRedirect />
                  </Route>

                  <DevOnly>
                    <Route exact path="/database-explorer">
                      <DatabaseExplorer />
                    </Route>
                    <Route path="/database-query-optimizer">
                      <DatabaseQueryOptimizer />
                    </Route>
                  </DevOnly>
                </Switch>
              </Suspense>
            </div>
            <Suspense fallback={null}>
              {hasRightSidebar && <RightSidebar />}
            </Suspense>
          </main>
          <TtsPlayer />
        </div>
        <Suspense fallback={null}>
          <ModalsContainer />
          <CommandPalette />
          <QuoteshotModal />
        </Suspense>
      </FileDropzone>
      <Suspense fallback={null}>
        <ToastContainer />
      </Suspense>
      <Ghost />
    </Sentry.ErrorBoundary>
  );
};


// Wrap in Router for urls to work nicely:
const App = (): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('react-has-mounted');
  }, []);

  return <Router>
    <Main />
  </Router>;
};

export default App;

// For debugging why components rendered:
// CommandPalette.whyDidYouRender = {
//   logOnDifferentValues: true,
// };
