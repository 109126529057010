import React from 'react';

import Icon from './Icon';

export default function LeftArrow({ className = '', text = 'left' }: { text?: string; className?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.77777 1.22217L1.77777 7.22217M1.77777 7.22217L7.77777 12.9999M1.77777 7.22217H14.4444" stroke="currentColor" strokeWidth="1.4" />
    </svg>
  </Icon>;
}
