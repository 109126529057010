import sortedIndexBy from 'lodash/sortedIndexBy';

import { TtsPosition, WordBoundary } from '../../../shared/types';

export const findWordBoundaryForTrackPosition = (position: number, wordBoundariesForVoice: WordBoundary[]): TtsPosition | undefined => {
  // this is going to be a very ... specific binary search because I'm too lazy to figure out the ideal solution
  // Basically, take the position, floor it. Binary search until we find the same integer.
  // Once we find that, iteratively search until we find the exact word that fits
  const intPos = Math.floor(position);
  let index = sortedIndexBy(wordBoundariesForVoice, [intPos, 0, '', 0, 0, 0, 0], (wordBoundary) => wordBoundary[0]);
  if (index >= wordBoundariesForVoice.length) {
    return;
  }
  let closestWord = wordBoundariesForVoice[index];
  if (!closestWord) {
    return;
  }
  if (closestWord[0] > position && index > 0) {
    // Honestly no clue why this if statement fires but it safeguards for the case that lodash returns
    // an index further ahead of the position
    index -= 1;
    closestWord = wordBoundariesForVoice[index];
  }
  if (closestWord[2] === 'e') {
    return;
  }
  // Take this value and binary search until we find the right index
  let prevPos: TtsPosition = {
    trackPos: closestWord[0],
    textPos: closestWord[1],
    word: closestWord[2],
    paraTextPos: closestWord[3],
    paraIndex: closestWord[4],
  };
  for (const [trackPos, textPos, word, paraTextPos, paraIndex] of wordBoundariesForVoice.slice(index) as WordBoundary[]) {
    if (word === 'e') {
      // This is most likely the special character we add to end of the chunk. Skip setting its word boundary
      continue;
    }
    if (position < trackPos) {
      return prevPos;
    }
    prevPos = { trackPos, textPos, word, paraTextPos, paraIndex };
  }
};
