import type { FileDocument } from '../types';
import exceptionHandler from '../utils/exceptionHandler.platform';
import getServerBaseUrl from '../utils/getServerBaseUrl.platform';
import requestWithAuth from '../utils/requestWithAuth.platformIncludingExtension';

// Web Cache used to store PDF response blobs, used only on desktop. On Web, it doesn't work sometimes for unknown reasons.
// `navigator.storage.estimate()` in the desktop app gives me an estimate of a storage quota of 20Gb, so we should be OK.
// https://developer.mozilla.org/en-US/docs/Web/API/Cache
const DOCUMENT_RAW_CONTENT_CACHE: Promise<Cache> = caches.open('document_raw_content');

export function getFilePath(docId?: FileDocument['parsed_doc_id']) {
  return `file/${docId}`;
}

export async function loadDocument(docId: number | string): Promise<Blob> {
  const cache = await DOCUMENT_RAW_CONTENT_CACHE;
  const url = `${getServerBaseUrl()}/reader/document_raw_content/${docId}`;
  let cachedResponse = await cache.match(url);
  if (!cachedResponse) {
    const response = await requestWithAuth(
      url,
      {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
      },
    );
    await cache.put(url, response);
    // Need to get the same response out of cache here because putting it in cache consumed the Response body.
    cachedResponse = await cache.match(url);
    if (!cachedResponse) {
      exceptionHandler.captureException(
        'Document not in document content cache after putting it there',
        {
          extra: {
            docId,
            url,
          },
        },
      );
      return new Blob();
    }
  }
  return cachedResponse.blob();
}
