import { setCursorAtEndOfElement } from './setCursorAtEndOfElement';

export default function focusFirstFocusableDescendant (ancestor: HTMLElement, selector?: string): HTMLElement | null {
  const descendant = ancestor.querySelector(
    selector ??
    [
      'button',
      '[href]',
      'input',
      'select',
      'textarea',
      '[tabindex]',
    ]
      .map((sel) => `${sel}:not([tabindex="-1"])`)
      .join(','),
  ) as HTMLElement;

  if (descendant) {
    descendant.focus();

    // Put cursor at end of textareas
    if (descendant.tagName.toLowerCase() === 'textarea') {
      const textarea = descendant as HTMLTextAreaElement;
      setCursorAtEndOfElement(textarea);
    }
  }

  return descendant;
}
