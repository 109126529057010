import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHotKeysPreventDefault } from '../hooks/hooks';
import { setCursorAtEndOfElement } from '../utils/setCursorAtEndOfElement';
import Button from './Button';
import MinusIcon from './icons/20StrokeMinus';
import PlusIcon from './icons/20StrokePlus';
import styles from './NumberInput.module.css';
import Tooltip from './Tooltip';

export default function NumberInput({ current, total, onChange, step = 1, steps, suffix, onFocus, tooltipText = '', shortcut = [], className = '', alwaysShowButtons = false, instantUpdate = true }: { current: number; total?: number; onChange: (value: number) => void; step?: number; steps?: number[]; suffix?: string; onFocus?: () => void; tooltipText?: string; shortcut?: string[]; className?: string; alwaysShowButtons?: boolean; instantUpdate?: boolean; }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(current);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setValue(current);
  }, [current]);

  const focusInput = useCallback(() => {
    const el = inputRef.current;

    if (!el) {
      return;
    }

    el.focus();
    setCursorAtEndOfElement(el);

    if (onFocus) {
      onFocus();
    }

  }, [onFocus]);

  useHotKeysPreventDefault(
    shortcut,
    focusInput,
    {
      description: tooltipText,
    },
  );

  const onClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const wasClickInButton = (e.target as HTMLElement).closest(`.${styles.actionButtons}`);

    if (wasClickInButton) {
      return;
    }

    focusInput();
  }, [focusInput]);

  const handleSetValue = useCallback(({ newValue, shouldEmitOnChange = false }: {newValue: number; shouldEmitOnChange?: boolean;}) => {
    setValue(newValue);

    if (newValue && (shouldEmitOnChange || instantUpdate)) {
      onChange(newValue);
    }
  }, [onChange, instantUpdate]);

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = parseInt(e.target.value, 10);

    if (total && newNumber > total) {
      handleSetValue({ newValue: total });
    } else if (newNumber < 1) {
      handleSetValue({ newValue: 1 });
    } else {
      handleSetValue({ newValue: newNumber });
    }
  }, [total, handleSetValue]);

  const handleOnBlur = useCallback(() => {
    if (total && value > total) {
      handleSetValue({ newValue: total, shouldEmitOnChange: true });
    } else if (!value || value < 1) {
      handleSetValue({ newValue: 1, shouldEmitOnChange: true });
    } else {
      handleSetValue({ newValue: value, shouldEmitOnChange: true });
    }

    setIsFocused(false);
  }, [value, total, handleSetValue]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEsc = e.key === 'Escape';

    if (isEsc) {
      handleSetValue({ newValue: current });
      setTimeout(() => {
        (e.target as HTMLInputElement).blur();
      }, 0);
      return;
    }

    const isEnter = e.key === 'Enter';

    if (isEnter && value) {
      (e.target as HTMLInputElement).blur();
      onChange(value);
    }

  }, [value, onChange, handleSetValue, current]);

  const increaseValue = useCallback(() => {
    if (steps) {
      const nextStep = steps.find((step) => step > value);

      if (nextStep) {
        handleSetValue({ newValue: nextStep, shouldEmitOnChange: true });
      }
      return;
    }

    handleSetValue({ newValue: Math.min(value + step, total ?? Infinity), shouldEmitOnChange: true });
  }, [value, total, step, steps, handleSetValue]);

  const decreaseValue = useCallback(() => {
    if (steps) {
      const nextStep = steps.slice().reverse().find((step) => step < value);

      if (nextStep) {
        handleSetValue({ newValue: nextStep, shouldEmitOnChange: true });
      }
      return;
    }

    handleSetValue({ newValue: Math.max(value - step, 1), shouldEmitOnChange: true });
  }, [value, step, steps, handleSetValue]);

  const inputStyle = useMemo(() => {
    const defaultWidth = `${(value || 1).toString().length}ch`;
    return { width: `calc(${defaultWidth} + 2px)` };
  }, [value]);

  return (
    <Tooltip content={tooltipText} shortcut={shortcut}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={`${className} ${styles.numberInput} ${isFocused ? styles.isFocused : ''} ${isHovered ? styles.isHovered : ''} ${alwaysShowButtons ? styles.alwaysShowButtons : ''}`}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
        <input
          ref={inputRef}
          className={styles.current}
          autoComplete="false"
          type="number"
          style={inputStyle}
          value={value}
          aria-label="number input"
          min={1}
          max={total}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={() => setIsFocused(true)}
          onKeyDown={handleKeyDown}
        />{suffix} {total && <>of {total}</>}

        <div className={styles.actionButtons}>
          <Button variant="unstyled" onClick={decreaseValue}>
            <MinusIcon />
          </Button>
          <Button variant="unstyled" onClick={increaseValue}>
            <PlusIcon />
          </Button>
        </div>
      </div>
      </Tooltip>
  );
}
