import React, { useCallback, useEffect, useState } from 'react';

import AnnotationPopovers from './AnnotationPopovers';

type Props = {
  containerNodeSelector: string;
};

const defaultExport = React.memo(function SelectionAnnotationPopovers({
  containerNodeSelector,
}) {
  const [isAnnotationBarPopoverShown, setIsAnnotationBarPopoverShown] = useState(false);
  const hideAnnotationBarPopover = useCallback(() => {
    setIsAnnotationBarPopoverShown(false);
  }, []);
  const showAnnotationBarPopover = useCallback(() => {
    setIsAnnotationBarPopoverShown(true);
  }, []);

  const getSelectionDomRect = useCallback(() => {
    const selection = window.getSelection();
    if (!selection?.toString().trim() || !selection.rangeCount) {
      return document.body.getBoundingClientRect();
    }
    const range = selection.getRangeAt(0);
    if (!range) {
      return document.body.getBoundingClientRect();
    }
    const result = range.getBoundingClientRect();
    return result;
  }, []);

  useEffect(() => {
    const container = document.querySelector<HTMLElement>(containerNodeSelector);
    if (!container) {
      return;
    }

    const onPointerUp = () => {
      const selection = window.getSelection();
      if (!selection?.rangeCount || !selection.toString().trim()) {
        return;
      }
      const range = selection.getRangeAt(0);
      if (!range) {
        return;
      }
      setIsAnnotationBarPopoverShown(Boolean(range));
    };
    container.addEventListener('pointerup', onPointerUp);

    const onSelectionChange = () => {
      const selection = window.getSelection();
      if (!selection?.rangeCount || !selection.toString().trim()) {
        hideAnnotationBarPopover();
      }
    };
    document.addEventListener('selectionchange', onSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange);
      container.removeEventListener('pointerup', onPointerUp);
    };
  }, [containerNodeSelector, hideAnnotationBarPopover]);

  return <AnnotationPopovers
    getBoundingClientRectForMargins={getSelectionDomRect}
    getHighlightBoundingClientRect={getSelectionDomRect}
    hideAnnotationBarPopover={hideAnnotationBarPopover}
    isAnnotationBarPopoverShown={isAnnotationBarPopoverShown}
    shouldShowInMargin={false}
    shouldUseSelection
    showAnnotationBarPopover={showAnnotationBarPopover}
    shouldAutoFocus={false}
  />;
}) as React.FC<Props>;

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
