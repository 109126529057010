import React, { useRef } from 'react';

// This works around the awkwardness of using React.forwardRef with valid TypeScript
export default function forwardRef <TProps, TRef>(
  callback: (props: TProps, ref: React.MutableRefObject<TRef>) => ReturnType<React.ForwardRefRenderFunction<TRef, TProps>>,
): React.ForwardRefExoticComponent<React.PropsWithoutRef<TProps> & React.RefAttributes<TRef>> {

  function forwardRefCallbackWrapper(props: TProps, ref: React.ForwardedRef<TRef>) {
    return callback(props, ref as unknown as React.MutableRefObject<TRef>);
  }

  return React.forwardRef<TRef, TProps>(forwardRefCallbackWrapper);
}

export function forwardRefOptional<TProps, TRef = null>(
  callback: (props: TProps, ref?: React.MutableRefObject<TRef>) => ReturnType<React.ForwardRefRenderFunction<TRef, TProps>>,
): React.ForwardRefExoticComponent<React.PropsWithoutRef<TProps> & React.RefAttributes<TRef>> {

  function ForwardRefCallbackWrapper(props: TProps, ref: React.ForwardedRef<TRef>) {
    const fallbackRef = useRef<TRef>(null);
    return callback(props, (ref ?? fallbackRef) as unknown as React.MutableRefObject<TRef>);
  }

  return React.forwardRef<TRef, TProps>(ForwardRefCallbackWrapper);
}
