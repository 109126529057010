import React from 'react';

import type { FirstClassDocument } from '../../../shared/types';
import { useIsRightSidebarHidden } from '../hooks/hooks';
import styles from './DocumentTextContent.module.css';
import ChevronUpIcon from './icons/ChevronUpIcon';
import Tooltip from './Tooltip';

export const ReturnToReadingButton = ({ onClick, currentScrollPos, currentReadingPos }: {onClick: () => void; currentScrollPos: FirstClassDocument['currentScrollPosition'] | undefined; currentReadingPos: FirstClassDocument['readingPosition'] | undefined;}) => {
  const isRightSidebarHidden = useIsRightSidebarHidden();

  let flip = false;
  if (currentScrollPos && currentReadingPos) {
    flip = (currentReadingPos.scrollDepth ?? 0) > (currentScrollPos.scrollDepth ?? 0);
  }
  return <Tooltip content="Return to reading position">
  <button type="button" onClick={onClick} className={`${styles.returnToReadingButton} ${flip ? styles.reverseButton : ''} ${isRightSidebarHidden ? styles.isRightSidebarHidden : ''}`}>
    <ChevronUpIcon text="Return to reading" />
  </button>
  </Tooltip>;
};
