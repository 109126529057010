import { ulid } from 'ulid';

import type {
  BaseDocument,
  Highlight,
  Note,
  ParentDocument,
  PartialDocument,
  TransientDocumentData,
  UserEvent,
} from '../../../../types';
import { Category } from '../../../../types';
import { notEmpty } from '../../../../typeValidators';
import nowTimestamp from '../../../../utils/dates/nowTimestamp';
import { appCategory, isExtension } from '../../../../utils/environment';
import makeLogger from '../../../../utils/makeLogger';
import foreground from '../../../portalGates/contentFrame/to/reactNativeWebview';
import { getDocument, getDocuments, getNoteFromHighlight } from '../../../stateGetters';
import { createToast } from '../../../toasts.platform';
import type { StateUpdateOptions, StateUpdateOptionsWithoutEventName, StateUpdateResult } from '../../../types';
import { trackUserEvent } from '../../../userEvents';
import { deleteReaderFile } from '../../../utils/uploadFiles';
import { setFocusedHighlightId } from '../../transientStateUpdaters/other';
import { upsertDocument } from './create';
import { deleteDocumentById, deleteDocumentsById } from './remove';
import { updateDocument } from './update';

const logger = makeLogger(__filename);

const source = isExtension
  ? 'Readwise web highlighter'
  : `reader-${appCategory}`;

export const createHighlightDocumentObject = (
  highlightData: Partial<Highlight> & Pick<Highlight, 'content' | 'html' | 'location' | 'markdown' | 'offset' | 'source_specific_data'> & { parent: ParentDocument['id']; },
): Highlight & { parent: ParentDocument['id']; } => {
  const savedAt = nowTimestamp();
  return {
    ...highlightData,
    category: highlightData.category ?? Category.Highlight,
    // This should be populated separately
    children: highlightData.children ?? [],
    id: highlightData.id ?? ulid().toLowerCase(),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    saved_at: highlightData.saved_at ?? savedAt,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    saved_at_history: highlightData.saved_at_history ?? [savedAt],
    source,
    source_specific_data: highlightData.source_specific_data ?? {},
    tags: highlightData.tags ?? {},
  };
};

export const createNoteDocument = (
  { content, highlightId, savedAt }: { content: Highlight['content']; highlightId: Highlight['id']; savedAt: number; },
): Note => {
  return {
    category: Category.Note,
    content,
    id: ulid().toLowerCase(),
    parent: highlightId,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    saved_at: savedAt,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    saved_at_history: [savedAt],
    source,
  };
};

export const createHighlight = async (
  highlightDetails: Parameters<typeof createHighlightDocumentObject>[0],
  transientDocumentData: Partial<TransientDocumentData>,
  {
    userInteraction = 'unknown',
  }: {
    userInteraction?: NonNullable<UserEvent['userInteraction']>['name'];
  },
): Promise<Highlight['id']> => {

  const doc = createHighlightDocumentObject(highlightDetails);
  await upsertDocument(
    doc,
    transientDocumentData,
    {
      eventName: 'highlight-created',
      userInteraction,
    },
  );
  await setFocusedHighlightId(doc.id);
  return doc.id;
};

export const createHighlightNote = async (
  { content, highlightId, savedAt }: Parameters<typeof createNoteDocument>[0],
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  if (!content) {
    throw new Error('No content');
  }

  const note = createNoteDocument({ content, highlightId, savedAt });
  await upsertDocument(note, {}, {
    eventName: 'note-created',
    userInteraction: options.userInteraction,
  });
};

export const updateHighlight = async (
  highlightId: Highlight['id'],
  updates: Partial<Highlight>,
): ReturnType<typeof updateDocument> => {
  return updateDocument<Highlight>(highlightId, (existingHighlight) => {
    const savedAtHistory = existingHighlight.saved_at_history ?? [];
    savedAtHistory.push(nowTimestamp());

    return {
      ...existingHighlight,
      ...updates,
      saved_at: nowTimestamp(),
      saved_at_history: savedAtHistory,
    } as Highlight;
  }, { eventName: 'update-highlight', userInteraction: 'unknown' });
};

export async function mergeHighlights(
  {
    otherHighlightIds,
    remainingHighlightDetails,
    sourceUrl,
  }: {
    otherHighlightIds: Highlight['id'][];
    remainingHighlightDetails: Pick<Highlight, 'content' | 'html' | 'id' | 'location' | 'offset'>;
    sourceUrl: string;
  },
  optionsArg: StateUpdateOptionsWithoutEventName & { userInteraction: string; },
): ReturnType<typeof upsertDocument> {
  const options: StateUpdateOptions = {
    eventName: 'highlights-merged',
    correlationId: ulid(),
    ...optionsArg,
  };
  const markdown = await foreground.convertHtmlToText(remainingHighlightDetails.html, sourceUrl);
  const savedAt = nowTimestamp();

  const [remainingHighlightBeforeAnyUpdates, otherHighlights] = await Promise.all([
    getDocument<Highlight>(remainingHighlightDetails.id),
    getDocuments<Highlight>(otherHighlightIds),
  ]);
  if (!remainingHighlightBeforeAnyUpdates) {
    throw new Error('Can\'t find remaining document');
  }

  // Gather all the notes first
  const noteIds = [
    remainingHighlightBeforeAnyUpdates,
    ...otherHighlights ?? [],
  ]
    .filter(notEmpty)
    .sort((a, b) => (a.offset ?? -1) - (b.offset ?? -1))
    .map((highlightInvolved) => highlightInvolved.children.map((noteId) => noteId))
    .flat();
  const noteDocuments = await getDocuments<Note>(noteIds);
  const notes = (noteDocuments ?? [])
    .map((note) => note.content)
    .filter(notEmpty);
  // Delete old note on remaining highlight, plus other highlights and their notes
  const allDocumentIdsToDelete = [
    ...remainingHighlightBeforeAnyUpdates.children,
    ...otherHighlightIds,
  ];
  await deleteDocumentsById(allDocumentIdsToDelete, {
    shouldDeleteChildren: true,
    ...options,
  });

  // Update remaining highlight
  const savedAtHistory = remainingHighlightBeforeAnyUpdates.saved_at_history ??
    [remainingHighlightBeforeAnyUpdates.saved_at];
  savedAtHistory.push(savedAt);
  const mergedHighlight = {
    ...remainingHighlightBeforeAnyUpdates,
    ...remainingHighlightDetails,
    children: [], // Remove existing note references
    markdown,
    saved_at: savedAt,
    saved_at_history: savedAtHistory,
  } as Highlight;
  const upsertHighlightPromise = await upsertDocument(mergedHighlight, {}, {
    ...options,
    disableWarningForDuplicateChild: true,
  });

  // Create new highlight note
  let upsertNotePromise;
  if (notes.length > 0) {
    const mergedNote = createNoteDocument({
      content: notes.join('\n\n---\n\n'),
      highlightId: remainingHighlightDetails.id,
      savedAt,
    });
    upsertNotePromise = upsertDocument(mergedNote, {}, options);
  }
  const [result] = await Promise.all([
    upsertHighlightPromise,
    upsertNotePromise,
  ]);
  return result;
}

export const updateHighlightNote = async (highlight: Highlight | PartialDocument<Highlight, 'children' | 'id'>, noteInput: string, options: Omit<Parameters<typeof createHighlightNote>[1], 'eventName'> & { userInteraction: string; }): Promise<void> => {
  const { correlationId } = await trackUserEvent({ ...options, eventName: 'highlight-note-updated' });
  const oldNote = await getNoteFromHighlight(highlight);
  const note = noteInput.trim().length ? noteInput : '';
  if (oldNote === note) {
    return;
  }

  for (const childId of highlight.children) {
    await deleteHighlightNote(
      childId,
      {
        correlationId,
        isUndoable: false,
        userInteraction: null, // this is really just internal wiring
      },
    );
  }

  if (note) {
    createHighlightNote(
      {
        content: note,
        highlightId: highlight.id.toString(),
        savedAt: nowTimestamp(),
      },
      {
        correlationId,
        ...options,
        userInteraction: null, // this is really just internal wiring
      },
    );
  }
};

function cleanupReaderFileForHighlight(highlight: Highlight) {
  const readerFileId = highlight?.source_specific_data?.pdf_highlight?.reader_file_id;

  if (readerFileId) {
    deleteReaderFile({ readerFileId });
  }
}

export const deleteHighlight = async (
  documentId: Highlight['id'],
  options: { shouldShowToast?: boolean; } & Omit<Parameters<typeof deleteDocumentById>[1], 'eventName' | 'shouldDeleteChildren'>,
): Promise<void> => {
  const highlight = await getDocument<Highlight>(documentId);
  if (!highlight) {
    logger.error('No such highlight to delete', { documentId });
    return;
  }
  cleanupReaderFileForHighlight(highlight);

  const updateResult = await deleteDocumentById(documentId, {
    ...options,
    shouldDeleteChildren: true,
    eventName: 'highlight-deleted',
  });
  if (options.shouldShowToast !== false) {
    createToast({
      content: `Highlight deleted`,
      category: 'success',
      undoableUserEventId: updateResult.userEvent?.id,
    });
  }
};

export async function deleteHighlights(
  documentIds: Highlight['id'][],
  options: Omit<Parameters<typeof deleteDocumentsById>[1], 'eventName' | 'shouldDeleteChildren'>,
) {
  const highlights = await getDocuments<Highlight>(documentIds);
  if (!highlights) {
    logger.error('No such highlights to delete', { documentIds });
    return;
  }
  highlights.forEach(cleanupReaderFileForHighlight);
  await deleteDocumentsById(documentIds, {
    ...options,
    eventName: 'highlights-deleted',
    shouldDeleteChildren: true,
  });
}

export async function deleteHighlightNote(
  documentId: BaseDocument['id'],
  options: Omit<Parameters<typeof deleteDocumentById>[1], 'eventName' | 'shouldDeleteChildren'>,
): StateUpdateResult {
  return deleteDocumentById(documentId, {
    ...options,
    shouldDeleteChildren: false,
    eventName: 'note-deleted',
  });
}
