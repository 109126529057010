import { Core } from '@pdftron/webviewer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import { useDocument, useIsPDFViewAsHTML } from '../../../../shared/foreground/stateHooks';
import { fetchDocumentContent } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/documentContent';
import { toggleZenMode } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { hideLeftReaderViewSidebar, hideReaderViewSidebars, hideRightReaderViewSidebar } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import type { AnyDocument, DocumentWithTransientData, Pdf } from '../../../../shared/types';
import { Category, ShortcutId } from '../../../../shared/types';
import {
  isDocumentWithParsedDocId,
  isDocumentWithPublishedDate,
  isDocumentWithUrl,
  isDocumentWithWordCount,
  isFirstClassDocument,
  isYouTubeUrl,
} from '../../../../shared/typeValidators';
import formatPublishedDate from '../../../../shared/utils/dates/formatPublishedDate';
import getDocumentAuthor from '../../../../shared/utils/getDocumentAuthor';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import { printDocumentContent } from '../../../../shared/utils/printDocumentContent';
import { AdaptiveHeaderContext } from '../../contexts';
import { useAppearanceStyleHotkeys } from '../../hooks/appearanceStyles';
import { useHotKeys, useIsLeftSidebarHidden } from '../../hooks/hooks';
import { useShortcutsMap } from '../../utils/shortcuts';
import updateDocumentTitle from '../../utils/updateDocumentTitle';
import AdaptiveHeader, { useHeaderIsHidden, useVideoHeaderIsHidden } from '../AdaptiveHeader';
import { ArticleAside, PDFAside } from '../Aside';
import { DocumentContent } from '../DocumentContent';
import EpubTableOfContents from '../EpubTableOfContents';
import { PdfTronContext, PDFViewer } from '../PDFViewer';
import TableOfContents from '../TableOfContents';
import styles from './DocumentReader.module.css';

type DocumentReaderProps = {
  docId?: string;
  documentPathPrefix: string;
  inboxDocumentIds: AnyDocument['id'][];
  parentPath: string;
  scrollableAncestorRef: React.MutableRefObject<HTMLElement>;
  onEndThresholdReached?: () => void;
};

export default function DocumentReader ({
  docId = '',
  documentPathPrefix,
  inboxDocumentIds,
  parentPath,
  scrollableAncestorRef,
  onEndThresholdReached,
}: DocumentReaderProps) {
  const hideLeftPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideLeftPanelOnEnteringReadingView, []));
  const hideRightPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideRightPanelOnEnteringReadingView, []));
  const hidePanelsOnEnteringReadingView = useMemo(() => hideLeftPanelOnEnteringReadingView && hideRightPanelOnEnteringReadingView, [hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView]);
  const [contentRoot, setContentRoot] = useState<HTMLDivElement | null>(null);
  const [currentFocusedElement, setCurrentFocusedElement] = useState<HTMLElement | null>(null);
  const [currentDoc, { isFetching: isFetchingDoc }] = useDocument(docId);

  // Try to fetch more docs if we are reading the last one in the list
  useEffect(() => {
    const lastDocId = inboxDocumentIds[inboxDocumentIds.length - 1];
    const isLastDoc = lastDocId === docId;

    if (isLastDoc && onEndThresholdReached) {
      onEndThresholdReached();
    }
  }, [inboxDocumentIds, docId, onEndThresholdReached]);

  useEffect(() => {
    if (hidePanelsOnEnteringReadingView) {
      hideReaderViewSidebars(true, { userInteraction: 'unknown' });
      return;
    }

    if (hideLeftPanelOnEnteringReadingView) {
      hideLeftReaderViewSidebar(true, { userInteraction: 'unknown' });
    }

    if (hideRightPanelOnEnteringReadingView) {
      hideRightReaderViewSidebar(true, { userInteraction: 'unknown' });
    }
  }, [docId, hidePanelsOnEnteringReadingView, hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView]);

  const leftSidebarHidden = useIsLeftSidebarHidden();

  const isPDF = currentDoc?.category === Category.PDF;
  const showHTMLContentForPDF = useIsPDFViewAsHTML(currentDoc?.id);
  const isPDFView = useMemo(() => isPDF && !showHTMLContentForPDF, [isPDF, showHTMLContentForPDF]);

  const isDocMoreActionsDropdownOpen = globalState(useCallback((state) => state.isDocMoreActionsDropdownOpen, []));
  const isYouTube = useMemo(() => currentDoc && isDocumentWithUrl(currentDoc) && currentDoc?.url ? isYouTubeUrl(currentDoc.url) && !isDocMoreActionsDropdownOpen : false, [currentDoc, isDocMoreActionsDropdownOpen]);

  const isDistributable = Boolean(currentDoc && !currentDoc.non_distributable);

  // Load content in case it hasn't been preloaded already, or in the case that the document's is parsed_doc_id newly set
  useEffect(() => {
    if (!docId) {
      return;
    }
    fetchDocumentContent([docId]);
  }, [docId, currentDoc?.parsed_doc_id]);

  const shortcutsMap = useShortcutsMap();

  useHotKeys(
    shortcutsMap[ShortcutId.ToggleZenMode],
    useCallback(async () => {
      await toggleZenMode({ userInteraction: 'keypress' });
    }, []),
    { description: 'Toggle focus mode' },
  );

  useAppearanceStyleHotkeys(isPDFView);

  const onRendered = useCallback((element: HTMLDivElement | null) => {
    setContentRoot(element);
  }, []);

  const onNewFocusTarget = useCallback((target: HTMLElement | void) => {
    setCurrentFocusedElement(target || null);
  }, []);

  const tableOfContents = useMemo(() => {
    if (Boolean(docId) && contentRoot) {
      if (currentDoc?.category === Category.EPUB) {
        return <EpubTableOfContents
          contentRoot={contentRoot}
          tocItems={currentDoc?.source_specific_data?.epub?.toc ?? []}
          currentFocusedElement={currentFocusedElement} />;
      } else {
        return <TableOfContents contentRoot={contentRoot} currentFocusedElement={currentFocusedElement} />;
      }
    } else {
      return null;
    }
  }, [docId, contentRoot, currentDoc?.category, currentDoc?.source_specific_data?.epub?.toc, currentFocusedElement]);

  const title = useMemo(() => getDocumentTitle(currentDoc), [currentDoc]);
  const author = useMemo(() => getDocumentAuthor(currentDoc) || '', [currentDoc]);
  const publishedDate = useMemo(() => currentDoc && isDocumentWithPublishedDate(currentDoc) && currentDoc.published_date
    ? formatPublishedDate(currentDoc.published_date)
    : '', [currentDoc]);

  useEffect(() => {
    if (currentDoc) {
      updateDocumentTitle(title);
    }
  }, [currentDoc, title]);

  const getWordCount = () => {
    if (currentDoc && isDocumentWithWordCount(currentDoc) && currentDoc.word_count) {
      return currentDoc.word_count;
    }

    return 0;
  };

  const getParsedDocId = () => {
    if (currentDoc && isDocumentWithParsedDocId(currentDoc) && currentDoc.parsed_doc_id) {
      return currentDoc.parsed_doc_id?.toString();
    }

    return undefined;
  };

  const [pdfBookmarks, setPdfBookmarks] = useState<Core.Bookmark[]>([]);
  const [customPDFPage, setCustomPDFPage] = useState<number | undefined>(undefined);

  useEffect(() => {
    setCustomPDFPage(undefined);
  }, [docId]);

  useHotKeys(
    shortcutsMap[ShortcutId.PrintDocument],
    useCallback((e) => {
      if (isPDFView) {
        return;
      }

      e.preventDefault();

      if (!isDistributable) {
        return;
      }

      printDocumentContent({ docTitle: title, author, publishedDate });
    }, [isPDFView, isDistributable, title, author, publishedDate]),
    { description: 'Print document with annotations' },
  );

  const aside = useMemo(() => {
    if (isPDFView) {
      return <PDFAside
        currentDoc={currentDoc}
        documentPathPrefix={documentPathPrefix}
        inboxDocumentIds={inboxDocumentIds}
        onBookmarkClicked={setCustomPDFPage}
        parentPath={parentPath}
        pdfBookmarks={pdfBookmarks}
        sidebarsHidden={leftSidebarHidden}
        leftSidebarHidden={leftSidebarHidden}
      />;
    } else {
      return <ArticleAside
        currentDoc={currentDoc}
        documentPathPrefix={documentPathPrefix}
        inboxDocumentIds={inboxDocumentIds}
        parentPath={parentPath}
        sidebarsHidden={leftSidebarHidden}
        tableOfContents={tableOfContents}
      />;
    }
  }, [isPDFView, currentDoc, documentPathPrefix, inboxDocumentIds, parentPath, pdfBookmarks, leftSidebarHidden, tableOfContents]);

  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [documentViewer, setDocumentViewer] = useState<Core.DocumentViewer>();
  const pdfTronContextValue = useMemo(() => ({ documentViewer, setDocumentViewer, documentLoaded, setDocumentLoaded }), [documentViewer, setDocumentViewer, documentLoaded, setDocumentLoaded]);

  const { headerIsHidden, setHeaderIsHidden, isBorderVisible } = useHeaderIsHidden({ scrollableAncestorRef });
  const [videoHeaderIsHidden, setVideoHeaderIsHidden] = useVideoHeaderIsHidden();
  const headerContextValue = useMemo(() => ({
    headerIsHidden: isYouTube ? videoHeaderIsHidden : headerIsHidden,
    setHeaderIsHidden: isYouTube ? setVideoHeaderIsHidden : setHeaderIsHidden,
    isBorderVisible,
  }), [headerIsHidden, setHeaderIsHidden, videoHeaderIsHidden, setVideoHeaderIsHidden, isYouTube, isBorderVisible]);

  return (
    <AdaptiveHeaderContext.Provider value={headerContextValue}>
      <PdfTronContext.Provider value={pdfTronContextValue}>
        <div className={styles.root}>
          {docId && aside}
          <div className={`${styles.main} ${leftSidebarHidden ? styles.sidebarsHidden : ''}`}>
            {docId && currentDoc && isFirstClassDocument(currentDoc) &&
              <AdaptiveHeader doc={currentDoc} parentPath={parentPath} documentPathPrefix={documentPathPrefix} listedDocumentIds={inboxDocumentIds} /> || null
            }

            {!isPDFView && docId &&
              <DocumentContent
                docId={docId}
                currentDoc={currentDoc}
                isFetchingDoc={isFetchingDoc}
                onRendered={onRendered}
                wordCount={getWordCount()}
                scrollableAncestorRef={scrollableAncestorRef}
                onNewFocusTarget={onNewFocusTarget}
              /> || null
            }

            <PDFViewer
              docId={docId}
              isPDF={isPDFView}
              currentDoc={currentDoc as DocumentWithTransientData<Pdf>}
              setPdfBookmarks={setPdfBookmarks}
              customPage={customPDFPage}
              scrollableAncestorRef={scrollableAncestorRef}
              parsedDocId={getParsedDocId()}
              readingPosition={currentDoc?.readingPosition}
              currentScrollPosition={currentDoc?.currentScrollPosition}
            />
          </div>
        </div>
      </PdfTronContext.Provider>
    </AdaptiveHeaderContext.Provider>
  );
}
