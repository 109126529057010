import React, { useCallback, useMemo } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import {
  Category,
  DisplayTheme,
} from '../../../../shared/types';
import getRandomItem from '../../../../shared/utils/getRandomItem';
import galleryBackgroundDark1 from './placeholder-bg-dark-1.png';
import galleryBackgroundDark2 from './placeholder-bg-dark-2.png';
import galleryBackgroundDark3 from './placeholder-bg-dark-3.png';
import galleryBackgroundDark4 from './placeholder-bg-dark-4.png';
import galleryBackgroundLight1 from './placeholder-bg-light-1.png';
import galleryBackgroundLight2 from './placeholder-bg-light-2.png';
import galleryBackgroundLight3 from './placeholder-bg-light-3.png';
import galleryBackgroundLight4 from './placeholder-bg-light-4.png';
import PlaceholderArticlesIcon from './PlaceholderArticlesIcon';
import PlaceholderEmailsIcon from './PlaceholderEmailsIcon';
import PlaceholderEpubsIcon from './PlaceholderEpubsIcon';
import PlaceholderFeedsIcon from './PlaceholderFeedsIcon';
import PlaceholderPdfsIcon from './PlaceholderPdfsIcon';

export default function usePlaceholderImage(docCategory: Category, hasImageUrl: boolean) {
  const webEffectiveTheme = globalState(useCallback((state) => state.webEffectiveTheme, []));
  const isDarkMode = useMemo(() => webEffectiveTheme === DisplayTheme.Dark, [webEffectiveTheme]);
  const placeholderBackgroundImage = useMemo(() => getRandomItem(
    isDarkMode
    ? [
      galleryBackgroundDark1,
      galleryBackgroundDark2,
      galleryBackgroundDark3,
      galleryBackgroundDark4,
    ]
    : [
      galleryBackgroundLight1,
      galleryBackgroundLight2,
      galleryBackgroundLight3,
      galleryBackgroundLight4,
    ],
  ), [isDarkMode]);

  const categoryIconMap = useMemo(() => ({
    [Category.Article]: <PlaceholderArticlesIcon />,
    [Category.Email]: <PlaceholderEmailsIcon />,
    [Category.RSS]: <PlaceholderFeedsIcon />,
    [Category.PDF]: <PlaceholderPdfsIcon />,
    [Category.EPUB]: <PlaceholderEpubsIcon />,
    [Category.Tweet]: <PlaceholderArticlesIcon />,
  }), []);

  const placeholderIcon = hasImageUrl ? null : categoryIconMap[docCategory];

  return { placeholderIcon, placeholderBackgroundImage };
}
