import Tippy from '@tippyjs/react';
import React from 'react';
import type { Props as TippyProps } from 'tippy.js';

import { isExtension } from '../../../shared/utils/environment';
import { getDisplayShortcutKeys } from '../../../shared/utils/shortcuts';
import KeyboardIcon from './icons/KeyboardIcon';
import styles from './Tooltip.module.css';

export default function Tooltip ({ children, content, shortcut = '', disabled = false, placement = 'top', offset = [0, 10], maxWidth, ...rest }: {children: JSX.Element; content: string | JSX.Element; shortcut?: string | string[]; disabled?: boolean; maxWidth?: TippyProps['maxWidth']; placement?: TippyProps['placement'] | string; offset?: TippyProps['offset']; }): JSX.Element {
  const getContent = () => {
    const hasShortcut = Array.isArray(shortcut) ? shortcut.length > 0 : Boolean(shortcut);
    if (hasShortcut && !isExtension) {
      return (
        <div className={styles.tooltip}>
          {content}
          <span className={styles.shortcut}>
            <KeyboardIcon text="" /> {getDisplayShortcutKeys(shortcut)}
          </span>
        </div>
      );
    }

    return <div className={styles.tooltip}>{content}</div>;
  };

  if (!content) {
    return children;
  }

  return (
    <Tippy
      allowHTML
      arrow={false}
      content={getContent()}
      disabled={disabled}
      offset={offset}
      placement={placement as TippyProps['placement']}
      maxWidth={maxWidth as TippyProps['maxWidth']}
      {...rest}>
      {children}
    </Tippy>
  );
}
