import React from 'react';

import { Dialog } from './Dialog';


export const DeleteViewDialog = ({ isOpen, onConfirm, onCancel }: {isOpen: boolean; onConfirm: () => void; onCancel: () => void;}) => {
  if (!isOpen) {
    return null;
  }

  return <div>
    <Dialog
      id="delete-view"
      title="Permanently delete this filtered view?"
      subtitle="If you click Delete, none of your documents will be deleted, but the filter will be permanently removed and need to be manually recreated."
      actionTitle="Delete"
      cancelTitle="Cancel"
      redActionButton
      action={onConfirm}
      cancelAction={onCancel}
    />
  </div>;
};
