import type { Highlight } from '../types';
import { Category, ContentParsingStatus, ContentRequestLoadingStatus } from '../types';
import nowTimestamp from '../utils/dates/nowTimestamp';
import { isWebApp } from '../utils/environment';
import { upsertDocument } from './stateUpdaters/persistentStateUpdaters/documents/create';
import removeFakePDFLineBreaks from './utils/removeFakePDFLineBreaks';

interface CreatePDFHighlightParameters {
  highlightId: Highlight['id'];
  highlightText: Highlight['content'];
  offset: Highlight['offset'];
  parentId: string;
  pdfData: string;
  pdfId: string;
  pdfPageNum: number;
  readerFileId?: string;
  imgUrl?: string;
}

export const createPDFHighlight = async ({ highlightId, highlightText, offset, parentId, pdfData, pdfId, pdfPageNum, readerFileId, imgUrl }: CreatePDFHighlightParameters) => {
  const savedAt = nowTimestamp();

  // FYI, we are only calling removeFakeLineBreaks on web because there is a bug
  // on PDFTron mobile lib where the highlight text doesn't have correct line breaks.
  // More info: https://github.com/readwiseio/rekindled/pull/2753#issuecomment-1677299763
  const highlightTextWithOnlyRealLineBreaks = isWebApp ? removeFakePDFLineBreaks(pdfData) || highlightText : highlightText;
  const highlight: Highlight = {
    category: Category.Highlight,
    children: [],
    html: imgUrl ? `<img src="${imgUrl}"/>` : highlightTextWithOnlyRealLineBreaks || '',
    content: highlightTextWithOnlyRealLineBreaks,
    location: '',
    markdown: imgUrl ? `![](${imgUrl})` : highlightTextWithOnlyRealLineBreaks || '',
    parent: parentId,
    offset,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    saved_at: savedAt,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    saved_at_history: [savedAt],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    source_specific_data: { pdf_highlight: { data: pdfData, id: pdfId, page: pdfPageNum, reader_file_id: readerFileId } },
    id: highlightId,
  };
  await upsertDocument(
    highlight,
    {
      contentRequestLoadingStatus: ContentRequestLoadingStatus.Loaded,
      contentParsingStatus: ContentParsingStatus.Success,
    },
    {
      eventName: 'highlight-created',
      userInteraction: 'unknown',
    },
  );
  return highlight;
};
