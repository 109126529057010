import { AnyDocument, PartialDocument } from '../types';
import { isDocumentWithPublishedDate } from '../typeValidators';
import getDocumentOverrideOrReal from './getDocumentOverrideOrReal';

export default (doc: AnyDocument | PartialDocument<AnyDocument, 'published_date' | 'category' | 'overrides'>): number | undefined => {
  if (!doc || !isDocumentWithPublishedDate(doc)) {
    return undefined;
  }

  const publishedDate = getDocumentOverrideOrReal(doc, 'published_date');
  if (publishedDate === '') {
    return undefined;
  }
  return publishedDate;
};
