import { type DocumentWithUrl } from '../types';
import { getDocument } from './stateGetters';
import { createToast } from './toasts.platform';
import { copyTextToClipboard } from './utils/copyTextToClipboard';

export default async (documentId: DocumentWithUrl['id']): Promise<void> => {
  const doc = await getDocument<DocumentWithUrl>(documentId);

  if (!doc) {
    return;
  }
  const isDistributable = Boolean(doc && !doc.non_distributable);
  if (!isDistributable) {
    createToast({ content: 'This action is not supported for this document', category: 'info' });
    return;
  }

  // TODO: have platform-specific code to make this also work on mobile...
  if (doc && doc.url) {
    copyTextToClipboard(doc.url);
    createToast({ content: 'Copied URL to clipboard', category: 'success' });
  }
};
