// Note: by default this returns elements only, not nodes

export default function getSiblings ({
  direction,
  element,
  matcher,
  shouldStopOnFirstFailedMatch,
  shouldIncludeNonElements,
}: {
  direction: 'next' | 'previous';
  element: Node;
  matcher?: (node: Node) => boolean;
  shouldStopOnFirstFailedMatch?: boolean;
  shouldIncludeNonElements: true;
}): {
  firstFailedMatch: Node | null;
  siblings: Node[];
};

export default function getSiblings ({
  direction,
  element,
  matcher,
  shouldStopOnFirstFailedMatch,
  shouldIncludeNonElements,
}: {
  direction: 'next' | 'previous';
  element: Element;
  matcher?: (node: Element) => boolean;
  shouldStopOnFirstFailedMatch?: boolean;
  shouldIncludeNonElements?: false;
}): {
  firstFailedMatch: Element | null;
  siblings: Element[];
};

export default function getSiblings ({
  direction,
  element,
  matcher = () => true,
  shouldStopOnFirstFailedMatch,
  shouldIncludeNonElements,
}: {
  direction: 'next' | 'previous';
  element: Node | Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matcher?: (node: any) => boolean;
  shouldStopOnFirstFailedMatch?: boolean;
  shouldIncludeNonElements?: boolean;
}): {
  firstFailedMatch: Node | Element | null;
  siblings: Node[] | Element[];
} {
  const results = [];
  let firstFailedMatch: ChildNode | Element | null = null;
  const siblingKey = `${direction}${shouldIncludeNonElements ? '' : 'Element'}Sibling`;

  let sibling = element[siblingKey] as typeof element.previousSibling;
  while (sibling) {
    if (matcher(sibling)) {
      results.push(sibling);
    } else if (!firstFailedMatch) {
      firstFailedMatch = sibling;
      if (shouldStopOnFirstFailedMatch) {
        break;
      }
    }
    sibling = sibling[siblingKey];
  }

  return {
    firstFailedMatch,
    siblings: results,
  };
}

// export default getSiblings;
