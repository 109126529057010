import React from 'react';

import Icon from './Icon';

export default function StrokeLinkIcon({ className = '', text = 'Link' }: {className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.52201 3.59359C11.4433 1.71919 14.5014 1.72509 16.3831 3.60684C18.2649 5.48858 18.2708 8.54666 16.3964 10.468L16.3898 10.4747L14.8893 11.9752C14.5952 12.2693 14.1203 12.2714 13.8287 11.9797C13.537 11.6881 13.5391 11.2132 13.8332 10.9191L15.3302 9.42204C16.6256 8.09082 16.6205 5.97462 15.3179 4.67205C14.0154 3.36948 11.8992 3.36435 10.5679 4.65975L9.07094 6.15679C8.7768 6.45095 8.30193 6.453 8.01029 6.16137C7.71866 5.86974 7.72069 5.39487 8.01484 5.10072L9.52201 3.59359Z" fill="#616C77" />
      <path d="M12.5733 7.41656C12.865 7.70819 12.8629 8.18306 12.5688 8.47721L8.54859 12.4974C8.25444 12.7915 7.77957 12.7936 7.48794 12.5019C7.19631 12.2103 7.19835 11.7354 7.4925 11.4413L11.5127 7.42112C11.8068 7.12697 12.2817 7.12493 12.5733 7.41656Z" fill="#616C77" />
      <path d="M6.16689 9.08107C6.46105 8.78693 6.4631 8.31206 6.17147 8.02042C5.87984 7.72879 5.40497 7.73083 5.11082 8.02497L3.61026 9.52541L3.60369 9.53214C1.72929 11.4534 1.7352 14.5115 3.61694 16.3933C5.49868 18.275 8.5568 18.281 10.4781 16.4066L11.9853 14.8994C12.2794 14.6053 12.2815 14.1304 11.9898 13.8388C11.6982 13.5472 11.2233 13.5492 10.9292 13.8433L9.43215 15.3403C8.10092 16.6358 5.98472 16.6306 4.68215 15.3281C3.37958 14.0255 3.37445 11.9093 4.66986 10.5781L6.16689 9.08107Z" fill="#616C77" />
    </svg>
  </Icon>;
}
