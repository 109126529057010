import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback } from 'react';

import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { globalState } from '../../../../shared/foreground/models';
import { slowDownYtPlaybackRate, speedUpYtPlaybackRate, toggleYTAutoScroll } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/youtubePlayer';
import { setVideoSettingsPanelOpen, toggleVideoSettingsPanelOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { toggleYtVideoPlaying } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/youtubePlayer';
import { ShortcutId } from '../../../../shared/types';
import { useHotKeysPreventDefault } from '../../hooks/hooks';
import { useShortcutsMap } from '../../utils/shortcuts';
import { baseShortcuts } from '../../utils/shortcuts/defaultsByLayout';
import Button from '../Button';
import VideoIcon from '../icons/20SolidVideoIcon';
import Tooltip from '../Tooltip';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './DropdownVideoSettings.module.css';

export default function DropdownVideoSettings() {
  const shortcutsMap = useShortcutsMap();
  const isVideoSettingsPanelShown = globalState(useCallback((state) => state.isVideoSettingsPanelShown, []));

  useHotKeysPreventDefault(
    shortcutsMap[ShortcutId.ToggleVideoControls],
    useCallback(() => toggleVideoSettingsPanelOpen({ userInteraction: 'keyup' }), []),
    {
      description: 'Toggle video settings panel',
    },
  );

  const options = [{
    type: DropdownOptionType.Item,
    name: 'Stop or start playback',
    shortcut: baseShortcuts.Space,
    onSelect: () => {
      toggleYtVideoPlaying();
    },
  }, {
    type: DropdownOptionType.Item,
    name: 'Toggle autoscroll',
    shortcut: shortcutsMap[ShortcutId.ToggleYtAutoScroll],
    onSelect: () => {
      toggleYTAutoScroll({ userInteraction: 'keyup' });
    },
  }, {
    type: DropdownOptionType.Item,
    name: 'Seek to focused paragraph',
    shortcut: shortcutsMap[ShortcutId.CmdOrCtrlAndEnter],
    onSelect: () => {
      eventEmitter.emit('yt-seek-to-focused-paragraph');
    },
  }, {
    type: DropdownOptionType.Separator,
  }, {
    type: DropdownOptionType.Item,
    name: 'Speed up playback',
    shortcut: shortcutsMap[ShortcutId.SpeedUpPlayback],
    onSelect: () => {
      speedUpYtPlaybackRate();
    },
  }, {
    type: DropdownOptionType.Item,
    name: 'Slow down playback',
    shortcut: shortcutsMap[ShortcutId.SlowDownPlayBack],
    onSelect: () => {
      slowDownYtPlaybackRate();
    },
  }, {
    type: DropdownOptionType.Separator,
  }, {
    type: DropdownOptionType.Item,
    name: 'Skip forward 15s',
    shortcut: shortcutsMap[ShortcutId.SkipForward],
    onSelect: () => {
      eventEmitter.emit('yt-skip-forward');
    },
  }, {
    type: DropdownOptionType.Item,
    name: 'Skip backward 15s',
    shortcut: shortcutsMap[ShortcutId.SkipBackwards],
    onSelect: () => {
      eventEmitter.emit('yt-skip-backwards');
    },
  }];

  return (
    <Dropdown
      trigger={
        <Tooltip disabled={isVideoSettingsPanelShown} content="Video settings" shortcut={shortcutsMap[ShortcutId.ToggleVideoControls]}>
          <DropdownMenu.Trigger asChild>
            <Button
              className={styles.triggerElement}
              variant="unstyled">
              <VideoIcon />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
      }
      options={options}
      isOpen={isVideoSettingsPanelShown}
      setIsOpen={(v) => setVideoSettingsPanelOpen(v, { userInteraction: 'click' })}
      appendToDocumentBody
      contentClassName={styles.dropdownContent}
      alignOffset={-10}
    />
  );
}
