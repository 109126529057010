import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useHighlights } from '../../../shared/foreground/database/helperHooks';
import { globalState } from '../../../shared/foreground/models';
import {
  useDocumentContentFromState,
  useFaviconUrlFromDoc,
  useRssSourceNameForDoc,
} from '../../../shared/foreground/stateHooks';
import getCmdOrCtrl from '../../../shared/foreground/utils/getCmdOrCtrl';
import { AnyDocument, BaseDocument, Category, ContentParsingStatus, ContentRequestLoadingStatus, DocumentWithTransientData } from '../../../shared/types';
import { isDocumentWithThirdPartyUrl, isDocumentWithUrl, isYouTubeUrl } from '../../../shared/typeValidators';
import getDocumentAuthor from '../../../shared/utils/getDocumentAuthor';
import getDocumentPublishedDate from '../../../shared/utils/getDocumentPublishedDate';
import getDocumentTitle from '../../../shared/utils/getDocumentTitle';
import getUrlDomain from '../../../shared/utils/getUrlDomain';
import useLocation from '../utils/useLocation';
import styles from './DocumentContent.module.css';
import DocumentTextContent from './DocumentTextContent';
import TextLoadingIndicator from './TextLoadingIndicator';

export const DocumentContent = React.memo(function DocumentContent({ docId, currentDoc, isFetchingDoc, onRendered, scrollableAncestorRef, wordCount, onNewFocusTarget = () => null }: {
  docId: BaseDocument['id'];
  currentDoc: DocumentWithTransientData<AnyDocument> | null;
  isFetchingDoc: boolean;
  onRendered: (contentRoot: HTMLDivElement | null) => void;
  scrollableAncestorRef: React.MutableRefObject<HTMLElement>;
  wordCount: number;
  onNewFocusTarget?: (newTarget: HTMLElement | void) => void;
}): JSX.Element {
  const { content } = useDocumentContentFromState(docId);
  const highlights = useHighlights({
    parentDocId: docId,
    excludePDFHighlights: true,
  });
  const haveSomeDocumentContentItemsLoaded = globalState(useCallback((state) => state.haveSomeDocumentContentItemsLoaded, []));
  const isOnline = globalState(useCallback((state) => state.isOnline, []));
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const rssSourceName = useRssSourceNameForDoc(currentDoc);
  const faviconUrl = useFaviconUrlFromDoc(currentDoc);
  const [docExisted, setDocExisted] = useState(false);

  useEffect(() => {
    if (docExisted) {
      return;
    }
    if (currentDoc) {
      setDocExisted(true);
    }
  }, [currentDoc, docExisted]);

  useEffect(() => {
    if (!currentDoc || currentDoc.transientData.contentRequestLoadingStatus !== ContentRequestLoadingStatus.Loaded) {
      return;
    }
    urlSearchParams.delete('documentExists');
    history.replace({ search: urlSearchParams.toString() });
  }, [currentDoc, history, urlSearchParams]);


  const contentElementRef = useRef<HTMLDivElement | HTMLIFrameElement | HTMLParagraphElement>(null);
  useEffect(() => {
    onRendered(contentElementRef.current);
  }, [contentElementRef, onRendered, content]);

  const sourceUrl = useMemo(() => currentDoc && isDocumentWithUrl(currentDoc) ? currentDoc.url : undefined, [currentDoc]);

  const isYouTube = useMemo(() => {
    if (!sourceUrl) {
      return false;
    }

    return isYouTubeUrl(sourceUrl);
  }, [sourceUrl]);

  const rootProps = {
    className: `${styles.textContentWrapper} ${isYouTube ? styles.isYouTube : ''}`,
  };

  const classNameContentWithHeaderMargin = `${rootProps.className} ${styles.contentWithHeaderMargin}`;

  if (isFetchingDoc) {
    return <div ref={contentElementRef} {...rootProps} className={classNameContentWithHeaderMargin}>
      <TextLoadingIndicator ref={contentElementRef} />
    </div>;
  }

  if (!currentDoc) {
    if (urlSearchParams.get('documentExists') === 'true' || docExisted) {
      return <div {...rootProps} className={classNameContentWithHeaderMargin}><TextLoadingIndicator ref={contentElementRef} /></div>;
    }

    return <div {...rootProps} className={classNameContentWithHeaderMargin}>
      <h1>Document not found</h1>
      <p>Either this document hasn&apos;t quite loaded yet, or you might have previously deleted it.</p>
      <p>Any issues? Hit {getCmdOrCtrl()}+k → feedback to let us know and we&apos;ll be in touch ASAP!</p>
    </div>;
  }

  if (currentDoc.transientData.contentRequestLoadingStatus === ContentRequestLoadingStatus.Failed ||
    !isOnline && currentDoc.transientData.contentRequestLoadingStatus === ContentRequestLoadingStatus.Unloaded) {
    const additionalText = isOnline
      ? "Hold on, we're trying again..."
      : 'You are currently offline.';
    const textLoadingIndicator = isOnline ? <TextLoadingIndicator ref={contentElementRef} /> : null;

    return <div ref={contentElementRef} {...rootProps} className={classNameContentWithHeaderMargin}>
      <p ref={contentElementRef}>Uh oh! We failed to load the document. {additionalText}</p>
      {textLoadingIndicator}
    </div>;
  }

  if ([ContentParsingStatus.Pending, ContentParsingStatus.ServerTaskNotStartedYet].includes(currentDoc.transientData.contentParsingStatus) && haveSomeDocumentContentItemsLoaded) {
    return <div ref={contentElementRef} {...rootProps} className={classNameContentWithHeaderMargin}>
      <p>Your document is taking longer than usual to parse into clean, distraction-free HTML... Please feel free to close this tab or document while the server catches up.</p>
      <TextLoadingIndicator ref={contentElementRef} />
    </div>;
  }

  if (!content && ([ContentRequestLoadingStatus.Loading, ContentRequestLoadingStatus.Unloaded].includes(currentDoc.transientData.contentRequestLoadingStatus) || !haveSomeDocumentContentItemsLoaded)) {
    return <div {...rootProps}><TextLoadingIndicator ref={contentElementRef} /></div>;
  }

  if (currentDoc.transientData.contentParsingStatus === ContentParsingStatus.Failed || !content) {
    if (currentDoc.category === Category.Article || currentDoc.category === Category.Video) {
      return <div {...rootProps} className={classNameContentWithHeaderMargin}>
      <p ref={contentElementRef}>
        <b>Uh oh! Reader couldn&apos;t parse this document.</b><br /> This could be because the original url no longer exists, the site blocks parsing, or our parsing has failed. <br /><br />See <a href={currentDoc.url} target="_blank" rel="noreferrer">the original page here</a>.
      </p>
      </div>;
    } else {
      return <div {...rootProps} className={classNameContentWithHeaderMargin}>
      <p ref={contentElementRef}>
        <b>Uh oh! Reader couldn&apos;t parse this document.</b><br /> This could be because the original url no longer exists, the site blocks parsing, or our parsing has failed..
      </p></div>;
    }
  }

  const title = getDocumentTitle(currentDoc);
  const url = isDocumentWithThirdPartyUrl(currentDoc) && currentDoc.url || undefined;
  const originUrl = url ? getUrlDomain(url) : undefined;
  const siteName = currentDoc.site_name;
  const author = getDocumentAuthor(currentDoc);
  const publishedDate = getDocumentPublishedDate(currentDoc);
  const readingPercent = currentDoc.readingPosition?.scrollDepth ? currentDoc.readingPosition.scrollDepth * 100 : 0;
  const scrollPercent = currentDoc.currentScrollPosition?.scrollDepth ? currentDoc.currentScrollPosition.scrollDepth * 100 : 0;

  return <div {...rootProps}>
    <DocumentTextContent
      author={author}
      category={currentDoc.category}
      content={content as string}
      currentScrollPosition={currentDoc.currentScrollPosition}
      docId={docId}
      faviconUrl={faviconUrl}
      highlights={highlights ?? []}
      languageCode={currentDoc.language}
      onNewFocusTarget={onNewFocusTarget}
      originUrl={originUrl}
      publishedDate={publishedDate}
      readingPercent={readingPercent}
      readingPosition={currentDoc.readingPosition}
      ref={contentElementRef}
      rssSourceName={rssSourceName}
      scrollableAncestorRef={scrollableAncestorRef}
      scrollPercent={scrollPercent}
      siteName={siteName}
      sourceSpecificData={currentDoc.source_specific_data}
      sourceUrl={sourceUrl}
      tags={currentDoc.tags}
      title={title}
      wordCount={wordCount}
    />
  </div>;
});
