import isEqual from 'lodash/isEqual';
import { MangoQuerySelectorAndIndex } from 'rxdb/dist/types/types';

import {
  type AnyDocument,
  type EmptyStateCategory,
  type MobileFocusedListState,
} from '../../../types';
import {
  CancelStateUpdate,
  updatePropertyInState,
  updateState,
} from '../../models';
import { StateUpdateOptionsWithoutEventName } from '../../types';

export const setDocumentsListScrolled = async (documentListScrolled: boolean): Promise<void> => {
  await updateState((state) => {
    if (state.documentsListScrolled === documentListScrolled) {
      throw new CancelStateUpdate();
    }
    state.documentsListScrolled = documentListScrolled;
  }, {
    eventName: 'documents-list-scrolled-updated',
    shouldCreateUserEvent: false, // we don't care when this happens
    userInteraction: 'scroll',
    isUndoable: false,
  });
};

export const setInboxZero = async (inboxZero: boolean, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    if (state.isInboxZero === inboxZero) {
      throw new CancelStateUpdate();
    }
    state.isInboxZero = inboxZero;
  }, { ...options, isUndoable: false, shouldCreateUserEvent: false, eventName: 'set-inbox-zero' });
};

export const setEmptyStateCategory = async (category: EmptyStateCategory | null, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    state.emptyStateCategory = category;
  }, { ...options, isUndoable: false, shouldCreateUserEvent: false, eventName: 'set-empty-state-category' });
};

export function setFocusedDocumentListQuery(query: MangoQuerySelectorAndIndex<AnyDocument> | null) {
  return updatePropertyInState(
    'focusedDocumentListQuery',
    query,
    {
      eventName: 'current-document-ids-list-query-updated',
      userInteraction: 'unknown',
      isUndoable: false,
      shouldCreateUserEvent: false,
    },
  );
}

export const updateMobileCurrentFocusedDocumentListId = async (listId: string) => {
  await updateState((state) => {
    if (state.mobileCurrentFocusedDocumentListId === listId) {
      throw new CancelStateUpdate();
    }
    state.mobileCurrentFocusedDocumentListId = listId;
  }, {
    eventName: 'current-focused-document-list-updated',
    userInteraction: 'unknown',
    isUndoable: false,
    shouldCreateUserEvent: false,
  });
};

export const updateFocusedMobileListState = async ({
  isFeedView,
  isFilteredView,
  savedFilterView,
  routeName,
  filterQuery,
}: MobileFocusedListState) => {
  await updateState((state) => {
    const newState = {
      isFeedView,
      isFilteredView,
      savedFilterView,
      routeName,
      filterQuery,
    };
    if (isEqual(state.mobileFocusedListState, newState)) {
      throw new CancelStateUpdate();
    }
    state.mobileFocusedListState = newState;
  }, {
    eventName: 'mobile-focused-list-state-updated',
    userInteraction: 'unknown',
    isUndoable: false,
    shouldCreateUserEvent: false,
  });
};

export const updateFocusedMobileListStateFilteredView = async ({
  savedFilteredView,
}: {savedFilteredView: MobileFocusedListState['savedFilterView'];}) => {
  await updateState((state) => {
    if (isEqual(state.mobileFocusedListState.savedFilterView, savedFilteredView)) {
      throw new CancelStateUpdate();
    }
    state.mobileFocusedListState.savedFilterView = savedFilteredView;
  }, {
    eventName: 'mobile-focused-list-state-saved-filtered-view-updated',
    userInteraction: 'unknown',
    isUndoable: false,
    shouldCreateUserEvent: false,
  });
};
