import React from 'react';

import Icon from './Icon';

export default function LargePauseIcon({ className, text = 'Pause' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 10C13.8954 10 13 10.8954 13 12V32C13 33.1046 13.8954 34 15 34H18C19.1046 34 20 33.1046 20 32V12C20 10.8954 19.1046 10 18 10H15Z" fill="currentColor" />
      <path d="M26 10C24.8954 10 24 10.8954 24 12V32C24 33.1046 24.8954 34 26 34H29C30.1046 34 31 33.1046 31 32V12C31 10.8954 30.1046 10 29 10H26Z" fill="currentColor" />
    </svg>
  </Icon>;
}
