import eventEmitter from '../../../shared/foreground/eventEmitter';
import { hideRightSidebar } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import { RightSidebarVisiblePanel } from '../../../shared/types';
import delay from '../../../shared/utils/delay';

export default async function focusDocumentNoteField(userInteraction = 'unknown') {
  hideRightSidebar(false, { userInteraction });
  await delay(1);
  eventEmitter.emit('set-visible-sidebar-panel', RightSidebarVisiblePanel.DocumentNotebook);
  await delay(5);
  eventEmitter.emit('focus-doc-note');
}
