import React from 'react';

import Icon from './Icon';

export default function VideosIconHeader({ className = '', text = 'Video' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5.5C10.8284 5.5 11.5 6.17157 11.5 7V13C11.5 13.8284 10.8284 14.5 10 14.5H4C3.17157 14.5 2.5 13.8284 2.5 13V7C2.5 6.17157 3.17157 5.5 4 5.5H10ZM4 4C2.34315 4 1 5.34315 1 7V13C1 14.6569 2.34315 16 4 16H10C11.6569 16 13 14.6569 13 13V7C13 5.34315 11.6569 4 10 4H4Z" />
      <path d="M15.5 8.42705L17.5 7.42705V12.5729L15.5 11.5729V8.42705ZM14.5528 7.22361C14.214 7.393 14 7.73926 14 8.11803V11.882C14 12.2607 14.214 12.607 14.5528 12.7764L17.5528 14.2764C18.2177 14.6088 19 14.1253 19 13.382V6.61803C19 5.87465 18.2177 5.39116 17.5528 5.72361L14.5528 7.22361Z" />
    </svg>
  </Icon>;
}
