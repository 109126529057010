import get from 'lodash/get';
import pick from 'lodash/pick';
import { ulid } from 'ulid';

import { FilteredView, JobType, SplitByKey, UserEvent } from '../../../types';
import nowTimestamp from '../../../utils/dates/nowTimestamp';
import { DEFAULT_DOCUMENT_SORT_RULES, updateState } from '../../models';
import { removeFilteredViewFromState, saveFilteredViewToState } from '../../stateUpdateHelpers';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import { queueJob } from './jobs';

export const mergeFilteredViews = async ({ viewIds, viewName, options }: {viewIds: FilteredView['id'][]; viewName: string; options: StateUpdateOptionsWithoutEventName & { showToast?: boolean; };}): Promise<void> => {
  const updateResult = await updateState((state) => {
    const queries = [];

    for (const viewId of viewIds) {
      if (state.persistent.filteredViews) {
        queries.push(`(${state.persistent.filteredViews[viewId].query})`);
      }

      removeFilteredViewFromState(state, viewId);
    }

    const newView = {
      name: viewName,
      description: '',
      query: queries.join(' OR '),
    };

    saveFilteredViewToState(state, newView);
  }, { ...options, eventName: 'filtered-views-merged' });

  createToast({
    content: 'Views merged',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};


export const saveFilteredView = async (filter: Omit<FilteredView, 'id' | 'created' | 'sortRules'>, pathname: string, options: StateUpdateOptionsWithoutEventName): Promise<string> => {
  const id = ulid().toLowerCase();
  let splitBy: SplitByKey;

  if (pathname.includes('/split/')) {
    splitBy = pathname.split('/split/')[1].split('/')[0] as SplitByKey;
  }

  const updateResult = await updateState((state) => {
    state.persistent.filteredViews[id] = {
      ...filter,
      id,
      created: nowTimestamp(),
      order: Infinity,
      sortRules: [{
        ...DEFAULT_DOCUMENT_SORT_RULES.filterView[0],
        id: ulid().toLowerCase(),
      }],
    };

    if (splitBy) {
      state.persistent.filteredViews[id].splitBy = splitBy;
    }
  }, { ...options, eventName: 'filtered-view-saved' });

  createToast({
    content: 'Filtered view saved',
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });

  return id;
};

export const updateFilteredViews = async (filteredViews: FilteredView[], options: StateUpdateOptionsWithoutEventName & {
  showToast?: boolean;
}): Promise<void> => {
  const propsChangedToTriggerBundleUpdates = ['name', 'description', 'extraData.bundleCoverImageURL'];
  const viewsIdsToUpdateBundle = new Set<string>();

  const updateResult = await updateState((state) => {
    for (const filteredView of filteredViews) {
      // E.g when removing an associated feed from a view the query could be empty
      // if that was the only thing the query was doing. So we make sure we delete that view.
      if (filteredView.query === '') {
        removeFilteredViewFromState(state, filteredView.id);
      } else {
        const currentView = state.persistent.filteredViews[filteredView.id];
        const updatedFilteredView = {
          ...currentView,
          ...pick(filteredView, ['name', 'description', 'icon', 'query', 'sortRules', 'splitBy', 'isUnpinned', 'showCountBadge', 'sharedAsBundle', 'extraData']),
        };

        if (updatedFilteredView.sharedAsBundle) {
          propsChangedToTriggerBundleUpdates.forEach((prop) => {
            if (get(currentView, prop) !== get(updateFilteredView, prop)) {
              viewsIdsToUpdateBundle.add(filteredView.id);
            }
          });
        }

        if (!updatedFilteredView.splitBy) {
          delete updatedFilteredView.splitBy;
        }

        state.persistent.filteredViews[filteredView.id] = updatedFilteredView;
      }
    }
  }, { ...options, eventName: 'filtered-view-updated' });

  if (options.showToast) {
    createToast({
      content: 'Filtered view updated',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }

  Array.from(viewsIdsToUpdateBundle).forEach((viewId) => {
    queueJob({
      jobType: JobType.UpdateBundle,
      jobArguments: { filtered_view_id: viewId, enabled: true },
      options: { userInteraction: 'click' },
    });
  });
};

export const updateFilteredView = async (filteredView: FilteredView, options: StateUpdateOptionsWithoutEventName & {
  showToast?: boolean;
}): Promise<void> => {
  updateFilteredViews([filteredView], options);
};

export const updateFilteredViewsOrder = async (filteredViews: Pick<FilteredView, 'id' | 'order'>[], options: StateUpdateOptionsWithoutEventName & {
  showToast?: boolean;
}): Promise<void> => {
  const updateResult = await updateState((state) => {
    filteredViews.forEach((filteredView) => {
      state.persistent.filteredViews[filteredView.id].order = filteredView.order;
    });
  }, { ...options, eventName: 'filtered-view-updated' });

  if (options.showToast) {
    createToast({
      content: 'Filtered view order updated',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

export const removeFilteredViews = async (viewIds: FilteredView['id'][], options: StateUpdateOptionsWithoutEventName & { showToast?: boolean; }): Promise<void> => {
  const viewsIdsToDisableBundle: string[] = [];

  const updateResult = await updateState((state) => {
    for (const viewId of viewIds) {
      const view = state.persistent.filteredViews[viewId];
      if (view.sharedAsBundle) {
        viewsIdsToDisableBundle.push(viewId);
      }
      removeFilteredViewFromState(state, viewId);
    }
  }, { ...options, eventName: 'filtered-view-removed' });


  for (const viewIdToDisableBundle of viewsIdsToDisableBundle) {
    queueJob({
      jobType: JobType.UpdateBundle,
      jobArguments: { filtered_view_id: viewIdToDisableBundle, enabled: false },
      options: { userInteraction: 'click' },
    });
  }

  if (options.showToast) {
    createToast({
      content: viewIds.length === 1 ? 'Filtered view removed' : `${viewIds.length} filtered views removed`,
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

export const removeFilteredView = async (viewId: FilteredView['id'], options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  removeFilteredViews([viewId], { ...options, showToast: true });
};
