import type { Highlight } from '../../types';
import type { HighlightElement } from '../types';
import isElementInRange from './isElementInRange';

type HighlightInfo = {
  id: Highlight['id'];
  elements: HighlightElement[];
};

export default (
  highlightInfos: HighlightInfo[],
  range: Range,
): HighlightInfo[] => {
  return highlightInfos.filter((highlight) => highlight.elements.some((element) => isElementInRange(element, range)));
};
