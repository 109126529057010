import type { FullZustandState } from '../../types';
import type { GlobalTag, GlobalTagsObject } from '../../types/tags';

export function compareTagsForSort({
  tagA,
  tagB,
  tagNamesUsedRecently,
}: {
  tagA: GlobalTag;
  tagB: GlobalTag;
  tagNamesUsedRecently: FullZustandState['tagNamesUsedRecently']; // Reverse-chronological
}): number {
  const indexOfTagAInTagNamesUsedRecently = tagNamesUsedRecently.indexOf(tagA.name);
  const indexOfTagBInTagNamesUsedRecently = tagNamesUsedRecently.indexOf(tagB.name);
  if (indexOfTagAInTagNamesUsedRecently > -1) {
    if (indexOfTagBInTagNamesUsedRecently === -1) {
      return -1;
    }
    // We're going to return here no matter what because tagNamesUsedRecently doesn't contain duplicates
    return indexOfTagAInTagNamesUsedRecently < indexOfTagBInTagNamesUsedRecently
      ? -1
      : 1;
  }
  if (indexOfTagBInTagNamesUsedRecently > -1) {
    return 1;
  }

  if (tagA.totalCount === tagB.totalCount) {
    return tagA.name.localeCompare(tagB.name);
  }

  return tagA.totalCount > tagB.totalCount ? -1 : 1;
}

export default function sortTags({
  tags,
  tagNamesUsedRecently,
}: {
  tags: GlobalTagsObject;
  tagNamesUsedRecently: FullZustandState['tagNamesUsedRecently']; // Reverse-chronological
}) {
  return Object.values(tags ?? {}).sort((tagA, tagB) => compareTagsForSort({
    tagA,
    tagB,
    tagNamesUsedRecently,
  }));
}
