import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { PointerEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PopperProps } from 'react-popper';
import { useHistory } from 'react-router-dom';

import { useHighlightNoteText } from '../../../../shared/foreground/database/helperHooks';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { useIsAutoHighlightingEnabled } from '../../../../shared/foreground/stateHooks/useIsAutoHighlightingEnabled';
import { setQuoteshotModalOpen } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/quoteshot';
import {
  deleteHighlight,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import {
  toggleIsAutoHighlightingEnabled,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/highlighterSettings';
import { setHighlightIdToOpenAt, setOpenedAnnotationBarPopoverHighlightId } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import getCmdOrCtrl from '../../../../shared/foreground/utils/getCmdOrCtrl';
import type { Highlight } from '../../../../shared/types';
import capitalize from '../../../../shared/utils/capitalize';
import { isExtension } from '../../../../shared/utils/environment';
import urlJoin from '../../../../shared/utils/urlJoin';
import getEventTarget from '../../utils/getEventTarget';
import Button from '../Button';
import NotebookHighlightDropdown from '../Dropdown/NotebookHighlightDropdown';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import CreateHighlightIcon from '../icons/CreateHighlightIcon';
import DeleteHighlightIcon from '../icons/DeleteHighlightIcon';
import EllipsisIcon from '../icons/EllipsisIcon';
// import EmojiIcon from '../../../web/src/components/icons/EmojiIcon';
import NoteIcon from '../icons/NoteIcon';
import TagIcon from '../icons/TagIcon';
import {
  copyHighlightContent,
  invokeGhostreader,
  openSingleParentNotebookView,
  shareHighlightAsImage,
  triggerHighlightPopover,
} from '../NotebookView/notebookHelpers';
import Tooltip from '../Tooltip';
import styles from './AnnotationBarPopover.module.css';
import Popover, { Props as PopoverProps } from './Popover';

const AutoHighlightListItem = React.memo(function AutoHighlightListItem({
  isAutoHighlightingEnabled,
}: {
  isAutoHighlightingEnabled: boolean;
}) {
  const autoHighlightListItemClasses = [styles.subPopoverListItem, styles.subPopoverAutoHighlightListItem];
  let descriptionSuffix = 'highlight text';
  if (isAutoHighlightingEnabled) {
    autoHighlightListItemClasses.push(styles.subPopoverAutoHighlightListItemEnabled);
    descriptionSuffix = 'make a text selection without highlighting';
  }

  const onClickToggleAutoHighlighting = useCallback(() => toggleIsAutoHighlightingEnabled('click'), []);
  const altOrOption = navigator.platform.toLowerCase().includes('mac') ? 'option' : 'alt';

  let autoHighlightShortcutInfo: JSX.Element | null = null;

  if (!isExtension) {
    autoHighlightShortcutInfo = <>
      <span className="hideAccessibly">(Keyboard shortcut: </span>Shift H<span className="hideAccessibly">)</span>
    </>;
  }

  return <li
    className={autoHighlightListItemClasses.join(' ')}>
    <Button
      className={styles.subPopoverButton}
      onClick={onClickToggleAutoHighlighting}>
      <span className={styles.subPopoverButtonPreContent}>
        {isAutoHighlightingEnabled && <CheckmarkIcon className={styles.checkmarkIcon} />}
      </span>
      <span className={styles.subPopoverButtonPrimaryContent}>
        <span>Auto highlight</span>
        <span className={styles.subPopoverButtonPrimaryContentDescription}>
          Hold <span className={styles.shortcut}>{altOrOption}</span> to {descriptionSuffix}
        </span>
      </span>
      <span className={styles.subPopoverKeyboardShortcut}>
        {autoHighlightShortcutInfo}
      </span>
    </Button>
  </li>;
});

type Props = {
  highlight?: Highlight | null | undefined;
  isAutoHighlightingAlwaysEnabled?: boolean;
  onClickCopy(event: MouseEvent): void;
  onClickCreate(event: MouseEvent): void;
  onClickDelete(event: MouseEvent): void;
  onClickEditNote(event: MouseEvent): void;
  onClickEditTags(event: MouseEvent): void;
  onClickShare(event: MouseEvent): void;
  references?: PopoverProps['reference'][];
  shouldUseSelection?: boolean;
  isPdfTron?: boolean;
  isNotebookView?: boolean;
} &
  Omit<PopoverProps, 'reference'>;

const defaultExport = React.memo(function AnnotationBarPopover({
  getBoundingClientRect,
  hidePopover,
  highlight,
  isAutoHighlightingAlwaysEnabled,
  isShown,
  onClickCopy: onClickCopyArgument,
  onClickCreate,
  onClickDelete,
  onClickEditNote,
  onClickEditTags,
  onClickShare: onClickShareArgument,
  references,
  shouldUseSelection,
  showPopover,
  isPdfTron,
  isNotebookView,
  ...otherProps
}) {
  const ellipsisButtonRef = useRef<HTMLElement>();
  const hasText = useMemo(() => Boolean(highlight?.content?.trim() ?? shouldUseSelection), [highlight?.content, shouldUseSelection]);
  const [isSubPopoverShown, setIsSubPopoverShown] = useState(false);
  const hideSubPopover = useCallback(() => setIsSubPopoverShown(false), [setIsSubPopoverShown]);
  const showSubPopover = useCallback(() => setIsSubPopoverShown(true), [setIsSubPopoverShown]);

  useEffect(() => {
    if (isShown && highlight?.id) {
      setOpenedAnnotationBarPopoverHighlightId(highlight.id);
    } else {
      setOpenedAnnotationBarPopoverHighlightId(null);
      setIsSubPopoverShown(false);
    }
  }, [isShown, highlight?.id]);

  const note = useHighlightNoteText(highlight);
  const history = useHistory();

  const isAutoHighlightingEnabled = useIsAutoHighlightingEnabled();

  const tooltipDestinationRef = useRef(null);
  const commonTooltipOptions = useMemo(() => ({
    appendTo: () => tooltipDestinationRef.current,
    trigger: 'mouseenter',
  }), []);

  const onClickCopy = useCallback((event: MouseEvent) => {
    onClickCopyArgument(event);
    hidePopover();
  }, [hidePopover, onClickCopyArgument]);

  const onClickShare = useCallback((event: MouseEvent) => {
    onClickShareArgument(event);
    hidePopover();
  }, [hidePopover, onClickShareArgument]);

  const onClickGpt = useCallback(async (_event: MouseEvent) => {
    hidePopover();
    if (highlight?.id) { // existing highlight, or auto-highlighting
      eventEmitter.emit('gpt:open', highlight.id);
    } else { // create a highlight, then send it to the GPT submenu
      const highlightId = (await eventEmitter.emitAsync('highlight')).find(Boolean);
      eventEmitter.emit('gpt:open', highlightId);
    }
  }, [hidePopover, highlight?.id]);

  const onClickShareImage = useCallback(async (_event: MouseEvent) => {
    hidePopover();
    if (highlight?.id) { // existing highlight, or auto-highlighting
      await setQuoteshotModalOpen(true, highlight.id);
    } else { // create a highlight, then open menu
      const highlightId = (await eventEmitter.emitAsync('highlight')).find(Boolean);
      await setQuoteshotModalOpen(true, highlightId);
    }
  }, [hidePopover, highlight?.id]);

  const onClickViewInNotebook = useCallback(() => {
    if (!highlight) {
      return;
    }
    if (!highlight.parent) {
      throw new Error('Highlight has no parent document.');
    }
    openSingleParentNotebookView(history, highlight.parent, highlight.id);
  }, [history, highlight]);

  let createOrDeleteListItem: JSX.Element;

  const onPointerDown: PointerEventHandler = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const commonButtonProps = useMemo(() => ({
    onPointerDown,
  }), [onPointerDown]);

  const highlightRelatedSubPopoverListItemClasses = [styles.subPopoverListItem];
  const highlightRelatedSubPopoverButtonClasses = [styles.subPopoverButton];
  if (shouldUseSelection) {
    createOrDeleteListItem = <li className={[styles.action, styles.createHighlightAction].join(' ')}>
      <Tooltip
        content="Create highlight"
        shortcut="h"
        {...commonTooltipOptions}>
        <Button
          {...commonButtonProps}
          className={styles.actionButton}
          onClick={onClickCreate}>
          <CreateHighlightIcon
            className={styles.actionIcon}
          />
        </Button>
      </Tooltip>
    </li>;
  } else {
    createOrDeleteListItem = <li className={[styles.action, styles.deleteHighlightAction].join(' ')}>
      <Tooltip
        content="Delete highlight"
        shortcut={isNotebookView ? `${getCmdOrCtrl()} ⌫` : 'h'}
        {...commonTooltipOptions}>
        <Button
          {...commonButtonProps}
          className={styles.actionButton}
          onClick={onClickDelete}>
          <DeleteHighlightIcon
            className={styles.actionIcon}
          />
        </Button>
      </Tooltip>
    </li>;
  }

  let autoHighlightListItem: JSX.Element | null = null;
  if (!isAutoHighlightingAlwaysEnabled) {
    autoHighlightListItem = <AutoHighlightListItem
      isAutoHighlightingEnabled={isAutoHighlightingEnabled}
    />;
  }

  let copyListItem: JSX.Element | null = null;
  let gptListItem: JSX.Element | null = null;
  let shareAsImageItem: JSX.Element | null = null;
  let viewInNotebookItem: JSX.Element | null = null;
  let shareListItem: JSX.Element | null = null;
  if (hasText) {
    const cmdOrCtrl = capitalize(getCmdOrCtrl());

    copyListItem = <li
      className={highlightRelatedSubPopoverListItemClasses.join(' ')}>
      <Button
        {...commonButtonProps}
        className={highlightRelatedSubPopoverButtonClasses.join(' ')}
        onClick={onClickCopy}>
        <span className={styles.subPopoverButtonPreContent} />
        <span className={styles.subPopoverButtonPrimaryContent}>Copy text</span>
        {!isPdfTron && <span className={styles.subPopoverKeyboardShortcut}>
          <span className="hideAccessibly">(Keyboard shortcut: </span>{cmdOrCtrl} C<span className="hideAccessibly">)</span>
        </span>}
      </Button>
    </li>;

    if (!isExtension) {
      gptListItem = <li
        className={highlightRelatedSubPopoverListItemClasses.join(' ')}>
        <Button
          {...commonButtonProps}
          className={highlightRelatedSubPopoverButtonClasses.join(' ')}
          onClick={onClickGpt}>
          <span className={styles.subPopoverButtonPreContent} />
          <span className={styles.subPopoverButtonPrimaryContent}>Invoke Ghostreader</span>
          <span className={styles.subPopoverKeyboardShortcut}>
            <span className="hideAccessibly">(Keyboard shortcut: </span>G<span className="hideAccessibly">)</span>
          </span>
        </Button>
      </li>;

      shareAsImageItem = <li
        className={highlightRelatedSubPopoverListItemClasses.join(' ')}>
        <Button
          {...commonButtonProps}
          className={highlightRelatedSubPopoverButtonClasses.join(' ')}
          onClick={onClickShareImage}>
          <span className={styles.subPopoverButtonPreContent} />
          <span className={styles.subPopoverButtonPrimaryContent}>Share as image</span>
        </Button>
      </li>;
    }

    if ('share' in navigator) {
      shareListItem = <li
        className={highlightRelatedSubPopoverListItemClasses.join(' ')}>
        <Button
          {...commonButtonProps}
          className={highlightRelatedSubPopoverButtonClasses.join(' ')}
          onClick={onClickShare}>
          <span className={styles.subPopoverButtonPreContent} />
          <span className={styles.subPopoverButtonPrimaryContent}>Share text</span>
        </Button>
      </li>;
    }
  }

  if (highlight && !isExtension) {
    viewInNotebookItem = <li
      className={highlightRelatedSubPopoverListItemClasses.join(' ')}>
      <Button
        {...commonButtonProps}
        className={highlightRelatedSubPopoverButtonClasses.join(' ')}
        onClick={onClickViewInNotebook}>
        <span className={styles.subPopoverButtonPreContent} />
        <span className={styles.subPopoverButtonPrimaryContent}>View in notebook</span>
        <span className={styles.subPopoverKeyboardShortcut}>
          <span className="hideAccessibly">(Keyboard shortcut: </span>Shift ⏎<span className="hideAccessibly">)</span>
        </span>
      </Button>
    </li>;
  }

  const triggerElement = useMemo(() => {
    return <DropdownMenu.Trigger asChild className={styles.action}>
      <Button
        className={styles.actionButton}>
        <EllipsisIcon
          className={styles.actionIcon}
        />
      </Button>
    </DropdownMenu.Trigger>;
  }, []);

  const isClickOutside: PopoverProps['isClickOutside'] = useCallback(
    ({ event, isInPopperElement }: Parameters<NonNullable<PopoverProps['isClickOutside']>>[0]) =>
      !isInPopperElement &&
      (!references || !references.some((reference) => reference?.contains(getEventTarget(event)))),
    [references],
  );

  const popperOptions = useMemo(() => ({ placement: 'top' } as PopperProps<void>), []);

  const hasNote = useMemo(() => highlight?.children.length, [highlight?.children]);
  const noteButtonTextDescription = useMemo(() => `${hasNote ? 'Edit' : 'Add'} note`, [hasNote]);
  const hasTags = useMemo(() => Object.keys(highlight?.tags ?? {}).length, [highlight?.tags]);
  const tagButtonTextDescription = useMemo(() => `${hasTags ? 'Edit' : 'Add'} tags`, [hasTags]);
  const isPDFHighlightWithImage = useMemo(() => isPdfTron && highlight?.source_specific_data?.pdf_highlight?.reader_file_id, [isPdfTron, highlight?.source_specific_data?.pdf_highlight?.reader_file_id]);
  const otherActionsButtonTextDescription = 'View other actions';

  const annotationBarPopoverClasses = [styles.root, 'js_annotation-bar-popover'];
  if (!isNotebookView) {
    if (isAutoHighlightingAlwaysEnabled) {
      annotationBarPopoverClasses.push(styles.rootAutoHighlightingAlwaysEnabled);
    }
    if (isAutoHighlightingEnabled) {
      annotationBarPopoverClasses.push(styles.rootAutoHighlightingEnabled);
    }
  }

  const goToHighlight = useCallback(() => {
    setHighlightIdToOpenAt(highlight?.id ?? null, { userInteraction: 'unknown' });
    history.push(urlJoin(['/read', highlight?.parent]));
  }, [highlight?.id, highlight?.parent, history]);

  const onEditNoteClicked = useCallback(() => {
    triggerHighlightPopover('note', highlight?.id);
  }, [highlight?.id]);

  const onEditTagsClicked = useCallback(() => {
    triggerHighlightPopover('tag', highlight?.id);
  }, [highlight?.id]);


  const listClasses = [styles.actions];
  if (highlight?.children.length) {
    listClasses.push(styles.actionsHasNote);
  }
  if (Object.keys(highlight?.tags || {}).length) {
    listClasses.push(styles.actionsHasTag);
  }

  if (!highlight && !shouldUseSelection) {
    return null;
  }

  return <>
    <Popover
      allowOverflow={isSubPopoverShown}
      className={annotationBarPopoverClasses.join(' ')}
      getBoundingClientRect={getBoundingClientRect}
      hidePopover={hidePopover}
      isClickOutside={isClickOutside}
      isShown={isShown}
      popperOptions={popperOptions}
      reference={references?.[0]}
      shouldHideOnBlur={false}
      shouldStayInDomWhenHidden
      showPopover={showPopover}
      {...otherProps}
    >
      <ul
        className={listClasses.join(' ')}
        onPointerDown={onPointerDown}>

        {createOrDeleteListItem}

        <li className={[styles.action, styles.noteAction].join(' ')}>
          <Tooltip
            content={noteButtonTextDescription}
            shortcut="n"
            {...commonTooltipOptions}>
            <Button
              {...commonButtonProps}
              className={styles.actionButton}
              onClick={onClickEditNote}>
              <NoteIcon
                className={styles.actionIcon}
                text={noteButtonTextDescription}
              />
            </Button>
          </Tooltip>
        </li>

        <li className={[styles.action, styles.tagAction].join(' ')}>
          <Tooltip
            content={tagButtonTextDescription}
            shortcut="t"
            {...commonTooltipOptions}>
            <Button
              {...commonButtonProps}
              className={styles.actionButton}
              onClick={onClickEditTags}>
              <TagIcon
                className={styles.actionIcon}
                text="Add/edit tags"
              />
            </Button>
          </Tooltip>
        </li>

        {/*
        <li className={styles.action}>
          <Button
            className={styles.actionButton}
            onClick={() => onClickAction('editEmoji')}>
            <EmojiIcon
              className={styles.actionIcon}
              text="Add/edit emoji"
            />
          </Button>
        </li>
        */}

        {isNotebookView && highlight && <div>
          <NotebookHighlightDropdown
            appendToDocumentBody
            buttonClassName={styles.highlightMenuButton}
            copyHighlightText={() => copyHighlightContent(highlight.content)}
            copyNote={() => copyHighlightContent(note)}
            shareHighlight={() => shareHighlightAsImage(highlight)}
            deleteHighlight={() => deleteHighlight(highlight.id, { userInteraction: 'unknown' })}
            invokeGhostreader={() => invokeGhostreader(highlight)}
            goToHighlight={goToHighlight}
            openEditNoteForm={onEditNoteClicked}
            openEditTagsForm={onEditTagsClicked}
            highlight={highlight}
            note={note}
            trigger={triggerElement}
            isNotebookView
          />
        </div>}
        {!isPDFHighlightWithImage && !isNotebookView && <li
          className={styles.action}>
          <Tooltip
            content={otherActionsButtonTextDescription}
            {...commonTooltipOptions}>
            <Button
              {...commonButtonProps}
              className={styles.actionButton}
              onClick={showSubPopover}
              ref={ellipsisButtonRef}>
              <EllipsisIcon
                className={styles.actionIcon}
                text={otherActionsButtonTextDescription}
              />
            </Button>
          </Tooltip>
          <Popover
            className={styles.subPopover}
            hidePopover={hideSubPopover}
            isShown={isSubPopoverShown}
            popperOptions={{
              placement: 'bottom-start',
            }}
            shouldAutoFocus={false}
            shouldHideOnBlur={false}
            showPopover={showSubPopover}
            reference={ellipsisButtonRef.current as HTMLElement}
          >
            <ul>
              {copyListItem}
              {shareListItem}
              {gptListItem}
              {shareAsImageItem}
              {viewInNotebookItem}
              {autoHighlightListItem}
            </ul>
          </Popover>
        </li>}
      </ul>
    </Popover>
    <div ref={tooltipDestinationRef} />
  </>;
}) as React.FC<Props>;

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
