// eslint-disable-next-line import/no-cycle
import { selectImageElementToHighlight } from '../../mobile/src/contentFrameInternals/getImagePressingInitializer';
import { getScrollingManager } from '../../mobile/src/contentFrameInternals/getScrollingManager';
import { computeHeaderImageColorData } from '../../mobile/src/contentFrameInternals/imageUtils';
import { buildWebviewStyles, WebviewStylesProps } from '../../mobile/src/contentFrameInternals/initStyles';
import { renderTags } from '../../mobile/src/contentFrameInternals/initTags';
import { updateTTSButton } from '../../mobile/src/contentFrameInternals/initTTSButton';
import { setYTAutoScrolling } from '../../mobile/src/contentFrameInternals/initYouTubeTeleprompter';
import { PaginatedScrollingManager } from '../../mobile/src/contentFrameInternals/PaginatedScrollingManager';
import { clearSearch, findAgain, findNext, findPrev, search } from '../../mobile/src/contentFrameInternals/searchUtils';
import { MobileContentFrameWindow } from '../../mobile/src/contentFrameInternals/types';
import { ThemeCSSMap } from '../../mobile/src/css/baseStyles';
import { generateAssetFontCss } from '../../mobile/src/utils/getAssetFontCss';
import { LenientReadingPosition, TshirtSize, TtsPosition, WordBoundary } from '../types';
import type { DocumentTag } from '../types/tags';
import { isInReactNativeWebView } from '../utils/environment';

declare let window: MobileContentFrameWindow;
const ensureIsMobileWebview = () => {
  if (!isInReactNativeWebView) {
    throw new Error('Cannot call this function outside react native webview');
  }
};
export const mobileSetStyles = ({ theme, marginTop, isYoutubeVideo, isPaginatedMode, deviceHeight }: WebviewStylesProps) => {
  ensureIsMobileWebview();
  window.theme = theme;
  const styleElement = document.querySelector<HTMLStyleElement>('#RWMainStyle');
  if (!styleElement) {
    return;
  }
  styleElement.innerHTML = buildWebviewStyles({ theme, marginTop, isYoutubeVideo, isPaginatedMode, deviceHeight });
  document.body.classList.remove(...Object.values(ThemeCSSMap));
  document.body.classList.add(ThemeCSSMap[theme.type]);
  computeHeaderImageColorData();
};
export const mobileCreateHighlightAtTtsPosition = async (ttsPos: TtsPosition) => {
  ensureIsMobileWebview();
  getScrollingManager().createHighlightAtTtsPosition(ttsPos);
};

export const mobileSetReadingPosition = (pos: LenientReadingPosition | null) => {
  ensureIsMobileWebview();
  getScrollingManager().setReadingPosition(pos);
};
export const mobileReturnToReadingPosition = () => {
  ensureIsMobileWebview();
  getScrollingManager().returnToReadingPosition();
};

export const mobileScrollToTop = () => {
  ensureIsMobileWebview();
  getScrollingManager().scrollToTop();
};

export const mobileToggleScrollingEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  getScrollingManager().toggleScrollingEnabled(enabled);
};

export const mobileToggleTTSAutoScrolling = (enabled: boolean) => {
  ensureIsMobileWebview();
  getScrollingManager().toggleTTSAutoScrolling(enabled);
};

export const mobilePlayTTSFromSelection = () => {
  ensureIsMobileWebview();
  getScrollingManager().playTtsFromSelection();
};

export const mobileUpdateWordBoundaries = (wordBoundaries: WordBoundary[]) => {
  ensureIsMobileWebview();
  getScrollingManager().updateWordBoundaries(wordBoundaries);
};
export const mobileUpdateWordBoundaryIndicator = (position: number) => {
  ensureIsMobileWebview();
  getScrollingManager().updateWordBoundaryIndicator(position);
};

export const mobileHideWordBoundaryIndicator = () => {
  ensureIsMobileWebview();
  getScrollingManager().hideWordBoundaryIndicator();
};

export const updateEndOfReadingText = (text: string) => {
  ensureIsMobileWebview();
  const endOfReadingText = document.getElementById('end-of-reading-text');
  if (!endOfReadingText) {
    return;
  }
  endOfReadingText.innerHTML = text;
};

export const mobileSelectImageElementToHighlight = async (imageId: string) => {
  ensureIsMobileWebview();
  const image = document.getElementById(imageId) as HTMLImageElement;
  if (image === null) {
    throw new Error('highlightImage - couldnt find image with provided id');
  }
  return selectImageElementToHighlight(image);
};

export const mobileChangeAutohighlightingStatus = async (value: boolean) => {
  ensureIsMobileWebview();
  window.isAutoHighlightingEnabled = value;
};

export const mobileChangeFontSettings = async ({ fontSize, lineHeight, marginInnerHTML, font, cssFont, platformOS }:
  {
    fontSize: TshirtSize;
    lineHeight: TshirtSize;
    marginInnerHTML: string;
    font: string;
    cssFont: string;
    platformOS: string;
  }) => {
  ensureIsMobileWebview();
  if (!document.scrollingElement) {
    throw new Error('document.scrollingElement does not exist');
  }

  const root = document.querySelector<HTMLElement>(':root');
  if (root) {
    root.style.setProperty('--reading-editable-font-size', fontSize);
    root.style.setProperty('--reading-editable-line-height', lineHeight);
  }
  const marginStyleElement = document.querySelector('#RWMarginStyle');
  if (marginStyleElement) {
    marginStyleElement.innerHTML = marginInnerHTML;
  }
  const fontFamilyStyleElement = document.querySelector('#RWFontFamilyStyle');
  if (fontFamilyStyleElement) {
    const currentFontName = fontFamilyStyleElement.getAttribute('font-name');
    if (currentFontName !== font) {
      fontFamilyStyleElement.setAttribute('font-name', font);
      fontFamilyStyleElement.innerHTML = generateAssetFontCss({
        fontName: font,
        cssFontName: cssFont,
        platformOS,
      }).styleContent;
    }
  }
  if (root) {
    root.style.setProperty('--reading-editable-font-family', cssFont);
  }
};

export const mobileRenderTags = (tags: DocumentTag[]) => {
  ensureIsMobileWebview();
  renderTags(tags);
};

export const mobileUpdateTTSButton = (isListeningToThisDoc: boolean) => {
  ensureIsMobileWebview();
  updateTTSButton(isListeningToThisDoc, true);
};

export const mobileToggleYTAutoScrolling = (enabled: boolean) => {
  ensureIsMobileWebview();
  setYTAutoScrolling(enabled);
};

export const mobileSearchText = (text: string) => {
  ensureIsMobileWebview();
  search(text);
};
export const mobileSearchFindAgain = (index: number) => {
  ensureIsMobileWebview();
  findAgain(index);
};
export const mobileSearchFindNext = (index: number) => {
  ensureIsMobileWebview();
  findNext(index);
};

export const mobileSearchFindPrev = (index: number) => {
  ensureIsMobileWebview();
  findPrev(index);
};
export const mobileClearSearch = () => {
  ensureIsMobileWebview();
  clearSearch();
};

export const mobileMaybeScrollToTopOnMenuOpen = () => {
  ensureIsMobileWebview();
  if (!window?.scrollingManager || !window.scrollingManager.firstTimeOpenedDocumentOffset) {
    return;
  }
  if (window.scrollingManager.getScrollingElementTop() <= window.scrollingManager.firstTimeOpenedDocumentOffset + 12) {
    window.scrollingManager.scrollToTop();
  }
};
export const mobileTogglePaginationAnimationsEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled) {
    return;
  }
  window.pagination.smoothAnimationsDisabled = enabled;
};
export const mobileTogglePaginationHapticsOnScrollEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled) {
    return;
  }
  window.pagination.hapticsOnScrollEnabled = enabled;
};

export const mobileScrollToNextPage = () => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled || !(window.scrollingManager instanceof PaginatedScrollingManager)) {
    return;
  }
  const scrollingManager = window.scrollingManager as PaginatedScrollingManager;
  scrollingManager.scrollToPage(scrollingManager.currentPage + 1);
};

export const mobileScrollToPreviousPage = () => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled || !(window.scrollingManager instanceof PaginatedScrollingManager)) {
    return;
  }
  const scrollingManager = window.scrollingManager as PaginatedScrollingManager;
  scrollingManager.scrollToPage(scrollingManager.currentPage - 1);
};
