import React from 'react';

import Icon from './Icon';

export default function IndeterminateIcon({
  className = '',
  text = 'Indeterminate',
}: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M3 8C3 7.44772 3.44772 7 4 7H12C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9H4C3.44772 9 3 8.55228 3 8Z"
        fill="white" />
    </svg>

  </Icon>;
}
