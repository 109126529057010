import { FAQ_DOC_URL } from '../../constants.platform';
import database from '../database';

export const getFaqDocument = async() => {
  const doc = await database.collections.documents.findOne({
    selector: {
      url: FAQ_DOC_URL,
    },
  });

  if (!doc) {
    return;
  }

  return doc;
};
