import * as RadixSlider from '@radix-ui/react-slider';
import React, { useCallback, useRef } from 'react';

import combineClasses from '../../../shared/foreground/utils/combineClasses';
import type { MaybePromise } from '../../../shared/utils/typescriptUtils';
import styles from './Slider.module.css';

type Props = {
  className?: string;
  minValue?: number;
  maxValue?: number;
  onValueChanged?: (newValue: number) => MaybePromise<void>;
  value: number;
  valueLabel: string;
};

export default function Slider({
  className,
  minValue = 0,
  maxValue = 1,
  onValueChanged: onValueChangedArgument,
  value,
  valueLabel,
}: Props) {
  const onValueChanged = useCallback((newValues) => onValueChangedArgument?.(newValues[0]), [onValueChangedArgument]);

  const isMouseOver = useRef(false);
  const onMouseEnter = useCallback(() => {
    isMouseOver.current = true;
  }, []);
  const onMouseLeave = useCallback(() => {
    isMouseOver.current = false;
  }, []);

  const onWheel = useCallback((event) => {
    if (!isMouseOver.current) {
      return;
    }
    const delta = -event.deltaY * 0.001;
    const newValue = Math.max(0, Math.min(value + delta, 1));
    onValueChanged([newValue]);
  }, [onValueChanged, value]);

  return <RadixSlider.Root
    className={combineClasses(styles.sliderRoot, className, 'sliderRoot')}
    min={minValue}
    max={maxValue}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onValueChange={onValueChanged}
    onWheel={onWheel}
    step={0.01}
    value={[value]}>
    <RadixSlider.Track className={combineClasses(styles.sliderTrack, 'sliderTrack')}>
      <RadixSlider.Range className={combineClasses(styles.sliderRange, 'sliderRange')} />
    </RadixSlider.Track>
    <RadixSlider.Thumb
      aria-label={valueLabel}
      className={combineClasses(styles.sliderThumb, 'sliderThumb')} />
  </RadixSlider.Root>;
}
