import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef } from 'react';

import { contentFocusIndicatorFocusedTargetClass } from '../../../shared/constants.platform';
import eventEmitter from '../../../shared/foreground/eventEmitter';
import { globalState } from '../../../shared/foreground/models';
import closestWith from '../../../shared/foreground/utils/closestWith';
import forwardRef from '../../../shared/foreground/utils/forwardRef';
import getClosestHTMLElement from '../../../shared/foreground/utils/getClosestHTMLElement';
import getFirstDescendantWith from '../../../shared/foreground/utils/getFirstDescendantWith';
import getFocusedElementOnScroll from '../../../shared/foreground/utils/getFocusedElementOnScroll';
import getLastDescendant from '../../../shared/foreground/utils/getLastDescendant';
import getNextElementWithinContainer from '../../../shared/foreground/utils/getNextNodeWithinContainer';
import getRangyClassApplier from '../../../shared/foreground/utils/getRangyClassApplier';
import getVisibilityDetails from '../../../shared/foreground/utils/getVisibilityDetails';
import isDeepestFocusableElement from '../../../shared/foreground/utils/isDeepestFocusableElement';
import isFocusableElement from '../../../shared/foreground/utils/isFocusableElement';
import { deserializePosition } from '../../../shared/foreground/utils/locationSerializer';
import type { BaseDocument, ReadingPosition } from '../../../shared/types';
import { ShortcutId } from '../../../shared/types';
import { isHTMLElement } from '../../../shared/typeValidators';
import makeLogger from '../../../shared/utils/makeLogger';
import { useCurrentAppearanceStyle } from '../hooks/appearanceStyles';
import { useHotKeys } from '../hooks/hooks';
import { useShortcutsMap } from '../utils/shortcuts';
import styles from './ContentFocusIndicator.module.css';

const logger = makeLogger(__filename);

/*
  - How the focus indicator works:
    - Which elements can be focused: any element within the content with `display: block` or `display: list-item`, which has text (except for `article`, `div`, and `figcaption` elements), or is an image outside of elements like `p`, `li`, etc. (they're given `display: block` in CSS).
    - If there are no such elements, the focus indicator *shouldn't* be visible.
    - On load of an article without reading progress, it'll find the first descendant which is focusable. This makes it tolerant of our varying content structure (e.g. `p`, `article > p`, `div > div > p`.
    - When you press `up` / `down`, the focus indicator will be moved to the next closest focusable element.
    - If you scroll, the content indicator will move and highlight an element now in view. I initially thought this would be annoying but it seems OK actually. This also covers when you press `cmd + down`, `Home`, `Page down` etc.
    - When you press tab into / click within a focusable element, the focus indicator will move.
    - When you press `h`, it'll highlight the element which is the target of focused indicator (unless it already contains a highlight).
  - Where this is not perfect: it doesn't handle really weird documents with table layouts, one giant paragraphs with `<br>`s, etc.
  - Moving the focus indicator does not cause an update to the reading position / status. If it causes a scroll, then yeah it could be (by another component/hook), but it doesn't manually update the reading position.
*/

const getFocusIndicatorTargetPercent = ({ isYouTubeVideo = false, isVideoHeaderShown = false }: {isYouTubeVideo: boolean; isVideoHeaderShown: boolean;}) => isYouTubeVideo ? {
  top: isVideoHeaderShown ? 0.7 : 0.6,
  bottom: 0.7,
} : {
  top: 0.15, // 15% down the screen is the "top" of the ideal position for focus indicator
  bottom: 0.7, // 70% down the screen is the "top" of the ideal position for focus indicator
};

const getVerticalPadding = (indicator: HTMLElement): number =>
  parseInt(getComputedStyle(indicator).getPropertyValue('--content-focus-indicator-vertical-padding'), 10);

const applyZenModeStyle = (target: Element | ChildNode | null, endTarget: Element) => {
  if (!target) {
    return;
  }
  if (target === endTarget) {
    return;
  }
  // Childnodes do not have classList as property. for some reason...
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  target.classList.add(contentFocusIndicatorFocusedTargetClass);
  if (target.parentElement && target.parentElement.tagName !== 'DIV') {
    applyZenModeStyle(target.parentElement, endTarget);
  }
};

export const positionFocusIndicator = (indicator: HTMLElement, target: Element, contentRoot: Element, zenMode = false): void => {
  const verticalPadding = getVerticalPadding(indicator);
  indicator.style.height = `${target?.clientHeight + verticalPadding}px`;

  let targetOffsetTop: number;
  if (isHTMLElement(target)) {
    targetOffsetTop = target.offsetTop;
  } else {
    logger.warn('positionIndicator: target is not an instance of HTMLElement');
    targetOffsetTop = 0;
  }

  indicator.style.transform = `translate(0, ${targetOffsetTop - verticalPadding / 2}px)`;

  const selectedTargets = document.querySelectorAll(`.${contentFocusIndicatorFocusedTargetClass}`);
  selectedTargets.forEach((selectedTarget) => {
    selectedTarget.classList.remove(contentFocusIndicatorFocusedTargetClass);
  });
  if (zenMode && contentRoot) {
    applyZenModeStyle(target, contentRoot);
  }
  target.classList.add(contentFocusIndicatorFocusedTargetClass);
};

type Props = {
  contentRootRef: React.MutableRefObject<HTMLDivElement>;
  docId: BaseDocument['id'];
  expectInitialExternalScroll?: boolean;
  initialSerializedScrollPosition?: ReadingPosition['serializedPosition'];
  isYouTubeVideo?: boolean;
  isVideoHeaderShown?: boolean;
  onNewFocusTarget?: (newTarget: Element | void) => void;
  scrollableAncestorRef: React.MutableRefObject<HTMLElement>;
  zenMode?: boolean;
};

export default forwardRef<Props, Element | HTMLElement>(
  function ContentFocusIndicator(
    {
      // The document content container
      contentRootRef,
      docId,
      // We do things slightly differently (to be as fast as possible) if something else is going to immediately scroll the document
      expectInitialExternalScroll,
      // If returning to a document with reading progress, this will be given (a serialized position string)
      initialSerializedScrollPosition,
      onNewFocusTarget = () => null,
      // The closest scrollable ancestor (which is not the content root at time of writing)
      scrollableAncestorRef,
      zenMode = false,
      isYouTubeVideo = false,
      isVideoHeaderShown = false,
    },
    // Which element in the content is "focused", i.e. the target of the focus indicator
    focusTargetRef,
  ) {
    // The actual focus indicator element
    const indicatorRef = useRef<HTMLDivElement>(null);
    const isDocumentMetadataShown = globalState(useCallback((state) => state.isDocumentMetadataShown, []));
    const { fontSize, lineHeight, lineLength, font } = useCurrentAppearanceStyle();
    const shortcutsMap = useShortcutsMap();

    const moveFocusIndicator = useCallback(async (target: Element | void, sideToAlign: false | 'start' | 'end', scrollPageIfNeeded = true): Promise<void> => {
      const focusIndicator = indicatorRef.current;
      if (!target || !focusIndicator || !contentRootRef.current) {
        return;
      }
      const isFirstTime = !focusTargetRef.current;
      const hasChanged = focusTargetRef.current ? !focusTargetRef.current.isEqualNode(target) : Boolean(target);
      focusTargetRef.current = target;

      if (hasChanged) {
        onNewFocusTarget(target);
      }

      const scrollableAncestor = scrollableAncestorRef.current;
      const { scrollableRect, rect } = await getVisibilityDetails({ subject: target, scrollableRoot: scrollableAncestor });

      const focusIndicatorInGoodSpot =
        rect.top >= scrollableRect.top + scrollableRect.height * getFocusIndicatorTargetPercent({ isYouTubeVideo, isVideoHeaderShown }).top &&
        rect.bottom <= scrollableRect.top + scrollableRect.height * getFocusIndicatorTargetPercent({ isYouTubeVideo, isVideoHeaderShown }).bottom;

      if (isFirstTime && expectInitialExternalScroll) {
        // Just so things happen as quickly as possible
        focusIndicator.classList.add(styles.rootWithoutAnimation);
      }

      positionFocusIndicator(focusIndicator, target, contentRootRef.current, zenMode);
      focusIndicator.classList.add(styles.rootShown);

      if (!sideToAlign || focusIndicatorInGoodSpot || !scrollPageIfNeeded || !contentRootRef.current) {
        focusIndicator.classList.remove(styles.rootWithoutAnimation);
        return;
      }

      // Below this point is all about scrolling so the focus target & indicator is in view
      focusIndicator.classList.add(styles.rootWithoutAnimation);
      let newScrollPosition = Math.max(scrollableAncestor.scrollTop + rect.top - scrollableRect.height * getFocusIndicatorTargetPercent({ isYouTubeVideo, isVideoHeaderShown }).top, 0);
      const contentRootTop = contentRootRef.current.getBoundingClientRect().top;
      const targetTop = target.getBoundingClientRect().top;
      if (contentRootTop - targetTop === 0) {
        newScrollPosition = 0;
      }
      scrollableAncestor.scroll({ left: 0, top: newScrollPosition, behavior: 'smooth' });
      focusIndicator.classList.remove(styles.rootWithoutAnimation);
    }, [focusTargetRef, scrollableAncestorRef, expectInitialExternalScroll, contentRootRef, zenMode, isYouTubeVideo, isVideoHeaderShown, onNewFocusTarget]);

    useEffect(() => {
      if (indicatorRef && focusTargetRef && indicatorRef.current && focusTargetRef.current) {
        positionFocusIndicator(indicatorRef.current, focusTargetRef.current, contentRootRef.current, zenMode);
      }
    }, [indicatorRef, focusTargetRef, contentRootRef, zenMode]);

    useEffect(() => {
      if (indicatorRef && focusTargetRef && indicatorRef.current && focusTargetRef.current) {
        moveFocusIndicator(focusTargetRef.current, 'start');
      }
    }, [fontSize, lineHeight, lineLength, font, moveFocusIndicator, indicatorRef, focusTargetRef, zenMode]);

    // On load
    useEffect(() => {
      (async () => {
        if (!contentRootRef.current) {
          return;
        }

        let target: Element | undefined;

        if (initialSerializedScrollPosition) {
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const position = deserializePosition({
              classApplier: getRangyClassApplier(),
              rootNode: contentRootRef.current,
              serialized: initialSerializedScrollPosition,
            });

            const range = document.createRange();
            range.setStart(position.node, position.offset);
            range.setEnd(position.node, position.offset);

            const closestElement = getClosestHTMLElement(position.node);
            if (closestElement) {
              target = isFocusableElement(closestElement)
                ? closestElement
                : getNextElementWithinContainer({
                  container: contentRootRef.current,
                  direction: 'next',
                  element: closestElement,
                  matcher: isFocusableElement,
                });
            } else {
              target = undefined;
            }
          } catch (error) {
            logger.warn('Failed to position content focus indicator based on initial serialized scroll position, falling back...', { error });
          }
        }

        if (!target) {
          // Get first focusable element
          target = getFirstDescendantWith(contentRootRef.current, isDeepestFocusableElement) ?? undefined;
        }
        await moveFocusIndicator(target, false);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      contentRootRef,
      contentRootRef.current, // This forces the hook to re-run if the content is unnecessarily re-rendered on load
      docId,
    ]);

    /*
      Below this point: listen for events
    */

    useEffect(() => {
      let destroy: () => void;
      (async () => {
        if (!contentRootRef.current || !scrollableAncestorRef.current) {
          return;
        }

        const contentRoot = contentRootRef.current;
        // This is called when focus changes, a click happens, etc.
        const onFocusChange = debounce(async (event) => {
          await moveFocusIndicator(
            closestWith<Element>(
              event.target,
              (node: Node) => contentRoot.contains(node) && isFocusableElement(node),
            ),
            'start',
            false,
          );
        }, 50);
        const contentEvents = ['click', 'focusin', 'mouseup'];
        contentEvents.forEach((eventName) => contentRoot.addEventListener(eventName, onFocusChange));
        eventEmitter.on('update-content-focus-indicator-target', onFocusChange);

        const scrollableRoot = scrollableAncestorRef.current;
        const onScroll = debounce(async () => {
          const target = await getFocusedElementOnScroll(contentRoot, scrollableRoot, focusTargetRef.current);
          if (!target) {
            return;
          }
          await moveFocusIndicator(target, false);
        }, 250);

        scrollableRoot.addEventListener('scroll', onScroll, { passive: true });

        destroy = () => {
          contentEvents.forEach((eventName) => contentRoot.removeEventListener(eventName, onFocusChange));
          eventEmitter.off('update-content-focus-indicator-target', onFocusChange);
          scrollableRoot.removeEventListener('scroll', onScroll);
        };
      })();

      return () => {
        if (!destroy) {
          return;
        }
        destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      contentRootRef,
      contentRootRef.current, // This forces the hook to re-run if the content is unnecessarily re-rendered on load
      docId,
      scrollableAncestorRef,
      zenMode,
    ]);

    useHotKeys(
      shortcutsMap[ShortcutId.MoveUpFocusIndicator],
      useCallback((event) => {
        (async () => {
          if (
            !contentRootRef.current ||
            isDocumentMetadataShown ||
            document.getElementById('notebook-sidebar-panel')?.contains(document.activeElement)
          ) {
            return;
          }

          event.preventDefault();
          await moveFocusIndicator(
            getNextElementWithinContainer({
              container: contentRootRef.current,
              direction: 'previous',
              element: focusTargetRef.current,
              matcher: isDeepestFocusableElement,
            }),
            'start',
          );
        })();
        // Disabling because we want to additionaly include contentRootRef.current and docId
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [contentRootRef, contentRootRef.current, docId, focusTargetRef, moveFocusIndicator, isDocumentMetadataShown]),
    );

    const moveDownShortcut = isYouTubeVideo ? shortcutsMap[ShortcutId.MoveDownFocusIndicator].filter((key) => key !== 'space') : shortcutsMap[ShortcutId.MoveDownFocusIndicator];

    useHotKeys(
      moveDownShortcut,
      useCallback((event) => {
        (async () => {
          if (
            !contentRootRef.current ||
            isDocumentMetadataShown ||
            document.getElementById('notebook-sidebar-panel')?.contains(document.activeElement)
          ) {
            return;
          }

          event.preventDefault();
          await moveFocusIndicator(
            getNextElementWithinContainer({
              container: contentRootRef.current,
              direction: 'next',
              element: focusTargetRef.current,
              matcher: isDeepestFocusableElement,
            }),
            'end',
          );
        })();
        // Disabling because we want to additionaly include contentRootRef.current and docid
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [contentRootRef, moveDownShortcut, contentRootRef.current, docId, focusTargetRef, moveFocusIndicator, isDocumentMetadataShown]),
    );

    // If a keyboard shortcut which scrolls all the way to the top is pressed, we focus the first focusable element
    useHotKeys(
      shortcutsMap[ShortcutId.ScrollToTop],
      useCallback((event) => {
        (async () => {
          if (!contentRootRef.current) {
            return;
          }

          event.preventDefault();
          await moveFocusIndicator(
            getFirstDescendantWith(contentRootRef.current, isDeepestFocusableElement),
            'start',
          );
        })();
        // Disabling because we want to additionaly include contentRootRef.current, docId, and focusTargetRef
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [contentRootRef, contentRootRef.current, docId, focusTargetRef, moveFocusIndicator]),
    );

    // If a keyboard shortcut which scrolls all the way to the bottom is pressed, we focus the last focusable element
    useHotKeys(
      shortcutsMap[ShortcutId.ScrollToBottom],
      useCallback((event) => {
        (async () => {
          if (!contentRootRef.current) {
            return;
          }

          event.preventDefault();
          let target = getLastDescendant(contentRootRef.current);
          if (!target) {
            return;
          }

          if (!isFocusableElement(target)) {
            target = getNextElementWithinContainer({
              container: contentRootRef.current,
              direction: 'previous',
              element: target,
              matcher: isDeepestFocusableElement,
            });
          }

          await moveFocusIndicator(target, 'end');
        })();
        // Disabling because we want to additionaly include contentRootRef.current, docId, and focusTargetRef
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [contentRootRef, contentRootRef.current, docId, focusTargetRef, moveFocusIndicator]),
    );

    // Re-position indicator after images are loaded and cause layout reflow
    useEffect(() => {
      if (!focusTargetRef.current || !scrollableAncestorRef.current) {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        if (!indicatorRef.current) {
          return;
        }
        positionFocusIndicator(indicatorRef.current, focusTargetRef.current, contentRootRef.current, zenMode);
      });
      for (const img of contentRootRef.current.querySelectorAll('img')) {
        resizeObserver.observe(img);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, [contentRootRef, docId, focusTargetRef, indicatorRef, scrollableAncestorRef, zenMode]);

    useEffect(() => {
      if (!focusTargetRef.current || !scrollableAncestorRef.current || !indicatorRef.current) {
        return;
      }

      const onContentMoved = () => {
        moveFocusIndicator(
          focusTargetRef.current,
          'start',
          false,
        );
      };
      const refocusIndicator = () => {
        moveFocusIndicator(
          focusTargetRef.current,
          'start',
        );
      };

      eventEmitter.on('refocus-content-focus-indicator', refocusIndicator);
      eventEmitter.on('content-frame:content-moved', onContentMoved);

      return () => {
        eventEmitter.off('refocus-content-focus-indicator', refocusIndicator);
        eventEmitter.off('content-frame:content-moved', onContentMoved);
      };
    }, [focusTargetRef, moveFocusIndicator, scrollableAncestorRef]);

    return <div
      className={styles.root}
      ref={indicatorRef}
    />;
  },
);
