import isEqual from 'lodash/isEqual';
import { ulid } from 'ulid';

import {
  type AnyDocument,
  type BaseDocument,
  type Highlight,
  type TransientDocumentData,
  Category,
  DocumentLocation,
  JobType,
} from '../../../../types';
import nowTimestamp from '../../../../utils/dates/nowTimestamp';
import { isDevOrTest } from '../../../../utils/environment';
import exceptionHandler from '../../../../utils/exceptionHandler.platform';
import getCategoryFromThirdPartyUrl from '../../../../utils/getCategoryFromThirdPartyUrl';
import makeLogger from '../../../../utils/makeLogger';
import createInitialTransientDocumentData from '../../../createInitialTransientDocumentData';
// eslint-disable-next-line import/no-cycle
import database from '../../../database';
import { CancelStateUpdate, globalState, updateState } from '../../../models';
import type { StateUpdateOptions, StateUpdateOptionsWithoutEventName, StateUpdateResult } from '../../../types';
import fixBadDocumentLocationsValue from '../../../utils/fixBadDocumentLocationsValue';
import { queueJob } from '../jobs';
import { updateDocument } from './update';

const logger = makeLogger(__filename);

export async function upsertDocument(
  doc: AnyDocument,
  transientDocumentDataArg: Partial<TransientDocumentData>,
  options: StateUpdateOptions & { disableWarningForDuplicateChild?: boolean; },
): StateUpdateResult {
  options.correlationId ??= ulid();
  const upsertDocumentResult = await database.collections.documents.incrementalUpsert(doc, options);
  let updateParentPromise: Promise<unknown> = Promise.resolve();
  if (doc.parent) {
    updateParentPromise = updateDocument(doc.parent, (parentDoc) => {
      if (!parentDoc.children) {
        logger.error('Trying to add child to parent doc without children', { doc, parentDoc });
        parentDoc.children = [];
      }
      if (parentDoc.children.includes(doc.id)) {
        // This may be allowed when updating a doc (e.g. when merging highlights),
        // but the caller must explicitly disable the warning if so.
        if (!options.disableWarningForDuplicateChild) {
          logger.error('Trying to add child which is already present in parent doc', { doc, parentDoc });
        }
        return;
      }
      parentDoc.children.push(doc.id);
    }, options);
  }
  checkHighlightMarkdown(doc);
  const transientDocumentData = createInitialTransientDocumentData(transientDocumentDataArg);
  await Promise.all([
    updateState((state) => {
      if (isEqual(state.transientDocumentsData[doc.id], transientDocumentData)) {
        throw new CancelStateUpdate();
      }
      state.transientDocumentsData[doc.id] = transientDocumentData;
    }, options),
    updateParentPromise,
  ]);
  return upsertDocumentResult;
}

function checkHighlightMarkdown(doc: AnyDocument) {
  if (doc.category !== Category.Highlight) {
    return;
  }
  const highlight = doc as Highlight;
  if (typeof highlight.markdown === 'string') {
    return;
  }
  const error = new Error(`Highlight created with non-string markdown property (${typeof highlight.markdown})`);
  if (isDevOrTest) {
    logger.error(error.message, { error });
  } else {
    exceptionHandler.captureException(error);
  }
}

export async function upsertDocuments(
  docs: AnyDocument[],
  options: Parameters<typeof updateState>[1],
): Promise<void> {
  options.correlationId ??= ulid();
  // TODO: bulk upsert
  await Promise.all(
    docs.map((doc) => upsertDocument(doc, {}, options)),
  );
}

export async function saveNewDocument(
  {
    author = '',
    firstOpenedAt,
    html,
    id: idArgument,
    isExtensionActivated,
    lastOpenedAt,
    onSentToServer,
    shouldQueueJob = true,
    source,
    title = '',
    url,
  }: {
    author?: BaseDocument['author'];
    firstOpenedAt?: BaseDocument['firstOpenedAt'];
    html?: string;
    id?: BaseDocument['id'];
    isExtensionActivated?: BaseDocument['isExtensionActivated'];
    lastOpenedAt?: BaseDocument['lastOpenedAt'];
    onSentToServer?: () => void;
    shouldQueueJob?: boolean;
    source: BaseDocument['source'];
    title?: BaseDocument['title'];
    url: BaseDocument['url'];
  },
  options: StateUpdateOptionsWithoutEventName,
): Promise<{ id: BaseDocument['id']; wasAlreadySaved: boolean; }> {
  let id: BaseDocument['id'];

  const doc = await database.collections.documents.findOne(idArgument ? idArgument : {
    selector: {
      url: { $eq: url },
      category: { $in: [Category.Article, Category.PDF, Category.Tweet, Category.Video] },
    },
  });
  if (doc) {
    id = doc.id;
    if (onSentToServer) {
      setTimeout(onSentToServer);
    }
  } else {
    id = idArgument ?? ulid().toLowerCase();

    const updateStateOptions = { ...options, eventName: 'save-new-article' };
    if (onSentToServer) {
      updateStateOptions.onSentToServer = onSentToServer;
    }
    const savedAt = nowTimestamp();
    const state = globalState.getState();
    const newDocument = {
      author,
      category: getCategoryFromThirdPartyUrl(url || ''),
      children: [],
      id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      image_url: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      last_status_update: savedAt,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      saved_at_history: [savedAt],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      saved_at: savedAt,
      source,
      title,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      triage_status: fixBadDocumentLocationsValue(state.persistent.settings.documentLocations)
        .find((documentLocation) => documentLocation !== DocumentLocation.Feed),
      url,
    } as AnyDocument;
    if (firstOpenedAt) {
      newDocument.firstOpenedAt = firstOpenedAt;
    }
    if (typeof isExtensionActivated === 'boolean') {
      newDocument.isExtensionActivated = isExtensionActivated;
    }
    if (lastOpenedAt) {
      newDocument.lastOpenedAt = lastOpenedAt;
    }
    const { userEvent } = await upsertDocument(newDocument, {}, updateStateOptions);
    if (shouldQueueJob) {
      await queueJob({
        jobType: JobType.ParseNewDocument,
        jobArguments: { docId: id, large: { html } },
        options: {
          correlationId: userEvent?.correlationId,
          ...options,
        },
      });
    }
  }

  return {
    id,
    wasAlreadySaved: Boolean(doc),
  };
}
