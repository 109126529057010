import type { DocumentTag } from '../../../types/tags';
import { notEmpty } from '../../../typeValidators';
import { cleanAndValidateTagName } from '../../../utils/cleanAndValidateTagName';
// eslint-disable-next-line import/no-cycle
import { globalState, updateState } from '../../models';
import { StateUpdateOptionsWithoutEventName, StateUpdateResult } from '../../types';

export async function addTagNamesToRecentlyUsedTags(
  tagNames: DocumentTag['name'][],
  options: StateUpdateOptionsWithoutEventName,
): StateUpdateResult {
  const cleanTagNames = tagNames
    .map((tagName) => cleanAndValidateTagName(tagName).cleanTagName)
    .filter(notEmpty);

  const oldState = globalState.getState();
  if (cleanTagNames.every((cleanTagName) => oldState.tagNamesUsedRecently.includes(cleanTagName))) {
    return {
      userEvent: undefined,
    };
  }

  return updateState((state) => {
    state.tagNamesUsedRecently = [
      ...cleanTagNames,
      ...state.tagNamesUsedRecently.filter((otherTagName) => !cleanTagNames.includes(otherTagName)),
    ];
  }, { ...options, eventName: 'recently-used-tag-names-added' });
}

export async function removeTagNamesFromRecentlyUsedTags(
  tagNames: DocumentTag['name'][],
  options: StateUpdateOptionsWithoutEventName,
): StateUpdateResult {
  const cleanTagNames = tagNames
    .map((tagName) => cleanAndValidateTagName(tagName).cleanTagName)
    .filter(notEmpty);

  const oldState = globalState.getState();
  if (!cleanTagNames.some((cleanTagName) => oldState.tagNamesUsedRecently.includes(cleanTagName))) {
    return {
      userEvent: undefined,
    };
  }

  return updateState((state) => {
    state.tagNamesUsedRecently = state.tagNamesUsedRecently
      .filter((otherTagName) => !cleanTagNames.includes(otherTagName));
  }, { ...options, eventName: 'recently-used-tag-names-removed' });
}
