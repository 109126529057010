import { isMobile } from '../../utils/environment';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import makeLogger from '../../utils/makeLogger';
import request from '../../utils/request';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import background from '../portalGates/toBackground/singleProcess';
import { createToast } from '../toasts.platform';

const logger = makeLogger(__filename);

export default async function uploadFiles(files: File[]): Promise<void> {
  try {
    for (const file of files) {
      createToast({ content: `Uploading ${file.name}...`, category: 'success' });

      if (file.type === 'text/csv') {
        const data = new FormData();
        data.append('instapaper_csv_file', file);
        const resp = await background.uploadInstapaperCSV(data);
        background.pollLatestStateAndContent(5, true);

        if (resp.ok) {
          createToast({ content: `Upload successful`, category: 'success', hasAnimatedSuccessIcon: true });
        } else {
          createToast({ category: 'error', content: `Oops! Failed to upload file ${file.name}` });
        }

        continue;
      }

      const preSignedData = await requestWithAuth(
        `${getServerBaseUrl()}/reader/api/sign_s3?file_name=${encodeURIComponent(file.name)}&type=${encodeURIComponent(file.type)}`,
        {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
        },
      );
      if (!preSignedData.ok) {
        logger.warn(`Presign request for s3 failed -> ${preSignedData.statusText}: ${await preSignedData.text()}`);
        createToast({ category: 'error', content: `Oops! Failed to upload file ${file.name}` });
        continue;
      }
      const s3Data = await preSignedData.json();
      const response = await request(
        s3Data.url,
        {
          method: 'PUT',
          body: file,
          headers: {
            /* eslint-disable @typescript-eslint/naming-convention */
            'Content-Type': file.type,
            /* eslint-enable @typescript-eslint/naming-convention */
          } },
      );
      if (!response.ok) {
        logger.warn(`Request to s3 failed -> ${response.statusText}: ${await response.text()}`);
        createToast({ category: 'error', content: `Oops! Failed to upload file ${file.name}` });
        continue;
      }
      const rwResponse = await requestWithAuth(
        `${getServerBaseUrl()}/reader/upload_files/`,
        {
          body: JSON.stringify({ content_type: file.type, reader_file_id: s3Data.file_id, file_name: file.name }),
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
        },
      );
      if (rwResponse.ok) {
        if (file.name.endsWith('.opml') || file.name.endsWith('.xml')) {
          createToast({ content: `Upload successful! More than 10 feeds may take a bit longer`, category: 'success', hasAnimatedSuccessIcon: true });
        } else {
          createToast({ content: `Upload successful`, category: 'success', hasAnimatedSuccessIcon: true });
        }
        background.pollLatestStateAndContent(1);
      } else {
        logger.warn(`Request to rw backend failed -> ${rwResponse.statusText}: ${await rwResponse.text()}`);
        createToast({ category: 'error', content: `Oops! Failed to upload file ${file.name}` });
      }
    }
    background.pollLatestStateAndContent(5);
  } catch (e) {
    createToast({ category: 'error', content: 'Oops! Failed to upload file' });
  }
}

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] ?? 'image/png';
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function upsertPDFHighlightImage({ highlightId, base64Img, isUpdate = false }: { highlightId: string; base64Img: string; isUpdate?: boolean; }) {
  try {
    const data = new FormData();
    const fileName = `${highlightId}.png`;

    if (isMobile) {
      data.append('highlight_image', {
        uri: base64Img,
        name: fileName,
        type: 'image/png',
      } as unknown as Blob);
    } else {
      const file = dataURLtoFile(base64Img, fileName);
      data.append('highlight_image', file);
    }

    const endpoint = isUpdate ? `${getServerBaseUrl()}/reader/api/update_highlight_image/${highlightId}` : `${getServerBaseUrl()}/reader/api/upload_highlight_image/`;

    const response = await requestWithAuth(
      endpoint,
      {
        body: data,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      logger.warn(`Request to rw backend failed -> ${response.statusText}: ${await response.text()}`);
    }
  } catch (e) {
    createToast({ category: 'error', content: `Oops! Failed to ${isUpdate ? 'update' : 'save'} highlight` });
  }
}

export async function deleteReaderFile({ readerFileId }: { readerFileId: string; }): Promise<string | undefined> {
  try {
    const response = await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/delete_reader_public_file/${readerFileId}`,
      {
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      logger.warn(`Request to rw backend failed -> ${response.statusText}: ${await response.text()}`);
    }
  } catch (e) {
    //
  }
}
