import React from 'react';

import Icon from './Icon';

export default function ToggleRightPanelIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="toggleRightPanelIcon">
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M10 2H15C17.2091 2 19 3.79086 19 6V14C19 16.2091 17.2091 18 15 18H10V2Z" fill="black" />
      <path d="M16.0001 6.5C16.0001 6.77614 15.7762 7 15.5001 7H11.9834C11.7073 7 11.4834 6.77614 11.4834 6.5C11.4834 6.22386 11.7073 6 11.9834 6H15.5001C15.7762 6 16.0001 6.22386 16.0001 6.5Z" fill="black" />
      <path d="M16.0001 8.5C16.0001 8.77614 15.7762 9 15.5001 9H11.9834C11.7073 9 11.4834 8.77614 11.4834 8.5C11.4834 8.22386 11.7073 8 11.9834 8H15.5001C15.7762 8 16.0001 8.22386 16.0001 8.5Z" fill="black" />
      <path d="M15.5001 11C15.7762 11 16.0001 10.7761 16.0001 10.5C16.0001 10.2239 15.7762 10 15.5001 10H11.9834C11.7073 10 11.4834 10.2239 11.4834 10.5C11.4834 10.7761 11.7073 11 11.9834 11H15.5001Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 5C19 3.34315 17.6569 2 16 2H4C2.34315 2 1 3.34315 1 5V15C1 16.6569 2.34315 18 4 18H16C17.6569 18 19 16.6569 19 15V5ZM16 3.5H4C3.17157 3.5 2.5 4.17157 2.5 5V15C2.5 15.8284 3.17157 16.5 4 16.5H16C16.8284 16.5 17.5 15.8284 17.5 15V5C17.5 4.17157 16.8284 3.5 16 3.5Z" fill="black" />
    </svg>
  </Icon>;
}
