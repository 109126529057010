import { findCenteredElementInViewport } from '../../../shared/foreground/utils/findCenteredElementInViewport';
import { TextToSpeechContentFrameError, trackError } from './errors';
import type { MobileContentFrameWindow } from './types';

const highlightableTags = new Set([
  'P',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'LI',
  'BLOCKQUOTE',
  'A',
  'SPAN',
  'FIGURE',
]);
function populateElements(parentNode: ChildNode, result: ChildNode[], tags: Set<string>): void {
  for (const childNode of parentNode.childNodes) {
    if (tags.has(childNode.nodeName)) {
      if (childNode.childNodes.length > 0 && childNode.textContent !== '') {
        result.push(childNode);
      }
    } else {
      populateElements(childNode, result, tags);
    }
  }
}

// populates the result array with a list of TTS'able nodes
// WARNING: This function closely maps to a similar function in unreal_speech_v1.py on the backend.
// Changing anything here necessitates a change in the python function too.
export function populateTtsAbleElements(
  element: ChildNode,
  result: ChildNode[],
): void {
  const readableTags = new Set([
    'P',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'LI',
    'BLOCKQUOTE',
  ]);
  populateElements(element, result, readableTags);
}

export function populateHighlightableElements(element: ChildNode, result: ChildNode[]): void {
  populateElements(element, result, highlightableTags);
}

/**
 *
 * @return object with "element" if it can be found
 */
export function findTTSableElementFromCenterOfViewport(
  documentElement: Element,
  ttsAbleElements: Element[],
  window: MobileContentFrameWindow,
): { element?: Element; index: number; } {
  if (ttsAbleElements.length === 0) {
    populateTtsAbleElements(documentElement, ttsAbleElements);
  }
    // No selection, get an element in the center of the screen
  const targetNode = findCenteredElementInViewport(ttsAbleElements, window);
  if (!targetNode) {
      // No element below center of screen, just skip back to the final TTS'able element in that case. Should be quite rare.
    return { element: ttsAbleElements[ttsAbleElements.length - 1], index: ttsAbleElements.length - 1 };
  }
  return { element: targetNode, index: ttsAbleElements.indexOf(targetNode) };
}


export function playTtsByGuessingTimestamp(
  documentElement: Element,
  ttsAbleElements: Element[],
  window: MobileContentFrameWindow,
) {
  const { element, index } = findTTSableElementFromCenterOfViewport(
    documentElement,
    ttsAbleElements,
    window,
  );
  if (!element) {
    trackError(new TextToSpeechContentFrameError('cannot play TTS, no TTSable element found from selection'), {
      extra: {
        documentElement,
        ttsAbleElements,
      },
    });
    return;
  }
  let precedingElementsTextLength = 0;
  // Add text length up to the parent node
  for (const ttsAbleElement of ttsAbleElements) {
    if (element === ttsAbleElement) {
      break;
    }
    precedingElementsTextLength += ttsAbleElement.textContent?.length ?? 0;
  }
  // We need to recursively add up all text up to this selection within the node
  const startOffset = precedingElementsTextLength;
  // TTS chars per second is 16.7 on backend
  const timestamp = startOffset / 16.7;
  window.portalGateToForeground.emit('play-tts-from-element', { elementIndex: index, timestamp });
}

export function findTtsAbleNode(
  textPos: number,
  ttsAbleElements: Element[],
): [Node | undefined, number] {
  let totalTextLength = 0;
  let currentNode;
  for (let i = 0; i < ttsAbleElements.length; i++) {
    // loop through all nodes, counting the total text length
    // Take the textPos, end the loop when the text length is greater than textPos
    let nextTextLength = totalTextLength;
    const textContent = ttsAbleElements[i].textContent;
    if (textContent) {
      nextTextLength = totalTextLength + textContent.length;
    }
    if (nextTextLength > textPos) {
      // the node which should contain the word will be the last non empty node right before we terminate.
      let j = i;
      while (j >= 0) {
        const textContent = ttsAbleElements[i].textContent;
        if (textContent && textContent.length > 0) {
          currentNode = ttsAbleElements[j];
          break;
        }
        j--;
      }
      break;
    }
    totalTextLength = nextTextLength;
  }
  const startOfParagraph = totalTextLength;
  const wordOffset = textPos - startOfParagraph;
  return [currentNode, wordOffset];
}
