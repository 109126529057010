import {
  type UserEvent,
  type YouTubePlaybackRate,
} from '../../../types';
import { DefaultYouTubePlaybackRate, YouTubePlaybackRates } from '../../../utils/youtubeConstants';
import {
  CancelStateUpdate,
  globalState,
  updateState,
} from '../../models';
import { createToast } from '../../toasts.platform';
import {
  StateUpdateOptionsWithoutEventName,
} from '../../types';

export const setYTAutoScroll = async (enabled: boolean, options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState((state) => {
    if (state.client.youtube.autoScroll === enabled) {
      throw new CancelStateUpdate();
    }
    state.client.youtube.autoScroll = enabled;
  }, { ...options, isUndoable: false, eventName: 'auto-scroll-set' });
};

export const toggleYTAutoScroll = async (options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  const newValue = !globalState.getState().client.youtube.autoScroll;

  const updateResult = await updateState((state) => {
    state.client.youtube.autoScroll = newValue;
  }, { ...options, isUndoable: false, eventName: 'auto-scroll-toggled' });

  createToast({
    content: `YouTube Auto-scroll turned ${newValue ? 'on' : 'off'}`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const setYtPlaybackRate = async (value: YouTubePlaybackRate) => {
  await updateState((state) => {
    state.client.youtube.playbackRate = value;
  }, { eventName: 'youtube-seek-to-updated', userInteraction: 'unknown', isUndoable: false });

  createToast({
    content: `Playback rate: ${value}x`,
    category: 'success',
  });
};

export const speedUpYtPlaybackRate = async () => {
  const currentPlaybackRate = globalState.getState().client.youtube.playbackRate;
  const currentPlaybackRateIndex = YouTubePlaybackRates.indexOf(currentPlaybackRate);
  const newPlaybackRate = YouTubePlaybackRates[currentPlaybackRateIndex + 1] || DefaultYouTubePlaybackRate;
  setYtPlaybackRate(newPlaybackRate);
};

export const slowDownYtPlaybackRate = async () => {
  const currentPlaybackRate = globalState.getState().client.youtube.playbackRate;
  const currentPlaybackRateIndex = YouTubePlaybackRates.indexOf(currentPlaybackRate);
  const newPlaybackRate = YouTubePlaybackRates[currentPlaybackRateIndex - 1] || DefaultYouTubePlaybackRate;
  setYtPlaybackRate(newPlaybackRate);
};
