import React from 'react';

import { Dialog } from './Dialog';

export const SkipOrDeleteAnnotatedDocsDialog = ({ isOpen, onConfirm, onCancel, onSkip }: {isOpen: boolean; onConfirm: () => void; onCancel: () => void; onSkip: () => void;}) => {
  if (isOpen) {
    return <div>
      <Dialog
        id="skip-or-delete-annotated-docs"
        title="Some documents have annotations"
        subtitle="If you want to delete ALL seen items, even if they have annotations, choose Delete all. If you want to delete every seen item except for those with annotations, choose Skip."
        actionTitle="Delete all"
        cancelTitle="Cancel"
        secondaryActionTitle="Skip"
        secondaryAction={onSkip}
        redActionButton
        blueSecondaryActionButton
        action={onConfirm}
        cancelAction={onCancel}
      />
      </div>;
  }

  return null;
};
