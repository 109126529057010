import React from 'react';

import Button from './Button';
import StrokeDeleteIcon from './icons/StrokeDeleteIcon';
import StrokePlusIcon from './icons/StrokePlusIcon';
import styles from './Tag.module.css';

type Props = {
  className?: string;
  children: string;
  hasHoverStyle?: boolean;
  isRemovable?: boolean;
  canAddTag?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClickRemoveIcon?: React.MouseEventHandler<HTMLSpanElement>;
  onClickAddIcon?: React.MouseEventHandler<HTMLSpanElement>;
};

export default (function Tag({
  children: name,
  className,
  onClick,
  onClickRemoveIcon,
  onClickAddIcon,
  hasHoverStyle = Boolean(onClick ?? onClickRemoveIcon ?? onClickAddIcon),
  isRemovable = Boolean(onClickRemoveIcon),
  canAddTag = Boolean(onClickAddIcon),
}) {
  const classes = [styles.root, className];
  if (hasHoverStyle) {
    classes.push(styles.rootHasHoverStyle);
  }

  if (isRemovable || canAddTag) {
    classes.push(styles.hasActionButton);
  }

  const commonProps = {
    className: classes.filter(Boolean).join(' '),
    onClick,
  };

  if (isRemovable) {
    return <Button
      onMouseDown={(event: React.MouseEvent<HTMLSpanElement>) => {
        // when a tag is clicked to remove it in edit tags popover, this stops the input text from the user from clearing
        event.preventDefault();
      }}
      {...commonProps}
      aria-label={`Remove "${name}"`}
      variant="unstyled">
      {name}
      <StrokeDeleteIcon
        aria-hidden
        className={styles.icon}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onClick={(event) => {
          if (!onClickRemoveIcon) {
            return;
          }
          event.preventDefault();
          event.stopPropagation();
          return onClickRemoveIcon(event);
        }}
      />
    </Button>;
  }

  if (canAddTag) {
    return <Button
      {...commonProps}
      aria-label={`Add "${name}"`}
      variant="unstyled">
      {name}
      <StrokePlusIcon
        aria-hidden
        className={styles.icon}
        onClick={(event) => {
          if (!onClickAddIcon) {
            return;
          }
          event.preventDefault();
          event.stopPropagation();
          return onClickAddIcon(event);
        }}
      />
    </Button>;
  }

  return <span {...commonProps}>
    {name}
  </span>;
}) as React.FC<Props>;
