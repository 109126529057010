import React from 'react';

import Icon from './Icon';

export default function TagIcon({ className, text = 'Tag' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.25 5.25C2.25 3.59314 3.59315 2.25 5.25 2.25H8.89002C9.58621 2.25 10.2539 2.52656 10.7462 3.01884L17.2045 9.47718C18.2296 10.5023 18.2296 12.1644 17.2045 13.1895L16.5303 13.8637C16.2374 14.1566 15.7626 14.1566 15.4697 13.8637C15.1768 13.5708 15.1768 13.0959 15.4697 12.803L16.1438 12.1288C16.5832 11.6895 16.5832 10.9772 16.1438 10.5378L9.68551 4.0795C9.47453 3.86853 9.18839 3.75 8.89002 3.75H5.25C4.42157 3.75 3.75 4.42157 3.75 5.25V8.89002C3.75 9.18839 3.86853 9.47453 4.07951 9.68551L6.28638 11.8924C6.57927 12.1853 6.57927 12.6602 6.28638 12.953C5.99348 13.2459 5.51861 13.2459 5.22572 12.953L3.01885 10.7462C2.52656 10.2539 2.25 9.58621 2.25 8.89002V5.25Z" />
      <path d="M7.5625 6.78125C7.5625 7.21272 7.21272 7.5625 6.78125 7.5625C6.34978 7.5625 6 7.21272 6 6.78125C6 6.34978 6.34978 6 6.78125 6C7.21272 6 7.5625 6.34978 7.5625 6.78125Z" />
      <path d="M11.4102 12C11.4102 11.5858 11.0744 11.25 10.6602 11.25C10.2459 11.25 9.91016 11.5858 9.91016 12V12.3535C9.46656 12.479 9.06853 12.713 8.74656 13.025L8.43666 12.8484C8.07678 12.6433 7.61878 12.7688 7.4137 13.1287C7.20861 13.4885 7.3341 13.9465 7.69397 14.1516L7.99419 14.3227C7.93931 14.5394 7.91016 14.7663 7.91016 15C7.91016 15.2337 7.93932 15.4606 7.99419 15.6773L7.69397 15.8484C7.3341 16.0535 7.20861 16.5115 7.4137 16.8714C7.61878 17.2312 8.07678 17.3567 8.43666 17.1516L8.74658 16.975C9.06854 17.287 9.46657 17.521 9.91016 17.6465V18C9.91016 18.4142 10.2459 18.75 10.6602 18.75C11.0744 18.75 11.4102 18.4142 11.4102 18V17.6465C11.8547 17.5208 12.2534 17.286 12.5758 16.9731L12.879 17.1489C13.2374 17.3566 13.6963 17.2345 13.904 16.8762C14.1118 16.5178 13.9897 16.0589 13.6314 15.8512L13.3268 15.6746C13.3812 15.4588 13.4102 15.2328 13.4102 15C13.4102 14.7673 13.3812 14.5413 13.3268 14.3254L13.6314 14.1488C13.9897 13.9411 14.1118 13.4822 13.904 13.1238C13.6963 12.7655 13.2374 12.6434 12.879 12.8512L12.5758 13.027C12.2535 12.714 11.8547 12.4792 11.4102 12.3535V12ZM10.5947 13.7517C10.6163 13.7535 10.6381 13.7545 10.6602 13.7545C10.6822 13.7545 10.704 13.7535 10.7256 13.7517C11.3855 13.7857 11.9102 14.3316 11.9102 15C11.9102 15.6684 11.3855 16.2143 10.7255 16.2483C10.704 16.2465 10.6822 16.2455 10.6602 16.2455C10.6381 16.2455 10.6164 16.2465 10.5948 16.2483C10.1821 16.2271 9.82228 16.0056 9.61061 15.6792C9.60313 15.6637 9.59507 15.6484 9.58642 15.6332C9.57527 15.6136 9.56338 15.5947 9.55079 15.5766C9.46094 15.4041 9.41016 15.208 9.41016 15C9.41016 14.7921 9.46092 14.596 9.55072 14.4235C9.56334 14.4054 9.57525 14.3864 9.58642 14.3668C9.59509 14.3516 9.60317 14.3362 9.61067 14.3207C9.82233 13.9944 10.1821 13.773 10.5947 13.7517Z" />
    </svg>
  </Icon>;
}

