import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

export const PlaySvg = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.93192 13.0053V2.99463C2.93192 2.49327 3.47137 2.17708 3.90881 2.42205L12.6765 7.33198C13.1986 7.62433 13.1986 8.37566 12.6765 8.668L3.90881 13.5779C3.47137 13.8229 2.93192 13.5067 2.93192 13.0053Z" />
</svg>`;

export const StopSvg = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3" y="3" width="10" height="10" rx="1" />
</svg>`;

export const updateTTSButton = (ttsPlaying: boolean, ttsEnabled = false) => {
  const buttonElement = document.querySelector<HTMLStyleElement>('.tts-play-button');
  if (!buttonElement) {
    return;
  }
  if (!ttsEnabled) {
    buttonElement.style.display = 'none';
  } else {
    buttonElement.style.display = 'initial';
  }
  buttonElement.classList.remove('tts-playing');
  if (ttsPlaying) {
    buttonElement.classList.add('tts-playing');
  }
};


export default () => {
  window.ttsButtonClicked = () => {
    window.portalGateToForeground.emit('tts_button_clicked');
  };
};
