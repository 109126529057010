import { KeyboardLayout, ShortcutId } from '../../../../shared/types';

const isMac = navigator.platform.toLowerCase().includes('mac');

const replaceKeyByPlatform = (keys: string): string => {
  return keys
    .replace(/CmdOrCtrl/g, isMac ? 'meta' : 'ctrl');
};

export const baseShortcuts = {
  [ShortcutId.Esc]: 'esc',
  [ShortcutId.Enter]: 'enter',
  [ShortcutId.Down]: 'down',
  [ShortcutId.Up]: 'up',
  [ShortcutId.Tab]: 'tab',
  [ShortcutId.PreviousTab]: 'shift+tab',
  [ShortcutId.Right]: 'right',
  [ShortcutId.Left]: 'left',
  [ShortcutId.Space]: 'space',
  [ShortcutId.Shift]: 'shift',
  [ShortcutId.Option]: 'option',
  [ShortcutId.Alt]: 'alt',
  [ShortcutId.CmdOrCtrl]: replaceKeyByPlatform('CmdOrCtrl'),
  [ShortcutId.CmdOrCtrlAndTab]: replaceKeyByPlatform('CmdOrCtrl+tab'),
};

export const blackBoxKeys = [
  baseShortcuts[ShortcutId.Esc],
  baseShortcuts[ShortcutId.Enter],
  baseShortcuts[ShortcutId.CmdOrCtrl],
  baseShortcuts[ShortcutId.CmdOrCtrlAndTab],
  baseShortcuts[ShortcutId.Shift],
  baseShortcuts[ShortcutId.Option],
  baseShortcuts[ShortcutId.Alt],
  '#',
  // Because these are the defaults to open pinned views
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const keysAllowedToBeInMoreThanOneShortcut = [
  baseShortcuts[ShortcutId.Up],
  baseShortcuts[ShortcutId.Down],
];

export const defaultShortcutsMapByLayout = {
  [KeyboardLayout.QwertyUS]: {
    [ShortcutId.GoToHome]: ['1'],
    [ShortcutId.GoToLibrary]: ['2'],
    [ShortcutId.Feed]: ['3'],
    [ShortcutId.Archive]: ['e'],
    [ShortcutId.Later]: ['l'],
    [ShortcutId.New]: ['shift+e'],
    [ShortcutId.Shortlist]: ['s'],
    [ShortcutId.Bump]: ['b'],
    [ShortcutId.Share]: ['shift+c'],
    [ShortcutId.ShareReaderLink]: ['shift+l'],
    [ShortcutId.HidePanels]: [replaceKeyByPlatform('CmdOrCtrl+#')],
    [ShortcutId.HideLeftPanel]: ['['],
    [ShortcutId.HideRightPanel]: [']'],
    [ShortcutId.Search]: ['/'],
    [ShortcutId.OpenShortcutsMenu]: ['shift+?'],
    [ShortcutId.ShowDocMetadata]: ['shift+m'],
    [ShortcutId.GoToNextDocument]: ['j'],
    [ShortcutId.GoToPreviousDocument]: ['k'],
    [ShortcutId.Tags]: ['t'],
    [ShortcutId.DocumentReaderTag]: ['shift+t'],
    [ShortcutId.ToggleDocAsOpened]: [baseShortcuts[ShortcutId.Space]],
    // FYI: # is \
    // 🤦‍♂️ https://github.com/JohannesKlauss/react-hotkeys-hook/blob/1864de312f4f378bf9fa55f71dc34cef1ca3f9a4/src/parseHotkeys.ts#L13
    [ShortcutId.ToggleZenMode]: [replaceKeyByPlatform('CmdOrCtrl+shift+#')],
    [ShortcutId.ToggleDarkMode]: [replaceKeyByPlatform('CmdOrCtrl+alt+t')],
    [ShortcutId.FullScreen]: [isMac ? 'ctrl+cmd+f' : 'F11'],
    [ShortcutId.CycleTypeFace]: ['ctrl+shift+f'],
    [ShortcutId.IncreaseFontSize]: ['shift+='],
    [ShortcutId.DecreaseFontSize]: ['shift+-'],
    [ShortcutId.WidenLineLength]: ['shift+.'],
    [ShortcutId.NarrowLineLength]: ['shift+comma'],
    [ShortcutId.IncreaseLineSpacing]: ['shift+"'],
    [ShortcutId.DecreaseLineSpacing]: ['shift+:'],
    [ShortcutId.PdfZoomIn]: ['shift+add', 'shift+='],
    [ShortcutId.PdfZoomOut]: ['shift+-'],
    [ShortcutId.PdfZoomReset]: ['shift+0'],
    [ShortcutId.PdfRotateLeft]: ['r'],
    [ShortcutId.PdfSnapshot]: [replaceKeyByPlatform('shift+CmdOrCtrl+s')],
    [ShortcutId.PdfFind]: [replaceKeyByPlatform('CmdOrCtrl+f')],
    [ShortcutId.PdfJumpToPage]: ['shift+j'],
    [ShortcutId.ManageFeedSubscriptions]: ['shift+a'],
    [ShortcutId.OpenFiltersCmdPanel]: ['shift+f'],
    [ShortcutId.OpenFilteredViews]: ['v'],
    [ShortcutId.OpenSplitBySubMenu]: ['#'],
    [ShortcutId.ToggleRssOrEmailSubscription]: ['shift+s'],
    [ShortcutId.OpenBulkActionsSubMenu]: ['shift+b'],
    [ShortcutId.OpenOriginalDoc]: ['o'],
    [ShortcutId.ToggleDocMoreActions]: ['m'],
    [ShortcutId.Undo]: ['z', replaceKeyByPlatform('CmdOrCtrl+z')],
    [ShortcutId.Redo]: ['y'],
    [ShortcutId.Esc]: [baseShortcuts[ShortcutId.Esc]],
    [ShortcutId.AddToFavorites]: ['f'],
    [ShortcutId.Down]: [baseShortcuts[ShortcutId.Down]],
    [ShortcutId.Up]: [baseShortcuts[ShortcutId.Up]],
    [ShortcutId.Highlight]: ['h'],
    [ShortcutId.Note]: ['n'],
    [ShortcutId.Ghostreader]: ['g'],
    [ShortcutId.DocGhostreader]: ['shift+g'],
    [ShortcutId.Enter]: [baseShortcuts[ShortcutId.Enter]],
    [ShortcutId.OpenCmdPalette]: [replaceKeyByPlatform('CmdOrCtrl+k')],
    [ShortcutId.ToggleNotebookView]: ['shift+1'],
    [ShortcutId.MoveUpFocusIndicator]: [baseShortcuts[ShortcutId.Up], 'shift+space'],
    [ShortcutId.MoveDownFocusIndicator]: [baseShortcuts[ShortcutId.Down], baseShortcuts[ShortcutId.Space]],
    [ShortcutId.ScrollToTop]: ['home', replaceKeyByPlatform('CmdOrCtrl+up')],
    [ShortcutId.ScrollToBottom]: ['end', replaceKeyByPlatform('CmdOrCtrl+down')],
    [ShortcutId.PageUp]: ['pageup'],
    [ShortcutId.PageDown]: ['pagedown'],
    [ShortcutId.Tab]: [baseShortcuts[ShortcutId.Tab]],
    [ShortcutId.PreviousTab]: [baseShortcuts[ShortcutId.PreviousTab]],
    [ShortcutId.PreviousMetadataItem]: [baseShortcuts[ShortcutId.PreviousTab], baseShortcuts[ShortcutId.Up]],
    [ShortcutId.NextMetadataItem]: [baseShortcuts[ShortcutId.Down], baseShortcuts[ShortcutId.Tab]],
    [ShortcutId.EnableOrViewPublicLink]: ['alt+s'],
    [ShortcutId.ResetReadingProgress]: ['shift+r'],
    [ShortcutId.ToggleAutoHighlighting]: ['shift+h'],
    [ShortcutId.CopyText]: [replaceKeyByPlatform('CmdOrCtrl+c')],
    [ShortcutId.OpenSaveDocFromUrlPalette]: ['a'],
    [ShortcutId.UploadFile]: ['u'],
    [ShortcutId.CmdOrCtrlAndEnter]: [replaceKeyByPlatform('CmdOrCtrl+enter')],
    [ShortcutId.SaveLinkToReader]: ['shift+o'],
    [ShortcutId.AddDocNote]: ['shift+n'],
    [ShortcutId.MarkAllFeedAsSeen]: [isMac ? 'shift+cmd+option+space' : 'ctrl+shift+space'],
    [ShortcutId.PlayOrPause]: ['p'],
    [ShortcutId.Stop]: ['shift+p'],
    [ShortcutId.ToggleVideoControls]: ['shift+v'],
    [ShortcutId.ToggleYtAutoScroll]: ['shift+enter'],
    [ShortcutId.SlowDownPlayBack]: ['comma'],
    [ShortcutId.SpeedUpPlayback]: ['.'],
    [ShortcutId.SkipForward]: [baseShortcuts[ShortcutId.Right]],
    [ShortcutId.SkipBackwards]: [baseShortcuts[ShortcutId.Left]],
    [ShortcutId.DecreaseVolume]: [`shift+${baseShortcuts[ShortcutId.Down]}`],
    [ShortcutId.IncreaseVolume]: [`shift+${baseShortcuts[ShortcutId.Up]}`],
    [ShortcutId.MediaPlay]: [`play`],
    [ShortcutId.MediaPause]: [`pause`],
    [ShortcutId.MediaStop]: [`stop`],
    [ShortcutId.MediaSeekBackward]: [`seekbackward`],
    [ShortcutId.MediaSeekForward]: [`seekforward`],
    [ShortcutId.MediaPreviousTrack]: [`nexttrack`],
    [ShortcutId.MediaNextTrack]: [`nexttrack`],
    [ShortcutId.ExportCopyToClipboard]: ['shift+alt+c'],
    [ShortcutId.ExportDownloadAnnotations]: ['shift+alt+d'],
    [ShortcutId.GoToPinnedView1]: ['4'],
    [ShortcutId.GoToPinnedView2]: ['5'],
    [ShortcutId.GoToPinnedView3]: ['6'],
    [ShortcutId.GoToPinnedView4]: ['7'],
    [ShortcutId.GoToPinnedView5]: ['8'],
    [ShortcutId.GoToPinnedView6]: ['9'],
    [ShortcutId.NextSplit]: [baseShortcuts[ShortcutId.Tab]],
    [ShortcutId.PreviousSplit]: [baseShortcuts[ShortcutId.PreviousTab]],
    [ShortcutId.Exit]: [baseShortcuts[ShortcutId.Esc]],
    [ShortcutId.DeleteDocument]: ['d'],
    [ShortcutId.SwitchRightSidebarTab]: ['`'],
    [ShortcutId.SwitchRightSidebarTabBackward]: ['shift+`'],
    [ShortcutId.OpenDocumentNoteActionsDropdown]: ['m'],
    [ShortcutId.CopyDocumentNote]: [replaceKeyByPlatform('CmdOrCtrl+c')],
    [ShortcutId.DeleteDocumentNote]: [
      replaceKeyByPlatform('CmdOrCtrl+delete'),
      replaceKeyByPlatform('CmdOrCtrl+backspace'),
    ],
    [ShortcutId.OpenNotebookHighlightActionsDropdown]: ['m'],
    [ShortcutId.EditHighlightTagsInNotebook]: ['t'],
    [ShortcutId.EditHighlightNoteInNotebook]: ['n'],
    [ShortcutId.ViewNotebookHighlightInDocument]: ['enter'],
    [ShortcutId.ViewHighlightInNotebookView]: ['shift+enter'],
    [ShortcutId.CopyNotebookHighlightText]: [replaceKeyByPlatform('CmdOrCtrl+c')],
    [ShortcutId.CopyNotebookHighlightNote]: [replaceKeyByPlatform('shift+CmdOrCtrl+c')],
    [ShortcutId.DeleteHighlightInNotebook]: [
      replaceKeyByPlatform('CmdOrCtrl+delete'),
      replaceKeyByPlatform('CmdOrCtrl+backspace'),
    ],
    [ShortcutId.DownloadDocument]: ['shift+d'],
    [ShortcutId.PrintDocument]: [replaceKeyByPlatform('CmdOrCtrl+p')],
    [ShortcutId.ChangeSidebarContentType]: ['shift+BRACKETLEFT'],
    [ShortcutId.Wildcard]: ['*'],
  },

  [KeyboardLayout.German]: {
    [ShortcutId.OpenFiltersCmdPanel]: ['shift+q'],
  },
};
