import React from 'react';

import Icon from './Icon';

export default function ChevronUp({ className = '', text = 'up' }: { text?: string; className?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 6.75C10.1989 6.75 10.3897 6.82902 10.5303 6.96967L15.5303 11.9697C15.8232 12.2626 15.8232 12.7374 15.5303 13.0303C15.2374 13.3232 14.7626 13.3232 14.4697 13.0303L10 8.56066L5.53033 13.0303C5.23744 13.3232 4.76256 13.3232 4.46967 13.0303C4.17678 12.7374 4.17678 12.2626 4.46967 11.9697L9.46967 6.96967C9.61032 6.82902 9.80109 6.75 10 6.75Z" fill="currentColor" />
    </svg>
  </Icon>;
}
