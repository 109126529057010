import React from 'react';

import { getTimeFromSeconds } from '../../../shared/utils/getTimeFromSeconds';
import styles from './TimeToListen.module.css';

type Props = {
  className?: string;
  listeningTimeInSeconds: number;
};

export default function TimeToListen ({
  listeningTimeInSeconds,
  className,
}: Props) {
  return (
    <span className={`${styles.timeToListen} ${className}`}>
      {getTimeFromSeconds(listeningTimeInSeconds)}
    </span>
  );
}
