import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { updateDocumentLocation } from '../../../shared/foreground/actions/documentLocations';
import { safeDelete } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import getUIFriendlyNameForDocumentLocation from '../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import { DocumentLocation, FeedDocumentLocation, ShortcutId, SplitByKey } from '../../../shared/types';
import documentLocationIconMap from '../utils/documentLocationIconMap';
import { getSplitByKeyOrSplittingByKeyFromLocation } from '../utils/pathnameHelpers';
import { documentLocationShortcutIdMap, useShortcutsMap } from '../utils/shortcuts';
import styles from './DocumentActionButtons.module.css';
import Tooltip from './Tooltip';

export default function DocumentActionButtons({
  actionDelay = 0,
  afterAction,
  beforeAction,
  buttonClassName = '',
  docId,
  docDocumentLocation,
}: {docId: string; docDocumentLocation?: DocumentLocation | null; buttonClassName?: string; actionDelay?: number; beforeAction?: (isMovingTo: string) => void; afterAction?: (...args: unknown[]) => void;}): JSX.Element {
  const history = useHistory();
  const { feedDocumentLocation: filterByFeedDocumentLocation } = useParams<{feedDocumentLocation?: FeedDocumentLocation;}>();
  const [isMovingTo, setIsMovingTo] = useState(''); // location, or 'delete' for document deletion

  const isFeedView = Boolean(filterByFeedDocumentLocation);
  const location = history.location as unknown as Location;
  const splitBy = getSplitByKeyOrSplittingByKeyFromLocation(location);
  const isSplitBySeen = splitBy === SplitByKey.Seen;
  const isFeedOrSplitBySeen = isFeedView || isSplitBySeen;

  // Sometimes we need a before/after callback E.g to toggle CSS classes in the
  // document list and have the nice effect when removing a document.
  const setIsMovingToWithBeforeCallback = (_isMovingTo: string) => {
    if (beforeAction) {
      beforeAction(_isMovingTo);
    }

    setIsMovingTo(_isMovingTo);
  };

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (!isMovingTo) {
        return;
      }
      setIsMovingTo('');

      if (isMovingTo === 'delete') {
        await safeDelete(docId, { userInteraction: 'click' });
      } else {
        await updateDocumentLocation(docId, isMovingTo as DocumentLocation, { userInteraction: 'click' });
      }

      // The after action on delete is being set in the DeleteDocumentDialog
      if (afterAction && isMovingTo !== 'delete') {
        afterAction();
      }
    }, actionDelay);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMovingTo, afterAction, isFeedOrSplitBySeen]);

  const documentLocations = useDocumentLocations();

  return (
    <>
      {documentLocations
        .map((documentLocationItem) => {
          const isCurrentDocumentLocation = documentLocationItem === docDocumentLocation;

          const onClick = async () => {
            if (isSplitBySeen) {
              setIsMovingTo(documentLocationItem);
            } else {
              setIsMovingToWithBeforeCallback(documentLocationItem);
            }
          };

          return (
            <DocumentLocationActionButton
              className={buttonClassName}
              documentLocation={documentLocationItem}
              isActive={isCurrentDocumentLocation}
              isFeedView={isFeedView}
              key={documentLocationItem}
              onClick={onClick}
            />
          );
        }).filter(Boolean)}
    </>
  );
}

interface ActionButtonProps {
  name: string;
  onClick?: (...args: unknown[]) => void;
  tooltipText: string;
  shortcut?: string | string[];
  className?: string;
  isActive?: boolean;
  children?: React.ReactNode;
}

// TODO: use <Button> instead of <button>
export function ActionButton ({ name, onClick, className = '', isActive = false, children, tooltipText, shortcut }: ActionButtonProps): JSX.Element {
  const _name = name.toLowerCase();

  const contentAttributes = {
    className: [
      'contentWrapper',
      'button',
      styles.actionButton,
      className,
      _name, // Used by end-to-end tests
      isActive ? styles.isActive : '',
    ].join(' '),
    onClick: (event: React.MouseEvent) => {
      event.preventDefault();
      onClick?.();
    },
  };

  let contentWrapper: JSX.Element;
  if (onClick) {
    contentWrapper = <button
      {...contentAttributes}
      tabIndex={-1}
      type="button">
        {children}
      </button>;
  } else {
    contentWrapper = <span {...contentAttributes}>
      {children}
    </span>;
  }

  return <Tooltip content={tooltipText} shortcut={shortcut}>
    {contentWrapper}
  </Tooltip>;
}

export function DocumentLocationActionButton ({
  className = '',
  documentLocation,
  isActive = false,
  isFeedView = false,
  onClick,
}: {
  className?: string;
  documentLocation: DocumentLocation;
  isActive?: boolean;
  isFeedView?: boolean;
  onClick: (...args: unknown[]) => void;
}): JSX.Element {
  const shortcutsMap = useShortcutsMap();
  const Icon = documentLocationIconMap[documentLocation];

  const isDisabled = isActive;
  const documentLocationUIFriendly = getUIFriendlyNameForDocumentLocation(documentLocation);

  const getTooltipText = () => {
    if (isActive) {
      return `Remove from ${documentLocationUIFriendly}`;
    }

    return `${isFeedView ? 'Save' : 'Move'} to ${documentLocationUIFriendly}`;
  };

  const tooltipText = getTooltipText();

  const buttonClasses = [
    'button',
    styles.actionButton,
    styles.documentLocationActionButton,
    className,
    documentLocation, // Used by end-to-end tests
    isActive ? styles.isActive : '',
    isDisabled ? styles.isDisabled : '',
  ];

  const Button = <button
    className={buttonClasses.join(' ')}
    tabIndex={-1}
    onClick={(evt) => {
      evt.stopPropagation();
      evt.preventDefault();

      if (isDisabled) {
        return;
      }

      onClick();
    }}
    type="button"
    >
    <Icon text={tooltipText} />
  </button>;

  if (isDisabled) {
    return Button;
  }

  const shortcutId = documentLocationShortcutIdMap[documentLocation];
  const shortcut = shortcutsMap[ShortcutId[shortcutId]];

  return <Tooltip content={tooltipText} shortcut={shortcut}>
    {Button}
  </Tooltip >;
}
