import React, { useState } from 'react';
import { MangoQueryNoLimit } from 'rxdb';

import { showModal } from '../../../../shared/foreground/actions/modals';
import {
  openBulkActionsSubMenu,
  openEditViewSubMenu,
  openFiltersSubMenu,
  openSaveFilterSubMenu,
  openSplitBySubMenu,
} from '../../../../shared/foreground/cmdPalette';
import database from '../../../../shared/foreground/database';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import background from '../../../../shared/foreground/portalGates/toBackground/singleProcess';
import { updateFilteredView } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { queueJob } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import { setFocusedViewId } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { AnyDocument, FilteredView, JobType } from '../../../../shared/types';
import { focusPaletteInput } from './docOptions';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown';

export const getEditFilterQuerOption = ({ isDisabled = false, view }: {
  isDisabled?: boolean;
  view?: FilteredView;
}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Edit filter',
  isDisabled,
  onSelect: () => {
    if (!view) {
      openFiltersSubMenu();
      return;
    }

    setFocusedViewId(view.id);
    openEditViewSubMenu();
    focusPaletteInput();
  },
});

export const getEditLibraryLocationsOption = (): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Edit Library locations',
  onSelect: () => {
    showModal({ id: 'library-locations' }, { userInteraction: 'unknown' });
  },
});

export const getUnpinFromSidebarOption = ({ isDisabled = false, view }: {
  isDisabled?: boolean;
  view?: FilteredView;
}): DropdownOption => {
  const name = view?.isUnpinned ? 'Pin to sidebar' : 'Unpin from sidebar';

  return {
    type: DropdownOptionType.Item,
    name,
    isDisabled,
    onSelect: () => {
      if (view) {
        updateFilteredView({
          ...view,
          isUnpinned: !view.isUnpinned,
        }, { userInteraction: 'keypress' });
      }
    },
  };
};

export const getOpenBulkActionsOption = ({ isDisabled = false, shortcut }: {
  isDisabled?: boolean;
  shortcut?: string | string[];
}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Apply bulk action',
  shortcut,
  isDisabled,
  onSelect: () => {
    openBulkActionsSubMenu();
    focusPaletteInput();
  },
});

export const getSplitViewOption = ({ isDisabled = false, shortcut }: {
  isDisabled?: boolean;
  shortcut?: string | string[];
}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Split view',
  shortcut,
  isDisabled,
  onSelect: () => {
    openSplitBySubMenu();
    focusPaletteInput();
  },
});

export const getShowBadgeCountOption = ({ isDisabled = false, view }: {
  isDisabled?: boolean;
  view?: FilteredView;
}): DropdownOption => {
  const name = view?.showCountBadge ? 'Hide count badge' : 'Show count badge';

  return {
    type: DropdownOptionType.Item,
    name,
    isDisabled,
    onSelect: () => {
      if (view) {
        updateFilteredView({
          ...view,
          showCountBadge: !view.showCountBadge,
        }, { userInteraction: 'keypress' });
      }
    },
  };
};


export const getEnableBundleLinkOption = ({ isDisabled = false, view, documentIdsQuery }: {
  isDisabled?: boolean;
  view?: FilteredView;
  documentIdsQuery?: MangoQueryNoLimit<AnyDocument>;
}): DropdownOption => {

  const bundleIsEnabled = Boolean(view?.sharedAsBundle);

  const name = bundleIsEnabled ? 'Disable public link' : 'Enable public link';


  return {
    type: DropdownOptionType.Item,
    name,
    isDisabled,
    onSelect: async () => {
      if (!view) {
        return;
      }
      let documentIds: string[] = [];
      if (!bundleIsEnabled) {
        // we are going to be enabling the bundle, so we need to fetch documents
        documentIds = await database.collections.documents.findIds({ ...documentIdsQuery, limit: 500 });
      }

      if (!documentIds.length) {
        createToast({
          content: 'No documents found in this view.',
          category: 'error',
        });
        return;
      }

      await updateFilteredView({
        ...view,
        sharedAsBundle: !view.sharedAsBundle,
      }, { userInteraction: 'click' });

      eventEmitter.emit('filter-view-shared-as-bundle-clicked');

      await queueJob({
        jobType: JobType.UpdateBundle,
        jobArguments: { filtered_view_id: view.id, enabled: !bundleIsEnabled, document_ids: documentIds },
        options: { userInteraction: 'click' },
      });

      background.pollLatestStateAndContent(2);
    },
  };
};


export const getSaveViewOption = ({ isDisabled = false }: { isDisabled?: boolean; }): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Save view',
  isDisabled,
  onSelect: () => {
    openSaveFilterSubMenu();
    focusPaletteInput();
  },
});

export const getDeleteViewOption = ({ isDisabled = false, onSelect }: {
  isDisabled?: boolean;
  onSelect?: () => void;
}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Delete view',
  isDanger: true,
  isDisabled,
  onSelect,
});

export default function DropdownMainHeader({ triggerElement, options }: {
  triggerElement: JSX.Element;
  options: DropdownOption[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      trigger={triggerElement}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      sideOffset={-10}
      appendToDocumentBody
    />
  );
}
