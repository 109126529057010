import React from 'react';

import Icon from './Icon';

export default function StrokeArrowUpRight({ className = '', text = 'ArrowUpRight' }: {className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.12868 5.75732C7.12868 5.34311 7.46446 5.00732 7.87868 5.00732L14.9926 5.00732L14.9926 12.1213C14.9926 12.5355 14.6568 12.8713 14.2426 12.8713C13.8284 12.8713 13.4926 12.5355 13.4926 12.1213V7.56798L6.28768 14.7729C5.99479 15.0658 5.51992 15.0658 5.22702 14.7729C4.93413 14.48 4.93413 14.0052 5.22702 13.7123L12.432 6.50732L7.87868 6.50732C7.46446 6.50732 7.12868 6.17154 7.12868 5.75732Z" fill="#616C77" />
    </svg>
  </Icon>;
}
