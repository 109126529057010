import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MangoQuery } from 'rxdb';

import { getUserFriendlyQuery } from '../../../shared/filters-compiler/getUserFriendlyQuery';
import { Token } from '../../../shared/filters-compiler/types';
import { openEditFeedSubMenu, openEditTagSubMenu, openSaveFilterSubMenu } from '../../../shared/foreground/cmdPalette';
import { globalState, SplitBySeenValues } from '../../../shared/foreground/models';
import { removeFilteredView } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import {
  setFocusedFeedId,
  setFocusedTagId,
} from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { toggleHideRightSidebar } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import getUIFriendlyNameForDocumentLocation
  from '../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import getUIFriendlyNameFromFeedDocumentLocation
  from '../../../shared/foreground/utils/getUIFriendlyNameFromFeedDocumentLocation';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import type { AnyDocument, FilteredView, SettingsState, SplitByValue, SplitTab } from '../../../shared/types';
import { DocumentLocation, FeedDocumentLocation, ShortcutId, SplitByKey } from '../../../shared/types';
import {
  getCategoriesIconMap,
  getIsFilteringByOneRss,
  getIsFilteringByOneTag,
  isAllowedToDeleteFilteredView,
  isAllowedToEditFilteredView,
} from '../../../shared/utils/filteredViews';
import { useHotKeys, useIsRightSidebarHidden } from '../hooks/hooks';
import { isValidQuery } from '../utils/isValidQuery';
import { getCurrentDocumentLocationFromPathname, getFeedDocumentLocationFromPathname } from '../utils/pathnameHelpers';
import { useShortcutsMap } from '../utils/shortcuts';
import useLocation from '../utils/useLocation';
import BundleLinkButton from './BundleLinkButton';
import Button from './Button';
import { DeleteViewDialog } from './DeleteViewDialog';
import { getSeparatorOption } from './Dropdown/docOptions';
import { DropdownOption } from './Dropdown/Dropdown';
import DropdownMainHeader, {
  getDeleteViewOption,
  getEditFilterQuerOption,
  getEditLibraryLocationsOption,
  getEnableBundleLinkOption,
  getOpenBulkActionsOption,
  getSaveViewOption,
  getShowBadgeCountOption,
  getSplitViewOption,
  getUnpinFromSidebarOption,
} from './Dropdown/DropdownMainHeader';
import ArticlesIconHeader from './icons/ArticlesIconHeader';
import BooksIconHeader from './icons/BooksIconHeader';
import ChevronDown from './icons/ChevronDownSmallIcon';
import EmailsIconHeader from './icons/EmailsIconHeader';
import FeedIconHeader from './icons/FeedIconHeader';
import FeedManagerIcon from './icons/FeedManagerIcon';
import LibraryIconHeader from './icons/LibraryIcon';
import PdfsIconHeader from './icons/PdfsIconHeader';
import TagIcon from './icons/TagIcon';
import TagsManagerIcon from './icons/TagsManagerIcon';
import ToggleRightPanelIcon from './icons/ToggleRightPanelIcon';
import TweetIconHeader from './icons/TweetIconHeader';
import VideosIconHeader from './icons/VideosIconHeader';
import styles from './InboxHeader.module.css';
import MainContentHeader from './MainContentHeader';
import { SortButton } from './SortView/SortView';
import Tooltip from './Tooltip';

interface InboxHeaderProps {
  isFeed?: boolean;
  filterQuery?: string;
  splitByUrlParam?: string;
  splitValue?: string;
  openDocumentId?: string;
  savedView?: FilteredView;
  splitBy?: string;
  filterTokens?: Token[];
  documentIdsQuery?: MangoQuery<AnyDocument>;
  listId: string;
}

export default function InboxHeader({ listId, filterTokens, splitByUrlParam, splitBy, savedView, filterQuery, splitValue, openDocumentId, isFeed = false, documentIdsQuery }: InboxHeaderProps): JSX.Element {
  const shortcutsMap = useShortcutsMap();

  const [isDeleteViewDialogOpen, setIsDeleteViewDialogOpen] = useState(false);
  const location = useLocation();
  const activeDocumentLocation = useMemo(() => getCurrentDocumentLocationFromPathname(location.pathname), [location.pathname]);
  const activeFeedDocumentLocation = useMemo(() => getFeedDocumentLocationFromPathname(location.pathname), [location.pathname]);

  const isFilterView = Boolean(filterQuery);
  const isValidView = useMemo(() => isValidQuery(filterQuery || ''), [filterQuery]);
  const isLibrary = !isFilterView && !isFeed;
  const isFilteringByOneTag = getIsFilteringByOneTag(filterTokens);
  const isFilteringByOneRss = getIsFilteringByOneRss(filterTokens);

  useEffect(() => {
    if (!isFilteringByOneRss || !filterTokens) {
      setFocusedFeedId(null);
      return;
    }

    const focusedFeedIdToken = filterTokens[2];

    if (focusedFeedIdToken) {
      setFocusedFeedId(focusedFeedIdToken.value);
    }
  }, [isFilteringByOneRss, filterTokens]);

  useEffect(() => {
    if (!isFilteringByOneTag || !filterTokens) {
      setFocusedTagId(null);
      return;
    }

    const focusedTagIdToken = filterTokens[2];

    if (focusedTagIdToken) {
      setFocusedTagId(focusedTagIdToken.value);
    }
  }, [isFilteringByOneTag, filterTokens]);

  const documentLocations = useDocumentLocations();

  const tabs = useMemo(() => {
    if (isFilterView) {
      return getSplitTabs({
        documentLocations,
        filterQuery,
        splitBy,
        splitByUrlParam,
        splitValue,
        view: savedView,
      });
    }

    return getRegularTabs({
      activeDocumentLocation,
      activeFeedDocumentLocation,
      documentLocations,
      isFeed,
    });
  }, [
    activeDocumentLocation,
    activeFeedDocumentLocation,
    documentLocations,
    filterQuery,
    isFeed,
    isFilterView,
    savedView,
    splitBy,
    splitByUrlParam,
    splitValue,
  ]);

  useShortcuts({ tabs, openDocumentId, splitBy, splitValue });
  const rightSidebarHidden = useIsRightSidebarHidden();

  const docListScrolled = globalState(useCallback((state) => {
    return state.documentsListScrolled;
  }, []));

  const isInboxZero = globalState(useCallback((state) => state.isInboxZero, []));

  const onDeleteView = useCallback(() => {
    if (savedView) {
      removeFilteredView(savedView.id, { userInteraction: 'click' });
    }
    setIsDeleteViewDialogOpen(false);
  }, [savedView]);

  const hideRightPanelShortcut = shortcutsMap[ShortcutId.HideRightPanel];

  return <>
    <DeleteViewDialog isOpen={isDeleteViewDialogOpen} onConfirm={onDeleteView} onCancel={() => setIsDeleteViewDialogOpen(false)} />
    <MainContentHeader
      className={`${styles.header} ${isInboxZero ? styles.headerInboxZero : ''}`}
      isPageScrolled={docListScrolled}>
      <div className={styles.headerLeft}>
        <Heading {...{ savedView, isFeed, filterQuery, filterTokens, setIsDeleteViewDialogOpen, documentIdsQuery }} />
        {isFilterView && <SplitTabs {...{ view: savedView, tabs, splitBy }} />}
        {!isFilterView && <Tabs tabs={tabs} />}
      </div>

      <div className={styles.headerRight}>
        {isFilterView && !savedView && isValidView &&
          <HeadingButton type="primary" onClick={openSaveFilterSubMenu}>
            Save view
          </HeadingButton>
        }
        {savedView && <BundleLinkButton view={savedView} documentIdsQuery={documentIdsQuery} />}
        {isLibrary &&
          <HeadingButton>
            <Link to="/tags">
              <TagsManagerIcon />
              Manage tags
            </Link>
          </HeadingButton>
        }
        {isFeed &&
          <HeadingButton>
            <Link to="/feed/sources">
              <FeedManagerIcon />
              Manage feeds
            </Link>
          </HeadingButton>
        }
        {isFilteringByOneTag &&
          <HeadingButton onClick={openEditTagSubMenu}>
            <TagIcon />
            Edit tag
          </HeadingButton>
        }
        {isFilteringByOneRss &&
          <HeadingButton onClick={openEditFeedSubMenu}>
            <FeedIconHeader />
            Edit feed
          </HeadingButton>
        }
        {!isInboxZero &&
          isAllowedToEditFilteredView(savedView?.id) &&
          <SortButton currentFilteredView={savedView} listId={listId} />}
        {rightSidebarHidden && <Tooltip content="Show right panel" shortcut={hideRightPanelShortcut}>
          <Button
            className={[
              styles.actionButton,
              styles.hidePanelsButton,
            ].join(' ')}
            tabIndex={-1}
            onClick={() => {
              toggleHideRightSidebar({ userInteraction: 'click' });
            }}
          >
            <ToggleRightPanelIcon />
          </Button>
        </Tooltip>}
      </div>
    </MainContentHeader>
  </>;
}

const HeadingButton = ({ children, onClick = () => null, isDisabled = false, tooltipText = '', type = 'default', className = '' }: {children: React.ReactNode; onClick?: () => void; isDisabled?: boolean; tooltipText?: string; type?: 'default' | 'primary'; className?: string;}): JSX.Element => {
  return (
    <Tooltip content={tooltipText}>
      <button type="button" onClick={onClick} className={`${styles.headingButton} ${styles[type]} ${className} ${isDisabled ? styles.isDisabled : ''}`}>
        {children}
      </button>
    </Tooltip>
  );
};

const categoriesIconMap = getCategoriesIconMap({
  articlesIcon: <ArticlesIconHeader />,
  emailsIcon: <EmailsIconHeader />,
  pdfsIcon: <PdfsIconHeader />,
  epubsIcon: <BooksIconHeader />,
  tweetsIcon: <TweetIconHeader />,
  videosIcon: <VideosIconHeader />,
});

const Heading = ({ savedView, filterTokens, isFeed, setIsDeleteViewDialogOpen, filterQuery, documentIdsQuery }: {savedView: InboxHeaderProps['savedView']; isFeed: InboxHeaderProps['isFeed']; filterTokens: InboxHeaderProps['filterTokens']; setIsDeleteViewDialogOpen: (val: boolean) => void; filterQuery: InboxHeaderProps['filterQuery']; documentIdsQuery: InboxHeaderProps['documentIdsQuery'];}) => {
  const rssFeeds = globalState(useCallback((state) => state.persistent.rssFeeds, []));

  const defaultTitle = useMemo(() => {
    if (filterTokens) {
      const friendlyQuery = getUserFriendlyQuery({ tokens: filterTokens, rssFeeds });

      setTimeout(() => {
        document.title = `${savedView?.name ?? friendlyQuery} | Readwise`;
      }, 0);

      if (savedView && categoriesIconMap[savedView.query]) {
        return <>
          <span className={styles.headingIcon}>
            {categoriesIconMap[savedView.query]}
          </span>
          {savedView.name}
        </>;
      }

      return <Tooltip content={friendlyQuery}><span>{savedView?.name ?? friendlyQuery}</span></Tooltip>;
    }

    if (isFeed) {
      return <><FeedIconHeader className={styles.headingIcon} /> Feed</>;
    }

    return <><LibraryIconHeader className={styles.headingIcon} /> Library</>;

  }, [filterTokens, savedView, rssFeeds, isFeed]);

  const shortcutsMap = useShortcutsMap();
  const openBulkActionsShortcut = shortcutsMap[ShortcutId.OpenBulkActionsSubMenu];
  const splitViewShortcut = shortcutsMap[ShortcutId.OpenSplitBySubMenu];

  const isValidView = isValidQuery(filterQuery || '');

  const getSaveOrDeleteViewOption = useCallback(() => {
    // We are in Library or Feed
    if (!filterTokens) {
      return getDeleteViewOption({ isDisabled: true });
    }

    if (savedView) {
      return getDeleteViewOption({
        isDisabled: !isAllowedToDeleteFilteredView(savedView.id),
        onSelect: () => setIsDeleteViewDialogOpen(true),
      });
    }

    if (!isValidView) {
      return getSaveViewOption({ isDisabled: true });
    }

    return getSaveViewOption({ isDisabled: false });

  }, [filterTokens, savedView, isValidView, setIsDeleteViewDialogOpen]);

  const options = useMemo(() => {
    const isFilteredView = Boolean(filterTokens);
    const cantEditView = !isFilteredView || !isAllowedToEditFilteredView(savedView?.id);

    return [
      getEditFilterQuerOption({ isDisabled: cantEditView, view: savedView }),
      getUnpinFromSidebarOption({ isDisabled: !savedView, view: savedView }),
      !isFeed && !isFilteredView && getEditLibraryLocationsOption(),
      getOpenBulkActionsOption({ isDisabled: false, shortcut: openBulkActionsShortcut }),
      getSplitViewOption({ isDisabled: cantEditView, shortcut: splitViewShortcut }),
      getShowBadgeCountOption({ isDisabled: !savedView, view: savedView }),
      getEnableBundleLinkOption({ isDisabled: !savedView, view: savedView, documentIdsQuery }),
      getSeparatorOption(),
      getSaveOrDeleteViewOption(),
    ].filter(Boolean) as DropdownOption[];
  }, [filterTokens, getSaveOrDeleteViewOption, isFeed, savedView, openBulkActionsShortcut, splitViewShortcut, documentIdsQuery]);

  const triggerElement =
    <DropdownMenu.Trigger className={styles.heading}>
      {defaultTitle}
      <ChevronDown className={styles.chevronDown} />
    </DropdownMenu.Trigger>;

  return (
    <>
      <DropdownMainHeader triggerElement={triggerElement} options={options} />
    </>
  );
};

const getRegularTabs = ({
  activeDocumentLocation,
  activeFeedDocumentLocation,
  documentLocations,
  isFeed,
}: {
  activeDocumentLocation?: DocumentLocation;
  activeFeedDocumentLocation?: FeedDocumentLocation;
  documentLocations: DocumentLocation[];
  isFeed: boolean;
}): SplitTab[] => {
  if (isFeed) {
    return Object.values(FeedDocumentLocation).map((feedDocumentLocation) => {
      return {
        title: getUIFriendlyNameFromFeedDocumentLocation(feedDocumentLocation),
        toUrl: `/${DocumentLocation.Feed}/${feedDocumentLocation}`,
        isActive: feedDocumentLocation === activeFeedDocumentLocation,
      };
    });
  }

  return (documentLocations ?? [])
    .filter((documentLocation) => documentLocation !== DocumentLocation.Feed)
    .map((documentLocation) => ({
      isActive: documentLocation === activeDocumentLocation,
      title: getUIFriendlyNameForDocumentLocation(documentLocation),
      toUrl: `/${documentLocation}`,
    }));
};

const getSplitTabUrl = ({ view, option, filterQuery, splitBy }: {view?: FilteredView; option: SplitByValue; filterQuery: InboxHeaderProps['filterQuery']; splitBy?: string;}) => {
  if (filterQuery) {
    return `/filter/${encodeURIComponent(filterQuery)}/split/${splitBy}/${option.queryParamValue}`;
  }

  return '';
};

const getSplitTabs = ({
  documentLocations,
  filterQuery,
  splitBy,
  splitValue,
  view,
}: {
  documentLocations: SettingsState['documentLocations'];
  filterQuery: InboxHeaderProps['filterQuery'];
  splitBy?: string;
  splitByUrlParam?: string;
  splitValue: InboxHeaderProps['splitValue'];
  view?: FilteredView;
}): SplitTab[] => {
  let tabs: SplitTab[] = [];

  switch (splitBy) {
    case SplitByKey.DocumentLocation: {
      tabs = documentLocations.map((documentLocation) => ({
        isActive: splitValue === documentLocation,
        title: getUIFriendlyNameForDocumentLocation(documentLocation),
        toUrl: getSplitTabUrl({
          filterQuery,
          option: {
            name: documentLocation,
            queryParamValue: documentLocation,
          },
          splitBy,
          view,
        }),
      }));

      break;
    }

    case SplitByKey.Seen: {
      const options = SplitBySeenValues;

      tabs = Object.keys(options).map((option) => ({
        title: options[option].name,
        toUrl: getSplitTabUrl({ view, option: options[option], filterQuery, splitBy }),
        isActive: splitValue === options[option].queryParamValue,
      }));

      break;
    }
  }

  return tabs;
};

const Tab = ({ isActive, title, toUrl, className = '' }: { isActive: boolean; title: string; toUrl: string; className?: string;}) => {
  return <Link
    to={toUrl}
    className={`${styles.headerTab} ${className} ${isActive ? styles.active : ''}`}>
      <span className={styles.headerTabTitle}>{title}</span>
      {isActive && <span className={styles.activeBorder} />}
  </Link>;
};

const Tabs = ({ tabs }: {tabs: SplitTab[];}) => {
  return <>
    {tabs.map(({ isActive, title, toUrl }) => <Tab
      isActive={isActive}
      key={title}
      title={title}
      toUrl={toUrl}
    />)}
  </>;
};

const SplitTabs = ({ tabs, splitBy, view }: {tabs: SplitTab[]; splitBy?: string; view?: FilteredView;}) => {
  if (!splitBy) {
    return null;
  }

  return <Tabs tabs={tabs} />;
};

function getNextOptionUrl({ tabs, direction }: {tabs?: SplitTab[]; direction: number;}): string {
  if (!tabs) {
    return '';
  }

  const activeIndex = tabs.findIndex((splitTab) => splitTab.isActive);

  if (direction > 0) {
    const firstOption = tabs[0];
    return tabs[activeIndex + 1] ? tabs[activeIndex + 1].toUrl : firstOption.toUrl;
  }

  const lastOption = tabs[tabs.length - 1];
  return tabs[activeIndex - 1] ? tabs[activeIndex - 1].toUrl : lastOption.toUrl;
}

const useShortcuts = ({ tabs, openDocumentId, splitBy, splitValue }: { tabs?: SplitTab[]; openDocumentId?: string; splitBy?: string; splitValue?: string;}) => {
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();
  const isDocumentMetadataShown = globalState(useCallback((state) => state.isDocumentMetadataShown, []));

  // We don't want to run these shortcuts if we are on the document
  // reader view or editing metadata
  useHotKeys(
    shortcutsMap[ShortcutId.NextSplit],
    useCallback((event) => {
      if (openDocumentId || isDocumentMetadataShown || !splitBy) {
        return;
      }

      event.preventDefault();

      const nextOptionUrl = getNextOptionUrl({ tabs, direction: +1 });

      history.push(nextOptionUrl);
    }, [history, isDocumentMetadataShown, openDocumentId, splitBy, tabs]),
    {
      description: 'Cycle forward through splits',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.PreviousSplit],
    useCallback((event) => {
      // We don't want to run this shortcut if we are on the document
      // reader view or editing metadata
      if (openDocumentId || isDocumentMetadataShown || !splitBy) {
        return;
      }

      event.preventDefault();

      const nextOptionUrl = getNextOptionUrl({ tabs, direction: -1 });
      history.push(nextOptionUrl);
    }, [history, isDocumentMetadataShown, openDocumentId, splitBy, tabs]),
    {
      description: 'Cycle backward through splits',
    },
  );
};
