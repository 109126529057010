import { isHTMLElement } from '../../typeValidators';
import closestWith from './closestWith';

export default function getClosestHTMLElement<T extends HTMLElement>(
  node: Parameters<typeof closestWith>[0],
  matcher: (element: HTMLElement) => boolean | undefined = () => true,
  containerNode?: Node,
): T | undefined {
  return closestWith<T>(
    node,
    (node) => isHTMLElement(node) && matcher(node),
    containerNode,
  );
}
