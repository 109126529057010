import React from 'react';

import Icon from './Icon';

export default function SolidCheckIcon({
  className = '',
  text = 'Check',
}: { className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM14.5303 8.03033C14.8232 7.73744 14.8232 7.26256 14.5303 6.96967C14.2374 6.67678 13.7626 6.67678 13.4697 6.96967L8.5 11.9393L6.53033 9.96967C6.23744 9.67678 5.76256 9.67678 5.46967 9.96967C5.17678 10.2626 5.17678 10.7374 5.46967 11.0303L7.96967 13.5303C8.26256 13.8232 8.73744 13.8232 9.03033 13.5303L14.5303 8.03033Z" />
</svg>
  </Icon>;
}
