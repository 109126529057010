/*
  `classList.add`, for example, *always* modifies the element.
*/

export function add (
  element: HTMLElement,
  ...classes: string[]
): ReturnType<HTMLElement['classList']['add']> {
  if (!element) {
    throw new Error('No element given');
  }
  const filteredClasses = classes.filter((className) => !element.classList.contains(className));
  if (!filteredClasses.length) {
    return;
  }
  return element.classList.add(...classes);
}

export function remove (
  element: HTMLElement,
  ...classes: string[]
): ReturnType<HTMLElement['classList']['remove']> {
  if (!element) {
    throw new Error('No element given');
  }
  const filteredClasses = classes.filter((className) => element.classList.contains(className));
  if (!filteredClasses.length) {
    return;
  }
  return element.classList.remove(...classes);
}

export default {
  add,
  remove,
};
