import React from 'react';

import Icon from './Icon';

export default function ToggleLeftPanelIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="toggleLeftPanelIcon">
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M1 6C1 3.79086 2.79086 2 5 2H10V18H5C2.79086 18 1 16.2091 1 14V6Z" fill="black" />
      <path d="M8.51669 6.5C8.51669 6.77614 8.29283 7 8.01669 7H4.5C4.22386 7 4 6.77614 4 6.5C4 6.22386 4.22386 6 4.5 6H8.01669C8.29283 6 8.51669 6.22386 8.51669 6.5Z" fill="black" />
      <path d="M8.51669 8.5C8.51669 8.77614 8.29283 9 8.01669 9H4.5C4.22386 9 4 8.77614 4 8.5C4 8.22386 4.22386 8 4.5 8H8.01669C8.29283 8 8.51669 8.22386 8.51669 8.5Z" fill="black" />
      <path d="M8.01669 11C8.29283 11 8.51669 10.7761 8.51669 10.5C8.51669 10.2239 8.29283 10 8.01669 10H4.5C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H8.01669Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 5C19 3.34315 17.6569 2 16 2H4C2.34315 2 1 3.34315 1 5V15C1 16.6569 2.34315 18 4 18H16C17.6569 18 19 16.6569 19 15V5ZM16 3.5H4C3.17157 3.5 2.5 4.17157 2.5 5V15C2.5 15.8284 3.17157 16.5 4 16.5H16C16.8284 16.5 17.5 15.8284 17.5 15V5C17.5 4.17157 16.8284 3.5 16 3.5Z" fill="black" />
    </svg>
  </Icon>;
}
