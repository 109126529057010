import type { RangySelection } from '../types/rangy';
import contractRangeIfPossible from './contractRangeIfPossible';
import isImage from './isImage';


// NOTE: this mutates the input selection
const trimSelectionStart = (rangySelection: RangySelection): void => {
  if (!rangySelection.rangeCount) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(0);
  if (isImage(rangyRange.startContainer) || isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset]) || isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset + 1])) {
    return;
  }

  const selectedText = rangySelection.toString();
  if (!selectedText || !/^\s/.test(rangySelection.toString())) {
    return;
  }

  const didContract = contractRangeIfPossible({
    doc: rangySelection.anchorNode?.ownerDocument ?? undefined,
    endToMove: 'start',
    rangyRange,
  });
  if (!didContract) {
    return;
  }
  const textAfterContraction = rangyRange.toString();
  if (!textAfterContraction || !textAfterContraction.includes(selectedText.trim())) {
    return;
  }
  rangySelection.setSingleRange(rangyRange);

  // Safety check; blocks hypotethical infinite loop
  if (rangySelection.toString() === selectedText) {
    return;
  }
  trimSelectionStart(rangySelection);
};


// NOTE: this mutates the input selection
const trimSelectionEnd = (rangySelection: RangySelection): void => {
  const rangyRange = rangySelection.getRangeAt(rangySelection.rangeCount - 1);
  if (isImage(rangyRange.endContainer) || isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset]) || isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset - 1])) {
    return;
  }

  const selectedText = rangySelection.toString();
  if (!selectedText || !/\s$/.test(rangySelection.toString())) {
    return;
  }

  const didContract = contractRangeIfPossible({
    doc: rangySelection.anchorNode?.ownerDocument ?? undefined,
    endToMove: 'end',
    rangyRange,
  });

  if (!didContract) {
    return;
  }
  const textAfterContraction = rangyRange.toString();
  if (!textAfterContraction || !textAfterContraction.includes(selectedText.trim())) {
    return;
  }
  rangySelection.setSingleRange(rangyRange);

  // Safety check; blocks hypotethical infinite loop
  if (rangySelection.toString() === selectedText) {
    return;
  }
  trimSelectionEnd(rangySelection);
};

// NOTE: this mutates the input selection
export default (...args: Parameters<typeof trimSelectionStart>): void => {
  trimSelectionStart(...args);
  trimSelectionEnd(...args);
};
