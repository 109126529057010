import React from 'react';

import Icon from './Icon';

export default function StrokeCancelIcon({
  text = 'Cancel',
  className = '',
}: {
  text?: string;
  className?: string;
} & React.HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L10 8.93934L13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967C14.8232 5.76256 14.8232 6.23744 14.5303 6.53033L11.0607 10L14.5303 13.4697C14.8232 13.7626 14.8232 14.2374 14.5303 14.5303C14.2374 14.8232 13.7626 14.8232 13.4697 14.5303L10 11.0607L6.53033 14.5303C6.23744 14.8232 5.76256 14.8232 5.46967 14.5303C5.17678 14.2374 5.17678 13.7626 5.46967 13.4697L8.93934 10L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" />
    </svg>
  </Icon>;
}
