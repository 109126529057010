
export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
export const HEADING_TAG_SELECTOR = HEADING_TAGS.join(', ');

export const EPUB_HEADING_SELECTOR = '[data-rw-epub-toc]';

const HEADING_ID_PREFIX = 'h__';

export function createHeadingIdFromIndex(index: number): string {
  return `${HEADING_ID_PREFIX}${index}`;
}

export function parseHeadingIdToIndex(hash: string): number | undefined {
  const index = parseInt(hash.replace(`#${HEADING_ID_PREFIX}`, ''), 10);
  if (Number.isNaN(index)) {
    return undefined;
  }
  return index;
}

export function getHeadingSelector(isEpub: boolean): string {
  if (isEpub) {
    return EPUB_HEADING_SELECTOR;
  } else {
    return HEADING_TAG_SELECTOR;
  }
}

export function parseHeadingLevel(heading: HTMLElement, useCustomAttributes = false) {
  if (useCustomAttributes) {
    return parseInt(heading.dataset?.rwTocLevel || '1', 10);
  } else {
    return parseInt(heading.tagName.substring(1), 10);
  }
}
