import { DocumentLocation, FeedDocumentLocation, SettingsState } from '../../types';

export default ({
  currentDocumentLocation,
  documentLocations,
  currentFeedDocumentLocation,
  direction,
}: {
  currentDocumentLocation: DocumentLocation;
  currentFeedDocumentLocation?: FeedDocumentLocation;
  documentLocations: SettingsState['documentLocations'];
  direction: number;
}): DocumentLocation | FeedDocumentLocation => {
  const keys = currentDocumentLocation === DocumentLocation.Feed
    ? Object.values(FeedDocumentLocation)
    : documentLocations;

  const currentIndex = keys.findIndex((key) => {
    return key === currentFeedDocumentLocation || key === currentDocumentLocation;
  });

  if (direction > 0) {
    const firstDocumentLocationView = keys[0];
    return keys[currentIndex + 1] || firstDocumentLocationView;
  }

  const lastDocumentLocationView = keys[keys.length - 1];
  return keys[currentIndex - 1] || lastDocumentLocationView;
};
