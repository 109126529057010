import React from 'react';

import Icon from '../icons/Icon';

export default function ArticlesIcon({ className = '' }: {className?: string;}): JSX.Element {
  return <Icon
    text="ArticlesIcon">
      <svg className={className} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.25 15C3.25 14.5858 3.58579 14.25 4 14.25H12C12.4142 14.25 12.75 14.5858 12.75 15C12.75 15.4142 12.4142 15.75 12 15.75H4C3.58579 15.75 3.25 15.4142 3.25 15Z" fill="#A1AAC4" />
      <path d="M4 10.25C3.58579 10.25 3.25 10.5858 3.25 11C3.25 11.4142 3.58579 11.75 4 11.75H12C12.4142 11.75 12.75 11.4142 12.75 11C12.75 10.5858 12.4142 10.25 12 10.25H4Z" fill="#A1AAC4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 7.65685C16 6.59599 15.5786 5.57857 14.8284 4.82843L11.1716 1.17157C10.4214 0.421428 9.40401 0 8.34315 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16L16 7.65685ZM4 18.5H12C13.3807 18.5 14.5 17.3807 14.5 16L14.5 7.65685C14.5 6.99381 14.2366 6.35793 13.7678 5.88909L10.1109 2.23223C9.64207 1.76339 9.00619 1.5 8.34315 1.5L4 1.5C2.61929 1.5 1.5 2.61929 1.5 4L1.5 16C1.5 17.3807 2.61929 18.5 4 18.5Z" fill="#A1AAC4" />
      </svg>
  </Icon>;
}
