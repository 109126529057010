import { DocumentLocation } from '../../types';
import capitalize from '../../utils/capitalize';

export default function getUIFriendlyNameForDocumentLocation (
  documentLocation: DocumentLocation,
  shouldCapitalize = true,
): string {
  let result: string = documentLocation;
  if (documentLocation === DocumentLocation.New) {
    result = 'inbox';
  }
  if (shouldCapitalize) {
    return capitalize(result);
  }
  return result;
}
