import React from 'react';

import Icon from './Icon';

export default function TagIcon({ className, text = 'Tag' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.07612 7.38268C6.02586 7.26135 6 7.13132 6 6.99999C6 6.86867 6.02586 6.73864 6.07612 6.61731C6.12637 6.49598 6.20003 6.38575 6.29289 6.29289C6.38575 6.20003 6.49599 6.12637 6.61732 6.07611C6.73864 6.02586 6.86868 5.99999 7 5.99999C7.13132 5.99999 7.26136 6.02586 7.38268 6.07611C7.50401 6.12637 7.61425 6.20003 7.70711 6.29289C7.79996 6.38575 7.87362 6.49598 7.92388 6.61731C7.97413 6.73864 8 6.86867 8 6.99999C8 7.13132 7.97413 7.26135 7.92388 7.38268C7.87362 7.504 7.79996 7.61424 7.70711 7.7071C7.61425 7.79996 7.50401 7.87362 7.38268 7.92387C7.26136 7.97413 7.13132 7.99999 7 7.99999C6.86868 7.99999 6.73864 7.97413 6.61732 7.92387C6.49599 7.87362 6.38575 7.79996 6.29289 7.7071C6.20003 7.61424 6.12637 7.504 6.07612 7.38268Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5 2.25C3.25736 2.25 2.25 3.25736 2.25 4.5V8.56802C2.25 9.36367 2.56607 10.1267 3.12868 10.6893L9.87868 17.4393C11.0503 18.6109 12.9497 18.6109 14.1213 17.4393L17.4393 14.1213C18.6109 12.9497 18.6109 11.0503 17.4393 9.87868L10.6893 3.12868C10.1267 2.56607 9.36367 2.25 8.56802 2.25H4.5ZM3.75 4.5C3.75 4.08579 4.08579 3.75 4.5 3.75L8.56802 3.75C8.96584 3.75 9.34737 3.90804 9.62868 4.18934L16.3787 10.9393C16.9645 11.5251 16.9645 12.4749 16.3787 13.0607L13.0607 16.3787C12.4749 16.9645 11.5251 16.9645 10.9393 16.3787L4.18934 9.62868C3.90803 9.34737 3.75 8.96585 3.75 8.56802L3.75 4.5Z" fill="black" />
    </svg>
  </Icon>;
}

