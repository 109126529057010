import React from 'react';

import Icon from './Icon';

export default function StrokeDeleteIcon({
  text = 'delete',
  ...extraProps
}: {
  text?: string;
} & React.HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return <span {...extraProps}>
    <Icon text={text}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.05548 6.99479C7.76258 6.70189 7.28771 6.70189 6.99482 6.99479C6.70192 7.28768 6.70192 7.76255 6.99482 8.05545L8.93936 9.99999L6.99482 11.9445C6.70192 12.2374 6.70192 12.7123 6.99482 13.0052C7.28771 13.2981 7.76258 13.2981 8.05548 13.0052L10 11.0606L11.9446 13.0052C12.2375 13.2981 12.7123 13.2981 13.0052 13.0052C13.2981 12.7123 13.2981 12.2374 13.0052 11.9445L11.0607 9.99999L13.0052 8.05545C13.2981 7.76255 13.2981 7.28768 13.0052 6.99479C12.7123 6.70189 12.2375 6.70189 11.9446 6.99479L10 8.93933L8.05548 6.99479Z" fill="black" />
</svg>
    </Icon>
  </span>;
}
