import React from 'react';

import { useIsRightSidebarHidden } from '../hooks/hooks';
import { readerViewUrl } from '../utils/pathnameHelpers';
import useLocation from '../utils/useLocation';
import styles from './FloatingPill.module.css';

export const FloatingPill = ({ children, position = 'right', className = '' }: {children: JSX.Element | string; position?: 'right' | 'left'; className?: string;}) => {
  const isRightSidebarHidden = useIsRightSidebarHidden();
  const { pathname } = useLocation();
  const isReaderViewUrl = readerViewUrl.test(pathname);

  if (isReaderViewUrl) {
    return null;
  }

  return (
    <div className={`${styles.floatingPill} ${position === 'right' ? styles.isRightPosition : styles.isLeftPosition} ${isRightSidebarHidden ? styles.isRightSidebarHidden : ''} ${className}`}>
      {children}
    </div>
  );
};
