import React from 'react';

import Icon from './Icon';

export default function PlayIcon({ className, text = 'Play' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9V1L7 5.4L1 9Z" fill="currentColor" stroke="currentColor" />
    </svg>
  </Icon>;
}
