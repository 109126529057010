import React, { Suspense } from 'react';

import { reactLazy } from '../utils/dynamicImport';
import styles from './StandardPage.module.css';

const InboxSidebar = reactLazy(() => import('./InboxSidebar'));

type Props = {
  children: JSX.Element | JSX.Element[];
  isSidebarHidden?: boolean;
};

export default (function StandardPage({
  children,
  isSidebarHidden,
}) {
  const sidebar = isSidebarHidden ? null : <InboxSidebar />;
  return (
    <div className={styles.page}>
      <Suspense fallback={null}>
        {sidebar}
      </Suspense>
      {children}
    </div>
  );
} as React.FC<Props>);
