import { ulid } from 'ulid';

import {
  type Job,
  type JobType,
  JobStatus,
} from '../../../types';
import nowTimestamp from '../../../utils/dates/nowTimestamp';
// eslint-disable-next-line import/no-cycle
import {
  updateState,
} from '../../models';
import { StateUpdateOptionsWithoutEventName } from '../../types';

export const queueJob = async (
  { jobType, jobArguments, options }:
    { jobType: JobType; jobArguments: Job['arguments']; options: StateUpdateOptionsWithoutEventName; },
): Promise<void> => {
  await updateState((state) => {
    const jobId = ulid().toLowerCase();
    if (!state.persistent.jobs) {
      state.persistent.jobs = {};
    }
    state.persistent.jobs[jobId] = {
      id: jobId,
      created: nowTimestamp(),
      status: JobStatus.Queued,
      type: jobType,
      arguments: jobArguments,
      resultData: {},
    };
  }, { ...options, eventName: 'queue-server-job' });
};
