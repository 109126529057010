import type { MangoQuery } from 'rxdb';

import { enableDocumentShare } from '../../../../shared/foreground/actions/documentShare';
import { showModal } from '../../../../shared/foreground/actions/modals';
import { openDocumentGptSubMenu } from '../../../../shared/foreground/cmdPalette';
import copyDocumentUrlOnWeb from '../../../../shared/foreground/copyDocumentUrlOnWeb';
import foregroundEventEmitter from '../../../../shared/foreground/eventEmitter';
import { ForegroundEventName, updateState } from '../../../../shared/foreground/models';
import { getDocument } from '../../../../shared/foreground/stateGetters';
import {
  bumpDocumentForUser,
  safeDelete,
  toggleDocumentOpened,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import {
  markDocumentsAsOpenOrUnopen,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/bulk';
import {
  updateReadingPosition,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/progressRelated';
import {
  setIsDocMoreActionsDropdownOpen,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/dropdowns';
import { toggleDocumentMetadataOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { exportHighlightsToClipboard } from '../../../../shared/foreground/utils/exportHighlightsToClipboard';
import { exportHighlightsToFile } from '../../../../shared/foreground/utils/exportHighlightsToFile';
import { AnyDocument, DocumentWithTransientData, FirstClassDocument } from '../../../../shared/types';
import exceptionHandlerPlatform from '../../../../shared/utils/exceptionHandler.platform';
import { printDocumentContent } from '../../../../shared/utils/printDocumentContent';
import { sliceDocListQuery } from '../../../../shared/utils/sliceDocListQuery';
import focusDocumentNoteField from '../../utils/focusDocumentNoteField';
import { openOriginalDocument } from '../../utils/openOriginalDocument';
import styles from '../CommandPalette/CommandPalette.module.css';
import { DropdownOption, DropdownOptionType } from './Dropdown';

export const focusPaletteInput = () => {
  // TODO: maybe there is a less hacky way? :(
  setTimeout(() => {
    const input = document.querySelector(`.${styles.paletteInput}`) as HTMLInputElement;
    input?.focus();
  }, 100);
};

export const getTitleOption = (name: string, className = ''): DropdownOption => ({
  type: DropdownOptionType.Title,
  name,
  className,
});

export const getSeparatorOption = (): DropdownOption => ({
  type: DropdownOptionType.Separator,
});

export const getAddTagOption = ({
  onSelect,
  shortcut,
}: {
  onSelect?: () => void;
  shortcut?: string | string[];
} = {}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Add document tag',
  shortcut,
  onSelect,
});

export const getAddDocNoteOption = ({ shortcut }: {shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Add document note',
  shortcut,
  onSelect: () => {
    focusDocumentNoteField();
  },
});

export const getBumpOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Bump to top',
  shortcut,
  onSelect: () => {
    bumpDocumentForUser(docId, { userInteraction: 'click' });
  },
});

export const getEnablePublicLinkOption = ({
  docId,
  isPublicLinkEnabled,
  onSelect,
  shortcut,
  isDisabled = false,
}: {
  docId: AnyDocument['id'];
  isPublicLinkEnabled?: boolean;
  shortcut: string | string[];
  onSelect?: () => void;
  isDisabled?: boolean;
}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: isPublicLinkEnabled ? 'View public link' : 'Enable public link',
  isDisabled,
  onSelect: () => {
    enableDocumentShare({ docId, userInteraction: 'unknown' });
    onSelect?.();
  },
  shortcut,
});

export const getMarkAsSeenOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Mark as seen',
  shortcut,
  onSelect: () => {
    toggleDocumentOpened(docId, true);
  },
});

export const getMarkAsUnseenOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Mark as unseen',
  shortcut,
  onSelect: () => {
    toggleDocumentOpened(docId, false);
  },
});

export const getMarkAsSeenOrUnseenOption = ({ doc, shortcut }: { doc: AnyDocument; shortcut: string | string[];}): DropdownOption => {
  const docId = doc.id;

  if (doc.firstOpenedAt) {
    return getMarkAsUnseenOption({ docId, shortcut });
  }
  return getMarkAsSeenOption({ docId, shortcut });
};

async function sliceDocumentListQueryAndMarkAs(
  documentListQuery: MangoQuery<AnyDocument> | null,
  direction: 'above' | 'below',
  cutoffDocId: string,
  markAsOpen: boolean,
) {
  if (!documentListQuery) {
    exceptionHandlerPlatform.captureException(new Error('getMarkAboveAsSeenOption: no documentListQuery'));
    return;
  }
  const cutoffDoc = await getDocument(cutoffDocId);
  if (!cutoffDoc) {
    exceptionHandlerPlatform.captureException(new Error('getMarkAboveAsSeenOption: no cutoffDoc'), {
      extra: {
        documentListQuery,
      },
    });
    return;
  }
  const slicedQuery = sliceDocListQuery(documentListQuery, direction, cutoffDoc);

  markDocumentsAsOpenOrUnopen({
    docQuery: slicedQuery,
    markAsOpen,
    options: { userInteraction: 'click' },
  });
}

export const getMarkAboveAsSeenOption = ({ docId, documentListQuery }: { docId: AnyDocument['id']; documentListQuery: MangoQuery<AnyDocument> | null; }): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Mark all as seen above',
  onSelect: () => {
    sliceDocumentListQueryAndMarkAs(documentListQuery, 'above', docId, true);
  },
});

export const getMarkBelowAsSeenOption = ({ docId, documentListQuery }: { docId: AnyDocument['id']; documentListQuery: MangoQuery<AnyDocument> | null; }): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Mark all as seen below',
  onSelect: () => {
    sliceDocumentListQueryAndMarkAs(documentListQuery, 'below', docId, true);
  },
});

export const getEditMetadataOption = ({ shortcut }: {shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Edit metadata',
  shortcut,
  onSelect: () => {
    toggleDocumentMetadataOpen({ userInteraction: 'click' });
  },
});


export const getGptOption = (newDocument = false, document: DocumentWithTransientData<FirstClassDocument> | null | void, shortcut: string | string[]): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Invoke Ghostreader',
  shortcut,
  isDisabled: !document?.transientData.content || document?.transientData.content.length === 0,
  disabledTooltip: 'This document is missing a text layer',
  onSelect: () => {
    if (newDocument) {
      updateState((state) => {
        state.gptPrompt = state.gptPrompt ?? {};
        state.gptPrompt.newDocument = true;
      }, { userInteraction: 'unknown', eventName: 'gpt-set-new-document' });
    }
    openDocumentGptSubMenu();
  },
});

export const getResetReadingProgressOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Reset reading progress',
  shortcut,
  onSelect: () => {
    updateReadingPosition(docId, {
      scrollDepth: 0,
      serializedPosition: null,
    }, {
      eventName: 'document-progress-position-updated',
      force: true,
      showToast: true,
      userInteraction: 'click',
    });
  },
});

export const getOpenOriginalOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Open original',
  shortcut,
  onSelect: () => {
    openOriginalDocument(docId);
  },
});

export const getCopyDocUrlOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Copy document URL',
  shortcut,
  onSelect: () => {
    copyDocumentUrlOnWeb(docId);
  },
});

export const getDeleteDocOption = ({ docId, callback, shortcut }: { docId: AnyDocument['id']; callback?: () => void; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Delete document',
  shortcut,
  isDanger: true,
  onSelect: async () => {
    await safeDelete(docId, { userInteraction: 'click' });

    // We need to update this right now so then the focus doc can change
    // We check this flag before changing the focused doc.
    // https://linear.app/readwise/issue/RW-12624/bug-when-navigating-the-feed-using-keyboard-shortcuts-after-closing-or
    window.isRadixDropdownOpen = false;

    // Without this timeout, the enter shortcut fires too soon
    setTimeout(() => {
      setIsDocMoreActionsDropdownOpen(false);
    }, 0);

    if (callback) {
      callback();
    }
  },
});

export const getExportHighlightsOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Copy to clipboard',
  shortcut,
  onSelect: async () => {
    await exportHighlightsToClipboard(docId);
  },
});


export const getExportHighlightsDownloadOption = ({ docId, shortcut }: { docId: AnyDocument['id']; shortcut: string | string[];}): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Download annotations',
  shortcut,
  onSelect: async () => {
    await exportHighlightsToFile(docId);
  },
});

export const getExportHighlightsEditTemplateOption = (): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: 'Edit export template',
  onSelect: () => {
    showModal({ id: 'export-template' }, { userInteraction: 'unknown' });
  },
});

export const getDownloadOrPrintDocOption = ({ docTitle, docNotes, isPdfViewer = false, printShortcut, downloadShortcut, author, publishedDate }: { docTitle: string; author: string; publishedDate: string; docNotes?: string; isPdfViewer?: boolean; printShortcut?: string | string[]; downloadShortcut?: string | string[]; }): DropdownOption => ({
  type: DropdownOptionType.Item,
  name: isPdfViewer ? 'Download with annotations' : 'Print with annotations',
  shortcut: isPdfViewer ? downloadShortcut : printShortcut,
  onSelect: () => {
    if (isPdfViewer) {
      foregroundEventEmitter.emit(ForegroundEventName.DownloadPdf);
      return;
    }

    printDocumentContent({ docTitle, docNotes, author, publishedDate });
  },
});
