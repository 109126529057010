import { useCallback, useEffect } from 'react';

import { globalState } from './foreground/models';
import { resetDocumentSummaryGeneration } from './foreground/stateUpdaters/transientStateUpdaters/documentSummary';
import { createToast } from './foreground/toasts.platform';
import { generateSummary } from './foreground/utils/inference';
import type { AnyDocument, DocumentWithTransientData, PartialDocument } from './types';
import { isDocumentWithSummary } from './typeValidators';
import getDocumentOverrideOrReal from './utils/getDocumentOverrideOrReal';

/**
 * @return
 *
 * `generatedSummary`: The GPT-generated summary if it exists, else undefined,
 *
 * `extractedSummary`: The summary extracted during parsing if it exists, else undefined.
 *
 * `summary`: The summary to display to the user. Generated summary, extracted summary, or empty string, in that order.
 *
 */
export function getSummaries(document: AnyDocument | DocumentWithTransientData | PartialDocument<AnyDocument, 'summary' | 'category' | 'generated_summary' | 'overrides'>): {
  generatedSummary: string | undefined;
  extractedSummary: string | undefined;
  summary: string;
} {
  const extractedSummary = isDocumentWithSummary(document) &&
    getDocumentOverrideOrReal(document, 'summary') || undefined;
  const generatedSummary = isDocumentWithSummary(document) &&
    getDocumentOverrideOrReal(document, 'generated_summary') || undefined;

  const summary = generatedSummary || extractedSummary || '';
  return { generatedSummary, extractedSummary, summary };
}

/**
 * A document summary isn't just a simple field, it is a field that might
 * contain a value extracted from the document, or a generated summary which is
 * either created manually by the user, or via the auto-summarization feature
 * for power users that bring their own OpenAI key.
 *
 * This hook is a convenience hook, to hide the complexity of dealing with
 * changes to those summaries, as well as handling undo actions, etc. Under all
 * circumstances, it will return a valid summary {@link String}, even the value
 * might be blank.
 */
export function useSummary(document: DocumentWithTransientData | PartialDocument<AnyDocument, 'summary' | 'generated_summary' | 'category' | 'id' | 'overrides'>) {
  const { generatedSummary, summary } = getSummaries(document);
  const isGenerated = Boolean(generatedSummary);
  const isGenerating = globalState((state) => state.isDocumentSummaryGenerating);

  useEffect(() => {
    resetDocumentSummaryGeneration('unknown');
  }, [document.id]);
  const generate = useCallback(async () => {
    try {
      await generateSummary(document.id, 'click');
    } catch (_err) {
      createToast({
        content: 'Failed to generate summary',
        category: 'error',
      });
    }
  }, [document.id]);

  return { summary, generate, isGenerating, isGenerated };
}
