import React, { useCallback, useEffect, useState } from 'react';

import { FontSizeNumberDesktop, getNextTshirtSizeFromKeys, globalState, LineHeightNumberDesktop, LineLengthNumberDesktop, WEB_CSS_FONTS, WEB_FONTS_SANS_SERIF, WEB_FONTS_SERIF } from '../../../../shared/foreground/models';
import { setTheme } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import {
  setFont,
  setFontSize,
  setLineSpacing,
  setReaderHorizontalMargin,
} from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import { toggleAppearancePanelOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { DisplayTheme, Font, ShortcutId, TshirtSize, TshirtSizeDisplayName } from '../../../../shared/types';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import CheckOffIcon from '../icons/20StrokeCheckboxOff';
import CheckOnIcon from '../icons/20StrokeCheckboxOn';
import MinusIcon from '../icons/20StrokeMinus';
import PlusIcon from '../icons/20StrokePlus';
import AppearanceIcon from '../icons/24StrokeAppearance';
import FontSizeIcon from '../icons/24StrokeFontSize';
import LineSpacingIcon from '../icons/24StrokeLineSpacing';
import LineWidthIcon from '../icons/24StrokeLineWidth';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import MoonIcon from '../icons/MoonIcon';
import SunAndMoonIcon from '../icons/SunAndMoonIcon';
import SunIcon from '../icons/SunIcon';
import Overlay from '../Overlay';
import Tooltip from '../Tooltip';
import styles from './AppearancePanel.module.css';

export enum SubPanel {
  Normal = 'Normal',
  Typeface = 'Typeface',
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
export default function AppearancePanel({ className = '', showTitle = true, isPDFView = false }: {className?: string; showTitle?: boolean; isPDFView?: boolean;}): JSX.Element {
  const [subPanel, setSubPanel] = useState(SubPanel.Normal);

  // Dinamically load the CSS file that loads all the fonts options
  useEffect(() => {
    import('../../../../shared/fonts/FontOptions.css');
  }, []);

  return (
    <>
      <div className={`${styles.panel} ${className}`}>
        {subPanel === SubPanel.Normal && <>
            {showTitle && <h4 className={styles.normalPanelTitle}>Appearance</h4>}
            <Theme />
            <div>
              <h5 className={styles.label}>Text styles</h5>
              <div className={`${styles.settingsWrapper} ${isPDFView ? styles.isPdfView : ''}`}>
                <Typeface setSubPanel={setSubPanel} />
                <FontSize />
                <LineSpacing />
                <LineLength />
              </div>
            </div>
          </>
         }

         {subPanel === SubPanel.Typeface && <TypefaceSubpanel setSubPanel={setSubPanel} />}
      </div>
      <Overlay onClick={() => toggleAppearancePanelOpen({ userInteraction: 'click' })} />
    </>
  );
}

const Theme = () => {
  const currentTheme = globalState(useCallback((state) => state.client.theme, []));
  const isActiveTheme = (theme: DisplayTheme): boolean => theme === currentTheme;

  return (
    <div className={`${styles.themeSelectorWrapper}`}>
      <h5 className={styles.label}>System theme</h5>
      <div className={styles.themes}>
        <div className={`${styles.theme} ${styles.light} ${isActiveTheme(DisplayTheme.Default) ? styles.activeTheme : ''}`} onClick={() => setTheme(DisplayTheme.Default, { userInteraction: 'click' })}>
          <div className={styles.box}>
            <SunIcon />
          </div>
          Light
        </div>
        <div className={`${styles.theme} ${styles.dark} ${isActiveTheme(DisplayTheme.Dark) ? styles.activeTheme : ''}`} onClick={() => setTheme(DisplayTheme.Dark, { userInteraction: 'click' })}>
          <div className={styles.box}>
            <MoonIcon />
          </div>
          Dark
        </div>
        <div className={`${styles.theme} ${styles.auto} ${isActiveTheme(DisplayTheme.System) ? styles.activeTheme : ''}`} onClick={() => setTheme(DisplayTheme.System, { userInteraction: 'click' })}>
          <div className={styles.box}>
            <SunAndMoonIcon />
          </div>
          Auto
        </div>
      </div>
    </div>
  );
};

const Typeface = ({ setSubPanel }: {setSubPanel: React.Dispatch<React.SetStateAction<SubPanel>>;}) => {
  const currentFont = globalState(useCallback((state) => state.client.readerSettings.desktop.font, []));

  return (
    <div className={styles.sizePickerWrapper}>
      <div className={styles.typeface} onClick={() => setSubPanel(SubPanel.Typeface)}>
        <div className={styles.left}>
          <AppearanceIcon />
          <span className={styles.fontLabel}>Typeface</span>
        </div>
        <div className={styles.right}>
          <span className={styles.currentFont}>{currentFont}</span>
          <ChevronRightIcon />
        </div>
      </div>
    </div>
  );
};

const FontItem = ({ name, onClick, isActive, className = '' }: {name: string; onClick: () => void; isActive: boolean; className?: string;}) => {
  return (
    <div className={`${styles.fontWrapper} ${className}`} onClick={onClick}>
      <span className={styles.fontName} style={{ fontFamily: WEB_CSS_FONTS[name] }}>{name}</span>
      {isActive ? <CheckOnIcon className={styles.checkOnIcon} /> : <CheckOffIcon className={styles.checkOffIcon} />}
    </div>
  );
};

const TypefaceSubpanel = ({ setSubPanel }: {setSubPanel: React.Dispatch<React.SetStateAction<SubPanel>>;}) => {
  const currentFont = globalState(useCallback((state) => state.client.readerSettings.desktop.font, []));

  const onFontPick = (font: string) => {
    setFont(font as Font, { userInteraction: 'click' });
  };

  return (
    <div className={styles.typefaceSubpanel}>
      <div className={styles.titleWrapper}>
        <div className={styles.backWrapper} onClick={() => setSubPanel(SubPanel.Normal)}>
          <ChevronRightIcon />
        </div>
        <h4 className={styles.typefaceSubpanelTitle}>Typeface</h4>
      </div>

      <div className={styles.fonts}>
        <h5 className={styles.subHeader}>Serif</h5>
        <div className={styles.fontsWrapper}>
          {WEB_FONTS_SERIF.map((font) =>
            <FontItem key={font} isActive={currentFont === font} name={font} onClick={() => onFontPick(font)} />)}
        </div>

        <h5 className={`${styles.subHeader} ${styles.sansSerif}`}>Sans Serif</h5>
        <div className={styles.fontsWrapper}>
          {WEB_FONTS_SANS_SERIF.map((font) =>
            <FontItem key={font} isActive={currentFont === font} name={font} onClick={() => onFontPick(font)} />)}
        </div>
      </div>
    </div>
  );
};

const FontSize = () => {
  const shortcutsMap = useShortcutsMap();
  const sizes = Object.keys(FontSizeNumberDesktop);
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.fontSize, []));

  const onChange = (newSize: TshirtSize) => {
    setFontSize(newSize, { userInteraction: 'click' });
  };

  return (
    <div className={styles.sizePickerWrapper}>
      <SizePicker
        sizes={sizes}
        label="Font size"
        onChange={onChange}
        currentSize={currentSize}
        displayValue={`${FontSizeNumberDesktop[currentSize]}px`}
        icon={<FontSizeIcon />}
        leftShortcut={shortcutsMap[ShortcutId.DecreaseFontSize]}
        leftTooltip="Decrease font size"
        rightShortcut={shortcutsMap[ShortcutId.IncreaseFontSize]}
        rightTooltip="Increase font size"
      />
    </div>
  );
};

const LineLength = () => {
  const shortcutsMap = useShortcutsMap();
  const sizes = Object.keys(LineLengthNumberDesktop);
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.lineLength, []));

  const onChange = (newSize: TshirtSize) => {
    setReaderHorizontalMargin(newSize, { userInteraction: 'click' });
  };

  return (
    <div className={styles.sizePickerWrapper}>
      <SizePicker
        sizes={sizes}
        label="Line width"
        onChange={onChange}
        displayValue={`${TshirtSizeDisplayName[currentSize]}`}
        currentSize={currentSize}
        icon={<LineWidthIcon />}
        leftShortcut={shortcutsMap[ShortcutId.NarrowLineLength]}
        leftTooltip="Narrow line length"
        rightShortcut={shortcutsMap[ShortcutId.WidenLineLength]}
        rightTooltip="Widen line length"
      />
    </div>
  );
};

const LineSpacing = () => {
  const shortcutsMap = useShortcutsMap();
  const sizes = Object.keys(LineHeightNumberDesktop);
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.lineHeight, []));

  const onChange = (newSize: TshirtSize) => {
    setLineSpacing(newSize, { userInteraction: 'click' });
  };

  return (
    <div className={styles.sizePickerWrapper}>
      <SizePicker
        sizes={sizes}
        label="Line spacing"
        onChange={onChange}
        displayValue={`${LineHeightNumberDesktop[currentSize]}`}
        currentSize={currentSize}
        icon={<LineSpacingIcon />}
        leftShortcut={shortcutsMap[ShortcutId.DecreaseLineSpacing]}
        leftTooltip="Decrease line spacing"
        rightShortcut={shortcutsMap[ShortcutId.IncreaseLineSpacing]}
        rightTooltip="Increase line spacing"
      />
    </div>
  );
};

const SizePicker = ({ sizes, label, displayValue, currentSize, icon, onChange, leftTooltip, leftShortcut, rightTooltip, rightShortcut, className = '' }: {label: string; displayValue: string; sizes: string[]; currentSize: string; icon: JSX.Element; onChange: (arg0: TshirtSize) => void; leftTooltip: string; leftShortcut: string | string[]; rightTooltip: string; rightShortcut: string | string[]; className?: string;}) => {
  const decreaseSize = () => {
    const prevSize = getNextTshirtSizeFromKeys({ keys: sizes, currentSize: currentSize as TshirtSize, direction: -1 });
    onChange(prevSize);
  };

  const increaseSize = () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: sizes, currentSize: currentSize as TshirtSize });
    onChange(nextSize);
  };

  return (
    <div className={`${styles.sizePicker} ${className}`}>
      <div className={styles.left}>
        {icon}
        <span className={styles.sizeLabel}>{label}</span>
      </div>
      <div className={styles.right}>
        <span className={styles.displayValue}>{displayValue}</span>
        <div className={styles.buttonsWrapper}>
          <Tooltip content={leftTooltip} shortcut={leftShortcut}>
            <Button onClick={decreaseSize}>
              <MinusIcon />
            </Button>
          </Tooltip>
          <Tooltip content={rightTooltip} shortcut={rightShortcut}>
            <Button onClick={increaseSize}>
              <PlusIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
/* eslint-enable jsx-a11y/click-events-have-key-events */

